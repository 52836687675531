














import { Component, Prop, Vue } from "vue-property-decorator";
import ActionTile from "@/components/common/ActionTile.vue";

@Component({
  components: {
    ActionTile,
  },
})
export default class EmptyDayplanningTile extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  emitClick(): void {
    this.$emit("click");
  }
}
