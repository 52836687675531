



























































































import { Component, Prop, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import DossierDetails from "@/components/dossiers/details/DossierDetails.vue";
import Loader from "@/components/common/Loader.vue";
import DossierDto from "@/contracts/dossier/DossierDto";
import { dossierService } from "@/services/dossier.service";
import { i18n } from "@/i18n";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { Routes } from "@/router";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { documentMapper } from "@/mappers/document.mapper";
import { AxiosPromise } from "axios";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import CreateDayPlanningDto from "@/contracts/dayplanning/CreateDayPlanningDto";
import UpdateDayPlanningDto from "@/contracts/dayplanning/UpdateDayPlanningDto";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import LotDto from "@/contracts/lot/LotDto";
import CheckInDialog from "@/components/lots/dayplanning/CheckInDialog.vue";
import CheckOutDialog from "@/components/lots/dayplanning/checkout/CheckOutDialog.vue";

@Component({
  components: {
    DossierDetails,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    Loader,
    ConfirmationDialog,
    CheckInDialog,
    CheckOutDialog,
  },
})
export default class DossierDetailsView extends Vue {
  @Prop({ default: null })
  id!: string;

  lots: LotDto[] = [];
  loading = false;
  submitting = false;
  editable = false;
  canRequestConformity = false;
  canEditDossier = false;
  showDeleteDialog = false;
  showRequestConformityDialog = false;
  loadingDayPlanning = false;
  showCheckInDialogValue = false;
  showCheckOutDialogValue = false;
  submittingCheckIn = false;
  submittingCheckOut = false;
  checkOutSubmitted = false;
  submittingReport = false;

  dossier = new DossierDto();
  dossierCopy = new DossierDto();
  lotIds: string[] | null = null;
  mostRecentDayPlanningEntry: DayPlanningHistoryEntryDto | null = null;

  get planningDocumentsToUpload(): CreateDocumentDto[] {
    return this.$store.state.dossiers.planningDocumentsToUpload;
  }

  get diagnosisDocumentsToUpload(): CreateDocumentDto[] {
    return this.$store.state.dossiers.diagnosisDocumentsToUpload;
  }

  get amountOfLots(): number {
    return this.lots.length;
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    this.fetchDossier();
    this.checkIfCanRequestConformity();
    this.checkIfCanEditDossier();
    this.fetchMostRecentDayplanningEntry();
  }

  fetchDossier(): void {
    this.loading = true;

    dossierService
      .getDossier(this.id)
      .then((data) => {
        if (data) {
          this.dossier = JSON.parse(JSON.stringify(data));
          this.fetchLots();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  fetchLots(): void {
    this.loading = true;
    this.dossier.lots.forEach((lot) => {
      if (lot.id != null) {
        dossierService
          .getLot(this.id, lot.id)
          .then((data) => {
            if (data) {
              this.lots.push(data);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => (this.loading = false));
      }
    });
  }

  checkIfCanRequestConformity(): void {
    dossierService
      .canRequestConformity(this.id)
      .then((canRequestConformity) => {
        this.canRequestConformity = canRequestConformity;
      });
  }

  checkIfCanEditDossier(): void {
    dossierService.canEditDossier(this.id).then((canEditDossier) => {
      this.canEditDossier = canEditDossier;
    });
  }

  get userIsAuthorizedToEditLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToEditLot();
  }

  get userIsAuthorizedToDeleteLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToDeleteLot();
  }

  get userIsAuthorizedToCheckInLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCheckInLot();
  }

  get userIsAuthorizedToCheckOutLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCheckOutLot();
  }

  showCheckInDialog(): void {
    this.showCheckInDialogValue = true;
  }

  submitCheckIn(checkInData: CreateDayPlanningDto): void {
    this.submittingCheckIn = true;
    dossierService
      .createDayPlanning(this.id, checkInData)
      .then(() => {
        Vue.$toast.success(i18n.t("dayplanning.check-in-successful"));
        this.showCheckInDialogValue = false;
        this.loadData();
      })
      .catch(() => Vue.$toast.error(i18n.t("dayplanning.check-in-failed")))
      .finally(() => {
        this.submittingCheckIn = false;
      });
  }

  closeCheckInDialog(): void {
    this.showCheckInDialogValue = false;
  }

  showCheckOutDialog(): void {
    this.showCheckOutDialogValue = true;
  }

  submitCheckOut(checkOutData: UpdateDayPlanningDto): void {
    this.submittingCheckOut = true;
    dossierService
      .updateDayPlanning(this.id, checkOutData)
      .then(() => {
        Vue.$toast.success(i18n.t("dayplanning.check-out-successful"));
        this.showCheckOutDialogValue = true;
        this.checkOutSubmitted = true;
      })
      .catch(() => Vue.$toast.error(i18n.t("dayplanning.check-out-failed")))
      .finally(() => {
        this.lotIds = checkOutData.checkOutLotIds;
        this.submittingCheckOut = false;
      });
  }

  submitReport(report: SelfMonitoringReportDto): void {
    if (report) {
      this.submittingReport = true;
      let formData =
        documentMapper.mapSelfMonitoringReportDtoToFormData(report);

      Promise.resolve(
        this.lotIds?.forEach((lotId) => {
          dossierService.addSelfMonitoringReport(this.id, lotId, formData);
        })
      )
        .then(() => {
          Vue.$toast.success(
            i18n.t("self-monitoring-reports.submit-successful")
          );
          this.loadData();
          this.closeCheckOutDialog();
        })
        .catch(() =>
          Vue.$toast.error(i18n.t("self-monitoring-reports.submit-failed"))
        )
        .finally(() => (this.submittingReport = false));
    }
  }

  skipReport(): void {
    this.loadData();
    this.closeCheckOutDialog();
  }

  closeCheckOutDialog(): void {
    this.checkOutSubmitted = false;
    this.showCheckOutDialogValue = false;
  }

  restoreDossier(): void {
    this.dossier = JSON.parse(JSON.stringify(this.dossierCopy));
    this.dossierCopy = new DossierDto();
  }

  cancel(): void {
    this.editable = false;
    if (this.dossier) {
      this.$root.$emit(
        "editDossierCancelled",
        this.dossier.activityDomains.activity
      );
    }
    this.restoreDossier();
  }

  submit(): void {
    this.submitting = true;
    if (this.dossier) {
      dossierService
        .updateDossier(this.dossier)
        .then(() => {
          this.fetchDossier();
          if (
            (this.planningDocumentsToUpload &&
              this.planningDocumentsToUpload.length) ||
            (this.diagnosisDocumentsToUpload &&
              this.diagnosisDocumentsToUpload.length)
          ) {
            const promises: AxiosPromise<void>[] = [];

            if (
              this.planningDocumentsToUpload &&
              this.planningDocumentsToUpload.length
            ) {
              this.planningDocumentsToUpload.forEach((doc) => {
                const formData =
                  documentMapper.mapCreateDocumentDtoToFormData(doc);
                if (this.dossier?.id) {
                  promises.push(
                    dossierService.addDocument(this.dossier.id, formData)
                  );
                }
              });
            }

            if (
              this.diagnosisDocumentsToUpload &&
              this.diagnosisDocumentsToUpload.length
            ) {
              this.diagnosisDocumentsToUpload.forEach((doc) => {
                const formData =
                  documentMapper.mapCreateDocumentDtoToFormData(doc);
                if (this.dossier?.id) {
                  promises.push(
                    dossierService.addDocument(this.dossier.id, formData)
                  );
                }
              });
            }

            return Promise.all(promises)
              .catch(() => {
                Vue.$toast.error(i18n.t("my-dossiers.document-upload-failed"));
              })
              .finally(() => {
                this.$store.commit("setAmountOfPlanningDocumentsToUpload", 0);
                this.$store.commit("setPlanningDocumentsToUpload", []);
                this.$store.commit("setAmountOfDiagnosisDocumentsToUpload", 0);
                this.$store.commit("setDiagnosisDocumentsToUpload", []);
              });
          }
          return Promise.resolve();
        })
        .then(() => {
          Vue.$toast.success(i18n.t("my-dossiers.dossier-updated"));
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("my-dossiers.dossier-update-failed"));
          this.restoreDossier();
        })
        .finally(() => {
          this.submitting = false;
          this.editable = false;
        });
    }
  }

  makeEditable(): void {
    this.dossierCopy = JSON.parse(JSON.stringify(this.dossier));
    this.editable = true;
  }

  showConfirmDeleteDialog(): void {
    this.showDeleteDialog = true;
  }

  closeConfirmDeleteDialog(): void {
    this.showDeleteDialog = false;
  }

  showConfirmRequestConformityDialog(): void {
    this.showRequestConformityDialog = true;
  }

  closeConfirmRequestConformityDialog(): void {
    this.showRequestConformityDialog = false;
  }

  goToMyDossiers(): void {
    this.$router.push({ name: Routes.MyDossiers });
  }

  deleteDossier(): Promise<unknown> {
    if (this.dossier && this.dossier.id) {
      this.submitting = true;
      return dossierService
        .deleteDossier(this.dossier.id)
        .then(() => {
          this.$router.push({ name: Routes.MyDossiers });
        })
        .finally(() => {
          this.submitting = false;
        });
    } else {
      return Promise.resolve();
    }
  }

  requestConformity(): Promise<unknown> {
    if (this.dossier && this.dossier.id) {
      this.submitting = true;
      return dossierService.requestConformity(this.dossier.id).finally(() => {
        this.checkIfCanRequestConformity();
        this.submitting = false;
      });
    } else {
      return Promise.resolve();
    }
  }

  fetchMostRecentDayplanningEntry(): void {
    this.loadingDayPlanning = true;
    dossierService
      .getDayPlanningLatestDossier(this.id)
      .then((data) => (this.mostRecentDayPlanningEntry = data))
      .catch((error) => console.error(error))
      .finally(() => (this.loadingDayPlanning = false));
  }
}
