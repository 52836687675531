import Vue from "vue";
import Vuex from "vuex";
import UserModule from "@/store/modules/user.module";
import AppConfigModule from "@/store/modules/app-config.module";
import DossiersModule from "@/store/modules/dossiers.module";
import UserDossierAccessModule from "@/store/modules/user-dossier-access.module";

import createPersistedState from "vuex-persistedstate";
import ExtensionModule from "@/store/modules/extension.module";
import FaqModule from "@/store/modules/faq.module";
import ActivityModule from "@/app/store/activity.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user: UserModule,
    userDossierAccess: UserDossierAccessModule,
    appConfig: AppConfigModule,
    dossiers: DossiersModule,
    extensions: ExtensionModule,
    faq: FaqModule,
    activity: ActivityModule,
  },
  plugins: [
    createPersistedState({
      paths: ["userDossierAccess"],
    }),
  ],
});
