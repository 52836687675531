import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.exchangeDefinition && _vm.loaded)?_c('div',[(_vm.isAdmin)?_c('div',{staticClass:"d-flex justify-end",staticStyle:{"margin-top":"-60px"}},[(!_vm.adminMode)?_c('div',{staticClass:"admintoggle admintoggle_activate",on:{"click":function($event){return _vm.toggleAdminMode()}}},[_c(VIcon,{staticClass:"ms-2 my-0 py-2",attrs:{"right":""}},[_vm._v(" mdi-tooltip-edit-outline ")]),_c('p',{staticClass:"mx-2 my-0 py-2"},[_vm._v(_vm._s(_vm.$t('admin-mode.toggleon')))])],1):_vm._e(),(_vm.adminMode)?_c('div',{staticClass:"admintoggle admintoggle_deactivate",on:{"click":function($event){return _vm.toggleAdminMode()}}},[_c(VIcon,{staticClass:"ms-2 my-0 py-2",attrs:{"right":""}},[_vm._v(" mdi-tooltip-check-outline ")]),_c('p',{staticClass:"mx-2 my-0 py-2"},[_vm._v(_vm._s(_vm.$t('admin-mode.toggleoff')))])],1):_vm._e()]):_vm._e(),_c(VStepper,{model:{value:(_vm.stepPosition),callback:function ($$v) {_vm.stepPosition=$$v},expression:"stepPosition"}},[_c(VStepperHeader,_vm._l((_vm.exchangeDefinition.steps),function(step,index){return _c(VStepperStep,{key:step.id,class:{
          'step-header-step__active': _vm.stepPosition == index + 1,
          'header-step__inprogress':
            _vm.exchange && _vm.exchange.currentStep === step.id,
        },attrs:{"complete":_vm.inProgressPosition > index + 1 ||
          (_vm.exchange && _vm.exchange.state === 'COMPLETED'),"editable":true,"edit-icon":'mdi-check',"step":index + 1}},[_c('p',{staticClass:"step-header",class:{
            'step-header__active': _vm.stepPosition == index + 1,
            header__inprogress: _vm.exchange && _vm.exchange.currentStep === step.id,
          }},[_vm._v(" "+_vm._s(_vm.$t(step.name))+" ")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.involvedPartyNames[step.involvedParty] || "Unknown"))])])}),1),_c(VStepperItems,_vm._l((_vm.exchangeDefinition.steps),function(step,index){return _c(VStepperContent,{key:step.id,attrs:{"step":index + 1}},[(_vm.exchangeDefinition.hideTitle !== true && _vm.$tc(step.title) !== '')?_c('h3',{staticClass:"primary--text mb-8"},[_vm._v(" "+_vm._s(_vm.$t(step.title))+" ")]):_vm._e(),( !_vm.adminMode &&
            _vm.exchangeDefinition.type === 'STEPPER' &&
            _vm.stepPosition > _vm.inProgressPosition
          )?_c('div',[_c('div',{staticClass:"mt-8 mb-8 d-flex justify-center"},[_c('div',[_vm._v(_vm._s(_vm.$t("general.complete_previous_step")))])])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.adminMode && _vm.stepPosition >=_vm.inProgressPosition && _vm.exchangeDefinition.type === 'FREEFILL' && _vm.isFreeFormStepBlocking()),expression:"!adminMode && stepPosition >=inProgressPosition && exchangeDefinition.type === 'FREEFILL' && isFreeFormStepBlocking()"}]},[_c('div',{staticClass:"mt-8 mb-1 d-flex justify-center"},[_c('div',[_vm._v(_vm._s(((_vm.$t("exchanges.blocked_by_step")) + ": " + (_vm.exchangeDefinition.steps[_vm.isBlockingStepPosition].name))))])])]),(
            !_vm.adminMode && _vm.stepPosition >= _vm.inProgressPosition &&
            _vm.externalFormContext[step.id].data['canEdit'] === 'readonly' &&
            !_vm.isFreeFormStepBlocking()
          )?_c('div',[_c('div',{staticClass:"mt-8 mb-1 d-flex justify-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("exchanges.filled_in_by") + " " + _vm.involvedPartyNames[step.involvedParty])+" ")])]),_c('div',{staticClass:"mt-1 mb-8 d-flex justify-center"})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            (_vm.stepPosition < _vm.inProgressPosition ||
            (_vm.stepPosition === _vm.inProgressPosition &&
              _vm.externalFormContext[step.id].data['canEdit'] === 'editable') ||
            (_vm.stepPosition > _vm.inProgressPosition &&
              _vm.exchangeDefinition.type === 'FREEFILL' &&
              _vm.externalFormContext[step.id].data['canEdit'] === 'editable'))
          ),expression:"\n            (stepPosition < inProgressPosition ||\n            (stepPosition === inProgressPosition &&\n              externalFormContext[step.id].data['canEdit'] === 'editable') ||\n            (stepPosition > inProgressPosition &&\n              exchangeDefinition.type === 'FREEFILL' &&\n              externalFormContext[step.id].data['canEdit'] === 'editable'))\n          "}]},[_c('FormWrapper',{directives:[{name:"show",rawName:"v-show",value:((_vm.adminMode || !_vm.readonly || _vm.stepPosition < _vm.inProgressPosition) && !_vm.isFreeFormStepBlocking()),expression:"(adminMode || !readonly || stepPosition < inProgressPosition) && !isFreeFormStepBlocking()"}],attrs:{"form-definition":step.form,"external-context":_vm.externalFormContext[step.id]},on:{"dynamicForm":function($event){return _vm.recieveForm($event, step)},"formDataChanged":function($event){return _vm.onFormDataChanged($event, step)}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.adminMode && _vm.readonly && (
            (_vm.stepPosition === _vm.inProgressPosition && _vm.externalFormContext[step.id].data['canEdit'] === 'editable') ||
            (_vm.stepPosition > _vm.inProgressPosition && _vm.exchangeDefinition.type === 'FREEFILL' && _vm.externalFormContext[step.id].data['canEdit'] === 'editable')
            )),expression:"!adminMode && readonly && (\n            (stepPosition === inProgressPosition && externalFormContext[step.id].data['canEdit'] === 'editable') ||\n            (stepPosition > inProgressPosition && exchangeDefinition.type === 'FREEFILL' && externalFormContext[step.id].data['canEdit'] === 'editable')\n            )"}]},[_c('div',{staticClass:"mt-8 mb-1 d-flex justify-center"},[_c('div',[_vm._v(_vm._s(_vm.$t("exchanges.readonly")))])])]),(
              _vm.exchange &&
              index < _vm.inProgressPosition - 1 &&
              _vm.exchange.steps[index] &&
              _vm.exchange.steps[index].state === 'COMPLETED'
            )?_c('div',{staticClass:"float-end primary--text font-italic"},[_vm._v(" "+_vm._s(_vm.$t("exchanges.completed_on"))+" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.exchange.steps[index].lastModifiedOn),"DD/MM/YYYY HH:mm"))+" ")]):_vm._e(),(
              !_vm.readonly &&
              _vm.exchange &&
              index > _vm.inProgressPosition - 1 &&
              _vm.exchange.steps[index] &&
              _vm.exchange.steps[index].state === 'TODO' &&
              !_vm.isFreeFormStepBlocking()
            )?_c('div',{staticClass:"float-end"},[_vm._v(" "+_vm._s(_vm.$t("exchanges.cannot-complete-step"))+" ")]):_vm._e()],1)])}),1)],1),_c('div',{staticClass:"d-flex justify-end align-end mt-8"},[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")]),(
        !_vm.adminMode &&
        !_vm.readonly &&
        _vm.exchange &&
        _vm.exchange.state !== 'COMPLETED' &&
        _vm.inProgressPosition > 1 &&
        _vm.stepPosition == _vm.inProgressPosition - 1 &&
        _vm.isCurrentStepParty()
      )?_c(VBtn,{staticClass:"ms-2",attrs:{"color":"secondary","loading":_vm.isSaving},on:{"click":_vm.rejectPrevStep}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.reset_step" || "general.reject")))])]):_vm._e(),(_vm.adminMode)?_c(VBtn,{staticClass:"ms-2",attrs:{"color":"secondary","loading":_vm.isSaving},on:{"click":function($event){return _vm.patchStep(_vm.stepPosition)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save_step_admin")))])]):_vm._e(),(
        !_vm.adminMode &&
        _vm.stepPosition < _vm.inProgressPosition &&
        _vm.stepPosition < _vm.exchangeDefinition.steps.length
      )?_c(VBtn,{staticClass:"ms-2",attrs:{"color":"primary"},on:{"click":_vm.viewNextStep}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.show_next")))])]):_vm._e(),( !_vm.adminMode &&
        !_vm.readonly &&
        _vm.externalFormContext &&
        _vm.exchange &&
        _vm.exchangeDefinition.steps[_vm.stepPosition - 1] &&
        _vm.externalFormContext[_vm.exchangeDefinition.steps[_vm.stepPosition - 1].id]
          .data['canEdit'] === 'editable' &&
          !_vm.isFreeFormStepBlocking()
      )?_c(VBtn,{staticClass:"ms-2",attrs:{"color":"secondary","loading":_vm.isSaving},on:{"click":function($event){return _vm.patchStep(_vm.stepPosition)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.save_step")))])]):_vm._e(),(
        (_vm.adminMode && _vm.stepPosition === _vm.inProgressPosition) || (
        !_vm.readonly &&
        _vm.stepPosition === _vm.inProgressPosition &&
        _vm.externalFormContext &&
        _vm.exchange &&
        _vm.externalFormContext[_vm.exchange.currentStep] &&
        _vm.externalFormContext[_vm.exchange.currentStep].data['canEdit'] ===
          'editable'
      ))?_c(VBtn,{staticClass:"ms-2",attrs:{"color":"primary","disabled":_vm.forms[_vm.stepPosition],"loading":_vm.isSaving},on:{"click":_vm.completeStep}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.complete_step" || "general.submit")))])]):_vm._e()],1)],1):_c('div',[_c(VSkeletonLoader,_vm._b({attrs:{"type":"table-thead, divider, card-heading, list-item, list-item-two-line, list-item, list-item-three-line, list-item, divider, actions","min-width":1100,"min-height":500}},'v-skeleton-loader',_vm.attrs,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }