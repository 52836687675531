









import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { organisationService } from "@/app/services/organisation.service";
import Loader from "@/components/common/Loader.vue";
import UserInfo from "@/contracts/generic/UserInfo";
import { userServiceV2 } from "@/app/services/user.service";

@Component({
  components: { Loader },
})
export default class OverviewCellOrganisationAndPersons extends OverviewCellBase {
  protected loadingOrg = true;
  protected loadingPersons = true;

  protected defaultContact: UserInfo = {
    id: "00000000-0000-0000-0000-000000000000",
    email: "",
    firstName: "",
    lastName: "",
    fullName: this.$tc("general.fetch_failed"),
    aannemer: "",
    language: "",
  };

  contacts: UserInfo[] = [];
  org = "...";

  protected PostResolveValue() {
    if (this.value || this.value === "$nan") {
      if (this.value && this.value.org && this.value.org.length > 0) {
        this.loadingOrg = true;
        organisationService
          .getOrganisationsByIds(Array.isArray(this.value.org) ? this.value.org : [this.value.org])
          .then((orgList) => {
            if (!orgList || orgList.length <= 0) {
              this.org = this.$tc("general.unknown");
              this.loadingOrg = false;
              return;
            }
            this.org = orgList[0].name;
            this.loadingOrg = false;
          })
          .catch((reason) => {
            this.org =
              (this.value?.org as string) || this.$tc("general.fetch_failed");
            this.loadingOrg = false;
            console.warn("Failed to fetch org");
          });
      } else {
        this.org = (this.value?.org as string) || this.$tc("general.unknown");
        this.loadingOrg = false;
      }
      if (this.value && this.value.persons && this.value.persons.length > 0) {
        this.loadingPersons = true;
        userServiceV2
          .getUsersByIds(Array.isArray(this.value.persons) ? this.value.persons : [this.value.persons])
          .then((personList) => {
            if (!personList || personList.length <= 0) {
              this.contacts = [];
              this.loadingPersons = false;
              return;
            }

            personList = personList.map((person) => !person ? this.defaultContact : person);
            this.contacts = personList;
            this.loadingPersons = false;
          })
          .catch((reason) => {
            this.contacts = [this.defaultContact];
            this.loadingPersons = false;
            console.warn("Failed to fetch users");
          });
      } else {
        this.contacts = [];
        this.loadingPersons = false;
      }
    } else {
      this.org = this.$tc("general.unknown");
      this.contacts = [];
      this.loadingOrg = false;
      this.loadingPersons = false;
    }
  }
}
