




















import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TextField from "@/components/common/TextField.vue";

export type MulticheckinSelectorMetaData = {
  unk: string;
};

@Component({
  components: { TextField },
})
export default class FormComponentMulticheckinSelector extends BaseDynamicComponent<MulticheckinSelectorMetaData> {
  mounted(): void {
    return;
  }
}
