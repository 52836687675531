








import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { validationRules } from "@/constants/ValidationRules";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TextField from "@/components/common/TextField.vue";

export type HeaderMetaData = unknown;

@Component({
  components: {},
})
export default class FormComponentText extends BaseDynamicComponent<any> {}
