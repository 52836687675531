




























































































































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { documentService } from "@/services/document.service";
import ReferenceData from "@/contracts/generic/ReferenceData";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { activityService } from "@/services/activity.service";
import AeTextField from "@/components/common/TextField.vue";
import AeFileInput from "@/components/common/FileInput.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import AeTextarea from "@/components/common/Textarea.vue";
import { documentTypes } from "@/constants/DocumentTypes";
import AeDatePicker from "@/components/common/DatePicker.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import OrganizationDto from "@/contracts/generic/OrganizationDto";
import { organizationService } from "@/services/organization.service";
import AllowedExtensionDto from "@/contracts/extension/AllowedExtensionsDto";
import { i18n } from "@/i18n";
import ExecutorDto from "@/contracts/organization/ExecutorDto";
import ContactDto from "@/contracts/generic/ContactDto";
import { userService } from "@/services/user.service";

@Component({
  components: {
    AeFileInput,
    AeAutocomplete,
    AeDatePicker,
    AeTextarea,
    AeTextField,
  },
})
export default class UploadDocumentForm extends Vue {
  @PropSync("document", { type: Object }) documentSync!:
    | CreateDocumentDto
    | SelfMonitoringReportDto;

  @Prop({ default: null })
  files!: File[];

  @Prop({ default: null })
  documentType!: string;

  @Prop({ default: null })
  dossierActivity!: string;

  @Prop({ default: null })
  prefilledApplicableDate!: Date;

  loading = false;

  acceptedFiles = this.$store.state.extensions.acceptedExtensions;

  selfMonitoringDocumentType = documentTypes.SELFMONITORING;

  datePickerDisabled = false;

  organizations: OrganizationDto[] = [];
  categories: ReferenceData[] = [];
  types: ReferenceData[] = [];
  activitiesData: ReferenceData[] = [];
  eligibleContacts: ContactDto[] = [];

  fileErrors: string[] = [];
  errorCount = 0;

  get activities(): ReferenceData[] {
    if (this.dossierActivity) {
      const activity = this.activitiesData.find(
        (activity: ReferenceData) => activity.id == this.dossierActivity
      );

      return activity ? [activity] : [];
    }
    return this.activitiesData;
  }

  get userIsAuthorizedToAddOrganizationToDocument(): boolean {
    return roleBasedActionsService.userIsAuthorizedToAddOrganizationToDocument();
  }

  get userIsAuthorizedToGetEligibleContacts(): boolean {
    return roleBasedActionsService.userIsAuthorizedToGetEligibleContacts();
  }

  mounted(): void {
    this.init();
  }

  /* Prop documentSync may be available when mounted: when it is initialized diretcly in the component that uses this form component.
  Sometimes documentSync may not be available when mounted: when it is initialized in the mounted method of the component that uses this form component
  That's why we do the init on mount but also when documentSync gets changed
   */
  @Watch("documentSync")
  init(): void {
    if (this.documentSync) {
      this.types = [];
      if (
        this.prefilledApplicableDate &&
        this.documentType == this.selfMonitoringDocumentType &&
        Object.prototype.hasOwnProperty.call(
          this.documentSync,
          "applicableDate"
        )
      ) {
        (this.documentSync as SelfMonitoringReportDto).applicableDate =
          this.prefilledApplicableDate;
        this.datePickerDisabled = true;
      }

      this.fetchCategories();
      this.fetchActivities();
      this.fetchOrganization();
      this.fetchEligibleContacts();

      if (this.files) {
        this.documentSync.files = this.files;
      }
    }
  }

  fetchCategories(): void {
    documentService.getCategories(this.documentType).then((data) => {
      if (data) {
        data.map((i) => (i.name = this.$t(i.translationKey) as string));
        this.categories = data;
      }
    });
  }

  fetchTypes(category: string): void {
    if (this.documentSync && category) {
      this.loading = true;
      documentService
        .getTypes(category, this.documentType)
        .then((data) => {
          if (data) {
            data.map((i) => (i.name = this.$t(i.translationKey) as string));
            this.types = data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.types = [];
    }
  }

  fetchActivities(): void {
    activityService.getActivities().then((data) => {
      if (data) {
        data.map((i) => (i.name = this.$t(i.translationKey) as string));
        this.activitiesData = data;
      }
    });
  }

  fetchOrganization(): void {
    if (this.userIsAuthorizedToAddOrganizationToDocument) {
      organizationService.getOrganizations().then((data) => {
        if (data) {
          this.organizations = data;
        }
      });
    }
  }

  fetchEligibleContacts() {
    if (this.userIsAuthorizedToGetEligibleContacts) {
      userService.getEligibleUsers().then((data) => {
        if (data) {
          this.eligibleContacts = data;
        }
      });
    }
  }

  checkExtensionsAllowed(): void {
    this.fileErrors = [];
    this.errorCount = 0;
    let filesAllowed;

    if (this.documentSync.activity) {
      const extensions: AllowedExtensionDto[] =
        this.$store.state.extensions.allowedExtensions;

      this.documentSync.files?.forEach((file) => {
        // credit goes to: https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript
        let fileExtension = `.${file.name.slice(
          ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
        )}`;

        let matchedFileExtension = extensions.find(
          (extension) =>
            extension.fileExtension.toLowerCase() ===
              fileExtension.toLowerCase() &&
            extension.contentType.toLowerCase() === file.type.toLowerCase() &&
            extension.activity === this.documentSync.activity
        );

        if (matchedFileExtension) {
          if (file.size > matchedFileExtension.fileSize) {
            this.fileErrors.push(
              `${file.name}: ${i18n.t("extension.file-size-too-big-one")} ${
                file.size
              }. ${i18n.t("extension.file-size-too-big-two")} ${
                matchedFileExtension.fileSize
              }`
            );
          }
        } else {
          this.fileErrors.push(
            `${file.name}: ${i18n.t("extension.type-extension-not-supported")}`
          );
        }
      });

      this.errorCount = this.fileErrors.length;

      filesAllowed =
        this.errorCount === 0 &&
        Array.isArray(this.documentSync.files) &&
        this.documentSync.files.length > 0;

      this.$emit("filesAllowedCheck", filesAllowed);
    }
  }
}
