
















import { Component, Prop, Vue } from "vue-property-decorator";
import { validationRules } from "@/constants/ValidationRules";

@Component
export default class AeSwitch extends Vue {
  @Prop({ default: false })
  value!: boolean;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  rules: unknown[] = [];

  mounted(): void {
    if (this.required) {
      this.rules.push(validationRules.required);
    }
  }

  update(newValue: boolean): void {
    this.$emit("input", newValue);
  }
}
