import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-header',{attrs:{"header-icon-name":"mdi-cards-diamond","back-button-title":"lot.to-dossier"},on:{"backButtonClicked":_vm.navigateToDossier},scopedSlots:_vm._u([{key:"pageTitle",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.lotSync.name))])]},proxy:true},{key:"content",fn:function(){return [_c(VRow,[_c(VCol,{staticClass:"col-md-4 col-lg-3 col-12"},[_c('h5',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("lot.title-check-in-out"))+" ")]),(!_vm.hasRoleInspector)?_c('lot-check-in-actions',{attrs:{"can-check-in":_vm.canCheckInLot,"can-check-out":_vm.canCheckOutLot,"loadingDayPlanning":_vm.loadingDayPlanning,"isCheckedIn":_vm.isCheckedIn,"mostRecentDayPlanningEntry":_vm.mostRecentDayPlanningEntry},on:{"checkIn":_vm.checkIn,"checkOut":_vm.checkOut}}):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between",class:{
            'flex-column': !_vm.$vuetify.breakpoint.xsOnly,
            'flex-row': _vm.$vuetify.breakpoint.xsOnly,
            'mt-6': _vm.$vuetify.breakpoint.xsOnly,
          }},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('div',[_c('h5',{staticClass:"mb-2 mt-6"},[_vm._v(" "+_vm._s(_vm.$t("lot.title-details"))+" ")]),_c('steps-overview',{attrs:{"steps":_vm.steps}})],1):_vm._e(),_c('div',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('h5',{staticClass:"mb-2 mt-6"},[_vm._v(" "+_vm._s(_vm.$t("lot.title-reports"))+" ")]):_vm._e(),_c('lot-reports-actions',{attrs:{"dossier-id":_vm.dossierId,"lot-id":_vm.lotSync.id}})],1),_c('div',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('h5',{staticClass:"mb-2 mt-6"},[_vm._v(" "+_vm._s(_vm.$t("lot.title-actions"))+" ")]):_vm._e(),(_vm.dossierId && _vm.lotSync)?_c('lot-actions',{attrs:{"lot":_vm.lotSync,"can-edit-lot":!_vm.editable && _vm.canEditLot,"can-delete-lot":_vm.canDeleteLot},on:{"editLot":_vm.editLot,"deleteLot":_vm.deleteLot}}):_vm._e()],1)])],1),_c(VCol,{staticClass:"col-md-8 col-lg-9 col-12"},[_c(VRow,[_c(VCol,[_c('steps-panel',{attrs:{"data":_vm.lot,"dossier-id":_vm.dossierId,"editable":_vm.editable,"steps":_vm.steps}})],1)],1),(_vm.editable)?_c(VRow,[_c(VCol,{staticClass:"text-end"},[_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.isSubmitDisabled,"loading":_vm.submitting,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("general.submit"))+" ")])],1)],1):_vm._e()],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }