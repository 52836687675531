



































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import DossierDto from "@/contracts/dossier/DossierDto";
import LotDto from "@/contracts/lot/LotDto";
import Step from "@/contracts/common/Step";
import ReferenceData from "@/contracts/generic/ReferenceData";

@Component
export default class StepsPanel extends Vue {
  @PropSync("data", { type: Object }) dataSync!: DossierDto | LotDto;

  @Prop({ default: null })
  dossier!: DossierDto;

  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: false })
  steps!: Step[];

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: false })
  canAddLot!: boolean;

  @Prop({ default: false })
  hasActivityDomain!: boolean;

  @Prop({ default: null })
  activity!: ReferenceData;

  expandedPanels: number[] = [];

  mounted(): void {
    this.expandPanel(0);
  }

  expandPanel(index: number): void {
    this.expandedPanels = [index];
  }

  addLot(): void {
    this.$emit("addLot");
  }
}
