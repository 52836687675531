




















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { validationRules } from "@/constants/ValidationRules";
import { ValidationError } from "@/app/dynamic-components/forms/form-validations/form-validator.base";

@Component({
  components: {}
})
export default class AeTimePicker extends Vue {
  @Prop({ default: [] })
  errors!: ValidationError[];
  @Prop({ default: [] })
  errorMessages!: string[];

  @Prop({ default: null })
  value!: Date;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: null })
  min!: string;

  @Prop({ default: null })
  max!: string;

  menu = false;
  timePicked: string | Date | null = null;

  mounted(): void {
    this.initializeTimePicked();
  }


  @Watch("value")
  private initializeTimePicked() {
    if (this.value) {
      //this.value is a string in ISO format
      const initialDate = new Date(this.value);
      this.timePicked =
        initialDate.getHours().toString().padStart(2, "0") +
        ":" +
        initialDate.getMinutes().toString().padStart(2, "0");
    } else {
      this.timePicked = null;
    }
  }

  pickerValueChanged(newValue: string): void {
    this.timePicked = newValue;

    console.warn('time picked', newValue);
    if (this.timePicked) {
      this.emitDate(this.timePicked);
    }
  }

  private emitDate(date: string): void {
    const [hours, minutes] = date.split(":");
    let datePicked = new Date();
    if (!this.value) {
      const today = new Date();
      datePicked = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        +hours,
        +minutes,
        0
      );
    } else {
      const initialDate = new Date(this.value);
      datePicked = new Date(
        initialDate.getFullYear(),
        initialDate.getMonth(),
        initialDate.getDate(),
        +hours,
        +minutes,
        0
      );
    }
    this.$emit("input", datePicked);
  }
}
