
































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import DossierDto from "@/contracts/dossier/DossierDto";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import { activityService } from "@/services/activity.service";
import ReferenceData from "@/contracts/generic/ReferenceData";
import ActivityDomainDto from "@/contracts/activity/ActivityDomainDto";
import Loader from "@/components/common/Loader.vue";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import DossierActivityDomainsTable from "@/app/dynamic-components/forms/form-components/components/activity-domain/DossierActivityDomainsTable.DynamicView.vue";

@Component({
  components: { DossierActivityDomainsTable, AeAutocomplete, Loader },
})
export default class DossierActivityDomainPanel extends BaseDynamicComponent<any> {
  // @PropSync("data", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: null })
  activity!: ReferenceData;

  loading = false;

  activityDomains: ActivityDomainDto[] = [];
  activities: ReferenceData[] = [];

  mounted(): void {
    this.fetchActivityDomains(this.activity.id);

    this.$root.$on("editDossierCancelled", (activityId: string) =>
      this.fetchActivityDomains(activityId)
    );
  }

  // changeActivity(newActivityId: string): void {
  //   if (this.dossierSync.activityDomains.activity != newActivityId) {
  //     this.dossierSync.activityDomains.activity = newActivityId;
  //     this.fetchActivityDomains(newActivityId);
  //
  //     if (newActivityId === null) {
  //       this.updateActivityDomains([]);
  //     }
  //   }
  // }

  fetchActivityDomains(activityId: string): void {
    if (activityId) {
      this.loading = true;

      activityService
        .getActivityDomainsOfActivity(activityId)
        .then((data) => {
          data.map((i) => {
            i.activityDomain = this.$t(i.translationKey) as string;
            i.activity = this.$t(i.activityTranslationKey) as string;
          });
          this.activityDomains = data;
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      this.activityDomains = [];
    }
  }

  updateActivityDomains(activityDomainIds: string[]): void {
    this.$emit("contentUpdated", {
      data: { activityDomains: activityDomainIds },
      valid: activityDomainIds.length > 0,
      id: this.entry.id,
    });

    // this.dossierSync.activityDomains.activityDomains = activityDomainIds;
  }
}
