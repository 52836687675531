

























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { documentService } from "@/services/document.service";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import DocumentFilterDto from "@/contracts/document/DocumentFilterDto";

@Component({
  components: { AeAutocomplete },
})
export default class DocumentsFilters extends Vue {
  @Prop({ default: true })
  filterByActivity!: boolean;

  @Prop({ default: true })
  filterByType!: boolean;

  @Prop({ default: true })
  filterByCategory!: boolean;

  @Prop({ default: false })
  filterByContractors!: boolean;

  @Prop({ default: null })
  documentType!: string;

  loading = false;

  selectedCategories: string[] = [];
  selectedTypes: string[] = [];
  selectedActivities: string[] = [];
  selectedUploadedBy: string[] = [];
  selectedOrganizations: string[] = [];

  filters: DocumentFilterDto | null = null;

  mounted(): void {
    this.fetchFilterData();
  }

  fetchFilterData(): void {
    this.loading = true;

    documentService
      .getDocumentFilters()
      .then((data) => {
        if (data) {
          this.filters = data;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  categoriesUpdated(categories: string[]): void {
    this.selectedCategories = categories;
    this.filterConfigUpdated();
  }

  typesUpdated(types: string[]): void {
    this.selectedTypes = types;
    this.filterConfigUpdated();
  }

  activitiesUpdated(activities: string[]): void {
    this.selectedActivities = activities;
    this.filterConfigUpdated();
  }

  uploadedByUpdated(uploadedBy: string[]): void {
    this.selectedUploadedBy = uploadedBy;
    this.filterConfigUpdated();
  }

  contractorsUpdated(contractors: string[]): void {
    this.selectedOrganizations = contractors;
    this.filterConfigUpdated();
  }

  private filterConfigUpdated(): void {
    const args = {
      categories: this.filterByCategory ? this.selectedCategories : undefined,
      types: this.filterByType ? this.selectedTypes : undefined,
      uploadedBy: this.selectedUploadedBy,
      activities: this.filterByActivity ? this.selectedActivities : undefined,
      organizations: this.selectedOrganizations,
    };

    this.$emit("filterConfigUpdated", args);
  }
}
