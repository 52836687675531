































import QualifiedEmployeeDto from "@/contracts/dayplanning/QualifiedEmployeeDto";
import { Component, Prop, Vue } from "vue-property-decorator";
import AeAvatar from "@/components/common/Avatar.vue";
import Loader from "@/components/common/Loader.vue";

@Component({ components: { AeAvatar, Loader } })
export default class QualifiedEmployeesList extends Vue {
  @Prop({ default: null })
  employees!: QualifiedEmployeeDto;

  @Prop({ default: false })
  showLabel!: boolean;

  @Prop({ default: false })
  showTableHeading!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "name" })
  nameProperty!: string;
}
