import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,{staticClass:"pa-0"},[_c(VList,[_c(VListItemGroup,_vm._l((_vm.steps),function(step){return _c(VListItem,{key:step.index,on:{"click":function($event){return _vm.scroll(step.id)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(("mdi-numeric-" + (step.index) + "-box")))])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.$t(step.title))}})],1),_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-play")])],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }