



































import { Vue, Component } from "vue-property-decorator";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";
import BaseSectionDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component-section.base";

export type FormLinearSectionMetaData = {
  direction: "vertical" | "horizontal";
};

@Component({
  computed: {
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class DumbRenderSection extends BaseSectionDynamicComponent<FormLinearSectionMetaData> {}
