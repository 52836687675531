




















import { Component, Prop, Vue } from "vue-property-decorator";
import { validationRules } from "@/constants/ValidationRules";

@Component
export default class AeTextField extends Vue {
  @Prop({ default: null })
  value!: unknown;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: null })
  hint!: string;

  @Prop({ default: "text" })
  type!: "text" | "password" | "email" | "tel" | "number" | "url" | "range";

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  rules: unknown[] = [];

  mounted(): void {
    if (this.required) {
      this.rules.push(validationRules.required);
    }

    if (this.type === "email") {
      this.rules.push(validationRules.email);
    } else if (this.type === "tel") {
      this.rules.push(validationRules.phone);
    }
  }

  update(newValue: string): void {
    if (this.type === "number") {
      this.$emit("input", Number.parseInt(newValue, 0));
    } else {
      this.$emit("input", newValue);
    }
  }

  validityChanged(isInvalid: boolean) {
    this.$emit("validityChanged", !isInvalid);
  }
}
