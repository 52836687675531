import PageConfig from "@/contracts/generic/PageConfig";
import axios from "@/plugins/axios";
import QuestionsDto from "@/contracts/faq/QuestionDto";
import ReleaseDto from "@/contracts/faq/ReleaseDto";
import store from "@/store";

class FaqService {
  public getQuestions(pageConfig: PageConfig): Promise<QuestionsDto> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/faq/questions?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}`;

    return axios.get(url).then((response) => {
      return {
        results: response?.data._embedded.results || [],
        currentPage: response?.data._page.number,
        pageCount: response?.data._page.totalPages,
        pageSize: response?.data._page.size,
        rowCount: response?.data._page.totalElements,
        firstRowOnPage: 0,
        lastRowOnPage: 0
      }
    });
  }

  public getReleases(pageConfig: PageConfig): Promise<ReleaseDto> {
    const url = `${store.state.appConfig.apiBaseUrl}api/faq/releases?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}&sortDescending=true`;

    return axios.get(url).then((response) => {
      return response?.data;
    });
  }

  public getFaqDocumentById(id: string, fileName: string): Promise<void> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/faq/document/${id}`, {
        responseType: "blob",
      })
      .then((response) => {
        if (response && response.data) {
          const fileURL = window.URL.createObjectURL(response.data);
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      });
  }
}

export const faqService = new FaqService();
