






































import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { validationRules } from "@/constants/ValidationRules";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TextField from "@/components/common/TextField.vue";
import { FormEvent } from "@/app/dynamic-components/forms/dynamic-form.model";

export type TextFieldMetaData = {
  label?: string;
  disabled?: boolean;
  type?: "text" | "password" | "email" | "tel" | "number" | "url" | "range";
  value?: string;
  subtext?: string;
};

@Component({
  components: { TextField },
})
export default class TextFieldBase extends BaseDynamicComponent<TextFieldMetaData> {
  mounted(): void {
    return;
  }

  onTextFieldContentChanged(newValue: string): void {
    if (this.entry.metadata.type === "number") {
      this.setValueView(Number.parseInt(newValue, 0));
    } else {
      this.setValueView(newValue);
    }
  }
}
