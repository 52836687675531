


























import { Component, Vue } from "vue-property-decorator";
import analyticsService from '@/services/analytics.service';

@Component
export default class CookieConsentMessage extends Vue {
  get cookiesAccepted(): boolean {
    return (
      localStorage.getItem("cookiesAccepted") &&
      JSON.parse(localStorage.getItem("cookiesAccepted") || "")
    );
  }

  acceptCookies(): void {
    localStorage.setItem("cookiesAccepted", true.toString());
    this.trackAcceptCookies();
  }

  trackAcceptCookies(): void {
    console.log('Cookie consent tracking method');
    analyticsService.sendEvent('User Engagement', 'accept_cookies', 'Cookies Policy', 1);
  }
}
