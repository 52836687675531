























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Routes } from "@/router";

@Component
export default class LotReportsActions extends Vue {
  @Prop({ default: null }) dossierId!: string;

  @Prop({ default: null }) lotId!: string;

  navigateToCheckInHistory(): void {
    this.$router.push({
      name: Routes.DayPlanningHistory,
      params: {
        dossierId: this.dossierId,
        lotId: this.lotId,
      },
    });
  }
  navigateToSelfMonitoringReports(): void {
    this.$router.push({
      name: Routes.SelfMonitoringReports,
      params: {
        dossierId: this.dossierId,
        lotId: this.lotId,
      },
    });
  }
}
