
















import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { dateFormat } from "@vuejs-community/vue-filter-date-format";

export type DateMetaData = {
  selector: string;
  showDate: boolean;
  showTime: boolean;
};

@Component({
  methods: { dateFormat },
})
export default class DetailComponentDate extends DetailComponentBase<DateMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (this.detailContext && this.entry) {
      this.value = this.detailContext.resolveDataPath(this.metadata.selector);
    }
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(this.metadata.selector);
    }
  }
}
