

































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { EventDictionary } from "@/app/events/event.dictionary";
import { DetailContext } from "@/app/contexts/detail.context";
import {
  DetailAction,
  DynamicDetailDefinition,
  DynamicDetailEntry
} from "@/app/dynamic-components/details/dynamic-detail.model";
import Loader from "@/components/common/Loader.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import router from "@/router";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { DetailComponentBase } from "@/app/dynamic-components/details/detail-component.base";
import store from "@/store";

@Component({
  components: { ExchangeComponent, PageHeader, Loader },
})
export default class DetailComponentDashboard extends DetailComponentBase {

  get attrs() {
    return {
      boilerplate: false,
    };
  }

  get dashboardTitle(){
    return this.$tc('pf.dashboard.name');
  }

  calculateWidth(slots: number){
    if(slots <= 0) slots = 1;

    if(this.windowWidth < 600) return '100%';
    if(this.windowWidth > 1600) return (slots * 16.6666) + '%';
    //return (slots * 33.3333) + '%';
    return (slots * 16.6666) + '%';
  }


  protected getDetailDefinition(definitionId: string): Promise<DynamicDetailDefinition> {
    return Promise.resolve({
      title: 'dashboard.title',
      subtitle: '',
      dialogs: {},
      actions: [],
      detailContextData: {},
      summary: null,
      children: [
        {
          id: 'link',
          type: 'WidgetText',
          name: 'dashboard.widget.intro.title',
          displayMode: '',
          displayModeRule: '',
          computedValue: '',
          metadata: {
            slots: 4,
            description: 'dashboard.widget.intro.description'
          },
          children: []
        },
        {
          id: 'link2',
          type: 'WidgetLink',
          name: 'dashboard.widget.dossier.title',
          displayMode: '',
          displayModeRule: '',
          computedValue: '',
          metadata: {
            slots: 2,
            link: '/my-dossiers',
            subtitle: 'dashboard.widget.dossier.subtitle',
            icon: '',
            image: 'my-dossiers.svg',
            imageHeightMax: '10vh'
          },
          children: []
        },
        {
          id: 'link2',
          type: 'WidgetComponentDossierAtgs',
          name: 'dashboard.widget.dossier.atgs',
          displayMode: '',
          displayModeRule: '',
          computedValue: '',
          metadata: {
            slots: 4
          },
          children: []
        },


        {
          id: 'linkd',
          type: 'WidgetLayoutVertical',
          name: '',
          displayMode: '',
          displayModeRule: '',
          computedValue: '',
          metadata: {
            slots: 2
          },
          children: [
            {
              id: 'link2',
              type: 'WidgetLink',
              name: 'dashboard.widget.faq.title',
              displayMode: '',
              displayModeRule: '',
              computedValue: '',
              metadata: {
                slots: 2,
                link: '/FAQ',
                subtitle: 'dashboard.widget.faq.subtitle',
                icon: '',
                imageHeightMax: '10vh',
                image: 'faq-illustration.svg'
              },
              children: []
            }
          ]
        }
      ]
    });
  }

  protected setDetailContext(data: any, detailDefinition: DynamicDetailDefinition | null) {
    this.detailContext.setData("activity", this.$store.state.activity.currentActivityDefinition);
    this.detailContext.setData("bccaId", store.state.appConfig.bccaId);
  }
}
