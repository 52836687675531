








































import { Component, Vue } from "vue-property-decorator";
import ActionTile from "@/components/common/ActionTile.vue";
import MyDocumentsTile from "@/components/documents/my-documents/MyDocumentsTile.vue";
import MyDossiersTile from "@/components/dossiers/my-dossiers/MyDossiersTile.vue";
import UploadDocumentTile from "@/components/documents/upload/UploadDocumentTile.vue";
import CreateDossierTile from "@/components/dossiers/create/CreateDossierTile.vue";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { documentMapper } from "@/mappers/document.mapper";
import { documentService } from "@/services/document.service";
import { i18n } from "@/i18n";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import CheckInTile from "@/components/checkin/CheckInTile.vue";
import { AxiosPromise } from "axios";
import { authorizationService } from "@/services/authorization.service";
import { authRoles } from "@/constants/AuthRoles";

@Component({
  components: {
    CheckInTile,
    CreateDossierTile,
    UploadDocumentTile,
    ActionTile,
    MyDocumentsTile,
    MyDossiersTile,
  },
})
export default class QuickActions extends Vue {
  get hasRoleInspector(): boolean {
    return authorizationService.userIsAuthorized([authRoles.INSPECTOR]);
  }

  get userIsAuthorizedToCreateDocument(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCreateDocument();
  }

  documentAdded(document: CreateDocumentDto | null): void {
    if (document) {
      let files = document.files;
      document.files = null;
      let promises: AxiosPromise<void>[] = [];

      files?.forEach((value) => {
        document.files = [value];
        let formData = documentMapper.mapCreateDocumentDtoToFormData(document);

        if (value instanceof File) {
          promises.push(documentService.addDocument(formData));
        }
      });

      Promise.all(promises)
        .then(() => {
          Vue.$toast.success(i18n.t("my-documents.document-added"));
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("my-documents.document-add-failed"));
        });
    }
  }
}
