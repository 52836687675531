



















import { Component, Prop, Vue } from "vue-property-decorator";
import PageConfig from "@/contracts/generic/PageConfig";
import { Question } from "@/contracts/faq/QuestionDto";
import QuestionSteps from "@/components/faq/QuestionSteps.vue";
@Component({
  components: { QuestionSteps },
})
export default class FaqTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  pageConfig!: PageConfig;

  @Prop({ default: "left" })
  alignment!: string;

  get questions(): Question[] {
    return this.$store.state.faq.questions;
  }

  get amountOfQuestions(): number {
    return this.$store.state.faq.amountOfQuestions;
  }

  pageUpdated(page: number | undefined): void {
    if (page) {
      this.pageConfig.page = page;
      this.emitPageConfigUpdatedEvent();
    }
  }

  itemsPerPageUpdated(pageSize: number | undefined): void {
    if (pageSize) {
      this.pageConfig.pageSize = pageSize;
      this.emitPageConfigUpdatedEvent();
    }
  }

  emitPageConfigUpdatedEvent(): void {
    this.$emit("pageConfigUpdated", this.pageConfig);
  }
}
