




















import { Component } from "vue-property-decorator";
import AeDatePicker from "@/components/common/DatePicker.vue";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";

type DatePickerMetaData = {
  disabled?: boolean;
};

@Component({
  components: { AeDatePicker },
})
export default class DatePickerBase extends BaseDynamicComponent<DatePickerMetaData> {
  userLanguage = "";

  mounted() {
    this.userLanguage = this.$store.state.user.language;
  }
  onDatePickerContentChanged(newValue: string) {
    this.setValueView(newValue);
  }
}
