import axios from "@/plugins/axios";
import AllowedExtensionDto from "@/contracts/extension/AllowedExtensionsDto";
import store from "@/store";

class ExtensionService {
  public getAllowedExtensions(): Promise<AllowedExtensionDto[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/fileextension`)
      .then((response) => {
        return response.data;
      });
  }
}

export const extensionService = new ExtensionService();
