






import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { organisationService } from "@/app/services/organisation.service";
import { userServiceV2 } from "@/app/services/user.service";
import Loader from "@/components/common/Loader.vue";
import { overviewService } from "@/app/services/overview.service";

@Component({
  components: { Loader },
})
export default class OverviewCellOverviewRef extends OverviewCellBase {
  protected loading = false;
  protected PostResolveValue() {
    if (this.value) {
      this.loading = true;
      overviewService
        .getOverviewDataItem(
          this.value,
          this.metadata.overviewPath,
          this.metadata.column
        )
        .then((value) => {
          if (
            !value ||
            !value._embedded ||
            !value._embedded.results ||
            value._embedded.results.length <= 0
          ) {
            this.value = "-";
            this.loading = false;
            return;
          }

          this.value = value._embedded.results[0];
          this.loading = false;
        })
        .catch((reason) => {
          this.value = "-";
          this.loading = false;
        });
    }
  }
}
