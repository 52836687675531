
































import Component from "vue-class-component";
import { Vue } from "vue-property-decorator";
import { translationService } from "@/services/translation.service";
import { userService } from "@/services/user.service";
import store from "@/store";
import { Language } from "@/contracts/common/Language";
import { loadMessagesAsync } from "@/i18n";

@Component
export default class LanguageSelector extends Vue {
  loading = false;
  supportedLanguages = translationService.supportedLanguages;

  get userLanguage(): string {
    return this.$store.state.user.language;
  }

  async changeUserLanguage(language: Language): Promise<void> {
    try {
      this.loading = true;

      await userService.changeLanguage(language);
      store.commit("setLanguage", language);
      await loadMessagesAsync(language);

      this.$emit("languageChanged");
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }
}
