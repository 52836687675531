import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"value":_vm.expandedPanels,"accordion":"","multiple":""}},_vm._l((_vm.steps),function(step){return _c(VExpansionPanel,{key:step.index,attrs:{"id":step.id}},[_c(VExpansionPanelHeader,{staticClass:"white--text",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $expand")])]},proxy:true}],null,true)},[_c('div',[_c(VIcon,{staticClass:"pr-3 white--text"},[_vm._v(_vm._s(("mdi-numeric-" + (step.index) + "-box"))+" ")]),_c('i18n',{attrs:{"path":step.title,"tag":"span"}})],1)]),_c(VExpansionPanelContent,{class:{
        'expansion-panel-content-no-padding': _vm.$vuetify.breakpoint.xsOnly,
      }},[_c(step.component,{tag:"component",attrs:{"data":_vm.dataSync,"editable":_vm.editable,"dossier":_vm.dossier,"dossierId":_vm.dossierId,"can-add-lot":_vm.canAddLot,"has-activity-domain":_vm.hasActivityDomain,"activity":_vm.activity},on:{"addLot":_vm.addLot}})],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }