


























import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component } from "vue-property-decorator";

export type SwitchMetaData = {
  disabled?: boolean;
  color?: string;
};

@Component({})
export default class SwitchBase extends BaseDynamicComponent<SwitchMetaData> {
  onSwitchValueChanged(newValue: boolean): void {
    if (!newValue) {
      newValue = false;
    }
    this.setValueView(newValue);
  }

  protected getDefaultValue(): any {
    return false;
  }
}
