





























import { Component } from "vue-property-decorator";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";

export type GeneralInfoMetaData = unknown;

@Component({
  computed: {
    dossierDetailComponentLookup() {
      return detailComponentLookup;
    },
  },
})
export default class DetailComponentGeneralInformation extends DetailComponentBase<GeneralInfoMetaData> {}
