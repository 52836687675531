import ContactDto from "@/contracts/generic/ContactDto";
import axios from "@/plugins/axios";
import { AxiosPromise } from "axios";
import { Language } from "@/contracts/common/Language";
import store from "@/store";

class UserService {
  public acceptPolicies(): AxiosPromise<void> {
    return axios.put(`${store.state.appConfig.apiBaseUrl}api/users/acceptpolicies`);
  }

  public getCurrentUser(): Promise<ContactDto> {
    return axios.get(`${store.state.appConfig.apiBaseUrl}api/users/me`).then((response) => {
      return response?.data;
    });
  }

  public changeLanguage(language: Language): Promise<void> {
    if (language === "NAN") return Promise.resolve();
    return axios
      .put(`${store.state.appConfig.apiBaseUrl}api/users/language`, { language })
      .then((response) => {
        return response?.data;
      });
  }

  public getEligibleUsers(): Promise<ContactDto[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/users/eligible-contacts`)
      .then((response) => {
        return response?.data;
      });
  }
}

export const userService = new UserService();
