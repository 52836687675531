import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[(_vm.showDialog)?_c(VDialog,{attrs:{"width":"500","persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("accept-policies.title"))+" ")]),_c(VCardText,[_c(VCheckbox,{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('i18n',{attrs:{"path":"accept-policies.terms","tag":"span"},scopedSlots:_vm._u([{key:"terms",fn:function(){return [_c('router-link',{attrs:{"to":"/terms-of-use"}},[_vm._v(_vm._s(_vm.$t("general.terms-of-use"))+" ")])]},proxy:true}],null,false,3170795014)})]},proxy:true}],null,false,3643311697),model:{value:(_vm.termsOfUseAccepted),callback:function ($$v) {_vm.termsOfUseAccepted=$$v},expression:"termsOfUseAccepted"}}),_c(VCheckbox,{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('i18n',{attrs:{"path":"accept-policies.process-data","tag":"span"},scopedSlots:_vm._u([{key:"policy",fn:function(){return [_c('router-link',{attrs:{"to":"/privacy-policy"}},[_vm._v(_vm._s(_vm.$t("general.privacy-policy"))+" ")])]},proxy:true}],null,false,1316201211)})]},proxy:true}],null,false,4154676136),model:{value:(_vm.dateProcessingPolicyAccepted),callback:function ($$v) {_vm.dateProcessingPolicyAccepted=$$v},expression:"dateProcessingPolicyAccepted"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","disabled":!(_vm.termsOfUseAccepted && _vm.dateProcessingPolicyAccepted),"loading":_vm.submitting},on:{"click":_vm.acceptPolicies}},[_vm._v(" "+_vm._s(_vm.$t("general.accept"))+" ")])],1)],1)],1):_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }