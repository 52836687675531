





































import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component } from "vue-property-decorator";

export type SliderMetaData = {
  disabled?: boolean;
  color?: string;
  trackColor?: string;
  thumbColor?: string;
  thumbLabel?: string | boolean;
  step?: number;
  ticks?: string | boolean;
  tickLabels?: string[];
  min?: number;
  max?: number;
  vertical?: boolean;
  inverseLabel?: boolean;
};

@Component({})
export default class SliderBase extends BaseDynamicComponent<SliderMetaData> {
  onSliderValueChanged(newValue: boolean): void {
    this.setValueView(newValue);
  }

  protected getDefaultValue(): any {
    return this.entry.metadata.min || 0;
  }
}
