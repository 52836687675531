import CreateTechniqueDto from "@/contracts/technique/CreateTechniqueDto";
import TechniqueDto from "@/contracts/technique/TechniqueDto";

class TechniqueMapper {
  public mapToCreateDto(dto: TechniqueDto | null): CreateTechniqueDto | null {
    const result = new CreateTechniqueDto();

    if (!dto || !dto.techniqueSteps) {
      return null;
    }
    result.id = dto.id;
    result.stepIds = dto.techniqueSteps
      .filter((step) => step.id !== null)
      .map((step) => step.id ?? "");
    return result;
  }
}

export const techniqueMapper = new TechniqueMapper();
