import CreateTechniqueDto from "../technique/CreateTechniqueDto";
import LotDto from "@/contracts/lot/LotDto";

export default class CreateDayPlanningDto {
  startDate: Date | null;
  endDate: Date | null;
  qualifiedExecutors: string[] | null;
  description: string | null;
  techniques: CreateTechniqueDto[] | null;
  lots: LotDto[] | null;
  checkInLotIds: string[] | null;

  constructor() {
    this.startDate = null;
    this.endDate = null;
    this.qualifiedExecutors = null;
    this.description = null;
    this.techniques = null;
    this.lots = null;
    this.checkInLotIds = null;
  }
}
