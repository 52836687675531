import QualifiedEmployeeDto from "../dayplanning/QualifiedEmployeeDto";
import ProductDto from "../products/ProductDto";
import TechniqueDto from "../technique/TechniqueDto";

export default class LotDto {
  id: string | null;
  name: string | null;
  startDate: Date | null;
  endDate: Date | null;
  qualifiedEmployees: QualifiedEmployeeDto[] | null;
  status: string | null;
  techniques: TechniqueDto[] | null;
  products: ProductDto[] | null;
  otherProducts: string | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.startDate = null;
    this.endDate = null;
    this.qualifiedEmployees = null;
    this.status = null;
    this.techniques = null;
    this.products = null;
    this.otherProducts = null;
  }
}
