import axios from "@/plugins/axios";
import AppConfig from "@/contracts/generic/AppConfig";

class ConfigService {
  public getConfig(): Promise<AppConfig> {
    return axios.get(`/config.json`).then((response) => {
      return response?.data;
    });
  }
}

export const configService = new ConfigService();
