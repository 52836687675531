



































































import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LotCheckInActions extends Vue {
  @Prop({ default: false })
  canCheckIn!: boolean;

  @Prop({ default: false })
  canCheckOut!: boolean;

  @Prop({ default: false })
  isCheckedIn!: boolean;

  @Prop({ default: false })
  loadingDayPlanning!: boolean;

  @Prop({ default: null })
  mostRecentDayPlanningEntry!: DayPlanningHistoryEntryDto;

  checkIn(): void {
    this.$emit("checkIn");
  }

  checkOut(): void {
    this.$emit("checkOut");
  }
}
