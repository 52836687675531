
































import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { validationRules } from "@/constants/ValidationRules";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TextField from "@/components/common/TextField.vue";
import {
  DynamicFormEntry,
  FormEvent,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";

export type OverviewSelectionMetaData = {
  overviewDefinitionId: string;
  path: string;
  selectableProperty: string;
};

@Component({
  components: { OverviewComponent, TextField },
})
export default class FormComponentOverviewSelection extends BaseDynamicComponent<OverviewSelectionMetaData> {
  selectedItems: any[] = [];
  recieveSelectedItems(items: any[]) {
    this.selectedItems = items;
    this.setValueEntry(this.selectedItems);
  }

  postEntityChanged(entry: DynamicFormEntry) {
    if (entry) {
      return;
    }
  }
}
