import {
  getEntryTypeFromRenderType,
  RenderItem,
  RenderType,
} from "@/app/Types";
import { DynamicFormEntryModel } from "@/app/dynamic-components/dynamic-components.model";
import { Subject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { ExternalContext } from "@/app/contexts/externalContext";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";
import {
  FormValidator,
  ValidationError,
} from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import { formValidatorLookup } from "@/app/dynamic-components/forms/form-validations/form-validator.lookup";
import axios from "@/plugins/axios";
import BaseDynamicCompositeComponent from "@/app/dynamic-components/forms/form-components/form-component-composite.base";

export interface FormEvent {
  eventType: "clear" | "other";
  metadata: { [key: string]: any };
}

export class DynamicFormEntry {
  public id: string;
  public name: string;
  public type: RenderType;
  public displayMode:
    | "inherit"
    | "rule"
    | "readonly"
    | "editable"
    | "hidden"
    | "";
  public displayModeRule: string;
  public computedValue: string;
  public entryType: "item" | "group" | "array";
  public metadata: { [key: string]: any };
  public children: DynamicFormEntry[];
  public validatorsNames: string[] = [];
  public validators: FormValidator[] = [];
  public hasRequiredValidator = false;

  parent: DynamicFormEntry | undefined;
  root: DynamicForm | undefined;

  value: any = null;
  changeValueDebounce = new Subject<{
    newValue: any;
    options: { emitEvents: boolean };
  }>();
  changeValueDebounceSubscription: Subscription | undefined = undefined;

  private view: BaseDynamicComponent<any> | undefined = undefined;
  constructor(
    id: string,
    name: string,
    type: RenderType,
    displayMode: "inherit" | "rule" | "readonly" | "editable" | "hidden" | "",
    displayModeRule: string,
    computedValue: string,
    metadata: { [key: string]: any },
    parent: DynamicFormEntry | undefined,
    root: DynamicForm | undefined
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.displayMode = displayMode;
    this.displayModeRule = displayModeRule;
    this.computedValue = computedValue;
    this.metadata = metadata;
    this.children = [];

    this.parent = parent;
    this.root = root;

    this.entryType = getEntryTypeFromRenderType(this.type);

    this.changeValueDebounceSubscription = this.changeValueDebounce
      .pipe(debounceTime(100))
      .subscribe((newValue) => {
        this.setValueDo(newValue);
      });
  }
  addChild(entry: DynamicFormEntry) {
    this.children?.push(entry);
  }

  setValue(value: any, options?: { emitEvents: boolean; immediate: boolean }) {
    if (options && options.immediate) {
      this.setValueDo({
        newValue: value,
        options: options,
      });
      return;
    }

    this.changeValueDebounce.next({
      newValue: value,
      options: options || { emitEvents: true, immediate: false },
    });
  }

  get formData(): any {
    if (this.entryType == "item") {
      return this.value;
    } else if (this.entryType == "group") {
      let formData: { [key: string]: any } = {};
      let allChilderenNull = true;
      this.children?.forEach((child) => {
        const childForm = child.formData;
        if (child?.metadata?.SkipElementAsFormData && childForm) {
          if (typeof childForm === "object" && !Array.isArray(childForm)) {
            // add next children directly to form
            Object.entries(childForm).forEach((entry) => {
              formData[entry[0]] = entry[1];
            });
          } else {
            // if an array override entire formdata with this. be careful using this.
            formData = childForm;
          }
        } else {
          formData[child.id] = childForm;
        }
        if (childForm !== null) allChilderenNull = false;
      });
      // disable allChilderenNull if you always want to send entire structure with null values. otherwise whole becomes null.
      // return formData;
      return allChilderenNull ? null : formData;
    } else if (this.entryType == "array") {
      const formData = this.children?.map((child) => {
        return child.formData;
      });
      return formData;
    }
    return null;
  }

  bindView(view: BaseDynamicComponent<any>) {
    this.view = view;
  }

  destroy() {
    if (this.changeValueDebounceSubscription) {
      this.changeValueDebounceSubscription.unsubscribe();
      this.changeValueDebounceSubscription = undefined;
    }
    if (this.children && this.children.length > 0) {
      this.children.forEach((value) => value.destroy());
    }
  }

  patchForm(formData: any) {
    if (this.root) {
      // Trigger update to debounced queue, even if nothing changed.
      // Because if no view elements are visible a change is ignored by vue cd
      this.root.bufferedFormData = null;
      this.root?.formDataChanged();
    }

    if (this.entryType === "item") {
      //console.warn("patch item ", formData, this.id);
      if (formData === undefined) {
        return;
      }
      if (formData === null) {
        this.value = formData;
        if (this.view) {
          this.view.setValueView(this.value);
        }
        this.getErrors();
        return;
      }

      const sameData = this.compareValue(formData, this.value);
      //console.warn("data compare", formData, this.value, sameData);
      if (!sameData) {
        this.value = formData;
        if (this.view) {
          this.view.setValueView(this.value);
        }
        this.getErrors();
      }
    } else if (this.entryType === "group") {
      /*console.warn("patch group ", formData, this.id);*/
      if (formData === undefined) {
        // do nothing, patch ignores not entered entries
        return;
      }
      if (formData === null) {
        // if null set all explicitly to null
        this.children.forEach((child) => child.patchForm(null));
        this.getErrors();
      } else {
        this.children.forEach((child) => {
          if (child.metadata?.SkipElementAsFormData) {
            //if entry is skipped in generated json, just pass form allong to next child
            /*console.warn(
              "apply group child data skip element",
              formData,
              this.id
            );*/
            child.patchForm(formData);
          } else {
            /*console.warn("apply group child data", formData, this.id);*/
            // otherwise, apply normal parent child logic
            if (formData[child.id] === undefined) {
              // do nothing, patch ignores not entered entries
            } else {
              child.patchForm(formData[child.id]);
            }
          }
        });
        this.getErrors();
      }
    } else if (this.entryType === "array") {
      /*console.warn("patch array ", formData, this.id);*/
      if (Array.isArray(formData)) {
        //TODO modify array
      } else {
        //TODO clear array
      }
      this.getErrors();
    }
  }

  public async getErrors(): Promise<ValidationError[]> {
    // console.log('get errors', this.name);
    //if(this.root === null) console.log('getErrorsFired')
    //if(this.root !== null) console.log('getErrorsFired', this.id);
    const errors: ValidationError[] = [];
    await this.getErrorsInternal(errors);
    return errors;
  }

  private async getErrorsInternal(collector: ValidationError[]) {
    const internalCollector: ValidationError[] = [];
    for (const validator of this.validators) {
      //skip compisite entries, as these depend on embedded errors
      const compositeEntry =
        this.view && this.view instanceof BaseDynamicCompositeComponent;

      const result = await validator.getErrors(this.value, this);
      if (!compositeEntry && result) {
        result.forEach((r) => {
          collector.push(r);
          internalCollector.push(r);
        });
      }
    }
    if (this.view) {
      const embeddedErrors = await this.view.getEmbeddedErrors();
      if (embeddedErrors && embeddedErrors.length > 0) {
        embeddedErrors.forEach((e) => collector.push(e));
      }
      //console.log('errors',this.id, JSON.stringify(internalCollector));
      this.view.setErrors(internalCollector);
    }
    if (this.children && this.children.length > 0) {
      for (const child of this.children) {
        await child.getErrorsInternal(collector);
      }
    }
  }

  private compareValue(newData: any, existingData: any) {
    if (newData == null && existingData == null) return true;
    if (newData != null && existingData == null) return false;
    if (newData == null && existingData != null) return false;

    if (
      typeof newData === "string" ||
      typeof newData === "number" ||
      typeof newData === "boolean" ||
      typeof newData === "bigint"
    ) {
      return newData === existingData;
    }

    if (typeof newData === "object" || Array.isArray(newData)) {
      return JSON.stringify(newData) === JSON.stringify(existingData);
    }
    return false;
  }

  clear() {
    this.patchForm(null);
  }

  get resolvedDisplayMode():
    | ""
    | "inherit"
    | "rule"
    | "readonly"
    | "editable"
    | "hidden" {

    let mode : | ""
    | "inherit"
    | "rule"
    | "readonly"
    | "editable"
    | "hidden"  = '';

    if (this.displayMode === "inherit" && this.parent) {
      mode = this.parent.resolvedDisplayMode;
    }else if(this.displayMode !== "rule"){
      mode = this.displayMode;
    }else{
      if (!this.root) {
        mode = "readonly";
      }else{
        mode = this.root.resolveRule(this.displayModeRule);
      }
    }

    if(mode === 'hidden') return mode;

    if(this.root && this.root.id !== this.id && this.root.resolvedDisplayMode === 'readonly') return 'readonly';
    return mode;
  }

  public async beforeSave(): Promise<boolean> {
    //self
    if (this.view) {
      const isBeforeSaveSuccess = await this.view.beforeSave();
      if (!isBeforeSaveSuccess) {
        return false;
      }
    }

    //childeren
    if (
      this.entryType !== "item" &&
      this.children &&
      this.children.length > 0
    ) {
      for (let i = 0; i < this.children.length; i++) {
        const childResponse = await this.children[i].beforeSave();
        if (!childResponse) {
          return false;
        }
      }
    }
    return true;
  }

  public async afterSave(sumbitData: any): Promise<boolean> {
    //self
    if (this.view) {
      const isAfterSaveSuccess = await this.view.afterSave(sumbitData);
      if (!isAfterSaveSuccess) {
        return false;
      }
    }

    //childeren
    if (
      this.entryType !== "item" &&
      this.children &&
      this.children.length > 0
    ) {
      for (let i = 0; i < this.children.length; i++) {
        const childResponse = await this.children[i].afterSave(sumbitData);
        if (!childResponse) {
          return false;
        }
      }
    }
    return true;
  }

  static BUILD_ENTRY_FROM_JSON_DEFINITION(
    jsonDefinition: RenderItem,
    parent: DynamicFormEntry,
    root: DynamicForm
  ): DynamicFormEntry {
    const entry = new DynamicFormEntry(
      jsonDefinition.id,
      jsonDefinition.name,
      jsonDefinition.type,
      jsonDefinition.displayMode,
      jsonDefinition.displayModeRule,
      jsonDefinition.computedValue,
      jsonDefinition.metadata || {},
      parent,
      root
    );
    if (jsonDefinition.validators && jsonDefinition.validators.length > 0) {
      entry.validatorsNames = jsonDefinition.validators;
      entry.validators = formValidatorLookup.parseValidators(
        jsonDefinition.validators
      );
    }
    if (jsonDefinition.validators && jsonDefinition.validators.length > 0) {
      entry.hasRequiredValidator = formValidatorLookup.hasRequiredValidator(
        jsonDefinition.validators
      );
    }

    if (entry.metadata.default) {
      entry.value = entry.metadata.default;
    }

    // construct childeren if no leaf
    if (entry.entryType !== "item") {
      if (
        jsonDefinition.children &&
        Array.isArray(jsonDefinition.children) &&
        jsonDefinition.children.length > 0
      ) {
        Array.from(jsonDefinition.children).forEach((child) => {
          const c = DynamicFormEntry.BUILD_ENTRY_FROM_JSON_DEFINITION(
            child,
            entry,
            root
          );
          entry.addChild(c);
        });
      }
    }
    // if there are still childeren present on item style, then the item will use if for further rendering in its component.
    // add the json as a metadata shorthand
    if (entry.entryType === "item") {
      if (
        jsonDefinition.children &&
        Array.isArray(jsonDefinition.children) &&
        jsonDefinition.children.length > 0
      ) {
        entry.metadata["subform"] = jsonDefinition.children[0];
      }
    }

    //console.warn("entry generated", entry.id);
    return entry;
  }

  private setValueDo(newValue) {
    // fix for select components starting at null and patching [] at creation, error should not show on this
    const emptyArrayDetection = !(
      (this.value === undefined || this.value === null) &&
      Array.isArray(newValue.newValue) &&
      Array.from(newValue.newValue).length === 0
    );

    this.value = newValue.newValue;

    if (emptyArrayDetection) {
      this.getErrors();
    }
    if (newValue.options?.emitEvents === false) return;
    this.root?.formDataChanged(newValue.options.immediate);
  }

  public resetValidation() {
    //self
    if (this.view) {
      this.view.setErrors([]);
      this.view.resetEmbeddedValidation();
    }

    //childeren
    if (
      this.entryType !== "item" &&
      this.children &&
      this.children.length > 0
    ) {
      for (let i = 0; i < this.children.length; i++) {
        this.children[i].resetValidation();
      }
    }
  }

  public static SEPERATE_FIELDS(
    formDataElement: any,
    modelElement: DynamicFormEntry
  ) {
    let dynamicProperties: any | undefined = undefined;
    let data: any | undefined = undefined;
    // console.log(modelElement.id, JSON.stringify(formDataElement));

    if (modelElement.children && modelElement.entryType !== "item") {
      let childrenDynamicProperties = {};
      let childrenData = {};

      modelElement.children.forEach((element) => {
        let mappedValue;
        const skipValue = element?.metadata?.SkipElementAsFormData || false;

        if (skipValue) {
          mappedValue = DynamicFormEntry.SEPERATE_FIELDS(
            formDataElement,
            element
          );

          if (mappedValue.dynamicProperties) {
            if (
              mappedValue.dynamicProperties !== null &&
              typeof mappedValue.dynamicProperties === "object" &&
              !Array.isArray(mappedValue.dynamicProperties)
            ) {
              childrenDynamicProperties = {
                ...childrenDynamicProperties,
                ...mappedValue.dynamicProperties,
              };
            } else {
              childrenDynamicProperties = mappedValue.dynamicProperties;
            }
          }
          if (mappedValue.data) {
            if (
              mappedValue.data !== null &&
              typeof mappedValue.data === "object" &&
              !Array.isArray(mappedValue.data)
            ) {
              childrenData = { ...childrenData, ...mappedValue.data };
            } else {
              childrenData = mappedValue.data;
            }
          }
        } else {
          if (formDataElement[element.id]) {
            mappedValue = DynamicFormEntry.SEPERATE_FIELDS(
              formDataElement[element.id],
              element
            );

            if (mappedValue.dynamicProperties) {
              childrenDynamicProperties[element.id] =
                mappedValue.dynamicProperties;
            }
            if (mappedValue.data) {
              childrenData[element.id] = mappedValue.data;
            }
          }
        }
      });

      dynamicProperties =
        Object.keys(childrenDynamicProperties).length > 0
          ? childrenDynamicProperties
          : undefined;
      data = Object.keys(childrenData).length > 0 ? childrenData : undefined;
    } else {
      if (modelElement.metadata.isDynamicApiField) {
        dynamicProperties = formDataElement;
      } else {
        data = formDataElement;
      }
    }

    return {
      dynamicProperties: dynamicProperties,
      data: data,
    };
  }
}

export class DynamicForm extends DynamicFormEntry {
  eventbus: Subject<FormEvent>;
  eventbusSubscription: Subscription | undefined;
  //this one emits changes
  formDataEmittor: Subject<any>;
  formDataEmittorSubscription: Subscription | undefined;
  //this one triggers changes
  formDataChangedSubject: Subject<any>;
  formDataChangedSubscription: Subscription | undefined;
  private wrapper: FormWrapper | undefined = undefined;

  public externalContext: ExternalContext = new ExternalContext();

  constructor(renderItem: RenderItem, externalContext?: ExternalContext) {
    super(
      renderItem.id,
      renderItem.name,
      renderItem.type,
      renderItem.displayMode,
      renderItem.displayModeRule,
      renderItem.computedValue,
      renderItem.metadata,
      undefined,
      undefined
    );
    this.root = this;
    if (externalContext) {
      this.setExternalContext(externalContext);
    }
    this.eventbus = new Subject<FormEvent>();
    this.eventbusSubscription = this.eventbus
      .asObservable()
      .subscribe((newFormEvent) => {
        return;
      });
    this.formDataEmittor = new Subject<any>();
    this.formDataEmittorSubscription = this.formDataEmittor.subscribe(
      (formData) => {
        if (this.wrapper) {
          this.wrapper.formDataChanged(formData);
        }
      }
    );
    this.formDataChangedSubject = new Subject<any>();
    this.formDataChangedSubscription = this.formDataChangedSubject
      .asObservable()
      .pipe(debounceTime(200))
      .subscribe((newFormEvent) => {
        this.bufferedFormData = null;
        this.formDataEmittor.next(this.formData);
      });
  }

  patchForm(formData: any) {
    this.bufferedFormData = null;
    super.patchForm(formData);
  }

  bufferedFormData: any | null = null;
  get formData(): any {
    if (this.bufferedFormData) return this.bufferedFormData;

    this.bufferedFormData = super.formData;
    return this.bufferedFormData;
  }

  setExternalContext(externalContext: ExternalContext) {
    this.externalContext = externalContext;
  }

  public reset() {
    this.clear();
    setTimeout(() => {
      this.resetValidation();
    }, 200);
  }

  public resolveDataPath(placeholder: string): any | null {
    if (placeholder.startsWith("data.")) {
      if (placeholder.startsWith("data.external.")) {
        const externalPath = placeholder.replace("data.external.", "");
        /*console.log(
          "external placeholder",
          externalPath,
          JSON.stringify(this.formData)
        );*/
        const dataResult = getObjectContentsFromPath(
          externalPath,
          this.externalContext.data
        );
        return dataResult;
      }
      if (placeholder.startsWith("data.form.")) {
        const FormPath = placeholder.replace("data.form.", "");
        const dataResult = getObjectContentsFromPath(FormPath, this.formData);
        /*console.log(
          "form placeholder",
          FormPath,
          JSON.stringify(this.formData)
        );*/
        return dataResult;
      }
    }
    return null;
  }

  public resolvePlaceholders(data: any): any {
    if (typeof data === "string") {
      return this.resolvePlaceholdersString(data);
    }
    return JSON.parse(this.resolvePlaceholdersString(JSON.stringify(data)));
  }

  private resolvePlaceholdersString(str: any): any {
    const calculatedReplaceRegex = /\${([^$}]*)}/;

    let replaceRegex;
    let c = 0;
    while ((replaceRegex = calculatedReplaceRegex.exec(str)) && c < 1000) {
      const fallbackorder = replaceRegex[1].split(';');
      let replaced = false;
      for (let i = 0; i < fallbackorder.length && !replaced; i++) {
        const placeholder = fallbackorder[i];
        let value = this.resolveDataPath(placeholder);
        // console.log("resolveDataPath", str, value);
        if (Array.isArray(value)) {
          value = Array.from(value).length <= 0 ? null : value[0];
        }
        if(value){
          str = str.replaceAll(replaceRegex[0], value ? value : "$nan");
          // console.log("found regex", JSON.stringify(replaceRegex), value, str);
          replaced = true;
        }
      }
      if(!replaced){
        str = str.replaceAll(replaceRegex[0], "$nan");
        // console.log("found regex", JSON.stringify(replaceRegex), "$nan", str);
      }
      c++;
    }
    return str;
  }

  public resolveRule(
    rule: string
  ): "" | "inherit" | "rule" | "readonly" | "editable" | "hidden" {
    if (!rule || rule === "") return "readonly";
    if (rule.indexOf(":") < 0) {
      //simple rule containing the value
      return this.resolveDataPath(rule) || "readonly";
    }

    // complex rule in format 'hidden:data.form.custom_materialType===true;editable'
    // mode:selector===equalsstatement
    // last statement is if non are matched
    const statements = rule.split(";");
    for (let i = 0; i < statements.length - 1; i++) {
      const statement = statements[i];
      const a = statement.split(":");
      const b = a[1].split("===");
      const mode = a[0];
      const datapath = b[0];
      const equals = b[1];
      const resolvedDataPath = this.resolveDataPath(datapath);
      if (resolvedDataPath && equals === resolvedDataPath + "")
        return mode as
          | ""
          | "inherit"
          | "rule"
          | "readonly"
          | "editable"
          | "hidden";
      if (!resolvedDataPath && equals === "null")
        return mode as
          | ""
          | "inherit"
          | "rule"
          | "readonly"
          | "editable"
          | "hidden";
    }
    //default matcher
    return statements[statements.length - 1] as
      | ""
      | "inherit"
      | "rule"
      | "readonly"
      | "editable"
      | "hidden";
  }

  formDataChanged(immediate?: boolean) {
    if (immediate) {
      this.bufferedFormData = null;
      this.formDataEmittor.next(this.formData);
      return;
    }

    this.formDataChangedSubject.next();
  }

  destroy() {
    super.destroy();
    if (this.eventbusSubscription) {
      this.eventbusSubscription.unsubscribe();
      this.eventbusSubscription = undefined;
    }
    if (this.formDataChangedSubscription) {
      this.formDataChangedSubscription.unsubscribe();
      this.formDataChangedSubscription = undefined;
    }
    if (this.formDataEmittorSubscription) {
      this.formDataEmittorSubscription.unsubscribe();
      this.formDataEmittorSubscription = undefined;
    }
  }

  bindWrapper(view: FormWrapper) {
    this.wrapper = view;
  }
  static BUILD_FROM_JSON_DEFINITION(
    jsonDefinition: RenderItem,
    wrapper?: FormWrapper,
    externalContext?: ExternalContext
  ): DynamicForm {
    const form = new DynamicForm(jsonDefinition, externalContext);
    if (wrapper) {
      form.bindWrapper(wrapper);
    }

    if (!jsonDefinition.children) return form;

    jsonDefinition.children.forEach((child) => {
      const c = DynamicFormEntry.BUILD_ENTRY_FROM_JSON_DEFINITION(
        child,
        form,
        form
      );
      form.addChild(c);
    });

    //after creation, emit first formdata structure
    form.formDataChanged();

    return form;
  }
}
