








import { Vue, Component } from "vue-property-decorator";
import DossierActivityDomainPanel from "@/app/dynamic-components/forms/form-components/components/activity-domain/DossierActivityDomainPanel.DynamicView.vue";

@Component({
  components: { ActivityDomainForm, DossierActivityDomainPanel },
})
export default class ActivityDomainForm extends Vue {
  mounted() {
    console.log(this.$store.state.user.activeActivity);
  }

  contentUpdated(data: any) {
    this.$emit("contentUpdated", data);
  }
}
