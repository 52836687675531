

































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AeSlideOut extends Vue {
  @Prop({ default: false })
  show!: boolean;

  get width(): string {
    if (this.$vuetify.breakpoint.xsOnly) {
      return "100%";
    } else if (this.$vuetify.breakpoint.smOnly) {
      return "60%";
    }
    return "40%";
  }

  closeSlideOut(state: boolean): void {
    if (!state) {
      this.emitClose();
    }
  }

  emitClose(): void {
    this.$emit("close");
  }
}
