






















































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import DossierDto from "@/contracts/dossier/DossierDto";
import DocumentTile from "@/components/documents/DocumentTile.vue";
import UploadDocumentTile from "@/components/documents/upload/UploadDocumentTile.vue";
import AeDatePicker from "@/components/common/DatePicker.vue";
import { dossierService } from "@/services/dossier.service";
import { documentTypes } from "@/constants/DocumentTypes";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { i18n } from "@/i18n";

@Component({
  components: { AeDatePicker, DocumentTile, UploadDocumentTile },
})
export default class DossierPlanningPanel extends Vue {
  @PropSync("data", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  editable!: boolean;

  initialLoading = false;
  loading = false;
  userLanguage = "en-us";

  documentType = documentTypes.PLANNING;

  amountOfDocuments = 0;
  documents: DocumentDto[] = [];

  get amountOfDocumentsToUpload(): number {
    return this.$store.state.dossiers.amountOfPlanningDocumentsToUpload;
  }

  get documentsToUpload(): CreateDocumentDto[] {
    console.log(this.$store.state.dossiers.planningDocumentsToUpload);
    return this.$store.state.dossiers.planningDocumentsToUpload;
  }

  mounted(): void {
    this.userLanguage = this.$store.state.user.language;

    if (this.dossierSync.id) {
      this.fetchDocuments(true);
    } else {
      this.amountOfDocuments = 0;
      this.documents = [];
    }

    this.$store.commit("setAmountOfPlanningDocumentsToUpload", 0);
    this.$store.commit("setPlanningDocumentsToUpload", []);
  }

  fetchDocuments(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    if (this.dossierSync.id) {
      dossierService
        .getDocuments(this.dossierSync.id, documentTypes.PLANNING)
        .then((data) => {
          if (data) {
            if (initialFetch) {
              this.amountOfDocuments = data.rowCount;
            }

            if (data.results) {
              this.documents = data.results;
            }
          }
        })
        .finally(() => {
          if (initialFetch) {
            this.initialLoading = false;
          }

          this.loading = false;
        });
    }
  }

  documentAdded(document: CreateDocumentDto | null): void {
    if (document) {
      let amountOfDocumentsToUpload: number = this.amountOfDocumentsToUpload;
      let planningDocumentsToUpload: CreateDocumentDto[] =
        this.documentsToUpload;

      // only needed to have a unique key in the v-for loop, won't be sent to API
      document.id = amountOfDocumentsToUpload.toString();

      amountOfDocumentsToUpload++;
      planningDocumentsToUpload.push(document);

      this.$store.commit(
        "setAmountOfPlanningDocumentsToUpload",
        amountOfDocumentsToUpload
      );
      this.$store.commit(
        "setPlanningDocumentsToUpload",
        planningDocumentsToUpload
      );
    }
  }

  deleteDocument(documentId: string): void {
    if (this.dossierSync.id) {
      dossierService
        .deleteDocument(this.dossierSync.id, documentId)
        .then(() => {
          this.fetchDocuments(true);
          Vue.$toast.success(i18n.t("my-documents.document-deleted"));
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("my-documents.document-delete-failed"));
        });
    }
  }

  deleteDocumentToUpload(index: number): void {
    let amountOfDocumentsToUpload: number = this.amountOfDocumentsToUpload;
    let planningDocumentsToUpload: CreateDocumentDto[] = this.documentsToUpload;

    amountOfDocumentsToUpload--;
    planningDocumentsToUpload.splice(index, 1);

    this.$store.commit(
      "setAmountOfPlanningDocumentsToUpload",
      amountOfDocumentsToUpload
    );
    this.$store.commit(
      "setPlanningDocumentsToUpload",
      planningDocumentsToUpload
    );
  }
}
