




























import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { organisationService } from "@/app/services/organisation.service";
import { userServiceV2 } from "@/app/services/user.service";
import Loader from "@/components/common/Loader.vue";
import { overviewService } from "@/app/services/overview.service";
import { planningService } from "@/app/services/planning.service";

@Component({
  components: { Loader },
})
export default class OverviewCellPlanning extends OverviewCellBase {
  protected loading = false;
  protected startDate: any;
  protected endDate: any;
  protected PostResolveValue() {
    if (this.value) {
      this.loading = true;
      planningService
        .getPlanning(this.value)
        .then((value) => {
          this.startDate = value.startDate;
          this.endDate = value.endDate;
          this.loading = false;
        })
        .catch((reason) => {
          this.loading = false;
        });
    }
  }
}
