






import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { organisationService } from "@/app/services/organisation.service";
import Loader from "@/components/common/Loader.vue";

@Component({
  components: { Loader },
})
export default class OverviewCellOrganisation extends OverviewCellBase {
  protected loading = false;

  protected PostResolveValue() {
    if (this.value) {
      this.loading = true;
      organisationService
        .getOrganisationsByIds([this.value])
        .then((value) => {
          if (!value || value.length <= 0) {
            this.value = this.$tc("general.unknown");
            this.loading = false;
            return;
          }

          this.value = value[0].name;
          this.loading = false;
        })
        .catch((reason) => {
          this.value = this.$tc("general.fetch_failed");
          this.loading = false;
        });
    }
  }
}
