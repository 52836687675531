




























































import { Component, Prop, Vue } from "vue-property-decorator";
import { Route } from "vue-router/types/router";
import router, { Routes } from "@/router";
import ReferenceData from "@/contracts/generic/ReferenceData";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import { activityService } from "@/services/activity.service";
import ExtendedActivityDto from "@/contracts/activity/ExtendedActivityDto";

@Component({ components: { AeAutocomplete } })
export default class SelectActivityDialog extends Vue {
  @Prop({ default: false })
  showDialog!: boolean;

  activities: ReferenceData[] = [];

  selectedActivity: string | null = null;

  loading = false;

  mounted(): void {
    this.fetchActivities();
  }

  fetchActivities(): void {
    this.loading = true;
    activityService
      .getActivities()
      .then((data) => {
        data.map((i) => (i.name = this.$t(i.translationKey) as string));

        const allowedActivities = this.$store.state.user.activities
          .filter((activity: ExtendedActivityDto) => activity.hasDossiers)
          .filter((activity: ExtendedActivityDto) => activity.translationKey==='activity.concrete-repair')
          .map((activity: ExtendedActivityDto) => activity.id);

        this.activities = data.filter((activity) =>
          allowedActivities.includes(activity.id)
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changeActivity(newActivityId: string): void {
    if (this.selectedActivity != newActivityId) {
      this.selectedActivity = newActivityId;
    }
  }

  navigateToCreateDossiers(): Promise<Route> | void {
    if (this.selectedActivity) {
      return router.push({
        name: Routes.CreateDossier,
        params: {
          activityId: this.selectedActivity,
        },
      });
    }
  }

  cancel(): void {
    this.$emit("close");
  }
}
