import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('authentication-interceptor',[_c('accept-policies-interceptor',[_c('page-header',{attrs:{"headerIconName":"mdi-file-document","headerTitle":"dossier.related-documents","backButtonTitle":"dossier.to-dossier-details"},on:{"backButtonClicked":_vm.navigateToDossierDetails},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c(VRow,[_c(VCol,{staticClass:"col-md-4 col-lg-3 col-12"},[(!_vm.$vuetify.breakpoint.xsOnly)?_c('div',[_c('h5',{staticClass:"mb-2 mt-6"},[_vm._v(" "+_vm._s(_vm.$t("dossier.title-details"))+" ")]),_c('related-documents-details-actions',{attrs:{"dossier-id":_vm.dossierId}})],1):_vm._e(),_c('div',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('h5',{staticClass:"mb-2 mt-6"},[_vm._v(" "+_vm._s(_vm.$t("dossier.title-actions"))+" ")]):_vm._e(),_c('related-documents-actions',{attrs:{"can-add-documents":_vm.canEditDocuments &&
                    _vm.userIsAuthorizedToCreateDossierDocument,"dossier-activity":_vm.dossierActivityId},on:{"documentAdded":_vm.documentAdded}})],1)]),_c(VCol,{staticClass:"col-md-8 col-lg-9 col-12"},[_c(VRow,{staticClass:"fill-height"},[(!_vm.initialLoading)?_c(VCol,[(_vm.amountOfDocuments > 0)?_c('div',[_c('documents-filters',{attrs:{"document-type":_vm.documentType,"filter-by-activity":false},on:{"filterConfigUpdated":_vm.filterConfigUpdated}}),_c('documents-table',{attrs:{"allowDocumentDelete":_vm.canEditDocuments &&
                        _vm.userIsAuthorizedToDeleteDossierDocument,"amount-of-documents":_vm.amountOfDocuments,"documents":_vm.documents,"loading":_vm.loading,"page-config":_vm.pageConfig,"show-activity":false,"sort-config":_vm.sortConfig,"warning-message":"delete-related-document.warning"},on:{"documentDeleted":function($event){return _vm.fetchDocuments(true)},"pageConfigUpdated":_vm.pageConfigUpdated,"sortConfigUpdated":_vm.sortConfigUpdated}}),_c('upload-document-tile',{staticClass:"mt-6",attrs:{"disabled":!_vm.canEditDocuments ||
                        !_vm.userIsAuthorizedToCreateDossierDocument,"document-type":_vm.documentType,"dossier-activity":_vm.dossierActivityId,"icon":"mdi-file-upload","title":"dossier.drag-drop-upload-new"},on:{"documentAdded":_vm.documentAdded}})],1):_c('upload-document-tile',{staticClass:"fill-height pb-6",attrs:{"disabled":!_vm.canEditDocuments ||
                      !_vm.userIsAuthorizedToCreateDossierDocument,"document-type":_vm.documentType,"dossier-activity":_vm.dossierActivityId,"image":"no-documents.svg","maxImageWidth":"50vw","subtitle":"dossier.drag-drop-upload","title":"dossier.no-documents"},on:{"documentAdded":_vm.documentAdded}})],1):_c(VCol,[_c('loader',{attrs:{"show":true}})],1)],1)],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }