

















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LotActions extends Vue {
  @Prop({ default: false }) canEditLot!: boolean;
  @Prop({ default: false }) canDeleteLot!: boolean;

  submitting = false;

  editLot(): void {
    this.$emit("editLot", true);
  }
  deleteLot(): void {
    this.$emit("deleteLot", true);
  }
}
