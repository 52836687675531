
































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Question } from "@/contracts/faq/QuestionDto";
import { faqService } from "@/services/faq.service";

@Component
export default class QuestionSteps extends Vue {
  @Prop({ default: false })
  steps!: Question[];

  @Prop({ default: "left" })
  alignment!: string;

  get userLanguage(): string {
    return this.$store.state.user.language;
  }

  expandedPanels: string[] = [];

  expandPanel(id: string): void {
    this.expandedPanels = [id];
  }

  hasDocuments(step: Question): boolean {
    return step.faqDocuments.length > 0;
  }

  downloadDocument(id: string, fileName: string): void {
    faqService.getFaqDocumentById(id, fileName);
  }
}
