import LotStatus from "@/contracts/lot/LotStatus";
import LotStatusDto from "@/contracts/lot/LotStatusDto";

class LotStatusMapper {
  public mapLotStatusDtoToLotStatus(status: LotStatusDto): LotStatus {
    const mappedStatus = new LotStatus();
    mappedStatus.label = status.label;
    mappedStatus.value = status.value;
    mappedStatus.translationKey = this.mapLabelToTranslatableLabel(
      status.label
    );
    return mappedStatus;
  }

  public mapLabelToTranslatableLabel(label: string | null): string {
    if (!label) {
      return "";
    }

    return `lot-status.${label.toLowerCase().replaceAll(" ", "-")}`;
  }
}

export const lotStatusMapper = new LotStatusMapper();
