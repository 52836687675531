import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,{staticClass:"fill-height"},[(!_vm.initialLoading)?_c(VCol,[_c(VRow,{staticClass:"flex justify-space-between ms-0 mt-3 pe-0"},[_c('div',{staticClass:"d-flex justify-start flex-wrap"},_vm._l((this.overviewDefinition.searchable),function(searchableItem){return _c(VCol,{key:searchableItem.id,staticClass:"p-0 m-0"},[(searchableItem.type === 'SearchableEnum')?_c('ae-autocomplete',{staticStyle:{"min-width":"250px"},attrs:{"clearable":true,"dense":true,"chips":false,"multiple":false,"items":_vm.searchableItemValues(searchableItem),"loading":_vm.loading,"value":searchableItem.metadata.enums[searchableItem.id],"translate":searchableItem.metadata.translate,"item-text":searchableItem.metadata.itemText || 'label',"item-value":searchableItem.metadata.itemValue || 'value',"label":searchableItem.name},on:{"input":function($event){return _vm.filtersUpdated(searchableItem.id, $event, searchableItem)}}}):_vm._e()],1)}),1),_c('div',{staticClass:"flex-grow-1 flex-shrink-1"}),_c('div',{},[(_vm.overviewDefinition.wildcardSearch)?_c('TextField',{staticClass:"mt-0 ms-4 me-6",staticStyle:{"min-width":"250px"},attrs:{"label":_vm.$tc('overview.quicksearch'),"value":this.quickSearch,"type":'text'},on:{"input":function($event){return _vm.onQuickSearch($event)}}}):_vm._e()],1),_c('div',[(
              _vm.overviewDefinition.canCreateNew &&
              _vm.canCreateNew &&
              _vm.page.totalElements > 0
            )?_c(VBtn,{staticClass:"mt-2",attrs:{"elevation":"2","color":"white"},on:{"click":_vm.createNewClicked}},[_vm._v(" "+_vm._s(_vm.$t(_vm.createNew.subtitle))+" "),_c(VSpacer),_c(VIcon,{staticClass:"ml-5"},[_vm._v(_vm._s("mdi-plus-box"))])],1):_vm._e()],1)]),_c(VRow,{staticClass:"mt-0"},[_c(VCol,[(_vm.data.length > 0 || _vm.loading)?_c('div',[_c(VDataTable,{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"elevation-1 row-pointer ps-2 pe-2 pt-2 pb-2",class:{
                tablestyle__overview: _vm.tableStyle === 'overview',
                tablestyle__accordion: _vm.tableStyle === 'accordion',
              },attrs:{"headers":_vm.headers(),"items":_vm.embeddedData == undefined ? _vm.data : _vm.embeddedData,"loading":_vm.loading,"page":_vm.page.number + 1,"items-per-page":_vm.page.size,"server-items-length":_vm.page.totalElements,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc,"footer-props":{ itemsPerPageText: _vm.$t('general.table_rows') },"fixed-header":"","item-key":"id"},on:{"update:page":_vm.pageUpdated,"update:sort-by":_vm.sortByUpdated,"update:sort-desc":_vm.sortDescUpdated,"update:items-per-page":_vm.itemsPerPageUpdated},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticStyle:{"position":"relative"}},[(_vm.selectable)?_c('div',{staticClass:"my-dossiers-table__non-mobile-row",staticStyle:{"position":"absolute","left":"10px","top":"16px"}},[_c(VChip,{staticClass:"me-0",staticStyle:{"transform":"scale(0.7)"},attrs:{"color":"primary","outlined":true}},[_vm._v(_vm._s(_vm.selectedItems.length))]),_vm._v(" "+_vm._s(_vm.$t("OVERVIEW.SELECTED_ITEMS"))+" "),_c('span',{staticClass:"red--text v-chip--clickable",on:{"click":function($event){return _vm.clearSelectedItems()}}},[_vm._v(_vm._s(_vm.$t("OVERVIEW.SELECTED_ITEMS_CLEAR")))])],1):_vm._e()])]},proxy:true},{key:"item",fn:function(ref){
              var item = ref.item;
              var expand = ref.expand;
              var isExpanded = ref.isExpanded;
              var index = ref.index;
return [_c('tr',{staticClass:"my-dossiers-table__non-mobile-row",class:{'clickable': _vm.canClickStyle(),'nclickable': !_vm.canClickStyle()},style:({'cursor': _vm.canClickStyleCss()}),on:{"click":function($event){_vm.clickable
                      ? _vm.onRowClick(item, expand, isExpanded, index)
                      : false}}},[(_vm.selectable)?_c('td',[_c(VSimpleCheckbox,{staticClass:"mx-0 my-0",attrs:{"value":_vm.hasSelectedItem(item)},on:{"input":function($event){return _vm.selectedItem($event, item)}}})],1):_vm._e(),_vm._l((_vm.filteredHeaders()),function(header){return _c('td',{key:header.value,style:({ width: header.metadata['width'] || 'auto' })},[_c(_vm.getOverviewCellComponent(header.type),{key:item.id,tag:"component",attrs:{"item":item,"headerId":header.value,"renderType":header.type,"metadata":header.metadata,"dataSelector":header.dataSelector,"additionalMetadata":_vm.additionalMetadata,"eventbus":_vm.eventbus,"overviewBaseContext":_vm.overviewContext,"itemRules":header.itemRules}})],1)}),(_vm.overviewDefinition.canExpand)?_c('td',{staticStyle:{"width":"200px"}},[_c('p',{staticClass:"mb-0 mt-0 text-end"},[_vm._v(" "+_vm._s(_vm.$t("general.show_details"))+" "),_c(VIcon,{staticClass:"chevron-expander",class:{ 'chevron-expander__open': isExpanded },staticStyle:{"color":"white !important"}},[_vm._v(_vm._s("mdi-chevron-down"))])],1)]):_vm._e()],2),_c('tr',{staticClass:"my-dossiers-table__mobile-row",class:{'clickable': _vm.canClickStyle(),'nclickable': !_vm.canClickStyle()},style:({'cursor': _vm.canClickStyleCss()}),on:{"click":function($event){_vm.clickable
                      ? _vm.onRowClick(item, expand, isExpanded, index)
                      : false}}},[_c('td',{staticClass:"v-data-table__mobile-row"},[(_vm.selectable)?_c('div',[_c(VSimpleCheckbox,{staticClass:"mx-0 my-0",attrs:{"value":_vm.hasSelectedItem(item)},on:{"input":function($event){return _vm.selectedItem($event, item)}}})],1):_vm._e()]),_vm._l((_vm.filteredHeaders()),function(header){return _c('td',{key:header.value,staticClass:"v-data-table__mobile-row"},[_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(header.text)+" ")]),_c(_vm.getOverviewCellComponent(header.type),{key:item.id,tag:"component",staticClass:"v-data-table__mobile-row__cell",attrs:{"item":item,"headerId":header.value,"renderType":header.type,"metadata":header.metadata,"dataSelector":header.dataSelector,"additionalMetadata":_vm.additionalMetadata,"eventbus":_vm.eventbus,"overviewBaseContext":_vm.overviewContext,"itemRules":header.itemRules}})],1)}),(_vm.overviewDefinition.canExpand)?_c('td',{staticClass:"v-data-table__mobile-row"},[_c('p',{staticClass:"mb-0 mt-0 text-end",staticStyle:{"position":"absolute","right":"30px"}},[_vm._v(" "+_vm._s(_vm.$t("general.show_details"))+" "),_c(VIcon,{staticClass:"chevron-expander",class:{ 'chevron-expander__open': isExpanded },staticStyle:{"color":"white !important"}},[_vm._v(_vm._s("mdi-chevron-down"))])],1)]):_vm._e()],2)]}},{key:"expanded-item",fn:function(ref){
                      var headers = ref.headers;
                      var item = ref.item;
return [(_vm.overviewDefinition.canExpand)?_c('td',{staticClass:"table-expand__non-mobile",attrs:{"colspan":headers.length + 1}},[_c(_vm.getOverviewExpanderComponent(
                        _vm.overviewDefinition.expandType
                      ),{tag:"component",attrs:{"item":item,"renderType":_vm.overviewDefinition.expandType,"metadata":_vm.additionalMetadata,"eventbus":_vm.eventbus}})],1):_vm._e(),(_vm.overviewDefinition.canExpand)?_c('td',{staticClass:"table-expand__mobile",attrs:{"colspan":headers.length}},[_c(_vm.getOverviewExpanderComponent(
                        _vm.overviewDefinition.expandType
                      ),{tag:"component",attrs:{"item":item,"renderType":_vm.overviewDefinition.expandType,"metadata":_vm.additionalMetadata,"eventbus":_vm.eventbus}})],1):_vm._e()]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(((items.pageStart) + " - " + (items.pageStop) + " " + (_vm.$t( "general.table_row_amount" )) + " " + (items.itemsLength)))+" ")]}}],null,true)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}]},[_c(VSkeletonLoader,_vm._b({attrs:{"type":"table","transition":"fade-transition"}},'v-skeleton-loader',_vm.attrs,false))],1)],1):(!_vm.overviewDefinition.canCreateNew && _vm.canCreateNew)?_c('div',[_c('action-tile',{staticClass:"fill-height pb-6 w-4",staticStyle:{"pointer-events":"none"},attrs:{"maxImageWidth":"15rem","image":_vm.createNew.largeImage,"title":_vm.$t('general.no_entries'),"subtitle":_vm.$t('general.no_matching_entries'),"border":false,"disabled":false},on:{"click":function($event){return _vm.createNewClicked()}}})],1):_vm._e()])],1),_c(VRow,{staticClass:"mt-0"},[_c(VCol,[(_vm.overviewDefinition.canCreateNew && _vm.canCreateNew)?_c('div',[(_vm.page.totalElements <= 0)?_c('div',[_c('action-tile',{staticClass:"fill-height pb-6 w-4",attrs:{"maxImageWidth":"15rem","image":_vm.createNew.largeImage,"title":_vm.$t(_vm.createNew.title),"subtitle":_vm.$t(_vm.createNew.subtitle),"border":false,"disabled":false},on:{"click":function($event){return _vm.createNewClicked()}}})],1):_vm._e()]):_vm._e()])],1)],1):_c(VCol,[_c(VSkeletonLoader,_vm._b({attrs:{"transition":"fade-transition","type":"actions, table"}},'v-skeleton-loader',_vm.attrs,false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }