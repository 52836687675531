















import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import AeTextField from "@/components/common/TextField.vue";
import LotDto from "@/contracts/lot/LotDto";

@Component({ components: { AeTextField } })
export default class LotGeneralInfoPanel extends Vue {
  @PropSync("data", { type: Object }) lotSync!: LotDto;

  @Prop({ default: false })
  editable!: boolean;

  loading = false;
}
