
































import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { documentService } from "@/services/document.service";
import ReferenceData from "@/contracts/generic/ReferenceData";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { activityService } from "@/services/activity.service";
import AeTextField from "@/components/common/TextField.vue";
import AeFileInput from "@/components/common/FileInput.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import AeTextarea from "@/components/common/Textarea.vue";
import { documentTypes } from "@/constants/DocumentTypes";
import AeDatePicker from "@/components/common/DatePicker.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import OrganizationDto from "@/contracts/generic/OrganizationDto";
import { organizationService } from "@/services/organization.service";
import AllowedExtensionDto from "@/contracts/extension/AllowedExtensionsDto";
import { i18n } from "@/i18n";
import ExecutorDto from "@/contracts/organization/ExecutorDto";
import ContactDto from "@/contracts/generic/ContactDto";
import { userService } from "@/services/user.service";
import { DocumentDto } from "@/contracts/document/DocumentsDto";

@Component({
  components: {
    AeFileInput,
    AeAutocomplete,
    AeDatePicker,
    AeTextarea,
    AeTextField,
  },
})
export default class ChangeShareDocumentForm extends Vue {
  loading = false;

  eligibleContacts: ContactDto[] = [];

  @Prop({ default: null })
  documentDto!: DocumentDto | null;

  mounted(): void {
    this.init();
    console.warn("load doc", this.documentDto);
  }

  /* Prop documentSync may be available when mounted: when it is initialized diretcly in the component that uses this form component.
  Sometimes documentSync may not be available when mounted: when it is initialized in the mounted method of the component that uses this form component
  That's why we do the init on mount but also when documentSync gets changed
   */
  @Watch("documentSync")
  init(): void {
    this.fetchEligibleContacts();
  }

  fetchEligibleContacts() {
    if (this.userIsAuthorizedToGetEligibleContacts) {
      this.loading = true;
      try {
        userService
          .getEligibleUsers()
          .then((data) => {
            if (data) {
              this.eligibleContacts = data;
            }
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
      }
    }
  }

  get userIsAuthorizedToGetEligibleContacts(): boolean {
    return roleBasedActionsService.userIsAuthorizedToGetEligibleContacts();
  }
}
