








































import { Component } from "vue-property-decorator";
import FileInput from "@/components/common/FileInput.vue";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { documentServiceV2 } from "@/app/services/document.service";
import { DocumentFile } from "@/app/models/document/document.model";
import Loader from "@/components/common/Loader.vue";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";

export type FileInputMetaData = {
  accept?: string;
  iconOnRight?: boolean;

  multiple?: boolean;

  categoryId: string;
  categoryIdTemplate: string;

  typeIds: string[];
  typeIdsTemplate: string;

  description: string;
  descriptionTemplate: string;
};

@Component({
  components: {
    AutocompleteWithErrors,
    Loader,
    FileInput,
  },
})
export default class FileInputBase extends BaseDynamicComponent<FileInputMetaData> {
  documents: DocumentFile[] = [];
  internalValue: File[] = [];
  isProcessing = false;

  async postSetValueView(value: any) {
    // new value being set
    //empty is a reset
    if (!value) {
      this.documents = [];
    }

    if (!Array.isArray(value)) {
      value = [value];
    }
    const currentDocumentIds = this.documents.map(
      (value1) => value1.documentId
    );

    if (JSON.stringify(value) === JSON.stringify(currentDocumentIds)) {
      return;
    }

    for (let documentId of value) {
      if (documentId && !currentDocumentIds.includes(documentId)) {
        const doc = new DocumentFile();
        doc.setUploadedFile(documentId);
        this.documents.push(doc);
      }
    }
    await this.resolveDocuments(false);
  }

  onFileInputContentChanged(newValue: File[]) {
    const currentDocumentNames = this.documents.map((value1) => value1.name);

    //add files
    for (let file of newValue) {
      const alreadyExisist = currentDocumentNames.includes(file.name);
      if (!alreadyExisist) {
        const doc = new DocumentFile();
        doc.setNewFile(file, file.name);
        this.documents.push(doc);
      }
    }

    //remove files
    const newFileNames = newValue.map((value) => value.name);
    this.documents = this.documents.filter((document) => {
      return document.name && newFileNames.includes(document.name);
    });

    this.resolveDocuments(false);
  }
  onFileInputValidityChanged(isValid: boolean) {
    return;
  }

  async beforeSave(): Promise<boolean> {
    console.log(this.entry.value, this.documents);
    if (this.entry.value && Array.isArray(this.entry.value)) {
      // create and upload all docs
      let doc: DocumentFile;
      for (doc of this.documents) {
        // only upload new files
        if (!doc.uploaded) {
          let categoryId = this.entry.metadata.categoryId
            ? this.entry.metadata.categoryId
            : this.entry.root?.resolveDataPath(
                this.entry.metadata.categoryIdTemplate
              );
          let typeIds = this.entry.metadata.typeIds
            ? this.entry.metadata.typeIds
            : this.entry.root?.resolveDataPath(
                this.entry.metadata.typeIdsTemplate
              );
          let description = this.entry.metadata.description
            ? this.entry.metadata.description
            : this.entry.root?.resolveDataPath(
                this.entry.metadata.descriptionTemplate
              );
          doc.category = categoryId;
          doc.type = typeIds;
          doc.description = description;
          doc.activityId = this.$store.state.activity.currentActivity.id;

          //create entry
          const recordId = await documentServiceV2.createDocumentEntry(doc);
          doc.documentId = recordId;

          //upload doc
          const uploadResult = await documentServiceV2.uploadDocument(doc);
          if (!uploadResult) {
            //revert if failed
            console.warn("failed to upload file", doc, uploadResult);
            doc.documentId = "nan";
            return false;
          } else {
            doc.uploaded = true;
          }
        }
      }
      await this.resolveDocuments(true);
      return true;
    }
    return true;
  }

  async afterSave(sumbitData: any): Promise<boolean> {
    if (this.entry.value && Array.isArray(this.entry.value) && this.documents) {
      let doc: DocumentFile;
      for (doc of Array.from(this.documents)) {
        console.warn('submitData', sumbitData);
        const uploadResult = await documentServiceV2.adoptDocument(
          doc,
          sumbitData["type"],
          sumbitData["referenceId"]
        );
      }
      return true;
    }
    return true;
  }

  public downloadFile(doc: DocumentFile) {
    if (doc.fetched && doc.uploaded) {
      documentServiceV2
        .downloadDocument(doc)
        .then((value) => {
          return;
        })
        .catch((reason) => {
          this.$toast.error("Something went wrong grabbing your file.");
        });
    }
  }

  private async resolveDocuments(setDataImmediate: boolean) {
    this.isProcessing = true;
    for (let doc of this.documents) {
      if (doc.uploaded && !doc.fetched && doc.documentId) {
        var documentMetadata = await documentServiceV2.getDocumentMetadata(
          doc.documentId
        );
        doc.setFetchedMetadata(documentMetadata);
      } else if (!doc.uploaded && !doc.fetched) {
        let i = 0;
      }
    }
    this.internalValue = this.documents.map((doc) => {
      return doc.fileToupload
        ? doc.fileToupload
        : new File([], doc.name || "unknown.file", {});
    });
    this.setValueView(this.documents.map((value) => value.documentId));
    this.setValueEntry(
      this.documents.map((value) => value.documentId),
      setDataImmediate
    );
    this.isProcessing = false;
  }
}
