import PageConfig from "@/contracts/generic/PageConfig";
import PagedResult from "@/contracts/common/PagedResult";
import InspectionDto from "@/contracts/inspection/InspectionDto";
import InspectionFilterConfig from "@/contracts/inspection/InspectionFilterConfig";
import axios from "@/plugins/axios";
import InspectionSortConfig from "@/contracts/inspection/InspectionSortConfig";
import InspectionDetailDto from "@/contracts/inspection/InspectionDetailDto";
import { lotStatusMapper } from "@/mappers/lot-status.mapper";
import InspectionFilterDto from "@/contracts/inspection/InspectionFilterDto";
import store from "@/store";

class InspectionService {
  public getInspectionFilters(): Promise<InspectionFilterDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/inspections/filters`)
      .then((response) => {
        return response?.data;
      });
  }

  public async getInspections(
    pageConfig: PageConfig,
    sortConfig: InspectionSortConfig,
    filterConfig: InspectionFilterConfig
  ): Promise<PagedResult<InspectionDto>> {
    let url = `${store.state.appConfig.apiBaseUrl}api/inspections?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}`;
    if (filterConfig) {
      if (filterConfig.statuses && filterConfig.statuses.length) {
        filterConfig.statuses.forEach((status) => {
          url += `&statuses=${status}`;
        });
      }
      if (filterConfig.constructors && filterConfig.constructors.length) {
        filterConfig.constructors.forEach((constructor) => {
          url += `&constructors=${constructor}`;
        });
      }
    }
    if (
      sortConfig &&
      sortConfig.sortBy &&
      sortConfig.sortBy.length &&
      sortConfig.sortDescending &&
      sortConfig.sortDescending.length &&
      sortConfig.sortBy.length === sortConfig.sortDescending.length
    ) {
      sortConfig.sortBy.forEach((sortBy) => {
        url += `&sortBy=${sortBy}`;
      });
      sortConfig.sortDescending.forEach((sortDesc) => {
        url += `&sortDescending=${sortDesc}`;
      });
    }
    return axios.get(url).then((response) => {
      if (response && response.data) {
        response.data.results.map(
          (inspection: InspectionDto) =>
            (inspection.lotStatus = lotStatusMapper.mapLabelToTranslatableLabel(
              inspection.lotStatus
            ))
        );
      }
      return response?.data;
    });
  }

  public getInspectionDetail(lotId: string): Promise<InspectionDetailDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/inspections/${lotId}`)
      .then((response) => {
        return response?.data;
      });
  }
}

export const inspectionService = new InspectionService();
