import CreateDossierDto from "@/contracts/dossier/CreateDossierDto";
import DossierDto from "@/contracts/dossier/DossierDto";
import { lotMapper } from "./lot.mapper";
import LotDto from "@/contracts/lot/LotDto";
import { lotStatusMapper } from "@/mappers/lot-status.mapper";

class DossierMapper {
  public map(dto: DossierDto): DossierDto {
    const result = new DossierDto();

    result.id = dto.id;
    result.name = dto.name;

    result.address.country = dto.address ? dto.address.country : null;
    result.address.municipality = dto.address ? dto.address.municipality : null;
    result.address.postalCode = dto.address ? dto.address.postalCode : null;
    result.address.street = dto.address ? dto.address.street : null;
    result.address.number = dto.address ? dto.address.number : null;
    result.address.box = dto.address ? dto.address.box : null;
    result.address.latitude = dto.address ? dto.address.latitude : null;
    result.address.longitude = dto.address ? dto.address.longitude : null;

    result.contact.id = dto.contact ? dto.contact.id : null;
    result.contact.firstName = dto.contact ? dto.contact.firstName : null;
    result.contact.lastName = dto.contact ? dto.contact.lastName : null;
    result.contact.email = dto.contact ? dto.contact.email : null;
    result.contact.telephone = dto.contact ? dto.contact.telephone : null;

    result.planning.startDate = dto.planning ? dto.planning.startDate : null;
    result.planning.endDate = dto.planning ? dto.planning.endDate : null;

    result.diagnosis.diagnosisByCLevel = dto.diagnosis
      ? dto.diagnosis.diagnosisByCLevel
      : false;
    result.diagnosis.offerByBLevel = dto.diagnosis
      ? dto.diagnosis.offerByBLevel
      : false;

    result.activityDomains.activityDomains = dto.activityDomains
      ? dto.activityDomains.activityDomains
      : [];
    result.activityDomains.activity = dto.activityDomains
      ? dto.activityDomains.activity
      : "";

    dto.lots.map((lot: LotDto) => {
      lot.status = lotStatusMapper.mapLabelToTranslatableLabel(lot.status);
    });
    result.lots = dto.lots;

    return result;
  }

  mapToCreateDto(dto: DossierDto): CreateDossierDto {
    const result = new CreateDossierDto();

    result.id = dto.id;
    result.name = dto.name;
    result.address = dto.address;
    result.contact = dto.contact;
    result.planning = dto.planning;
    result.diagnosis = dto.diagnosis;
    result.activityDomains = dto.activityDomains;

    result.lots = dto.lots.map((lot) => lotMapper.mapToCreateDto(lot));

    return result;
  }
}

export const dossierMapper = new DossierMapper();
