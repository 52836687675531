import { DossierCreationModel } from "@/app/models/dossier/creation/dossier-creation.model";
import { DynamicFormEntryModel } from "@/app/dynamic-components/dynamic-components.model";
import {
  DynamicForm,
  DynamicFormEntry,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import { deepMergeObjects } from "@/app/helpers/stringpath.helper";

class DossierMapper {
  mapFromApi(data: any) {
    return {
      data: deepMergeObjects(data.data, data["dynamic_Properties"]),
      id: data.id,
      status: data.status,
      dossierDefinitionId: data.dossierDefinitionId,
    };
  }

  mapToApi(formData: any, model: DynamicForm, dossierDefinitionId: string) {
    const separatedFields = DynamicFormEntry.SEPERATE_FIELDS(formData, model);
    return {
      dossierDefinitionId: dossierDefinitionId,
      data: separatedFields.data,
      dynamic_properties: separatedFields.dynamicProperties,
    };
  }
}

export const dossierMapper = new DossierMapper();
