import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","width":"700"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("my-dossiers.add-new-lot"))+" ")]),_c(VCardText,[_c('steps-panel',{attrs:{"data":_vm.lot,"dossier":_vm.dossier,"editable":_vm.editable,"steps":_vm.steps}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.isSubmitDisabled,"loading":_vm.submittingLot,"color":"primary"},on:{"click":_vm.submit}},[_c('span',[_vm._v(_vm._s(_vm.$t("general.add")))])])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }