















import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component } from "vue-property-decorator";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { userServiceV2 } from "@/app/services/user.service";
import UserInfo from "@/contracts/generic/UserInfo";

export type MultiPersonMetaData = unknown;

@Component({})
export default class DetailComponentMultiPerson extends DetailComponentBase<MultiPersonMetaData> {
  value: string[] | null = null;
  contacts: UserInfo[] | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    this.resolveValue();
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    this.resolveValue();
  }

  private resolveValue() {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
      if (this.value && Array.isArray(this.value)) {
        userServiceV2
          .getUsersByIds(this.value)
          .then((userList) => {
            if (!userList || userList.length <= 0) return;
            this.contacts = userList;
          })
          .catch((reason) => {
            console.warn("Failed to fetch users");
          });
      }
    }
  }
}
