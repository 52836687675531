import IamRole from "@/contracts/iam/IamRole";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Language } from "@/contracts/common/Language";
import ExtendedActivityDto from "@/contracts/activity/ExtendedActivityDto";
import ActivityModel from "@/app/models/activity/activity.model";

@Module
export default class UserModule extends VuexModule {
  accessToken = "";
  isSignedIn = false;
  hasAcceptedPolicies = true;
  firstName = "";
  lastName = "";
  iamUserId = "";
  contactId = "";
  organizationId = "";
  membershipUserId = "";
  language = "";
  email = "";
  roles: IamRole[] | null = null;
  roleIds: string[] | null = null;
  activities: ExtendedActivityDto[] = [];
  @Mutation
  setContactId(id: string): void {
    this.contactId = id;
  }

  @Mutation
  setAccessToken(id: string): void {
    this.accessToken = id;
  }

  @Mutation
  setSignedIn(isSignedIn: boolean): void {
    this.isSignedIn = isSignedIn;
  }

  @Mutation
  setPoliciesAccepted(hasAcceptedPolicies: boolean): void {
    this.hasAcceptedPolicies = hasAcceptedPolicies;
  }

  @Mutation
  setFirstName(firstName: string): void {
    this.firstName = firstName;
  }

  @Mutation
  setLastName(lastName: string): void {
    this.lastName = lastName;
  }

  @Mutation
  setIamUserId(id: string): void {
    this.iamUserId = id;
  }

  @Mutation
  setOrganizationId(id: string): void {
    this.organizationId = id;
  }

  @Mutation
  setMembershipUserId(id: string): void {
    this.membershipUserId = id;
  }

  @Mutation
  setLanguage(lang: Language): void {
    this.language = lang.toUpperCase();
  }

  @Mutation
  setEmail(email: string): void {
    this.email = email;
  }

  @Mutation
  setRoles(roles: IamRole[]): void {
    this.roles = roles;
    this.roleIds = roles.map(value => value.id);
  }

  @Mutation
  setActivities(activities: ExtendedActivityDto[]): void {
    this.activities = activities;
  }
}
