




























import { Component, Prop, Vue } from "vue-property-decorator";
import Step from "@/contracts/common/Step";

@Component
export default class StepsOverview extends Vue {
  @Prop({ default: false })
  steps!: Step[];

  scroll(id: string): void {
    const target = document.getElementById(id);
    if (target) {
      target.scrollIntoView({
        behavior: "smooth",
      });
    }
  }
}
