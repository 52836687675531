


























import { Component, Prop, Vue } from "vue-property-decorator";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import { documentService } from "@/services/document.service";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";

@Component
export default class DocumentTile extends Vue {
  @Prop({ default: null })
  document!: DocumentDto | CreateDocumentDto;

  @Prop({ default: null })
  name!: string;

  @Prop({ default: false })
  documentToUpload!: boolean;

  @Prop({ default: true })
  allowDelete!: boolean;

  documentWasUploadedByMe(uploadedByMembershipId: string): boolean {
    return uploadedByMembershipId == this.$store.state.user.membershipUserId;
  }

  downloadDocument(id: string, fileName: string): void {
    if (
      this.documentToUpload &&
      this.document &&
      this.documentIsOfTypeDocumentDto()
    ) {
      const fileURL = window.URL.createObjectURL(
        (this.document as CreateDocumentDto).files
      );
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "file");
      document.body.appendChild(fileLink);
      fileLink.click();
    } else {
      documentService.getDocumentById(id, fileName);
    }
  }

  deleteDocument(id: string): void {
    if (this.allowDelete) {
      this.$emit("deleteDocument", id);
    }
  }

  documentIsOfTypeDocumentDto(): boolean {
    return Object.prototype.hasOwnProperty.call(this.document, "file");
  }
}
