import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,_vm._g({staticClass:"fill-height",class:{ 'action-tile--disabled': _vm.disabled, 'action-tile': _vm.clickable },attrs:{"ripple":!_vm.disabled && _vm.clickable}},_vm.clickable ? { click: _vm.onTileClick } : {}),[_c(VCardText,{staticClass:"fill-height"},[(_vm.droppable && !_vm.disabled)?_c('div',{staticClass:"action-tile__call-to-action fill-height",class:{
        'action-tile__is-dragover': _vm.isDragover,
      },on:{"drag":_vm.stopPropagation,"dragstart":_vm.stopPropagation,"dragover":_vm.draggingStarted,"dragenter":_vm.draggingStarted,"dragleave":_vm.draggingStopped,"dragend":_vm.draggingStopped,"drop":_vm.fileDropped}},[_c('action-tile-content',{staticClass:"fill-height",attrs:{"image":_vm.image,"max-image-width":_vm.maxImageWidth,"max-image-height":_vm.maxImageHeight,"icon":_vm.icon,"title":_vm.title,"subtitle":_vm.subtitle}})],1):_c('action-tile-content',{staticClass:"fill-height",class:{
        'action-tile__call-to-action': _vm.border,
      },attrs:{"image":_vm.image,"max-image-width":_vm.maxImageWidth,"max-image-height":_vm.maxImageHeight,"icon":_vm.icon,"title":_vm.title,"subtitle":_vm.subtitle}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }