








































import { Component, Vue } from "vue-property-decorator";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import { dossierService } from "@/services/dossier.service";
import { DossierStatusDto } from "@/contracts/dossier/DossiersDto";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import OrganizationDto from "@/contracts/generic/OrganizationDto";
import { organizationService } from "@/services/organization.service";
import DocumentFilterDto from "@/contracts/document/DocumentFilterDto";
import DossierFilterDto from "@/contracts/dossier/DossierFilterDto";
import { documentService } from "@/services/document.service";

@Component({
  components: { AeAutocomplete },
})
export default class MyDossiersFilters extends Vue {
  loading = false;

  selectedStatuses: string[] = [];
  selectedOrganizations: string[] = [];

  filters: DossierFilterDto | null = null;

  get userIsAuthorizedToFilterDossiersByContractor(): boolean {
    return roleBasedActionsService.userIsAuthorizedToFilterDossiersByOrganization();
  }

  mounted(): void {
    this.fetchFilterData();
  }

  fetchFilterData(): void {
    this.loading = true;

    dossierService
      .getDossierFilters()
      .then((data) => {
        if (data) {
          this.filters = data;
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  statusesUpdated(statuses: string[]): void {
    this.selectedStatuses = statuses;
    this.filterConfigUpdated();
  }

  contractorsUpdated(contractors: string[]): void {
    this.selectedOrganizations = contractors;
    this.filterConfigUpdated();
  }

  private filterConfigUpdated(): void {
    this.$emit("filterConfigUpdated", {
      statuses: this.selectedStatuses,
      organizations: this.selectedOrganizations,
    });
  }
}
