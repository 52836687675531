




































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import { Dossier } from "@/contracts/dossier/DossiersDto";
import { Routes } from "@/router";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import { TableColumns } from "@/constants/TableColumns";

@Component
export default class MyDossiersTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  pageConfig!: PageConfig;

  @Prop({ default: null })
  sortConfig!: SortConfig;

  headers: DataTableHeader[] = [
    {
      text: this.$t("my-dossiers.title") as string,
      value: TableColumns.TITLE,
      sortable: false,
    },
    {
      text: this.$t("my-dossiers.location") as string,
      value: TableColumns.LOCATION,
      sortable: false,
    },
    {
      text: this.$t("my-dossiers.status") as string,
      value: TableColumns.STATUS,
      sortable: false,
    },
    {
      text: this.$t("my-dossiers.start-date") as string,
      value: TableColumns.START_DATE,
      sortable: true,
    },
    {
      text: this.$t("my-dossiers.end-date") as string,
      value: TableColumns.END_DATE,
      sortable: false,
    },
    {
      text: this.$t("my-dossiers.technical-contact") as string,
      value: TableColumns.TECHNICAL_CONTACT,
      sortable: false,
    },
  ];

  organizationHeader = {
    text: this.$t("my-dossiers.contractor") as string,
    value: TableColumns.ORGANIZATION,
    sortable: false,
  };

  get dossiers(): Dossier[] {
    return this.$store.state.dossiers.dossiers;
  }

  get amountOfDossiers(): number {
    return this.$store.state.dossiers.amountOfDossiers;
  }

  get userIsAuthorizedToFilterDossiersByOrganization(): boolean {
    return roleBasedActionsService.userIsAuthorizedToFilterDossiersByOrganization();
  }

  mounted(): void {
    this.initHeaders();
  }

  initHeaders(): void {
    if (this.userIsAuthorizedToFilterDossiersByOrganization) {
      this.headers.push(this.organizationHeader);
    }
  }

  pageUpdated(page: number | undefined): void {
    if (page) {
      this.pageConfig.page = page;
      this.emitPageConfigUpdatedEvent();
    }
  }

  itemsPerPageUpdated(pageSize: number | undefined): void {
    if (pageSize) {
      this.pageConfig.pageSize = pageSize;
      this.emitPageConfigUpdatedEvent();
    }
  }

  emitPageConfigUpdatedEvent(): void {
    this.$emit("pageConfigUpdated", this.pageConfig);
  }

  sortByUpdated(sortBy: string | undefined): void {
    if (sortBy) {
      this.sortConfig.sortBy = sortBy;
      this.emitSortConfigUpdatedEvent();
    }
  }

  sortDescUpdated(sortDescending: boolean | undefined): void {
    if (sortDescending != undefined) {
      this.sortConfig.sortDesc = sortDescending;
      this.emitSortConfigUpdatedEvent();
    }
  }

  emitSortConfigUpdatedEvent(): void {
    this.$emit("sortConfigUpdated", this.sortConfig);
  }

  showDetails(item: Dossier): void {
    this.$router.push({
      name: Routes.DossierDetails,
      params: {
        id: item.id,
      },
    });
  }
}
