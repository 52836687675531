


















































import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosPromise } from "axios";

@Component
export default class ConfirmationDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: "" })
  icon!: boolean;

  @Prop({ default: "" })
  title!: boolean;

  @Prop({ default: "" })
  confirmLabel!: boolean;

  @Prop({ default: "" })
  submitAction!: () => AxiosPromise;

  @Prop({ default: "" })
  submitSuccessMessage!: string;

  submitting = false;

  close(): void {
    this.$emit("close");
  }

  submit(): void {
    this.submitting = true;

    this.submitAction()
      .then(() => {
        Vue.$toast.success(this.$t(this.submitSuccessMessage));
        this.$emit("submitDone");
        this.close();
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
