import DeleteDossierEvent from "@/app/events/dossier/delete-dossier.event";
import EventBase from "@/app/events/event-base";
import EditDossierEvent from "@/app/events/dossier/edit-dossier.event";
import ModifyLotsEvent from "@/app/events/dossier/modify-lots.event";
import CancelDossierEvent from "@/app/events/dossier/cancel-dossier.event";
import CompleteDossierEvent from "@/app/events/dossier/complete-dossier.event";

interface EventDictionaryInterface {
  [key: string]: EventBase;
}

export const EventDictionary: EventDictionaryInterface = {
  deleteDossier: new DeleteDossierEvent(),
  editDossier: new EditDossierEvent(),
  modifyLotsEvent: new ModifyLotsEvent(),
  cancelDossier: new CancelDossierEvent(),
  completeDossier: new CompleteDossierEvent(),
};
