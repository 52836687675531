import Vue from "vue";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import "./styles/main.css";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import {i18n} from "./i18n";
import {msalService} from "@/services/msal.service";
import {configService} from "@/services/config.service";
import AppConfig from "@/contracts/generic/AppConfig";
import VueFilterDateFormat from "@vuejs-community/vue-filter-date-format";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import moment from "moment/moment";
import {overviewCellDependencies} from "@/app/dynamic-components/overviews/cells/overview-cell.dependencies";
import {formComponentsDependencies} from "@/app/dynamic-components/forms/form-components/form-components.dependencies";
import {
    dossierDetailComponentsDependencies
} from "@/app/dynamic-components/details/details-components/detail-components.dependencies";
import {
    overviewExpanderDependencies
} from "@/app/dynamic-components/overviews/expanders/overview-expander.dependencies";
import analyticsService from '@/services/analytics.service';


Vue.config.productionTip = false;

Vue.use(Toast, {});

Vue.use(VueFilterDateFormat);


overviewCellDependencies.registerDependencies();
overviewExpanderDependencies.registerDependencies();
formComponentsDependencies.registerDependencies();
dossierDetailComponentsDependencies.registerDependencies();

Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

Vue.filter("formatDate", function (value: Date) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});

configService
    .getConfig()
    .then((appConfig: AppConfig) => {
        store.commit("setClientId", appConfig.clientId);
        store.commit("setTenantName", appConfig.tenantName);
        store.commit("setApiBaseUrl", appConfig.apiBaseUrl);
        store.commit("setBccaId", appConfig.bccaId);
        const userId = appConfig.clientId || 'defaultUserId'; // Retrieve userId from appConfig
        const organization = appConfig.tenantName || 'defaultOrganization'; // Retrieve organization from appConfig
        const activity = appConfig.apiBaseUrl || 'defaultActivity'; // Retrieve activity from appConfig
        console.log('Setting user context:', {userId, organization, activity});
        analyticsService.setUserContext(userId, organization, activity);

        return msalService.init();
    })
    .then(() => {

        router.beforeEach((to, from, next) => {
            console.log('route changed to: ' + to.path + ' with name: ' + to.name)
            analyticsService.trackPageView(to.path);
            next();
        });

        new Vue({
            router,
            store,
            vuetify,
            i18n,
            render: (h) => h(App),
        }).$mount("#app");
    });
