












import { Component, Vue } from "vue-property-decorator";
import ActionTile from "@/components/common/ActionTile.vue";

@Component({
  components: {
    ActionTile,
  },
})
export default class EmptyInspectionsTile extends Vue {}
