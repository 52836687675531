








import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";

@Component({
  components: {},
})
export default class OverviewCellLink extends OverviewCellBase {
  mounted(): void {
    const i = 0;
  }

  public cellText(): string {
    const label = this.resolvePlaceholdersString(this.metadata.label);
    return (this.metadata["translated"] ? this.$t(label) : label) || "-";
  }

  public path(): string {
    const path = this.resolvePlaceholdersString(this.value);
    return path;
  }

  get iconColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "primary";
  }
}
