import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Question } from "@/contracts/faq/QuestionDto";
import { Release } from "@/contracts/faq/ReleaseDto";

@Module
export default class FaqModule extends VuexModule {
  amountOfQuestions = 0;
  amountOfReleases = 0;
  questions: Question[] = [];
  releases: Release[] = [];

  @Mutation
  setAmountOfQuestions(amountOfQuestions: number): void {
    this.amountOfQuestions = amountOfQuestions;
  }

  @Mutation
  setQuestions(questions: Question[]): void {
    this.questions = questions;
  }

  @Mutation
  setAmountOfReleases(amountOfReleases: number): void {
    this.amountOfReleases = amountOfReleases;
  }

  @Mutation
  setReleases(releases: Release[]): void {
    this.releases = releases;
  }
}
