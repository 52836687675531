import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('div',{staticClass:"label pb-4"},[_c('i18n',{attrs:{"path":_vm.label,"tag":"span"}}),(_vm.required)?_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" *")])]):_vm._e()],1):_vm._e(),(!_vm.selectRootNodesOnly)?_c('div',[(!_vm.editable)?_c(VTreeview,{attrs:{"value":_vm.selectedTechniqueSteps,"items":_vm.availableTechniques,"item-children":"techniqueSteps","item-disabled":"notEditable","selected-color":"undefined","item-key":"id","selectable":""},on:{"input":_vm.techniqueStepsSelectionUpdated},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c(VRow,{staticClass:"mt-2 mb-2",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.$t(item.translationKey)))]),(item.activityDomainTranslationKey)?_c(VCol,[_c('strong',[_vm._v(_vm._s(_vm.$t(item.activityDomainTranslationKey)))])]):_vm._e()],1)]}}],null,false,3392485381)}):_c(VTreeview,{attrs:{"value":_vm.selectedTechniqueSteps,"items":_vm.availableTechniques,"item-children":"techniqueSteps","item-key":"id","selectable":"","open-all":"","selected-color":"undefined"},on:{"input":_vm.techniqueStepsSelectionUpdated},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c(VRow,{staticClass:"mt-2 mb-2",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.$t(item.translationKey)))]),(item.activityDomainTranslationKey && _vm.showActivityDomain)?_c(VCol,[_c('strong',[_vm._v(_vm._s(_vm.$t(item.activityDomainTranslationKey)))])]):_vm._e()],1)]}}],null,false,702674347)})],1):_c('div',_vm._l((_vm.availableTechniques),function(item){return _c(VCheckbox,{key:item.id,staticClass:"py-0 my-0",attrs:{"value":item.id,"disabled":!_vm.editable},on:{"change":_vm.techniquesSelectionUpdated},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('b',[_vm._v(_vm._s(_vm.$t(item.translationKey)))]),_c('div',{staticClass:"technique-steps text-wrap"},[_vm._v(" "+_vm._s(_vm.$t("lot.technique-steps"))+": "+_vm._s(_vm.getTechniqueSteps(item))+" ")])])]},proxy:true}],null,true),model:{value:(_vm.selectedTechniques),callback:function ($$v) {_vm.selectedTechniques=$$v},expression:"selectedTechniques"}})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }