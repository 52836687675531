import axios from "@/plugins/axios";
import ReferenceData from "@/contracts/generic/ReferenceData";
import store from "@/store";

class CountryService {
  public getCountries(): Promise<ReferenceData[]> {
    return axios.get(`${store.state.appConfig.apiBaseUrl}api/countries`).then((response) => {
      return response?.data;
    });
  }
}

export const countryService = new CountryService();
