import { i18n } from "@/i18n";

export const validationRules = {
  required: (value: string): boolean | string =>
    !!value || (i18n.t("validation.required") as string),
  email: (value: string): boolean | string | undefined => {
    if (value && value.length > 0) {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return (
        pattern.test(value) || (i18n.t("validation.invalid-email") as string)
      );
    }
  },
  phone: (value: string): boolean | string | undefined => {
    if (value && value.length > 0) {
      const pattern =
        /^(((\+|00)32[ ]?(?:\(0\)[ ]?)?)|0){1}(4(60|[789]\d)\/?(\s?\d{2}\.?){2}(\s?\d{2})|(\d\/?\s?\d{3}|\d{2}\/?\s?\d{2})(\.?\s?\d{2}){2})$/;
      return (
        pattern.test(value) || (i18n.t("validation.invalid-phone") as string)
      );
    }
  },
};
