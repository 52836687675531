



import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { Component } from "vue-property-decorator";
import { documentService } from "@/services/document.service";

@Component({
  components: {},
})
export default class OverviewCellDownload extends OverviewCellBase {
  mounted(): void {
    const i = 0;
  }

  get iconColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "grey-darken-2";
  }

  downloadDocument() {
    documentService.getDocumentById(this.item["id"], this.item["name"]);
  }
}
