















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AeLabel extends Vue {
  @Prop({ default: null })
  path!: string;

  @Prop({ default: "medium" })
  height!: "low" | "medium" | "high";
}
