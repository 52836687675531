export default class CreateDocumentDto {
  id: string | null;
  files: File[] | null;
  name: string | null;
  description: string | null;
  organization: string | null;
  category: string | null;
  type: string | null;
  activity: string | null;
  eligibleContacts: string[] | null;
  dossier: string | null;

  constructor() {
    this.id = null;
    this.files = null;
    this.name = null;
    this.description = null;
    this.category = null;
    this.organization = null;
    this.type = null;
    this.activity = null;
    this.eligibleContacts = null;
    this.dossier = null;
  }
}
