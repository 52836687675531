



































import { Component, Prop, Vue } from "vue-property-decorator";
import { validationRules } from "@/constants/ValidationRules";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import { ValidationError } from "@/app/dynamic-components/forms/form-validations/form-validator.base";

@Component({
  components: { AutocompleteWithErrors },
})
export default class AeFileInput extends Vue {
  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: [] })
  errors!: ValidationError[];
  @Prop({ default: [] })
  errorMessages!: string[];

  @Prop({ default: null })
  value!: unknown;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: false })
  accept!: string;

  @Prop({ default: false })
  iconOnRight!: boolean;

  @Prop({ default: false })
  multiple!: boolean;

  rules: unknown[] = [];

  mounted(): void {
    if (this.required) {
      this.rules.push(validationRules.required);
    }
  }

  update(newValue: File[]): void {
    if (
      Array.isArray(newValue) &&
      newValue.length !== 0 &&
      this.value !== null &&
      Array.isArray(this.value)
    ) {
      this.value.forEach((file) => {
        newValue.push(file);
      });
    }

    this.$emit("input", newValue);
  }

  closeChip(index: number) {
    if (this.value instanceof Array) {
      this.value.splice(index, 1);
    }

    this.$emit("input", this.value);
  }
}
