

















import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component, Prop } from "vue-property-decorator";
import { EventDictionary } from "@/app/events/event.dictionary";

export type DetailButtonMetaData = unknown;

@Component({})
export default class DossierDetailComponentButton extends DetailComponentBase<DetailButtonMetaData> {
  @Prop({ default: false })
  disabled!: boolean;
  @Prop({ default: null })
  text!: string;
  @Prop({ default: null })
  color!: string;
  @Prop({ default: null })
  startIcon!: string;
  @Prop({ default: null })
  endIcon!: string;
  @Prop({ default: null })
  eventId!: string;

  buttonColors = [
    { key: "NormalButton", value: "primary" },
    { key: "InfoButton", value: "blue" },
    { key: "WarningButton", value: "orange" },
    { key: "DangerButton", value: "red" },
  ];

  getFrontColor(color: string) {
    if (color === "DangerButton") return "white!important";
  }

  emitClick(): void {
    this.$emit("click", this.eventId);
  }

  getButtonColor(buttonType: string) {
    return this.buttonColors.find((color) => color.key === buttonType)?.value;
  }
}
