








import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";

@Component({
  components: {},
})
export default class OverviewCellText extends OverviewCellBase {
  public cellText(): any {
    if (this.value === null || this.value === undefined) return "-";

    if (this.metadata["translated"]) {
      return this.$t(this.value);
    }

    return this.value;
  }

  get iconColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "primary";
  }

  protected PostResolveValue() {
    if (this.metadata?.translateList && this.value) {
      //entry in dataresolver
      let path = this.metadata.translateList;
      if (path.startsWith("data.")) {
        path = path.replace("data.", "");
      }
      const resolvedItems = getObjectContentsFromPath(
        path,
        this.additionalMetadata
      );
      if (Array.isArray(resolvedItems)) {
        resolvedItems
          .filter((it) => {
            return this.value === it[this.metadata.itemValue];
          })
          .forEach((value) => {
            this.value = value[this.metadata.itemText];
          });
      }
    }
  }
}
