import { Component, Vue, Watch } from "vue-property-decorator";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import {
  ActivitySecurity,
  DossierSecurity,
} from "@/app/models/activity/security.model";

@Component({})
export default class ActivityDependendViewBase extends Vue {
  get activityDefinition(): ActivityDefinitionModel {
    return this.$store.state.activity.currentActivityDefinition;
  }

  get activitySecurity(): ActivitySecurity {
    return this.$store.state.activity.currentActivitySecurity;
  }

  getDossierSecurity(dossierDefinitionId: string): DossierSecurity | null {
    return this.activitySecurity?.dossiers[dossierDefinitionId] || null;
  }

  get pageReady() {
    return this.activityDefinition?.id != "" && this.pageReadyFired;
  }

  private pageReadyFired = false;

  @Watch("activityDefinition", { immediate: true })
  private async pageIsReady(
    newValue: ActivityDefinitionModel,
    prevValue: ActivityDefinitionModel
  ) {
    if (
      newValue &&
      newValue != prevValue &&
      newValue.id != ""
    ) {
      if(!this.pageReadyFired){
        await this.onPageReady();
        this.pageReadyFired = true;
      }
      await this.onActivityUpdated();
    }
  }

  async onPageReady(): Promise<any> {
    return true;
  };

  async onActivityUpdated(): Promise<any> {
    return true;
  };
}
