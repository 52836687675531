











import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { dateFormat } from "@vuejs-community/vue-filter-date-format";

@Component({
  methods: { dateFormat },
  components: {},
})
export default class OverviewCellDate extends OverviewCellBase {}
