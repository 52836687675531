import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

@Module
export default class AppConfigModule extends VuexModule {
  clientId = "";
  tenantName = "";
  apiBaseUrl = "";
  bccaId = "";
  menuName = "primaryNavigation";

  @Mutation
  setBccaId(bccaId: string): void {
    this.bccaId = bccaId;
  }
  @Mutation
  setClientId(clientId: string): void {
    this.clientId = clientId;
  }

  @Mutation
  setTenantName(tenantName: string): void {
    this.tenantName = tenantName;
  }

  @Mutation
  setApiBaseUrl(apiBaseUrl: string): void {
    this.apiBaseUrl = apiBaseUrl;
  }

  @Mutation
  setMenuOptions(menuName: string): void {
    this.menuName = menuName;
  }
}
