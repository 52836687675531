import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { RenderItem, RenderType } from "@/app/Types";
import {
  DynamicForm,
  DynamicFormEntry,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import { Component } from "vue-property-decorator";
import { ValidationError } from "@/app/dynamic-components/forms/form-validations/form-validator.base";

@Component({})
export default class BaseDynamicCompositeComponent<
  T
> extends BaseDynamicComponent<T> {
  definition: RenderItem | null = null;

  form: DynamicForm | null = null;
  recieveFormView(form: DynamicForm) {
    this.form = form;
    if (
      this.entry &&
      this.entry.value &&
      this.entry.value !== this.getDefaultValue()
    ) {
      this.setValueView(this.entry.value);
    }
  }

  public formData: any | null = null;
  public init = true;
  async onFormDataChanged(formData: any) {
    if (this.init && formData === null) {
      this.init = false;
      return;
    }
    this.formData = formData;
    this.setValueEntry(await this.convertFormDataToValue(formData));
  }
  postEntityChanged(newValue: DynamicFormEntry) {
    this.definition = this.createDefinition();
    if (
      newValue &&
      newValue.value &&
      newValue.value !== this.getDefaultValue()
    ) {
      this.setValueView(newValue.value);
    }
  }

  async getEmbeddedErrors(): Promise<ValidationError[]> {
    if (!this.form) return [];
    const errors = await this.form.getErrors();
    console.log('embedded errors', errors, this.entry.name);
    return errors;
  }

  async postSetValueView(value: any) {
    const val = await this.convertValueToFormData(value);
    if(!this.form) return;
    const currentValue = this.form.formData;

    if(currentValue === val) return;
    this.form.patchForm(val);
  }

  // to override functions:

  protected createDefinition(): RenderItem | null {
    return null;
  }

  protected async convertFormDataToValue(formData: any): Promise<any> {
    return null;
  }

  protected async convertValueToFormData(value: any): Promise<any> {
    return null;
  }

  public resetEmbeddedValidation() {
    this.form?.resetValidation();
  }
}
