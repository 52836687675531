



























import { Component, Watch } from "vue-property-decorator";
import Autocomplete from "@/components/common/Autocomplete.vue";
import BaseDynamicComponent from "../form-component.base";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import AutocompleteWithErrors from "@/app/components/AutocompleteWithErrors.vue";
import { translationService } from "@/services/translation.service";

export type SelectMetaData = {
  items: unknown[];
  itemText: string;
  itemValue: string;

  label?: string;
  disabled?: boolean;
  value?: string;
  chips: boolean;
  clearable: boolean;
  dense: boolean;
  multiple: boolean;

  loading: boolean;
  deletableChips: boolean;
  translate: boolean;
  autoSetOneItem: boolean;
  sortAlphabetically: boolean;
};
@Component({
  components: {
    AutocompleteWithErrors,
    Autocomplete,
  },
})
export default class DynamicSelect extends BaseDynamicComponent<SelectMetaData> {
  selectedList: string[] | string = [];
  list: any[] = [];

  get displayList(): string {
    return this.list
      .filter((e) => {
        if (this.entry?.metadata?.itemValue) {
          return this.selectedList.includes(e[this.entry?.metadata?.itemValue]);
        } else {
          return this.selectedList.includes(e);
        }
      })
      .map((e) => {
        if (this.entry?.metadata?.itemText) {
          return e[this.entry?.metadata?.itemText];
        } else {
          return e;
        }
      })
      .map(value => {
        if(this.entry.metadata.translate){
          return this.$tc(value);
        }
        return value;
      })
      .join(", ");
  }

  postEntityChanged(entry: DynamicFormEntry) {
    if (entry && entry.metadata) {
      if (entry.metadata.items && Array.isArray(entry.metadata.items)) {
        //entry in metadata
        this.list = entry.metadata.items;
      } else if (
        entry.metadata.items &&
        typeof entry.metadata.items === "string"
      ) {
        //entry in dataresolver
        const resolvedItems = this.entry.root?.resolveDataPath(
          entry.metadata.items
        );
        if (!resolvedItems) {
          this.list = [];
        } else {
          if (Array.isArray(resolvedItems)) {
            if (entry.metadata.property) {
              this.list = resolvedItems.map((p) => p[entry.metadata.property]);
            } else {
              this.list = resolvedItems;
            }
          } else {
            if (!this.entry.metadata.itemText)
              this.entry.metadata.itemText = "value";
            if (!this.entry.metadata.itemValue)
              this.entry.metadata.itemValue = "key";
            this.list = Object.entries(resolvedItems).map((value) => {
              return { key: value[0], value: value[1] };
            });
          }
        }
      }
    }
  }

  @Watch("selectedList")
  onPersonsChanged() {
    console.warn("cganged list", this.selectedList);
    this.setValueView(this.selectedList);
  }
  postSetValueView(value: any) {
    if (!value) {
      this.selectedList = [];
    } else {
      if (JSON.stringify(value) !== JSON.stringify(this.selectedList)) {
        this.selectedList = value;
      }
    }
  }
}
