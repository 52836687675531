



















import { Component, Prop, Vue } from "vue-property-decorator";
import PageConfig from "@/contracts/generic/PageConfig";
import { Release } from "@/contracts/faq/ReleaseDto";
import ReleaseSteps from "@/components/faq/ReleaseSteps.vue";

@Component({
  components: { ReleaseSteps },
})
export default class ReleaseTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  pageConfig!: PageConfig;

  get releases(): Release[] {
    return this.$store.state.faq.releases;
  }

  get amountOfReleases(): number {
    return this.$store.state.faq.amountOfReleases;
  }

  pageUpdated(page: number | undefined): void {
    if (page) {
      this.pageConfig.page = page;
      this.emitPageConfigUpdatedEvent();
    }
  }

  itemsPerPageUpdated(pageSize: number | undefined): void {
    if (pageSize) {
      this.pageConfig.pageSize = pageSize;
      this.emitPageConfigUpdatedEvent();
    }
  }

  emitPageConfigUpdatedEvent(): void {
    this.$emit("pageConfigUpdated", this.pageConfig);
  }
}
