









































import { Prop, Component, Vue } from "vue-property-decorator";
import { RootRenderItem } from "@/app/Types";
import BaseSectionDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component-section.base";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";

@Component({
  computed: {
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class AccordionView extends BaseSectionDynamicComponent<any> {
  defaultAllExpanded = [0];

  postEntityChanged(newValue: DynamicFormEntry) {
    if (
      this.entry &&
      this.entry.children &&
      this.entry.children.length !== this.defaultAllExpanded.length
    ) {
      this.defaultAllExpanded = this.entry.children.map((value, index) => {
        return index;
      });
      console.warn("new entity", this.defaultAllExpanded);
    }
  }

  constructor() {
    super();
  }
}
