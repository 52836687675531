import { ContextAware, DetailContext } from "@/app/contexts/detail.context";
import { ExternalContext } from "@/app/contexts/externalContext";

class RuleEngine {
  public resolveRule(context: ContextAware, ruleLine: string): boolean{
    if(!context) return false;
    if(!ruleLine || ruleLine === '') return true;

    while(ruleLine.includes('(') && ruleLine.includes(')')){
      ruleLine = this.resolveParenthesis(context, ruleLine);
    }

    let ruleAddition = '&&'
    let rules: string[] = [];
    if(ruleLine.includes('||')){
      ruleAddition = '||';
      rules = ruleLine.split('||');
    }else if(ruleLine.includes('&&')){
      ruleAddition = '&&';
      rules = ruleLine.split('&&');
    }else{
      rules = [ruleLine]
    }

    const results: boolean[] = [];

    rules.forEach(rule => {
      if(rule === 'true' || rule === 'false'){
        results.push(rule === 'true');
        return;
      }

      // 'data.dossier.lot:{eq:test}'
      // 'data.user.roles:{incl:test}'
      const indexOfSplit = rule.indexOf(':');
      if(indexOfSplit < 0) {
        results.push(false);
        return;
      }

      const field = rule.substring(0,indexOfSplit);
      const toParse = rule.substring(indexOfSplit+1);

      let op = '';
      let param = '';
      if(toParse.startsWith('{')){
        const indexOfOpsSplit = toParse.indexOf(':');
        if(indexOfOpsSplit < 0) return false;
        op = toParse.substring(1, indexOfOpsSplit);
        param = toParse.substring(indexOfOpsSplit + 1);
        param = param.substring(0, param.length-1)
      }else{
        op = 'eq';
        param = toParse;
      }
      if(op === ''){
        results.push(false);
        return;
      }

      const result = this.resolveRuleDo(context, field, op, param);
      results.push(result);
    })

    console.log(results);
    if(ruleAddition === '||'){
      return results.includes(true);
    }else if(ruleAddition === '&&'){
      return !results.includes(false);
    }else{
      return false;
    }
  }

  private resolveParenthesis(context: ContextAware, ruleLine: string) {
    const regex = /\(([^()]+)\)/
    const results = ruleLine.match(regex);
    if (!results || results.length <= 0) return ruleLine;
    const result = this.resolveRule(context, results[1])

    const replacedRuleLine = ruleLine.replace('('+ results[1] + ')', result+'');
    return replacedRuleLine
  }

  private resolveRuleDo(context: ContextAware, field: string, op: string, param: string): boolean {
    let fieldValue = context.resolveDataPath(field);


    switch (op){
      case 'eq':
        if(typeof fieldValue !== "string" && typeof fieldValue !== "number") fieldValue = JSON.stringify(fieldValue);
        if(fieldValue === undefined) fieldValue = 'undefined';
        if(fieldValue === null) fieldValue = 'null';
        console.log('rule exec', field, fieldValue, op, param, fieldValue === param);
        return fieldValue === param;
      case 'neq':
        if(typeof fieldValue !== "string" && typeof fieldValue !== "number") fieldValue = JSON.stringify(fieldValue);
        if(fieldValue === undefined) fieldValue = 'undefined';
        if(fieldValue === null) fieldValue = 'null';
        console.log('rule exec', field, fieldValue, op, param, fieldValue !== param);
        return fieldValue !== param;
      case 'incl':
        if(!fieldValue) return false;
        if(Array.isArray(fieldValue)) return Array.from(fieldValue).includes(param);
        if(typeof fieldValue === "object") return Object.keys(fieldValue).includes(param);
        return false;
      default:
        return false;
    }
  }
}

export const ruleEngine = new RuleEngine();
