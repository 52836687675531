



























































































































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component, Prop } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import Loader from "@/components/common/Loader.vue";
import { RenderType } from "@/app/Types";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import {
  Exchange,
  ExchangeControl,
  ExchangeEvent,
  ExchangeStep,
} from "@/app/components/exchange/exchange.model";
import {
  DynamicDetailEntry,
  ExchangeDefinition,
  ExchangeDefinitionStep,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { exchangeService } from "@/app/services/exchange.service";
import { Subject, Subscription } from "rxjs";
import { ExternalContext } from "@/app/contexts/externalContext";

export type DetailComponentExchangeStepsMetaData = {
  exchangeDefinitionId: string;
  involvedParties: { [key: string]: string };
  referenceId: string;
  referenceType: string;
};

@Component({
  computed: {
    RenderType() {
      return RenderType;
    },
  },
  components: { ExchangeComponent, Loader, FormWrapper, OverviewComponent },
})
export default class DetailComponentExchangeSteps extends DetailComponentBase<DetailComponentExchangeStepsMetaData> {
  protected externalContext = new ExternalContext();

  get attrs() {
    return {
      class: "mb-6",
      boilerplate: false,
      elevation: 2,
    };
  }

  @Prop({ default: null })
  exchangeId!: string | null;

  showExchangeDialog = false;
  private controlbus: Subject<ExchangeEvent> | null = null;
  private controlbusSubscription: Subscription | null = null;
  exchangeDefinition: ExchangeDefinition = {} as ExchangeDefinition;
  protected exchange: Exchange | null = null;

  private exchangeSteps: { [key: string]: ExchangeStep } = {};

  private loadingExchange = true;

  async postEntityChanged(newValue: DynamicDetailEntry) {
    this.loadingExchange = true;
    if (newValue && this.entry) {
      if (this.metadata.exchangeDefinitionId) {
        //get stepper data
        this.exchangeDefinition = await exchangeService.getExchangeDefinition(
          this.metadata.exchangeDefinitionId
        );
        await this.fetchOrPlaceholderExchange();
      }
    }
  }

  setShowExchangeDialog(step: ExchangeDefinitionStep) {
    this.externalContext = this.externalContext.inherit(this.detailContext);
    this.showExchangeDialog = true;
    setTimeout(()=>{
      if (this._exchangeControl) {
        this._exchangeControl.setStepPosition(step.id);
      }
    },1000);
  }

  private _exchangeControl: ExchangeControl | undefined;
  recieveExchangeControl(exchangeControl: ExchangeControl) {
    this._exchangeControl = exchangeControl;
  }

  recieveControlBus(controlbus: Subject<ExchangeEvent>) {
    if (this.controlbusSubscription) {
      this.controlbusSubscription.unsubscribe();
    }
    this.controlbus = controlbus;
    this.controlbusSubscription = this.controlbus.subscribe((event) => {
      switch (event.action) {
        case "CLOSE":
          this.showExchangeDialog = false;
          this.fetchOrPlaceholderExchange();
          break;
      }
    });
  }

  private async fetchOrPlaceholderExchange() {
    this.loadingExchange = true;
    await exchangeService
      .getFirstExchange(
        this.metadata.exchangeDefinitionId,
        this.metadata.referenceId
      )
      .then((value) => {
        if (value) {
          this.exchange = value;
          this.exchangeId = value.id;
        } else {
          this.exchange = ExchangeComponent.CONSTRUCT_EXCHANGE(
            this.exchangeDefinition
          );
          this.exchangeId = null;
        }
        this.exchangeSteps = {};
        this.exchange?.steps.forEach((value) => {
          this.exchangeSteps[value.id] = value;
        });
        this.loadingExchange = false;
      })
      .catch((reason) => {
        this.$toast.error("Failed to fetch current steps for dossier");
        this.exchangeSteps = {};
      });
  }
}
