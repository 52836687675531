import LotStatusDto from "./LotStatusDto";

export default class LotStatus extends LotStatusDto {
  translationKey: string | null;

  constructor() {
    super();
    this.translationKey = null;
  }
}
