import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"value":_vm.show,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"width":"400"},on:{"input":_vm.close}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("lot.qualified-employees"))+" ")]),_c(VCardText,[_c('qualified-employees-list',{attrs:{"employees":_vm.employees,"nameProperty":_vm.nameProperty,"showTableHeading":true}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }