import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticClass:"document-tile"},[_c(VListItemAvatar,{on:{"click":function($event){return _vm.downloadDocument(_vm.document.id, _vm.document.name)}}},[_c(VIcon,{staticClass:"pr-1 primary--text"},[_vm._v("mdi-file-document")])],1),_c(VListItemContent,{on:{"click":function($event){return _vm.downloadDocument(_vm.document.id, _vm.document.name)}}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.name)}})],1),(
      _vm.documentWasUploadedByMe(_vm.document.uploadedByMembershipId) ||
      _vm.documentToUpload
    )?_c(VListItemIcon,[(_vm.allowDelete)?_c(VIcon,{attrs:{"title":_vm.$t('my-documents.delete-document')},on:{"click":function($event){return _vm.deleteDocument(_vm.document.id)}}},[_vm._v("mdi-delete")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }