











































import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { DossierCreationModel } from "@/app/models/dossier/creation/dossier-creation.model";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import router from "@/router";
import DynamicComponentsEntryList from "@/app/components/dynamic-components-entry-list.vue";
import { Subject, Subscription } from "rxjs";
import { formService } from "@/app/services/form.service";
import { RenderType } from "@/app/Types";
import {
  DynamicForm,
  FormEvent,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import { defaultDynamicFormEntry } from "@/app/dynamic-components/dynamic-components.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import analyticsService from "@/services/analytics.service";

@Component({
  computed: {
    RenderType() {
      return RenderType;
    },
  },
  components: {
    DynamicComponentsEntryList,
    AuthenticationInterceptor,
    PageHeader,
    AcceptPoliciesInterceptor,
    FormWrapper,
  },
})
export default class DossiersCreateView extends ActivityDependendViewBase {
  protected dossierDefinitionId = "";
  protected dossierDefinitionCreationModel: DossierCreationModel = {
    form: defaultDynamicFormEntry,
  };
  protected isSaving = false;
  protected formStartTime: number | null = null;

  protected externalContext = new ExternalContext();
  async onPageReady(): Promise<any> {
    this.dossierDefinitionId =
      this.activityDefinition?.dossiers?.entries[0]?.dossierCreationDefinitionId;
    if (this.dossierDefinitionId) {
      dossierServiceV2
        .getDossierCreationDefinition(this.dossierDefinitionId)
        .then((value) => {
          this.dossierDefinitionCreationModel = value;
          this.externalContext.setData("user", this.$store.state.user);
        })
        .catch((reason) => {
          console.error("Could not find creation definition model");
        });
    }
  }
  mounted(): void {
    this.trackFormStart();
  }

  goBack() {
    router.back();
  }

  formData: any = null;
  formDataChanged(formData: any) {
    console.warn("form data changed recieve", formData);
    this.formData = formData;
  }

  private form: DynamicForm = new DynamicForm({
    id: "",
    children: [],
    name: "",
    type: RenderType.LinearSection,
    displayMode: "editable",
    displayModeRule: "",
    computedValue: "",
    metadata: {},
  });

  initializedForm(form: DynamicForm) {
    this.form = form;
  }

  async handleCreateDossier() {
    /*if (!userInputFormData.valid) {
      Vue.$toast.error("Form not valid.");
      return;
    }*/
    const errors = await this.form.getErrors();
    console.warn("errors", errors, JSON.stringify(errors));
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      return;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      return;
    }

    this.isSaving = true;

    const beforeSaveSuccess = await this.form.beforeSave();
    if (!beforeSaveSuccess) {
      this.$toast.error("Couldn't save attachments.");

      this.isSaving = false;
      return;
    }

    dossierServiceV2
      .createNewDossier(this.formData, this.dossierDefinitionId, this.form)
      .then(async (res) => {
        Vue.$toast.success("Successfully saved.");

        const afterSaveSuccess = await this.form.afterSave({
          type: "dossier",
          referenceId: res.data,
        });
        if (!afterSaveSuccess) {
          this.$toast.error("Some post actions failed!");

          this.isSaving = false;
          return;
        }

        this.isSaving = false;
        this.trackFormSubmission();
        this.goBack();
      })
      .catch((reason) => {
        Vue.$toast.error("Something went wrong.");
        this.isSaving = false;
      });
  }
  // Function to track form start time
  trackFormStart() {
    this.formStartTime = Date.now();
    console.log('trackFormStart');
    analyticsService.sendEvent('Form Interaction', 'dossiers_create_form_open', 'dossiers-create-view Form Opened', 1);
  }

  // Function to track form submission time
  trackFormSubmission() {
    const formEndTime = Date.now();
    if (this.formStartTime) {
      const timeSpent = (formEndTime - this.formStartTime) / 1000; // Convert time spent to seconds
      console.log('trackFormSubmission timespent: ' + timeSpent);
      analyticsService.sendEvent('Form Interaction', 'dossiers_create_form_submit', 'dossiers-create-view Form Submitted ', 1, {
        time_spent: timeSpent,
      });
    }
  }
}
