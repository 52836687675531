































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import DossierDto from "@/contracts/dossier/DossierDto";
import { Routes } from "@/router";
import LotsTable from "@/components/lots/LotsTable.vue";
import AeSwitch from "@/components/common/Switch.vue";
import AddLotTile from "@/components/lots/AddLotTile.vue";
import { roleBasedActionsService } from "@/services/role-based-actions.service";

@Component({
  components: {
    AddLotTile,
    AeSwitch,
    LotsTable,
  },
})
export default class DossierLotsPanel extends Vue {
  @PropSync("data", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: true })
  canAddLot!: boolean;

  @Prop({ default: false })
  hasActivityDomain!: boolean;

  loading = false;
  showActiveLotsOnly = false;

  get userIsAuthorizedToCreateLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCreateLot();
  }

  addLot(): void {
    this.$emit("addLot");
  }

  showLotDetails(lotId: string): void {
    if (this.dossierSync.id) {
      this.$router.push({
        name: Routes.LotDetails,
        params: {
          dossierId: this.dossierSync.id,
          lotId: lotId,
        },
      });
    }
  }
}
