import { PlanningModel } from "@/app/models/planning/planning.model";
import axios from "@/plugins/axios";
import {
  CheckinModel,
  CompleteCheckinRequest,
  CreateCheckinRequest,
  UpdateCheckinRequest,
} from "@/app/models/checkin/checkin.model";
import {
  DynamicForm,
  DynamicFormEntry,
} from "@/app/dynamic-components/forms/dynamic-form.model";
import { convertTypeToApiType } from "@/app/helpers/api.helper";
import { Overview } from "@/app/models/overview/overview.model";
import store from "@/store";

export class CheckinService {
  async getCheckinByReferenceId(referenceId: string): Promise<CheckinModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/checkin?page=0&pageSize=100&sort=&filter=nrq_referenceid:'${referenceId}'`;
    return axios.get<Overview>(url).then((response) => {
      if (response?.data?._embedded?.results) {
        response.data._embedded.results = Array.from(
          response.data._embedded.results
        ).map((value) => this.convertGetCheckinToCheckin(value));
      }
      return response.data._embedded.results.length > 0
        ? response.data._embedded.results[0]
        : null;
    });
  }
  async getCheckin(checkinId: string): Promise<CheckinModel> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/checkin/${checkinId}`;
    return axios.get(url).then((response) => {
      return this.convertGetCheckinToCheckin(response.data);
    });
  }

  async createCheckin(form: DynamicForm, create: any): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/checkin`;
    const createRequest = this.convertFormDataToCheckinCreateRequest(
      form,
      create
    );
    createRequest.regardingType = convertTypeToApiType(
      createRequest.regardingType
    );
    return axios.post(url, createRequest).then((response) => {
      return response.data;
    });
  }

  async updateCheckin(
    form: DynamicForm,
    checkinId: string,
    update: any,
    cancelCheckin: boolean
  ): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/checkin/${checkinId}`;
    const updateRequest = this.convertFormDataToCheckinUpdateRequest(
      form,
      update
    );
    if (cancelCheckin) {
      updateRequest.status = "Canceled";
    } else {
      updateRequest.status = "Created";
    }
    return axios.patch(url, updateRequest).then((response) => {
      return response.data;
    });
  }

  async completeCheckin(
    form: DynamicForm,
    checkinId: string,
    update: any
  ): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/checkin/${checkinId}`;
    const updateRequest = this.convertFormDataToPlanningCompleteRequest(
      form,
      update
    );
    return axios.put(url, updateRequest).then((response) => {
      return response.data;
    });
  }

  async addPartyToCheckin(
    checkinId: string,
    contactId: string,
    contactType: "USER" | "ORGANISATION"
  ): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/checkin/${checkinId}/party`;

    return axios
      .post(url, {
        contactId: contactId,
        portalId: contactId,
        contactType: contactType,
      })
      .then((response) => {
        return response.data;
      });
  }

  private convertGetCheckinToCheckin(apiResponse: any): CheckinModel {
    apiResponse = { ...apiResponse, ...apiResponse.dynamic_Properties };
    apiResponse.status = apiResponse?.status?.toUpperCase();
    return apiResponse;
  }

  private convertFormDataToCheckinCreateRequest(
    form: DynamicForm,
    formdata: any
  ): CreateCheckinRequest {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;
    result.status = "CREATED";
    console.log("mappedValues", mappedValues, formdata, result);
    return result;
  }

  private convertFormDataToCheckinUpdateRequest(
    form: DynamicForm,
    formdata: any
  ): UpdateCheckinRequest {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;
    console.log("mappedValues", mappedValues, formdata, result);
    return result;
  }

  private convertFormDataToPlanningCompleteRequest(
    form: DynamicForm,
    formdata: any
  ): CompleteCheckinRequest {
    const mappedValues = DynamicFormEntry.SEPERATE_FIELDS(formdata, form);
    const result = mappedValues.data;
    result.dynamic_Properties = mappedValues.dynamicProperties;
    result.status = "Completed";
    console.log("mappedValues", mappedValues, formdata, result);
    return result;
  }
}
export const checkinService = new CheckinService();
