import { render, staticRenderFns } from "./UploadDocumentForm.vue?vue&type=template&id=1fca4725&scoped=true&"
import script from "./UploadDocumentForm.vue?vue&type=script&lang=ts&"
export * from "./UploadDocumentForm.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fca4725",
  null
  
)

export default component.exports