import { render, staticRenderFns } from "./DossierDetails.vue?vue&type=template&id=18e61030&scoped=true&"
import script from "./DossierDetails.vue?vue&type=script&lang=ts&"
export * from "./DossierDetails.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18e61030",
  null
  
)

export default component.exports