























import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";

export type DetailHeaderMetaData = {
  primaryColor: boolean;
  type: "h2" | "h3" | "h4";
  spacing: boolean
  selector: string;
};

@Component({})
export default class DetailComponentHeader extends DetailComponentBase<DetailHeaderMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (this.detailContext && this.entry) {
      this.value = this.metadata.selector
        ? this.metadata.selector
        : this.detailContext.resolvePlaceholders(this.entry.name);
    }
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.entry && this.detailContext) {
      this.value = this.metadata.selector
        ? this.metadata.selector
        : this.detailContext.resolvePlaceholders(this.entry.name);
    }
  }
}
