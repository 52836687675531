import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";

class DocumentMapper {
  public mapCreateDocumentDtoToFormData(dto: CreateDocumentDto): FormData {
    const formData = new FormData();
    if (dto && dto.files) {
      formData.append("file", dto.files[0]);
    }
    if (dto && dto.name) {
      formData.append("name", dto.name);
    }
    if (dto && dto.dossier) {
      formData.append("dossier", dto.dossier);
    }
    if (dto && dto.description) {
      formData.append("description", dto.description);
    }
    if (dto && dto.category) {
      formData.append("category", dto.category);
    }
    if (dto && dto.type) {
      formData.append("type", dto.type);
    }
    if (dto && dto.activity) {
      formData.append("activity", dto.activity);
    }
    if (dto && dto.organization) {
      formData.append("organization", dto.organization);
    }
    if (dto && dto.eligibleContacts) {
      for (let i = 0; i < dto.eligibleContacts.length; i++) {
        formData.append("eligibleContacts", dto.eligibleContacts[i]);
      }
    }
    return formData;
  }

  public mapSelfMonitoringReportDtoToFormData(
    dto: SelfMonitoringReportDto
  ): FormData {
    const formData = this.mapCreateDocumentDtoToFormData(dto);
    if (dto && dto.applicableDate) {
      formData.append("applicableDate", dto.applicableDate.toString());
    }
    return formData;
  }
}

export const documentMapper = new DocumentMapper();
