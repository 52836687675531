
















import { Component } from "vue-property-decorator";
import BaseSectionDynamicComponent from "../form-component-section.base";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";

type TestSectionMetaData = {
  test?: string;
};

@Component({
  computed: {
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class TestSection extends BaseSectionDynamicComponent<TestSectionMetaData> {}
