

















































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PageHeader extends Vue {
  @Prop({ default: "mdi-help-circle" })
  headerIconName!: string;

  @Prop({ default: null })
  headerTitle!: string;

  @Prop({ default: null })
  headerSubtitle!: string;

  @Prop({ default: null })
  backButtonTitle!: string;

  get hasPageTitleSlot(): boolean {
    return !!this.$slots.pageTitle;
  }

  get hasPageSubtitleSlot(): boolean {
    return !!this.$slots.pageSubtitle;
  }

  get hasReturnButtonSlot(): boolean {
    return !!this.$slots.returnButton;
  }

  backButtonClicked(): void {
    this.$emit("backButtonClicked");
  }
}
