








import { Component, Vue } from "vue-property-decorator";
import VueI18n from "vue-i18n";

@Component
export default class TermsOfUseView extends Vue {
  get termsOfUse(): string {
    const termsOfUse: VueI18n.TranslateResult = this.$t(
      "terms-of-use.text"
    ) as string;
    return termsOfUse.replace(/\n/g, "<br>");
  }
}
