
















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import router, { RouteConfigs, RoutePaths, Routes } from "@/router";
import { authenticationService } from "@/services/authentication.service";
import { msalService } from "@/services/msal.service";
import { RouteConfig } from "vue-router";
import { authorizationService } from "@/services/authorization.service";
import LanguageSelector from "@/components/app-bar/LanguageSelector.vue";
import ActiveActivitySwitcher from "@/components/app-bar/ActiveActivitySwitcher.vue";
import { ActivitySecurity } from "@/app/models/activity/security.model";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import store from "@/store";
import IamRole from "@/contracts/iam/IamRole";

@Component({
  components: { ActiveActivitySwitcher, LanguageSelector },
})
export default class AppBar extends Vue {
  tabsKey = 0;
  drawer = false;

  authorizedRoutesInNavBar: RouteConfig[] = [];

  get isSignedIn(): boolean {
    return this.$store.state.user.isSignedIn;
  }

  get firstName(): string {
    return this.$store.state.user.firstName;
  }

  get lastName(): string {
    return this.$store.state.user.lastName;
  }

  get navBarHasRoutesToShow(): boolean {
    return this.authorizedRoutesInNavBar.length > 0;
  }

  mounted(): void {
    this.resolveNavigation();
  }

  @Watch("$store.state.activity.currentActivityDefinition")
  onActiveActivitySecuritySet(activity: ActivityDefinitionModel) {
    this.resolveNavigation();
  }

  @Watch("$store.state.user.roles")
  updateAuthorizedRoutesInNavBar(): void {
    this.resolveNavigation();
  }

  @Watch("$store.state.appConfig.menuName")
  updateMenu(menuName: string): void {
    this.updateAuthorizedRoutesInNavBar();
  }

  signIn(): void {
    authenticationService.signIn();
  }

  signOut(): void {
    msalService.logoutRedirect();
    this.$store.commit("setSignedIn", false);
  }

  navigateToHome(): void {
    this.$store.commit("setMenuOptions", "primaryNavigation");
    const activityDefinition = this.$store.state.activity.currentActivityDefinition as ActivityDefinitionModel;
    if(activityDefinition && activityDefinition.version !== 'v1'){
      const roles = store.state.user.roles as IamRole[];
      if(roles && roles.length > 0) {
        for (const role of roles) {
          if (activityDefinition.security?.home[role.id]) {
            router.push({ path: activityDefinition.security?.home[role.id] });
            return;
          }
        }
      }
    }else{
      router.push({ name: Routes.Dashboard });
    }
  }

  navigateToFAQ(): void {
    router.push({ name: Routes.FAQ });
  }

  reload(): void {
    this.tabsKey++;
  }

  private useOldFallbackNavigation() {
    this.authorizedRoutesInNavBar = RouteConfigs.filter(
      (routeConfig) => routeConfig.meta
    )
      .filter(
        (routeConfig) =>
          routeConfig.meta?.navigationTab &&
          routeConfig.meta?.navigationTab.legacy &&
          routeConfig.meta?.navigationTab.translationKey &&
          routeConfig.meta?.navigationTab.icon &&
          routeConfig.meta?.navigationTab.menuName ===
            this.$store.state.appConfig.menuName
      )
      .filter((routeConfig) =>
        authorizationService.userIsAuthorized(routeConfig.meta?.authorizedRoles)
      )
      .filter((routeConfig) =>
        authorizationService.userIsAuthorizedByBusinessRules(
          routeConfig.meta?.authorizationBusinessRules
        )
      );
  }
  private useActivityDefinitionBasedNavigation() {
    const activityDefinition: ActivityDefinitionModel =
      this.$store.state.activity.currentActivityDefinition;
    const activitySecurity: ActivitySecurity =
      this.$store.state.activity.currentActivitySecurity;
    if (!activityDefinition || !activitySecurity) {
      return;
    }

    this.authorizedRoutesInNavBar = [];
    Object.entries(activitySecurity.tabs).forEach((entry) => {
      if (entry[1]) {
        const routeConfigs = RouteConfigs.filter(
          (routeConfig) => routeConfig.name === entry[0]
        );
        if (routeConfigs.length > 0) {
          routeConfigs.forEach((value) => {
            this.authorizedRoutesInNavBar.push(value);
          });
        }
      }
    });
  }

  private resolveNavigation() {
    const activity = this.$store.state.activity.currentActivityDefinition;
    if (activity && activity.version === "v1") {
      this.useOldFallbackNavigation();
    } else {
      this.useActivityDefinitionBasedNavigation();
    }
  }
}
