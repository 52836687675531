








































import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import MyDossiersView from "@/views/dossiers/MyDossiersView.vue";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import router, { Routes } from "@/router";
import {
  defaultDossierSecurity,
  DossierSecurity,
} from "@/app/models/activity/security.model";
import Loader from "@/components/common/Loader.vue";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import ActionTile from "@/components/common/ActionTile.vue";
import { Overview } from "@/app/models/overview/overview.model";
import { deepMergeObjects } from "@/app/helpers/stringpath.helper";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import { overviewService } from "@/app/services/overview.service";

@Component({
  components: {
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class DocumentsOverviewView extends ActivityDependendViewBase {
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};


  async onActivityUpdated(): Promise<any> {
    if (this.activityDefinition.version === 'v1') return;

    this.additionalFilters.ae_documentcategory =
        this.activityDefinition.generalDocuments.entries
            .map((value) => value.categoryId)
            .join(";");
    const categories = this.activityDefinition.generalDocuments.entries.map(
        (value) => {
          return { id: value.categoryId, label: value.name };
        }
    );
    const types = this.activityDefinition.generalDocuments.entries
        .flatMap((value) => Object.entries(value.types))
        .map((entry) => {
          return { id: entry[0], label: entry[1] };
        });
    this.additionalMetadata.types = types;
    this.additionalMetadata.categories = categories;
    return;
  }
}
