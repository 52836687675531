export const getObjectContentsFromPath = (path: string, data: any) => {
  const pathArray = path.split(".");
  if (!data) return null;
  const result = recursiveGetObjectContentsFromPathStringArray(pathArray, data);
  //console.warn("data resolve",result, path, JSON.stringify(data));
  return result;
};

export const deepMergeObjects = (d1: any, d2: any): any => {
  if (!d2) return d1;
  if (!d1) return d2;
  const r = JSON.parse(JSON.stringify(d1));
  Object.entries(d2).forEach((entry) => {
    if (!r[entry[0]]) {
      r[entry[0]] = entry[1];
    } else {
      r[entry[0]] = deepMergeObjects(r[entry[0]], entry[1]);
    }
  });
  return r;
};

const recursiveGetObjectContentsFromPathStringArray = (
  pathArray: string[],
  object: any
): any => {
  if (pathArray.length === 0) {
    return object;
  }

  const pathPart = pathArray.shift();

  if (pathPart === undefined) {
    return object;
  }

  if (object === undefined || object === null) {
    return null;
  }

  if(object[pathPart] === undefined || object[pathPart] === null){
    //try to match entire next part to check for . variables
    const full = pathPart + '.' + pathArray.join('.');
    if(object[full]) return object[full];
  }

  return recursiveGetObjectContentsFromPathStringArray(
    pathArray,
    object[pathPart]
  );
};
