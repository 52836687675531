





















import { Component, Vue } from "vue-property-decorator";
import router, { Routes } from "@/router";
import { Route } from "vue-router/types/router";

@Component
export default class Footer extends Vue {
  links = [
    {
      label: "general.terms-of-use",
      name: Routes.TermsOfUse,
    },
    {
      label: "general.privacy-policy",
      name: Routes.PrivacyPolicy,
    },
  ];

  navigate(name: string): Promise<Route> {
    return router.push({ name: name });
  }
}
