





















































































































import LotDto from "@/contracts/lot/LotDto";
import { Component, Prop, Vue } from "vue-property-decorator";
import AeTextField from "@/components/common/TextField.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import AeTimePicker from "@/components/common/TimePicker.vue";
import CreateDayPlanningDto from "@/contracts/dayplanning/CreateDayPlanningDto";
import TechniqueAndTechniqueStepsSelector from "@/components/lots/TechniqueAndTechniqueStepsSelector.vue";
import ExecutorDto from "@/contracts/organization/ExecutorDto";
import TechniqueDto, {
  TechniqueStepDto,
} from "@/contracts/technique/TechniqueDto";
import { techniqueMapper } from "@/mappers/technique.mapper";
import CreateTechniqueDto from "@/contracts/technique/CreateTechniqueDto";
import { dossierService } from "@/services/dossier.service";

@Component({
  components: {
    AeAutocomplete,
    AeTextField,
    AeTimePicker,
    TechniqueAndTechniqueStepsSelector,
  },
})
export default class CheckInDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: null })
  lots!: LotDto[];

  @Prop({ default: false })
  submittingCheckIn!: boolean;

  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: null })
  lotId!: string;

  @Prop({ default: false })
  dossierCheckIn!: boolean;

  availableQualifiedExecutors: ExecutorDto[] = [];
  loadingAvailableQualifiedExecutors = false;
  availableLots: LotDto[] = [];
  availableLotsCopy: LotDto[] = [];
  loadingAvailableLots = false;
  availableTechniques: TechniqueDto[] = [];
  availableTechniquesCopy: TechniqueDto[] = [];

  checkInData = new CreateDayPlanningDto();

  mounted(): void {
    this.startTimeChanged(new Date());
    this.loadingAvailableLots = true;
    dossierService
      .getDossier(this.dossierId)
      .then((data) => {
        if (data) {
          this.availableLots = data.lots;
          this.availableLotsCopy = this.availableLots;
        }

        if (this.lotId && !this.dossierCheckIn) {
          let selectedLot = this.availableLots.find(
            (lot) => lot.id === this.lotId
          );
          this.checkInData.lots = selectedLot ? [selectedLot] : [];
          this.checkInData.checkInLotIds =
            selectedLot && selectedLot.id ? [selectedLot.id] : [];
        }
      })
      .then(() => {
        if (this.availableLots && this.availableLots[0].id) {
          this.loadingAvailableQualifiedExecutors = true;
          dossierService
            .getQualifiedExecutorsForLot(this.dossierId, this.lotId)
            .then((data) => {
              if (data) {
                this.availableQualifiedExecutors = data.filter(
                  (executor) => executor.name !== null && executor.id !== null
                );
              }
            })
            .finally(() => (this.loadingAvailableQualifiedExecutors = false));
        }
      })
      .finally(() => {
        this.getAvailableTechniques();
        this.loadingAvailableLots = false;
      });
  }

  getAvailableTechniques(): void {
    this.availableTechniques = [];
    if (!this.dossierCheckIn) {
      let selectedLot = this.lots.find((lot) => lot.id === this.lotId);
      this.availableTechniques = selectedLot?.techniques
        ? selectedLot.techniques
        : [];
    } else {
      this.lots.forEach((lot) => {
        if (lot.techniques) {
          this.availableTechniques = this.availableTechniques.concat(
            lot.techniques
          );
        }
      });
    }

    this.availableTechniques = this.availableTechniques.filter(
      (availableTechnique, index, techniques) =>
        index ===
        techniques.findIndex(
          (technique) => technique.id === availableTechnique.id
        )
    );

    this.availableTechniquesCopy = this.availableTechniques;
  }

  startTimeChanged(date: Date | null): void {
    this.checkInData.startDate = date;
    if (!this.checkInData.endDate && this.checkInData.startDate) {
      var endDate = new Date(this.checkInData.startDate.getTime());
      endDate.setHours(this.checkInData.startDate.getHours() + 8);
      this.checkInData.endDate = endDate;
    } else if (this.checkInData.endDate && this.checkInData.startDate) {
      if (this.checkInData.endDate < this.checkInData.startDate) {
        this.checkInData.endDate = new Date(
          this.checkInData.startDate.getTime()
        );
      }
    }
  }

  endTimeChanged(date: Date | null): void {
    this.checkInData.endDate = date;
    if (this.checkInData.endDate && this.checkInData.startDate) {
      if (this.checkInData.endDate < this.checkInData.startDate) {
        this.checkInData.endDate = new Date(
          this.checkInData.startDate.getTime()
        );
      }
    }
  }

  get startTimeAsString(): string | null {
    if (this.checkInData.startDate) {
      const startDate = new Date(this.checkInData.startDate);
      return (
        startDate.getHours().toString().padStart(2, "0") +
        ":" +
        startDate.getMinutes().toString().padStart(2, "0")
      );
    }
    return null;
  }

  get isSubmitDisabled(): boolean {
    return !(
      this.checkInData &&
      this.checkInData.startDate &&
      this.checkInData.endDate &&
      this.checkInData.qualifiedExecutors &&
      this.checkInData.qualifiedExecutors.length > 0 &&
      this.checkInData.techniques &&
      this.checkInData.techniques.length > 0 &&
      this.checkInData.techniques.filter(
        (technique) => !technique.stepIds || technique.stepIds.length == 0
      ).length == 0
    );
  }

  updateTechniquesOnCheckIn(techniqueStepIds: string[]): void {
    const availableTechniquesCopy: TechniqueDto[] = JSON.parse(
      JSON.stringify(this.availableTechniques)
    );
    const techniques = availableTechniquesCopy
      .filter(
        (availableTechnique) => availableTechnique.techniqueSteps !== null
      )
      .map((availableTechnique) => {
        availableTechnique.techniqueSteps = this.getArrayOfSharedTechniqueSteps(
          availableTechnique.techniqueSteps as TechniqueStepDto[],
          techniqueStepIds
        );
        return availableTechnique;
      })
      .filter(
        (availableTechnique) =>
          availableTechnique.techniqueSteps &&
          availableTechnique.techniqueSteps.length > 0
      );
    this.checkInData.techniques = techniques
      .map((technique) => techniqueMapper.mapToCreateDto(technique))
      .filter((technique) => technique !== null) as CreateTechniqueDto[];

    this.availableLots = this.availableLotsCopy;
    let selectableLots: (string | null)[] = [];

    selectableLots = this.lots
      .filter((lot) => {
        return this.checkInData.techniques?.every((technique) => {
          return lot.techniques?.some(
            (lotTechnique) => lotTechnique.id === technique.id
          );
        });
      })
      .map((lot) => lot.id);

    this.availableLots =
      selectableLots.length === 0
        ? this.availableLotsCopy
        : this.availableLots.filter((lot) => selectableLots.includes(lot.id));
  }

  getArrayOfSharedTechniqueSteps(
    availableTechniqueSteps: TechniqueStepDto[],
    selectedTechniqueStepIds: string[]
  ): TechniqueStepDto[] {
    return availableTechniqueSteps
      .filter((availableStep) => availableStep.id !== null)
      .filter(
        (availableStep) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedTechniqueStepIds.indexOf(availableStep.id!) > -1
      );
  }

  updateSelectableTechniques(): void {
    this.availableTechniques = this.availableTechniquesCopy;

    this.checkInData.checkInLotIds?.forEach((lotId) => {
      let foundLot = this.lots.find((lot) => lot.id === lotId);
      let selectedLot = foundLot ? foundLot : new LotDto();
      if (selectedLot) {
        this.availableTechniques = this.availableTechniques.filter(
          (technique) => {
            return selectedLot.techniques?.some(
              (lotTechnique) => technique.id === lotTechnique.id
            );
          }
        );
      }
    });
  }

  submit(): void {
    if (!this.isSubmitDisabled) {
      this.$emit("submit", this.checkInData);
    }
  }

  cancel(): void {
    this.$emit("close");
    this.checkInData = new CreateDayPlanningDto();
  }
}
