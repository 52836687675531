import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-x":"","offset-y":"","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"mt-1",attrs:{"title":_vm.$t('general.changeActiveActivity'),"loading":_vm.loading,"text":""}},on),[(_vm.activeActivityName !== 'nan')?_c('span',{staticClass:"pr-1 text-dots text-end",staticStyle:{"max-width":"350px"}},[_vm._v(" "+_vm._s(_vm.activeActivityName)+" ")]):_c('span',{staticClass:"pr-1"},[_c('loader',{attrs:{"size":24}})],1),(_vm.activeActivityName !== 'nan')?_c(VIcon,[_vm._v("mdi-swap-horizontal")]):_vm._e()],1)]}}])},[_c(VList,{staticStyle:{"width":"450px"}},[_c(VListItemGroup,_vm._l((_vm.eligibleActivities),function(activity){return _c(VListItem,{key:activity.id,staticClass:"text-sm-justify",on:{"click":function($event){return _vm.changeActiveActivity(activity)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(activity.name.toUpperCase())+" ")])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }