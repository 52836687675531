import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageReady)?_c('div',[_c('div',[_c('authentication-interceptor',[_c('accept-policies-interceptor',[_c('page-header',{attrs:{"headerIconName":"mdi-bolt","headerTitle":"products.page-title"}}),_c(VTabs,{staticClass:"mt-2 transparenttabs"},[_c(VTab,[_vm._v(_vm._s(_vm.$t("products.own.title")))]),_c(VTab,[_vm._v(_vm._s(_vm.$t("products.public.title")))]),_c(VTabItem,[_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"mt-4 mb-4",staticStyle:{"max-width":"620px"}},[_vm._v(" "+_vm._s(_vm.$t("products.own.page-description"))+" ")]),( _vm.activityDefinition && _vm.activityDefinition.products && _vm.activityDefinition.products.overviewDefinitionId)?_c('overview-component',{attrs:{"overview-definition-id":_vm.activityDefinition.products.overviewDefinitionId,"path":'api/v2/products',"entityType":'products',"additional-filters":_vm.additionalFiltersOwn,"additional-metadata":_vm.additionalMetadataOwn,"can-create-new":true,"create-new":{
                  title: 'products.overview.no-products',
                  subtitle: 'products.overview.add-products',
                  icon: 'mdi-file-upload',
                  largeImage: 'no-dossiers.svg',
                },"modify-fetched-data":_vm.mapApiProductToProduct},on:{"overviewControl":_vm.recieveOverviewControl,"createNewClicked":_vm.showCreateDialog,"rowClicked":_vm.showUpdateDialog}}):_vm._e()],1)]),_c(VTabItem,[_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"mt-4 mb-4",staticStyle:{"max-width":"620px"}},[_vm._v(" "+_vm._s(_vm.$t("products.public.page-description"))+" ")]),( _vm.activityDefinition && _vm.activityDefinition.products && _vm.activityDefinition.products.overviewDefinitionId)?_c('overview-component',{attrs:{"overview-definition-id":_vm.activityDefinition.products.overviewDefinitionId,"path":'api/v2/products',"entityType":'products-overview',"additional-filters":_vm.additionalFiltersPublic,"additional-metadata":_vm.additionalMetadataPublic,"can-create-new":false,"clickable":false,"create-new":{
                  title: 'products.overview.no-products',
                  subtitle: '',
                  icon: 'mdi-file-upload',
                  largeImage: 'no-dossiers.svg',
                },"modify-fetched-data":_vm.mapApiProductToProduct}}):_vm._e()],1)])],1)],1)],1)],1),_c('form-dialog-component',{attrs:{"form-dialog-model":_vm.createNewProductDialog},on:{"formDialogControl":_vm.recieveCreateNewProductControl}}),_c('form-dialog-component',{attrs:{"form-dialog-model":_vm.updateProductDialog},on:{"formDialogControl":_vm.recieveUpdateProductControl}})],1):_c('div',[_c('loader')],1)}
var staticRenderFns = []

export { render, staticRenderFns }