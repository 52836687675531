import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";
import { Subject, Subscription } from "rxjs";

export default class OverviewExpanderBase extends Vue {
  @Prop({ default: null })
  protected item!: any;

  @Prop({ default: null })
  protected renderType!: string;

  @Prop({ default: {} })
  protected metadata!: { [key: string]: any };

  @Prop({ default: null })
  protected eventbus!: Subject<OverviewEvent> | null;

  protected eventbusSubscription: Subscription | undefined;

  @Watch("eventbus", { immediate: true })
  protected setEventbus(bus: Subject<OverviewEvent>) {
    if (this.eventbusSubscription) this.eventbusSubscription.unsubscribe();

    if (!bus) return;
    this.eventbusSubscription = bus.subscribe((ev) => {
      this.onEventRecieved(ev);
    });
  }

  //don't override this one
  beforeDestroy(): void {
    if (this.eventbusSubscription) this.eventbusSubscription.unsubscribe();
    this.onDestroy();
  }

  protected onDestroy() {
    return;
  }

  protected onEventRecieved(ev: OverviewEvent) {
    return;
  }
}
