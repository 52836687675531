import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.entry)?_c(VExpansionPanels,{staticStyle:{"width":"fit-content"},attrs:{"value":0}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var open = ref.open;
return [_c(VRow,{attrs:{"no-gutters":""},on:{"click":open}},[_c('h3',{staticClass:"me-4"},[_vm._v(_vm._s(_vm.$t(_vm.entry.name)))])])]}}],null,false,1298956275)}),_c(VExpansionPanelContent,{attrs:{"eager":""}},[_c('div',{staticClass:"d-flex justify-start flex-wrap flex-md-row flex-column"},_vm._l((this.entry.children),function(definition,i){return _c(_vm.getDetailComponent(definition.type),{key:i,tag:"component",staticClass:"pe-10",attrs:{"entry":definition,"detailContext":_vm.detailContext}})}),1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }