


































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import PageConfig from "@/contracts/generic/PageConfig";
import InspectionDto from "@/contracts/inspection/InspectionDto";
import InspectionSortConfig from "@/contracts/inspection/InspectionSortConfig";
import { TableColumns } from "@/constants/TableColumns";

@Component
export default class InspectionsTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  pageConfig!: PageConfig;

  @Prop({ default: null })
  sortConfig!: InspectionSortConfig;

  @Prop({ default: [] })
  inspections!: InspectionDto[];

  @Prop({ default: 0 })
  amountOfInspections!: number;

  headers: DataTableHeader[] = [
    {
      text: this.$t("my-inspections.lot-name") as string,
      value: TableColumns.LOT_NAME,
      sortable: true,
    },
    {
      text: this.$t("my-inspections.dossier-name") as string,
      value: TableColumns.DOSSIER_NAME,
      sortable: true,
    },
    {
      text: this.$t("my-inspections.constructor-name") as string,
      value: TableColumns.CONSTRUCTOR_NAME,
      sortable: false,
    },
    {
      text: this.$t("my-inspections.lot-status") as string,
      value: TableColumns.LOT_STATUS,
      sortable: false,
    },
    {
      text: this.$t("my-inspections.lot-start-date") as string,
      value: TableColumns.START_DATE,
      sortable: true,
    },
    {
      text: this.$t("my-inspections.lot-end-date") as string,
      value: TableColumns.END_DATE,
      sortable: true,
    },
  ];

  pageUpdated(page: number | undefined): void {
    if (page) {
      this.pageConfig.page = page;
      this.emitPageConfigUpdatedEvent();
    }
  }

  itemsPerPageUpdated(pageSize: number | undefined): void {
    if (pageSize) {
      this.pageConfig.pageSize = pageSize;
      this.emitPageConfigUpdatedEvent();
    }
  }

  emitPageConfigUpdatedEvent(): void {
    this.$emit("pageConfigUpdated", this.pageConfig);
  }

  sortByUpdated(sortBy: string[] | undefined): void {
    if (sortBy) {
      this.sortConfig.sortBy = sortBy;
      this.emitSortConfigUpdatedEvent();
    }
  }

  sortDescendingUpdated(sortDescending: boolean[] | undefined): void {
    if (sortDescending != undefined) {
      this.sortConfig.sortDescending = sortDescending;
      this.emitSortConfigUpdatedEvent();
    }
  }

  emitSortConfigUpdatedEvent(): void {
    this.$emit("sortConfigUpdated", this.sortConfig);
  }

  emitRowClickedEvent(item: InspectionDto): void {
    this.$emit("rowClicked", item);
  }
}
