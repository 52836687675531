


















import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { getObjectContentsFromPath } from "@/app/helpers/stringpath.helper";

@Component({
  components: {},
})
export default class OverviewCellList extends OverviewCellBase {
  mounted(): void {
    const i = 0;
  }

  list: string[] = [];

  protected PostResolveValue() {
    if (this.metadata) {
      if (this.metadata?.translateList && this.value) {
        //entry in dataresolver
        let path = this.metadata.translateList;
        if (path.startsWith("data.")) {
          path = path.replace("data.", "");
        }
        const resolvedItems = getObjectContentsFromPath(
          path,
          this.additionalMetadata
        );
        if (Array.isArray(resolvedItems)) {
          this.list = resolvedItems
            .filter((it) => {
              return Array.from(this.value).includes(
                it[this.metadata.itemValue]
              );
            })
            .map((value) => {
              return value[this.metadata.itemText];
            });
          console.warn('mapped n', JSON.stringify(this.list), this.additionalMetadata, this.additionalMetadata?.translated);
          if (this.metadata?.translated || this.additionalMetadata?.translated) {
            this.list = this.list.map((v) => this.$tc(v));
            console.warn('mapped', JSON.stringify(this.list));
          }
        } else {
          this.list = this.value;
        }
      } else {
        this.list = this.value;
      }
    }
  }

  get iconColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "primary";
  }
}
