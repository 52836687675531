
































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import AeTextField from "@/components/common/TextField.vue";
import AeTextarea from "@/components/common/Textarea.vue";
import QualifiedEmployeesList from "@/components/lots/QualifiedEmployeesList.vue";
import TechniqueAndTechniqueStepsSelector from "@/components/lots/TechniqueAndTechniqueStepsSelector.vue";
import LotDto from "@/contracts/lot/LotDto";
import DocumentTile from "@/components/documents/DocumentTile.vue";

@Component({
  components: {
    AeTextarea,
    AeTextField,
    DocumentTile,
    TechniqueAndTechniqueStepsSelector,
    QualifiedEmployeesList,
  },
})
export default class DayPlanningHistorySlideOutContent extends Vue {
  @Prop({ default: null })
  historyEntry!: DayPlanningHistoryEntryDto;

  @Prop({ default: null })
  lot!: LotDto;

  editDisabled = true;

  entryHasDocuments(): boolean {
    return this.historyEntry?.documents?.results.length > 0;
  }
}
