export default class UpdateDayPlanningDto {
  endDate: Date | null;
  description: string | null;
  checkOutLotIds: string[] | null;

  constructor() {
    this.endDate = null;
    this.description = null;
    this.checkOutLotIds = null;
  }
}
