









import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { userServiceV2 } from "@/app/services/user.service";
import { organisationService } from "@/app/services/organisation.service";

export type TextMetaData = unknown;

@Component({})
export default class DetailComponentOrganisation extends DetailComponentBase<TextMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    this.resolveValue();
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    this.resolveValue();
  }

  private resolveValue() {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
      if (this.value && this.value != "") {
        organisationService
          .getOrganisationsByIds([this.value])
          .then((orgList) => {
            if (!orgList || orgList.length <= 0) return;
            this.value = orgList[0].name;
          })
          .catch((reason) => {
            console.warn("Failed to fetch org");
          });
      }
    }
  }
}
