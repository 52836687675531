















































































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import StepsOverview from "@/components/common/steps/StepsOverview.vue";
import StepsPanel from "@/components/common/steps/StepsPanel.vue";
import DossierDto from "@/contracts/dossier/DossierDto";
import DossierGeneralInfoPanel from "@/components/dossiers/details/panels/DossierGeneralInfoPanel.vue";
import DossierPlanningPanel from "@/components/dossiers/details/panels/DossierPlanningPanel.vue";
import DossierDiagnosisPanel from "@/components/dossiers/details/panels/DossierDiagnosisPanel.vue";
import DossierActivityDomainPanel from "@/components/dossiers/details/panels/activity-domain/DossierActivityDomainPanel.vue";
import DossierLotsPanel from "@/components/dossiers/details/panels/DossierLotsPanel.vue";
import { Routes } from "@/router";
import Step from "@/contracts/common/Step";
import DossierActions from "@/components/dossiers/details/actions/DossierActions.vue";
import AddLotDialog from "@/components/lots/AddLotDialog.vue";
import LotDto from "@/contracts/lot/LotDto";
import { dossierService } from "@/services/dossier.service";
import { i18n } from "@/i18n";
import DossierDocumentsActions from "@/components/dossiers/details/actions/DossierDocumentsActions.vue";
import { authRoles } from "@/constants/AuthRoles";
import { authorizationService } from "@/services/authorization.service";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import ReferenceData from "@/contracts/generic/ReferenceData";
import { activityService } from "@/services/activity.service";
import PageHeader from "@/components/layout/PageHeader.vue";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import LotCheckInActions from "@/components/lots/details/actions/LotCheckInActions.vue";

@Component({
  components: {
    DossierDocumentsActions,
    AddLotDialog,
    DossierActions,
    LotCheckInActions,
    DossierGeneralInfoPanel,
    DossierPlanningPanel,
    DossierDiagnosisPanel,
    DossierActivityDomainPanel,
    DossierLotsPanel,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    Loader,
    StepsOverview,
    StepsPanel,
    PageHeader,
  },
})
export default class DossierDetails extends Vue {
  @PropSync("dossier", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  submitting!: boolean;

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: false })
  canRequestConformity!: boolean;

  @Prop({ default: false })
  canEditDossier!: boolean;

  @Prop({ default: null })
  selectedActivityId!: string;

  @Prop({ default: false })
  canCheckInLot!: boolean;

  @Prop({ default: false })
  canCheckOutLot!: boolean;

  @Prop({ default: false })
  loadingDayPlanning!: boolean;

  @Prop({ default: null })
  mostRecentDayPlanningEntry!: DayPlanningHistoryEntryDto;

  showDialog = false;
  submittingLot = false;

  selectedActivity: ReferenceData | null = null;

  steps: Step[] = [
    {
      index: 1,
      id: "my-dossiers-general-info",
      title: "my-dossiers.general-information",
      component: DossierGeneralInfoPanel,
    },
    {
      index: 2,
      id: "my-dossiers-planning",
      title: "my-dossiers.planning",
      component: DossierPlanningPanel,
    },
    {
      index: 3,
      id: "my-dossiers-diagnosis",
      title: "my-dossiers.diagnosis",
      component: DossierDiagnosisPanel,
    },
    {
      index: 4,
      id: "my-dossiers-activity-domain",
      title: "my-dossiers.activity-domain",
      component: DossierActivityDomainPanel,
    },
    {
      index: 5,
      id: "my-dossiers-lots",
      title: "my-dossiers.lots",
      component: DossierLotsPanel,
    },
  ];

  mounted(): void {
    this.getActivity();
  }

  get userIsAuthorizedToCreateLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCreateLot();
  }

  get dossierHasActivityDomain(): boolean {
    return !!(
      this.dossierSync &&
      this.dossierSync.activityDomains &&
      this.dossierSync.activityDomains.activityDomains &&
      this.dossierSync.activityDomains.activityDomains.length
    );
  }

  get isSubmitDisabled(): boolean {
    return !(
      this.dossierSync &&
      this.dossierSync.name &&
      this.dossierSync.address &&
      this.dossierSync.address.country &&
      this.dossierSync.address.municipality &&
      this.dossierSync.address.postalCode &&
      (this.dossierSync.address.street ||
        (this.dossierSync.address.latitude &&
          this.dossierSync.address.longitude)) &&
      this.dossierSync.planning.startDate &&
      this.dossierSync.planning.endDate &&
      this.dossierSync.activityDomains.activity &&
      this.dossierSync.activityDomains.activityDomains.length > 0 &&
      this.dossierSync.lots &&
      this.dossierSync.lots.length > 0
    );
  }

  get hasRoleInspector(): boolean {
    return authorizationService.userIsAuthorized([authRoles.INSPECTOR]);
  }

  getActivity(): void {
    if (this.dossierSync) {
      if (this.selectedActivityId) {
        this.dossierSync.activityDomains.activity = this.selectedActivityId;
      }
      if (this.dossierSync.activityDomains.activity) {
        activityService
          .getActivityById(this.dossierSync.activityDomains.activity)
          .then((activity) => (this.selectedActivity = activity));
      }
    }
  }

  addLot(): void {
    this.showDialog = true;
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  lotAdded(lot: LotDto): void {
    if (this.editable) {
      this.dossierSync.lots.push(lot);
      this.showDialog = false;
    } else if (this.dossierSync.id) {
      this.submittingLot = true;
      dossierService
        .createLot(this.dossierSync.id, lot)
        .then((createdLotId) => {
          this.showDialog = false;
          lot.status = "general.created";
          lot.id = createdLotId;
          this.dossierSync.lots.push(lot);
          Vue.$toast.success(i18n.t("my-dossiers.lot-added"));
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("my-dossiers.lot-add-failed"));
        })
        .finally(() => {
          this.submittingLot = false;
        });
    }
  }

  navigateToMyDossiers(): void {
    this.$router.push({ name: Routes.MyDossiers });
  }

  navigateToInspections(): void {
    // TODO: verify if correct return action
    this.$router.push({ name: Routes.MyInspections });
  }

  makeDossierEditable(): void {
    this.$emit("makeDossierEditable");
  }

  deleteDossier(): void {
    this.$emit("deleteDossier");
  }

  requestConformity(): void {
    this.$emit("requestConformity");
  }

  doCancel(): void {
    this.$emit("cancel");
  }

  doSubmit(): void {
    this.$emit("submit");
  }

  checkIn(): void {
    this.$emit("checkIn");
  }

  checkOut(): void {
    this.$emit("checkOut");
  }

  get isCheckedIn(): boolean {
    if (this.mostRecentDayPlanningEntry) {
      return this.mostRecentDayPlanningEntry.endDate == null;
    }
    return false;
  }
}
