






















import { Component, Vue, Prop } from "vue-property-decorator";
import AeAvatar from "@/components/common/Avatar.vue";

@Component({ components: { AeAvatar } })
export default class AvatarStack extends Vue {
  @Prop({ default: null })
  names!: unknown[];

  @Prop({ default: null })
  nameProperty!: string;

  @Prop({ default: null })
  nameKey!: string;

  @Prop({ default: "md" })
  size!: "sm" | "md" | "lg";

  @Prop({ default: "primary" })
  color!: string;

  @Prop({ default: null })
  maxStackAmount!: number;

  namesSliced: unknown[] | null = null;
  namesRemainder: number | null = null;

  private readonly sizes = {
    sm: 30,
    md: 36,
    lg: 48,
  };

  mounted(): void {
    if (this.names) {
      const totalNames = this.names.length;
      if (this.maxStackAmount && this.maxStackAmount < totalNames) {
        if (this.maxStackAmount == 1) {
          this.namesSliced = [];
        } else {
          this.namesSliced = this.names.slice(0, this.maxStackAmount - 1);
        }
        this.namesRemainder = totalNames - this.namesSliced.length;
      } else {
        this.namesSliced = this.names;
      }
    }
  }

  get convertedSize(): number {
    if (this.size) {
      return this.sizes[this.size];
    }
    return this.sizes["md"];
  }

  click(): void {
    this.$emit("click");
  }
}
