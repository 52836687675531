





































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import Loader from "@/components/common/Loader.vue";
import { RenderType } from "@/app/Types";
import { exchangeService } from "@/app/services/exchange.service";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { ExchangeEvent } from "@/app/components/exchange/exchange.model";
import { Subject, Subscription } from "rxjs";
import { OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import TextField from "@/components/common/TextField.vue";
import FileInput from "@/components/common/FileInput.vue";
import DossierDetailDocumentExchanges from "@/app/components/dossier-detail-document-exchanges.vue";
import DetailComponentExchangeSteps from "@/app/dynamic-components/details/details-components/components/dossier/detail-component-exchange-steps.vue";
import {
  DynamicDetailEntry,
  ExchangeDefinition,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { DetailContext } from "@/app/contexts/detail.context";

export type DetailDocumentsLinearSectionMultiplicityMetaData = {
  direction: "vertical" | "horizontal";
  multiplicity: string;
  multiplicityKey: string;
  title: boolean;
  title_translated: boolean;
};

@Component({
  computed: {
    RenderType() {
      return RenderType;
    },
  },
})
export default class DetailComponentLinearSectionMultiplicity extends DetailComponentBase<DetailDocumentsLinearSectionMultiplicityMetaData> {
  public entries: string[] = [];
  public detailContextClones: DetailContext[] = [];

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (newValue && this.entry?.metadata?.multiplicity) {
      const arr = this.detailContext.resolveDataPath(
        this.entry?.metadata?.multiplicity
      );
      if (arr && Array.isArray(arr)) {
        this.entries = [];
        this.detailContextClones = [];
        Array.from(arr).forEach((value) => {
          this.entries.push(value);
          const clone = this.detailContext.Clone();
          clone.setData(this.entry?.metadata?.multiplicityKey, value);
          this.detailContextClones.push(clone);
        });
      }
    }
  }
}
