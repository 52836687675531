


































import { Component, Prop, Vue } from "vue-property-decorator";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { documentTypes } from "@/constants/DocumentTypes";
import UploadDocumentForm from "@/components/documents/upload/UploadDocumentForm.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import ChangeShareDocumentForm from "@/components/documents/upload/ChangeShareDocumentForm.vue";
import { documentService } from "@/services/document.service";

@Component({
  components: {
    ChangeShareDocumentForm,
  },
})
export default class ChangeShareDocumentDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: null })
  submitLabel!: string;

  loading = false;

  @Prop({ default: null })
  documentDto!: DocumentDto | null;

  original!: DocumentDto | null;

  get isSubmitDisabled(): boolean {
    return !(this.documentDto && !this.loading);
  }

  mounted(): void {
    this.original = JSON.parse(JSON.stringify(this.documentDto));
  }

  close(): void {
    if (this.documentDto && this.original) {
      this.documentDto.sharedWith = this.original.sharedWith;
    }

    this.$emit("close");
    this.reset();
  }

  submit(): void {
    if (!this.documentDto) return;

    this.loading = true;
    documentService
      .modifySharedPersons(this.documentDto, this.documentDto.sharedWith)
      .then((documentDto) => {
        if (!this.documentDto) return;
        this.documentDto.sharedWith = documentDto.data.sharedWith;
        this.documentDto.shared = documentDto.data.shared;

        this.$emit("changedShare", documentDto.data);
        this.reset();
      })
      .catch((reason) => {
        console.error("Failed to change document shareing", reason);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  reset(): void {
    this.original = null;
  }
}
