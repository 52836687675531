



import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { Component } from "vue-property-decorator";

@Component({
  components: {},
})
export default class OverviewCellState extends OverviewCellBase {
  public translatedValue() {
    if (!this.metadata?.translated) return this.value || 'Not available';
    return this.$t(this.value || "general.not_available");
  }

  get chipColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "primary";
  }
}
