import { DossierStatusDto } from "@/contracts/dossier/DossiersDto";

class DossierStatusMapper {
  public mapDossierStatusToDossierStatusWithTranslatableLabel(
    status: DossierStatusDto
  ): DossierStatusDto {
    const mappedStatus = { ...status };
    mappedStatus.label = this.mapLabelToTranslatableLabel(status.label);
    return mappedStatus;
  }

  public mapLabelToTranslatableLabel(label: string): string {
    return `dossier-status.${label.toLowerCase().replaceAll(" ", "-")}`;
  }
}

export const dossierStatusMapper = new DossierStatusMapper();
