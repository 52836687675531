


























































































import { Component, Prop, Vue } from "vue-property-decorator";
import DossierDto from "@/contracts/dossier/DossierDto";
import { roleBasedActionsService } from "@/services/role-based-actions.service";

@Component
export default class DossierActions extends Vue {
  @Prop({ default: null }) dossier!: DossierDto;

  @Prop({ default: false }) canAddLot!: boolean;
  @Prop({ default: false }) canEditDossier!: boolean;
  @Prop({ default: false }) canDeleteDossier!: boolean;
  @Prop({ default: false }) canRequestConformity!: boolean;

  submitting = false;

  get userIsAuthorizedToEditDossier(): boolean {
    return roleBasedActionsService.userIsAuthorizedToEditDossier();
  }

  get userIsAuthorizedToDeleteDossier(): boolean {
    //test
    return roleBasedActionsService.userIsAuthorizedToDeleteDossier();
  }

  get userIsAuthorizedToCreateLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCreateLot();
  }

  addLot(): void {
    this.$emit("addLot");
  }

  editDossier(): void {
    this.$emit("editDossier");
  }

  deleteDossier(): void {
    this.$emit("deleteDossier");
  }

  requestConformity(): void {
    this.$emit("requestConformity");
  }
}
