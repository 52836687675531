import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import ExtendedActivityDto from "@/contracts/activity/ExtendedActivityDto";

@Module
export default class UserDossierAccessModule extends VuexModule {
  hasAccessToDossiers = false;

  @Mutation
  setHasAccessToDossiers(activities: ExtendedActivityDto[]): void {
    this.hasAccessToDossiers = activities.some(
      (activity: ExtendedActivityDto) => activity.hasDossiers
    );
  }
}
