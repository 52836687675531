













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Loader extends Vue {
  @Prop({ default: 70 })
  protected size!: number;
}
