

















import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import AeDatePicker from "@/components/common/DatePicker.vue";
import LotDto from "@/contracts/lot/LotDto";

@Component({ components: { AeDatePicker } })
export default class LotPlanningPanel extends Vue {
  @PropSync("data", { type: Object }) lotSync!: LotDto;

  @Prop({ default: false })
  editable!: boolean;

  loading = false;
  userLanguage = "en-us";
}
