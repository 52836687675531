








import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { ExchangeDefinition } from "@/app/dynamic-components/details/dynamic-detail.model";

@Component({
  components: {},
})
export default class OverviewCellStepcount extends OverviewCellBase {
  getCurrentStep(stepId: string) {
    if (!this.additionalMetadata?.definition?.steps) return 1;
    const exchangeDefinition: ExchangeDefinition = this.additionalMetadata
      ?.definition as ExchangeDefinition;

    const indexOfExhangeStep = exchangeDefinition.steps.findIndex(
      (value) => value.id === stepId
    );
    return indexOfExhangeStep + 1;
  }

  getTotalSteps() {
    if (!this.additionalMetadata?.definition?.steps) return 0;
    const exchangeDefinition: ExchangeDefinition = this.additionalMetadata
      ?.definition as ExchangeDefinition;
    return exchangeDefinition.steps.length;
  }
}
