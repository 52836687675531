














































import { Vue, Component } from "vue-property-decorator";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import TextField from "@/components/common/TextField.vue";

type AddressFormMetaData = {
  allowCoordinates: boolean;
};

@Component({
  components: { TextField },
})
export default class ContactDetailsFormBase extends BaseDynamicComponent<AddressFormMetaData> {

  mounted(): void {
    this.value = {
      firstName: '',
      lastName: '',
      email: '',
      telephone: ''
    };
  }
  onContentChanged(newValue: unknown, valueKey: string) {
    if (this.value == null) {
      this.setValueView({});
    }
    this.value[valueKey] = newValue;
  }
}
