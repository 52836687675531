











import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { WidgetMetaData } from "@/app/dynamic-components/details/details-components/widgets/widget-metaData.model";
import ActionTile from "@/components/common/ActionTile.vue";
import ActionTileContent from "@/components/common/ActionTileContent.vue";

export interface TextWidgetMetaData extends WidgetMetaData {
  description: string;
}

@Component({
  components: { ActionTileContent, ActionTile }
})
export default class WidgetComponentText extends DetailComponentBase<TextWidgetMetaData> {

}
