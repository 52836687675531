










































import { Component, Vue } from "vue-property-decorator";
import ActivityModel from "@/app/models/activity/activity.model";
import store from "@/store";
import router, { Routes } from "@/router";
import Loader from "@/components/common/Loader.vue";
import { authenticationService } from "@/services/authentication.service";

@Component({
  components: { Loader },
})
export default class ActiveActivitySwitcher extends Vue {
  loading = false;

  get activeActivityName(): string {
    return this.$store.state.activity.currentActivity.name;
  }

  get eligibleActivities(): ActivityModel[] {
    return this.$store.state.activity.eligibleActivities;
  }

  changeActiveActivity(selectedActivity: ActivityModel): void {
    store.dispatch("setCurrentActivity", selectedActivity);
    setTimeout(()=>{router.push('/')},100);
  }
}
