















































import { Component, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import CreateDossierTile from "@/components/dossiers/create/CreateDossierTile.vue";
import { dossierService } from "@/services/dossier.service";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import { Dossier } from "@/contracts/dossier/DossiersDto";
import MyDossiersTable from "@/components/dossiers/my-dossiers/MyDossiersTable.vue";
import DossierFilterConfig from "@/contracts/dossier/DossierFilterConfig";
import MyDossiersFilters from "@/components/dossiers/my-dossiers/MyDossiersFilters.vue";
import { TableColumns } from "@/constants/TableColumns";
import PageHeader from "@/components/layout/PageHeader.vue";

@Component({
  components: {
    CreateDossierTile,
    MyDossiersTable,
    MyDossiersFilters,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    Loader,
    PageHeader,
  },
})
export default class MyDossiersView extends Vue {
  initialLoading = false;
  loading = false;

  pageConfig: PageConfig = {
    page: 1,
    pageSize: 10,
  };

  sortConfig: SortConfig = {
    sortBy: TableColumns.START_DATE,
    sortDesc: true,
  };

  filterConfig: DossierFilterConfig = {
    statuses: [],
    organizations: [],
  };

  get amountOfDossiers(): number {
    return this.$store.state.dossiers.amountOfDossiers;
  }

  get documents(): Dossier[] {
    return this.$store.state.dossiers.dossiers;
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    this.fetchDossiers(true);
  }

  fetchDossiers(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    dossierService
      .getDossiers(this.pageConfig, this.sortConfig, this.filterConfig)
      .then((data) => {
        if (data) {
          if (initialFetch) {
            this.$store.commit("setAmountOfDossiers", data.rowCount);
          }

          if (data.results) {
            this.$store.commit("setDossiers", data.results);
          }
        }
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }

        this.loading = false;
      });
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchDossiers();
  }

  sortConfigUpdated(sortConfig: SortConfig): void {
    this.sortConfig = sortConfig;
    this.fetchDossiers();
  }

  filterConfigUpdated(filterConfig: DossierFilterConfig): void {
    this.filterConfig = filterConfig;
    this.fetchDossiers();
  }
}
