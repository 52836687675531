































import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { userServiceV2 } from "@/app/services/user.service";
import { planningService } from "@/app/services/planning.service";

export type TextMetaData = unknown;

@Component({})
export default class DetailComponentPlanning extends DetailComponentBase<TextMetaData> {
  value: string | null = null;
  protected startDate: any;
  protected endDate: any;

  postEntityChanged(newValue: DynamicDetailEntry) {
    this.resolveValue();
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    this.resolveValue();
  }

  private resolveValue() {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
      if (this.value && this.value != "") {
        planningService
          .getPlanning(this.value)
          .then((planning) => {
            this.startDate = planning.startDate;
            this.endDate = planning.endDate;
            this.$forceUpdate();
          })
          .catch((reason) => {
            console.warn("Failed to fetch user");
          });
      }
    }
  }
}
