import LotDto from "../lot/LotDto";

export default class DossierDto {
  id: string | null;
  name: string | null;
  address: DossierAddress;
  contact: DossierContact;
  planning: DossierPlanning;
  diagnosis: DossierDiagnosis;
  activityDomains: DossierActivityDomains;
  lots: LotDto[];

  constructor() {
    this.id = null;
    this.name = null;
    this.address = new DossierAddress();
    this.contact = new DossierContact();
    this.planning = new DossierPlanning();
    this.diagnosis = new DossierDiagnosis();
    this.activityDomains = new DossierActivityDomains();
    this.lots = [];
  }
}

export class DossierAddress {
  country: string | null;
  municipality: string | null;
  postalCode: string | null;
  street: string | null;
  number: string | null;
  box: string | null;
  latitude: string | null;
  longitude: string | null;

  constructor() {
    this.country = null;
    this.municipality = null;
    this.postalCode = null;
    this.street = null;
    this.number = null;
    this.box = null;
    this.latitude = null;
    this.longitude = null;
  }
}

export class DossierContact {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  telephone: string | null;

  constructor() {
    this.id = null;
    this.firstName = null;
    this.lastName = null;
    this.email = null;
    this.telephone = null;
  }
}

export class DossierPlanning {
  startDate: Date | null;
  endDate: Date | null;

  constructor() {
    this.startDate = null;
    this.endDate = null;
  }
}

export class DossierDiagnosis {
  diagnosisByCLevel: boolean;
  offerByBLevel: boolean;

  constructor() {
    this.diagnosisByCLevel = false;
    this.offerByBLevel = false;
  }
}

export class DossierActivityDomains {
  activity: string;
  activityDomains: string[];

  constructor() {
    this.activity = "";
    this.activityDomains = [];
  }
}
