






































import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { Component } from "vue-property-decorator";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import ChangeShareDocumentDialog from "@/components/documents/upload/ChangeShareDocumentDialog.vue";
import ContactDto from "@/contracts/generic/ContactDto";
import { userService } from "@/services/user.service";

@Component({
  components: { ChangeShareDocumentDialog },
})
export default class OverviewCellShare extends OverviewCellBase {
  showSharedDialog = false;
  selectedShareItem: DocumentDto | null = null;

  currentUser: ContactDto = {
    organizationId: "",
    activities: [],
    email: "",
    firstName: "",
    fullName: "",
    language: "NL",
    id: "",
    lastName: "",
    policiesAccepted: true,
    roles: [],
  };
  mounted(): void {
    const i = 0;
    userService.getCurrentUser().then((value) => {
      this.currentUser = value;
    });
  }

  get iconColor(): string {
    return this.metadata["color"] ? this.metadata["color"] : "grey-darken-2";
  }

  closeShareDialog(): void {
    this.showSharedDialog = false;
    this.selectedShareItem = null;
  }
  openShareDialog(document: DocumentDto): void {
    this.selectedShareItem = JSON.parse(JSON.stringify(document));
    this.showSharedDialog = true;
  }

  changedShare(document: DocumentDto): void {
    console.warn("triggered func", document);
    this.closeShareDialog();
  }
}
