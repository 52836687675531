























































import LotDto from "@/contracts/lot/LotDto";
import ProductDto from "@/contracts/products/ProductDto";
import { techniquesService } from "@/services/techniques.service";
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import Loader from "@/components/common/Loader.vue";
import AeTextField from "@/components/common/TextField.vue";
import { TableColumns } from "@/constants/TableColumns";

@Component({
  components: {
    AeTextField,
    Loader,
  },
})
export default class LotProductsPanel extends Vue {
  @PropSync("data", { type: Object }) lotSync!: LotDto;

  @Prop({ default: false })
  editable!: boolean;

  availableProducts: ProductDto[] = [];
  selectedProducts: ProductDto[] = [];

  loading = false;

  headers: DataTableHeader[] = [
    {
      text: this.$t("product.name") as string,
      value: TableColumns.NAME,
      sortable: true,
    },
    {
      text: this.$t("product.manufacturer") as string,
      value: TableColumns.MANUFACTURER,
      sortable: true,
    },
    {
      text: this.$t("product.system-component") as string,
      value: TableColumns.SYSTEM_COMPONENT,
      sortable: true,
    },
  ];

  mounted(): void {
    if (this.lotSync.techniques) {
      this.fetchProducts();
    }
  }

  @Watch("lotSync.techniques")
  fetchProducts(): void {
    this.loading = true;
    if (
      this.lotSync &&
      this.lotSync.techniques &&
      this.lotSync.techniques.length > 0
    ) {
      if (!this.lotSync.products) {
        this.lotSync.products = [];
      }
      techniquesService
        .getProducts(this.lotSync.techniques)
        .then((data) => {
          this.availableProducts = data;
          this.updateSelectedProducts();
        })
        .finally(() => (this.loading = false));
    }
  }

  updateSelectedProducts(): void {
    this.selectedProducts = this.availableProducts
      .filter((availableProduct) => availableProduct.id)
      .filter((availableProduct) =>
        this.lotSync.products?.some(
          (lotProduct) => availableProduct.id === lotProduct.id
        )
      );
  }

  setSelectedProductsOnLot(products: ProductDto[]): void {
    this.selectedProducts = products;
    this.lotSync.products = products;
  }
}
