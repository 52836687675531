export const TableColumns = {
  ACTIONS: "actions",
  ACTIVITY: "activity",
  ACTIVITY_DOMAIN: "activityDomain",
  CATEGORY: "category",
  CHECKED_IN_BY: "checkedInBy",
  CHECKED_OUT_BY: "checkedOutBy",
  CONSTRUCTOR_NAME: "constructorName",
  DATE: "date",
  DOCUMENTS: "documents",
  DOSSIER_NAME: "dossierName",
  END_DATE: "endDate",
  EXPECTED_END_DATE: "expectedEndDate",
  LOCATION: "location",
  LOT_STATUS: "lotStatus",
  LOT_NAME: "lotName",
  MANUFACTURER: "manufacturer",
  NAME: "name",
  ORGANIZATION: "organization",
  QUALIFIED_EMPLOYEES: "qualifiedEmployees",
  QUALIFIED_EXECUTORS: "qualifiedExecutors",
  SHOW_DETAILS: "showDetails",
  START_DATE: "startDate",
  STATUS: "status",
  SYSTEM_COMPONENT: "systemComponent",
  TECHNICAL_CONTACT: "technicalContact",
  TITLE: "title",
  TYPE: "type",
  UPLOADED_BY: "uploadedBy",
  SHARED: "shared",
};
