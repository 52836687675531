



























































































import { Component } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { ExternalContext } from "@/app/contexts/externalContext";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import { RenderType } from "@/app/Types";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { productService } from "@/app/services/product.service";
import { OverviewControl } from "@/app/dynamic-components/overviews/overiew.model";
import { Overview } from "@/app/models/overview/overview.model";

@Component({
  components: {
    FormDialogComponent,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class ProductsOverviewView extends ActivityDependendViewBase {
  tabs: any

  additionalFiltersOwn: { [key: string]: string } = {};
  additionalMetadataOwn: { [key: string]: any } = {};

  additionalFiltersPublic: { [key: string]: string } = {};
  additionalMetadataPublic: { [key: string]: any } = {};

  overviewControl: OverviewControl | null = null;
  createNewProductControl: FormDialogControl | null = null;
  updateProductControl: FormDialogControl | null = null;

  async onPageReady(): Promise<any> {
    this.additionalFiltersOwn = {
      nrq_ispublic: 'false',
      nrq_ownerorganisation: this.$store.state.user.organizationId
    };
    this.additionalFiltersPublic = {
      nrq_ispublic: 'true'
    };

    const activityDefinition = this.$store.state.activity.currentActivityDefinition as ActivityDefinitionModel;
    this.additionalMetadataOwn.activitydefinition = activityDefinition;
    this.additionalMetadataPublic.activitydefinition = activityDefinition;
    this.createNewProductDialog.externalContext.setData("activitydefinition", activityDefinition);
    this.updateProductDialog.externalContext.setData("activitydefinition", activityDefinition);
  }

  public recieveOverviewControl(control: OverviewControl) {
    this.overviewControl = control;
  }

  public async refreshOverview(): Promise<boolean> {
    this.overviewControl?.refresh();
    return true;
  }

  public mapApiProductToProduct(overview: Overview): Overview {
    overview._embedded.results = overview?._embedded?.results?.map((val) => {
      return productService.convertGetProductToProduct(val);
    });
    return overview;
  }

  recieveCreateNewProductControl(control: FormDialogControl) {
    this.createNewProductControl = control;
    control.submitChanges = this.saveNewProduct;
    control.afterSubmitChanges = this.refreshOverview;
  }

  public async saveNewProduct(
    form: DynamicForm,
    formData: any
  ): Promise<boolean> {
    if (formData.custom_materialType) {
      formData.materialType = formData.materialTypeManual;
    }
    if (formData.custom_manufacturer) {
      formData.manufacturer = formData.manufacturerManual;
    }
    formData.ownerContactId = this.$store.state.user.contactId;
    formData.ownerOrganisationId = this.$store.state.user.organizationId;
    return productService.createProduct(form, formData).then((value) => {
      this.createNewProductDialog.referenceId = value;
      return !!value;
    });
  }

  recieveUpdateProductControl(control: FormDialogControl) {
    this.updateProductControl = control;
    control.submitChanges = this.updateProduct;
    control.afterSubmitChanges = this.refreshOverview;
    control.destroyItem = this.deactivateProduct;
    control.afterDestroyItem = this.refreshOverview;
  }

  public async deactivateProduct(
    form: DynamicForm,
    formData: any,
    referenceId: string
  ): Promise<boolean> {
    if (formData.custom_materialType) {
      formData.materialType = formData.materialTypeManual;
    }
    if (formData.custom_manufacturer) {
      formData.manufacturer = formData.manufacturerManual;
    }
    formData.ownerContactId = this.$store.state.user.contactId;
    formData.ownerOrganisationId = this.$store.state.user.organizationId;
    return productService
      .deleteProduct(form, referenceId, formData)
      .then((value) => !!value);
  }

  public async updateProduct(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    if (formData.custom_materialType) {
      formData.materialType = formData.materialTypeManual;
    }
    if (formData.custom_manufacturer) {
      formData.manufacturer = formData.manufacturerManual;
    }
    this.updateProductDialog.referenceId = referenceId || "nan";
    formData.ownerContactId = this.$store.state.user.contactId;
    formData.ownerOrganisationId = this.$store.state.user.organizationId;
    return productService
      .updateProduct(form, this.updateProductDialog.referenceId, formData)
      .then((value) => !!value);
  }

  showCreateDialog() {
    this.createNewProductControl?.show();
  }

  showUpdateDialog(item: { item: any; index: number }) {
    console.warn("item", item);
    this.updateProductControl?.show(item.item.id, item.item);
  }

  createNewProductDialog: FormDialogModel = {
    title: "products.createnew.title",
    submitLabel: "products.createnew.form.submit",
    referenceType: "product",
    referenceId: "sqd",
    destroyLabel: null,
    externalContext: new ExternalContext(),
    form: {
      id: "root",
      name: "",
      type: RenderType.ACORDEON,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
      children: [
        {
          id: "product",
          name: "products.createnew.form.product",
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "name",
              name: "products.createnew.form.name",
              type: RenderType.TEXTFIELD,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
            {
              id: "ownerContactId",
              name: "",
              type: RenderType.TEXTFIELD,
              displayMode: "hidden",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
            {
              id: "ownerOrganisationId",
              name: "",
              type: RenderType.TEXTFIELD,
              displayMode: "hidden",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
            {
              id: "materialType",
              name: "products.createnew.form.materialType",
              type: RenderType.SELECTV2,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_materialType===null;hidden",
              computedValue: "",
              metadata: {
                items:
                  "data.external.activitydefinition.products.materialTypes",
                itemText: "name",
                itemValue: "name",
                multiple: false,
              },
            },
            {
              id: "custom_materialType",
              name: "products.createnew.form.customMaterialType",
              type: RenderType.Switch,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
            {
              id: "materialTypeManual",
              name: "products.createnew.form.materialType",
              type: RenderType.TEXTFIELD,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_materialType===true;hidden",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
            {
              id: "manufacturer",
              name: "products.createnew.form.manufacturer",
              type: RenderType.SELECTV2,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_manufacturer===null;hidden",
              computedValue: "",
              metadata: {
                items:
                  "data.external.activitydefinition.products.manufacturers",
                itemText: "name",
                itemValue: "name",
                multiple: false,
              },
            },
            {
              id: "custom_manufacturer",
              name: "products.createnew.form.customManufacturer",
              type: RenderType.Switch,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
            {
              id: "manufacturerManual",
              name: "products.createnew.form.manufacturer",
              type: RenderType.TEXTFIELD,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_manufacturer===true;hidden",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
          ],
        },
        {
          id: "docs",
          name: "products.createnew.form.documents",
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "FileInput",
              name: "products.createnew.form.document",
              type: RenderType.FileInput,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: [],
              metadata: {
                isDynamicApiField: true,
                multiple: true,
                categoryId: "405d4bef-2062-ee11-8df0-6045bd895bfb",
                typeIds: ["aff2f58d-b5ef-eb11-bacb-000d3abfee00"],
                description: "Product info document for custom Product",
                accept: ".xlsx,.docx, .pdf",
              },
              children: [],
            },
          ],
        },
      ],
    },
  };

  updateProductDialog: FormDialogModel = {
    title: "products.update.title",
    submitLabel: "products.update.submitUpdate",
    referenceType: "product",
    referenceId: "sqd",
    destroyLabel: "products.update.submitDeactive",
    externalContext: new ExternalContext(),
    form: {
      id: "root",
      name: "",
      type: RenderType.ACORDEON,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
      children: [
        {
          id: "product",
          name: "products.update.form.product",
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "name",
              name: "products.update.form.name",
              type: RenderType.TEXTFIELD,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
            {
              id: "ownerContactId",
              name: "",
              type: RenderType.TEXTFIELD,
              displayMode: "hidden",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
            {
              id: "ownerOrganisationId",
              name: "",
              type: RenderType.TEXTFIELD,
              displayMode: "hidden",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
            {
              id: "materialType",
              name: "products.update.form.materialType",
              type: RenderType.SELECTV2,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_materialType===null;hidden",
              computedValue: "",
              metadata: {
                items:
                  "data.external.activitydefinition.products.materialTypes",
                itemText: "name",
                itemValue: "name",
                multiple: false,
              },
            },
            {
              id: "custom_materialType",
              name: "products.update.form.customMaterialType",
              type: RenderType.Switch,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
            {
              id: "materialTypeManual",
              name: "products.update.form.materialType",
              type: RenderType.TEXTFIELD,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_materialType===true;hidden",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
            {
              id: "manufacturer",
              name: "products.update.form.manufacturer",
              type: RenderType.SELECTV2,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_manufacturer===null;hidden",
              computedValue: "",
              metadata: {
                items:
                  "data.external.activitydefinition.products.manufacturers",
                itemText: "name",
                itemValue: "name",
                multiple: false,
              },
            },
            {
              id: "custom_manufacturer",
              name: "products.update.form.customManufacturer",
              type: RenderType.Switch,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
            {
              id: "manufacturerManual",
              name: "products.update.form.manufacturer",
              type: RenderType.TEXTFIELD,
              displayMode: "rule",
              displayModeRule:
                "editable:data.form.custom_manufacturer===true;hidden",
              computedValue: "",
              metadata: {
                isDynamicApiField: true,
              },
            },
          ],
        },
        {
          id: "docs",
          name: "products.update.form.documents",
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "FileInput",
              name: "products.update.form.document",
              type: RenderType.FileInput,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              validators: [],
              metadata: {
                isDynamicApiField: true,
                multiple: true,
                categoryId: "405d4bef-2062-ee11-8df0-6045bd895bfb",
                typeIds: ["aff2f58d-b5ef-eb11-bacb-000d3abfee00"],
                description: "Product info document for custom Product",
                accept: ".xlsx,.docx, .pdf",
              },
              children: [],
            },
          ],
        },
      ],
    },
  };
}
