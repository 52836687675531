import axios from "@/plugins/axios";
import {
  DocumentFile,
  DocumentMetadata,
} from "@/app/models/document/document.model";
import { MutationAction } from "vuex-module-decorators";
import { convertTypeToApiType } from "@/app/helpers/api.helper";
import store from "@/store";

class DocumentService {
  createDocumentEntry(doc: DocumentFile): Promise<string> {
    if (!doc || !doc.fileToupload) return Promise.reject("document null");

    //create
    const createUrl = `${store.state.appConfig.apiBaseUrl}api/v2/documents`;
    return axios
      .post(createUrl, {
        name: doc.fileToupload.name,
        category: doc.category || "c12268fb-b2ef-eb11-bacb-000d3abfee00",
        type:
          doc.type && doc.type.length > 0
            ? doc.type
            : ["b5993dd7-b4ef-eb11-bacb-000d3abfee00"],
        description: doc.description || "",
        activityId: doc.activityId || "",
      })
      .then((response) => {
        return response.data;
      });
  }

  adoptDocument(
    doc: DocumentFile,
    type: string,
    referenceId: string
  ): Promise<any> {
    type = convertTypeToApiType(type);

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/documents/adopt`;
    return axios.post(url, {
      documentId: doc.documentId,
      type: type,
      referenceId: referenceId,
    });
  }
  uploadDocument(doc: DocumentFile): Promise<boolean> {
    if (!doc || !doc.documentId || !doc.fileToupload)
      return Promise.reject("document null or no id");

    const url = `${store.state.appConfig.apiBaseUrl}api/v2/documents/${doc.documentId}`;

    // document upload
    return axios
      .post(url, doc.ToFormData(), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  async getDocumentMetadata(documentId: string): Promise<DocumentMetadata> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/documents/${documentId}/metadata`;
    return axios.get(url).then((response) => {
      return response.data;
    });
  }

  async downloadDocument(doc: DocumentFile): Promise<any> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/documents/${doc.documentId}/body`;
    return axios.get(url).then((response) => {
      if (!response.data) return Promise.reject("");
      window.open(response.data, "_blank");
      return response.data;
    });
  }

  getAllDocumentsByCategory(
    catergoryId: string,
    regardingId: string
  ): Promise<DocumentMetadata[]> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/documents/metadata?page=0&pageSize=-1&sort=createdon,desc,desc&filter=ae_documentcategory:'${catergoryId}',nrq_regarding:'${regardingId}'`;
    return axios.get(url).then((response) => {
      return response?.data?._embedded?.results || [];
    });
  }

  deleteDocumentById(documentId: string): Promise<boolean> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/documents/${documentId}`;
    return axios.delete(url).then((response) => {
      return response?.status === 200;
    });
  }

  updateDocumentMetadata(documentId, item: DocumentMetadata): Promise<boolean> {
    const url = `${store.state.appConfig.apiBaseUrl}api/v2/documents/${documentId}/metadata`;
    return axios.put(url, item).then((response) => {
      return response?.status === 200;
    });
  }
}
export const documentServiceV2 = new DocumentService();
