















































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import { DataTableHeader } from "vuetify";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import { documentService } from "@/services/document.service";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import { AxiosPromise } from "axios";
import { authorizationService } from "@/services/authorization.service";
import { authRoles } from "@/constants/AuthRoles";
import { TableColumns } from "@/constants/TableColumns";
import ChangeShareDocumentDialog from "@/components/documents/upload/ChangeShareDocumentDialog.vue";
import { userService } from "@/services/user.service";
import ContactDto from "@/contracts/generic/ContactDto";
@Component({
  components: { ChangeShareDocumentDialog, ConfirmationDialog },
})
export default class DocumentsTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  pageConfig!: PageConfig;

  @Prop({ default: null })
  sortConfig!: SortConfig;

  @Prop({ default: true })
  showActivity!: boolean;

  @Prop({ default: true })
  showCategory!: boolean;

  @Prop({ default: false })
  showOrganization!: boolean;

  @Prop({ default: true })
  showType!: boolean;

  @Prop({ default: [] })
  documents!: DocumentDto[];

  @Prop({ default: 0 })
  amountOfDocuments!: number;

  @Prop({ default: null })
  warningMessage!: string;

  @Prop({ default: false })
  allowDocumentDelete!: boolean;

  showDialog = false;
  selectedItem!: DocumentDto;

  showSharedDialog = false;
  selectedShareItem: DocumentDto | null = null;
  closeShareDialog(): void {
    this.showSharedDialog = false;
    this.selectedShareItem = null;
  }
  openshareDialog(document: DocumentDto): void {
    this.selectedShareItem = JSON.parse(JSON.stringify(document));
    this.showSharedDialog = true;
  }
  changedShare(document: DocumentDto): void {
    console.warn("triggered func", document);
    this.$emit("sortConfigUpdated", this.sortConfig);
    this.closeShareDialog();
  }

  nameHeader = {
    text: this.$t("my-documents.name") as string,
    value: TableColumns.NAME,
    sortable: false,
  };
  categoryHeader = {
    text: this.$t("my-documents.category") as string,
    value: TableColumns.CATEGORY,
    sortable: false,
  };
  typeHeader = {
    text: this.$t("my-documents.type") as string,
    value: TableColumns.TYPE,
    sortable: false,
  };
  dateHeader = {
    text: this.$t("my-documents.date") as string,
    value: TableColumns.DATE,
    sortable: true,
  };
  activityHeader = {
    text: this.$t("my-documents.activity") as string,
    value: TableColumns.ACTIVITY,
    sortable: false,
  };
  uploadedByHeader = {
    text: this.$t("my-documents.uploaded-by") as string,
    value: TableColumns.UPLOADED_BY,
    sortable: false,
  };
  contractorHeader = {
    text: this.$t("my-documents.contractor") as string,
    value: TableColumns.ORGANIZATION,
    sortable: false,
  };
  sharedHeader = {
    text: "",
    value: TableColumns.SHARED,
    sortable: false,
  };
  actionsHeader = {
    text: "",
    value: TableColumns.ACTIONS,
    sortable: false,
  };

  headers: DataTableHeader[] = [];

  currentUser: ContactDto = {
    organizationId: "",
    activities: [],
    email: "",
    firstName: "",
    fullName: "",
    language: "NL",
    id: "",
    lastName: "",
    policiesAccepted: true,
    roles: [],
  };

  mounted(): void {
    this.initHeaders();
    userService.getCurrentUser().then((value) => (this.currentUser = value));
  }

  canDeleteDocument(document: DocumentDto): boolean {
    // This check is to restrict the possibility to delete other's documents for executors
    // The check if the user can delete documents based on his role is passed
    // to this component via the allowDocumentDelete and that value is combined with the result of this method
    // That's why true is returned for every role except for executor
    if (authorizationService.userIsAuthorized([authRoles.EXECUTOR])) {
      return document.uploadedByEmail === this.$store.state.user.email;
    } else {
      return true;
    }
  }

  initHeaders(): void {
    this.headers.push(this.nameHeader);

    if (this.showCategory) this.headers.push(this.categoryHeader);

    if (this.showType) this.headers.push(this.typeHeader);

    this.headers.push(this.dateHeader);

    if (this.showActivity) this.headers.push(this.activityHeader);

    this.headers.push(this.uploadedByHeader);

    if (this.showOrganization) {
      this.headers.push(this.contractorHeader);
    }

    this.headers.push(this.sharedHeader);

    this.headers.push(this.actionsHeader);
  }

  pageUpdated(page: number | undefined): void {
    if (page) {
      this.pageConfig.page = page;
      this.emitPageConfigUpdatedEvent();
    }
  }

  itemsPerPageUpdated(pageSize: number | undefined): void {
    if (pageSize) {
      this.pageConfig.pageSize = pageSize;
      this.emitPageConfigUpdatedEvent();
    }
  }

  emitPageConfigUpdatedEvent(): void {
    this.$emit("pageConfigUpdated", this.pageConfig);
  }

  sortByUpdated(sortBy: string | undefined): void {
    if (sortBy) {
      this.sortConfig.sortBy = sortBy;
      this.emitSortConfigUpdatedEvent();
    }
  }

  sortDescUpdated(sortDescending: boolean | undefined): void {
    if (sortDescending != undefined) {
      this.sortConfig.sortDesc = sortDescending;
      this.emitSortConfigUpdatedEvent();
    }
  }

  emitSortConfigUpdatedEvent(): void {
    this.$emit("sortConfigUpdated", this.sortConfig);
  }

  downloadDocument(id: string, fileName: string): void {
    documentService.getDocumentById(id, fileName);
  }

  showConfirmDeleteDialog(item: DocumentDto): void {
    this.showDialog = true;
    this.selectedItem = item;
  }

  closeConfirmDeleteDialog(): void {
    this.showDialog = false;
  }

  deleteDocument(): AxiosPromise {
    return documentService.deleteDocument(this.selectedItem.id);
  }

  emitDocumentDeletedEvent(): void {
    this.$emit("documentDeleted");
  }
}
