






































































































import { Component, Prop, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import DocumentFilterConfig from "@/contracts/document/DocumentFilterConfig";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import DocumentsTable from "@/components/common/documents/DocumentsTable.vue";
import UploadDocumentTile from "@/components/documents/upload/UploadDocumentTile.vue";
import ActionTile from "@/components/common/ActionTile.vue";
import DocumentsFilters from "@/components/common/documents/DocumentsFilters.vue";
import { dossierService } from "@/services/dossier.service";
import { Routes } from "@/router";
import { documentTypes } from "@/constants/DocumentTypes";
import { i18n } from "@/i18n";
import { documentMapper } from "@/mappers/document.mapper";
import SelfMonitoringReportsActions from "@/components/lots/self-monitoring-reports/SelfMonitoringReportsActions.vue";
import SelfMonitoringReportsDetailsActions from "@/components/lots/self-monitoring-reports/SelfMonitoringReportsDetailsActions.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import { TableColumns } from "@/constants/TableColumns";
import PageHeader from "@/components/layout/PageHeader.vue";
import { AxiosPromise } from "axios";

@Component({
  components: {
    SelfMonitoringReportsActions,
    SelfMonitoringReportsDetailsActions,
    DocumentsTable,
    DocumentsFilters,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    UploadDocumentTile,
    ActionTile,
    Loader,
    PageHeader,
  },
})
export default class SelfMonitoringReportsView extends Vue {
  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: null })
  lotId!: string;

  initialLoading = false;
  loading = false;
  documentType = documentTypes.SELFMONITORING;

  pageConfig: PageConfig = {
    page: 1,
    pageSize: 10,
  };

  sortConfig: SortConfig = {
    sortBy: TableColumns.DATE,
    sortDesc: true,
  };

  filterConfig: DocumentFilterConfig = {
    categories: [],
    types: [],
    activities: [],
    uploadedBy: [],
    organizations: [],
  };

  canEditReports = false;
  amountOfSelfMonitoringReports = 0;
  selfMonitoringReports: DocumentDto[] = [];
  dossierActivityId: string | null = null;

  get userIsAuthorizedToCreateSelfMonitoringReport(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCreateSelfMonitoringReport();
  }

  get userIsAuthorizedToDeleteSelfMonitoringReport(): boolean {
    return roleBasedActionsService.userIsAuthorizedToDeleteSelfMonitoringReport();
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    this.checkIfCanEditReports();
    this.fetchDossierActivity();
    this.fetchSelfMonitoringReports(true);
  }

  checkIfCanEditReports(): void {
    dossierService.canEditDossier(this.dossierId).then((canEditDossier) => {
      this.canEditReports = canEditDossier; // if the dossier cannot be edited, the reports linked to the lots cannot be edited either
    });
  }

  fetchSelfMonitoringReports(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    dossierService
      .getSelfMonitoringReports(
        this.dossierId,
        this.lotId,
        this.pageConfig,
        this.sortConfig,
        this.filterConfig
      )
      .then((data) => {
        if (data) {
          if (initialFetch) {
            this.amountOfSelfMonitoringReports = data.rowCount;
          }

          if (data.results) {
            this.selfMonitoringReports = data.results;
          }
        }
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }

        this.loading = false;
      });
  }

  fetchDossierActivity(): void {
    dossierService
      .getDossier(this.dossierId)
      .then((data) => (this.dossierActivityId = data.activityDomains.activity));
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchSelfMonitoringReports();
  }

  sortConfigUpdated(sortConfig: SortConfig): void {
    this.sortConfig = sortConfig;
    this.fetchSelfMonitoringReports();
  }

  filterConfigUpdated(filterConfig: DocumentFilterConfig): void {
    this.filterConfig = filterConfig;
    this.fetchSelfMonitoringReports();
  }

  navigateToLotDetails(): void {
    this.$router.push({
      name: Routes.LotDetails,
      params: {
        dossierId: this.dossierId,
        lotId: this.lotId,
      },
    });
  }

  documentAdded(document: SelfMonitoringReportDto | null): void {
    if (document) {
      this.loading = true;
      let files = document.files;
      document.files = null;
      let promises: AxiosPromise<void>[] = [];

      files?.forEach((file) => {
        document.files = [file];
        let formData =
          documentMapper.mapSelfMonitoringReportDtoToFormData(document);

        if (file instanceof File) {
          promises.push(
            dossierService.addSelfMonitoringReport(
              this.dossierId,
              this.lotId,
              formData
            )
          );
        }
      });

      Promise.all(promises)
        .then(() => {
          Vue.$toast.success(i18n.t("self-monitoring-reports.report-added"));
          this.fetchSelfMonitoringReports(true);
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("self-monitoring-reports.report-add-failed"));
        })
        .finally(() => (this.loading = false));
    }
  }
}
