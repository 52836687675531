





























import { Component, Prop, Vue } from "vue-property-decorator";
import { Release } from "@/contracts/faq/ReleaseDto";

@Component
export default class ReleaseSteps extends Vue {
  @Prop({ default: false })
  steps!: Release[];

  expandedPanels: string[] = [];

  expandPanel(id: string): void {
    this.expandedPanels = [id];
  }
}
