






































import { Component, Prop, Vue } from "vue-property-decorator";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { documentTypes } from "@/constants/DocumentTypes";
import UploadDocumentForm from "@/components/documents/upload/UploadDocumentForm.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";

@Component({
  components: {
    UploadDocumentForm,
  },
})
export default class UploadDocumentDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: null })
  files!: File[];

  @Prop({ default: null })
  submitLabel!: string;

  @Prop({ default: null })
  documentType!: string;

  @Prop({ default: null })
  dossierActivity!: string;

  loading = false;
  filesAllowed = false;

  document: CreateDocumentDto | SelfMonitoringReportDto | null = null;

  selfMonitoringDocumentType = documentTypes.SELFMONITORING;

  get isSubmitDisabled(): boolean {
    return !(
      this.document &&
      this.document.files &&
      this.filesAllowed &&
      this.document.category &&
      this.document.type &&
      this.document.activity &&
      (this.applicableDatePresent() ||
        this.documentType != this.selfMonitoringDocumentType)
    );
  }

  mounted(): void {
    if (this.documentType == this.selfMonitoringDocumentType) {
      this.document = new SelfMonitoringReportDto();
    } else {
      this.document = new CreateDocumentDto();
    }
  }

  applicableDatePresent(): boolean {
    if (
      this.document &&
      Object.prototype.hasOwnProperty.call(this.document, "applicableDate")
    ) {
      return (this.document as SelfMonitoringReportDto).applicableDate !== null;
    }
    return false;
  }

  close(): void {
    this.$emit("close");
    this.reset();
  }

  submit(): void {
    this.$emit("documentAdded", this.document);
    this.close();
    this.reset();
  }

  reset(): void {
    this.document = new CreateDocumentDto();
  }

  filesAllowedCheck(filesAllowed: boolean): void {
    this.filesAllowed = filesAllowed;
  }
}
