
































































import { Component, Prop, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import PageConfig from "@/contracts/generic/PageConfig";
import { dossierService } from "@/services/dossier.service";
import { Routes } from "@/router";
import { i18n } from "@/i18n";
import EmptyDayplanningTile from "@/components/lots/dayplanning/EmptyDayplanningTile.vue";
import LotDto from "@/contracts/lot/LotDto";
import CreateDayPlanningDto from "@/contracts/dayplanning/CreateDayPlanningDto";
import CheckInDialog from "@/components/lots/dayplanning/CheckInDialog.vue";
import DayPlanningTable from "@/components/lots/dayplanning/DayPlanningTable.vue";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import AeSlideOut from "@/components/layout/SlideOut.vue";
import DayPlanningHistorySlideOutContent from "@/components/lots/dayplanning/DayPlanningHistorySlideOutContent.vue";
import { authRoles } from "@/constants/AuthRoles";
import { authorizationService } from "@/services/authorization.service";
import PageHeader from "@/components/layout/PageHeader.vue";

@Component({
  components: {
    AcceptPoliciesInterceptor,
    AeSlideOut,
    AuthenticationInterceptor,
    CheckInDialog,
    DayPlanningHistorySlideOutContent,
    DayPlanningTable,
    EmptyDayplanningTile,
    Loader,
    PageHeader,
  },
})
export default class DayPlanningHistoryView extends Vue {
  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: null })
  lotId!: string;

  initialLoading = false;
  loading = false;

  lot: LotDto | null = null;
  showCheckInDialogValue = false;
  submittingCheckIn = false;
  dayPlanningHistoryEntries: DayPlanningHistoryEntryDto[] | null = null;
  totalDayPlanningHistoryEntries = 0;
  showSlideOut = false;
  itemActiveInSlideOut: DayPlanningHistoryEntryDto | null = null;

  allowCheckIn = false;

  pageConfig: PageConfig = {
    page: 1,
    pageSize: 10,
  };

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    this.checkIfCheckInAllowed();
    this.fetchDayPlanningHistory(true);
    this.fetchLot();
  }

  checkIfCheckInAllowed(): void {
    if (authorizationService.userIsAuthorized([authRoles.EXECUTOR])) {
      dossierService.canEditDossier(this.dossierId).then((canEditDossier) => {
        this.allowCheckIn = canEditDossier; // if the dossier cannot be edited, the related documents cannot be edited
      });
    } else {
      this.allowCheckIn = false;
    }
  }

  fetchDayPlanningHistory(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    dossierService
      .getDayPlanningHistory(this.dossierId, this.lotId, this.pageConfig)
      .then((data) => {
        if (data) {
          this.dayPlanningHistoryEntries = data.results;
          this.totalDayPlanningHistoryEntries = data.rowCount;
        }
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }
        this.loading = false;
      });
  }

  fetchLot(): void {
    dossierService.getLot(this.dossierId, this.lotId).then((data) => {
      if (data) {
        this.lot = data;

        if (this.lot && this.lot.techniques) {
          // to allow treeview disabled view
          this.lot.techniques.forEach(
            (technique) => (technique.notEditable = true)
          );
        }
      }
    });
  }

  navigateToLotDetails(): void {
    this.$router.push({
      name: Routes.LotDetails,
      params: {
        dossierId: this.dossierId,
        lotId: this.lotId,
      },
    });
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchDayPlanningHistory();
  }

  showCheckInDialog(): void {
    this.showCheckInDialogValue = true;
  }

  submitCheckIn(checkInData: CreateDayPlanningDto): void {
    this.submittingCheckIn = true;
    dossierService
      .createDayPlanning(this.dossierId, checkInData)
      .then(() => {
        Vue.$toast.success(i18n.t("dayplanning.check-in-successful"));
        this.showCheckInDialogValue = false;
        this.fetchDayPlanningHistory(true);
      })
      .catch(() => Vue.$toast.error(i18n.t("dayplanning.check-in-failed")))
      .finally(() => {
        this.submittingCheckIn = false;
      });
  }

  closeCheckInDialog(): void {
    this.showCheckInDialogValue = false;
  }

  openSlideOut(item: DayPlanningHistoryEntryDto): void {
    if (!this.showSlideOut) {
      this.itemActiveInSlideOut = item;
      this.showSlideOut = true;
    }
  }

  closeSlideOut(): void {
    if (this.showSlideOut) {
      this.itemActiveInSlideOut = null;
      this.showSlideOut = false;
    }
  }
}
