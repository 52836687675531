import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticClass:"d-flex flex-column"},[_c(VBtn,{staticClass:"my-1 primary",attrs:{"disabled":!_vm.canAddLot || !_vm.userIsAuthorizedToCreateLot},on:{"click":_vm.addLot}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_c('i18n',{attrs:{"path":"my-dossiers.add-new-lot","tag":"span"}})],1),_c(VBtn,{staticClass:"my-1 black white--text",attrs:{"disabled":!_vm.canEditDossier || !_vm.userIsAuthorizedToEditDossier},on:{"click":_vm.editDossier}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_c('i18n',{attrs:{"path":"my-dossiers.edit-dossier-details","tag":"span"}})],1),_c(VBtn,{staticClass:"mt-6 mb-1 black white--text",attrs:{"disabled":!_vm.canRequestConformity || !_vm.userIsAuthorizedToEditDossier},on:{"click":_vm.requestConformity}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_c('i18n',{attrs:{"path":"my-dossiers.request-conformity","tag":"span"}})],1),_c(VBtn,{staticClass:"my-1 error",attrs:{"loading":_vm.submitting,"disabled":!_vm.canDeleteDossier || !_vm.userIsAuthorizedToDeleteDossier},on:{"click":_vm.deleteDossier}},[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_c('i18n',{attrs:{"path":"my-dossiers.delete-dossier","tag":"span"}})],1)],1):_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c(VList,[_c(VListItem,{attrs:{"disabled":!_vm.canAddLot || !_vm.userIsAuthorizedToCreateLot},on:{"click":_vm.addLot}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_c('i18n',{attrs:{"path":"my-dossiers.add-new-lot","tag":"span"}})],1)],1),_c(VListItem,{attrs:{"disabled":!_vm.canEditDossier || !_vm.userIsAuthorizedToEditDossier},on:{"click":_vm.editDossier}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_c('i18n',{attrs:{"path":"my-dossiers.edit-dossier-details","tag":"span"}})],1)],1),_c(VListItem,{attrs:{"disabled":!_vm.canRequestConformity || !_vm.userIsAuthorizedToEditDossier},on:{"click":_vm.requestConformity}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_c('i18n',{attrs:{"path":"my-dossiers.request-conformity","tag":"span"}})],1)],1),_c(VListItem,{attrs:{"loading":_vm.submitting,"disabled":!_vm.canDeleteDossier || !_vm.userIsAuthorizedToDeleteDossier},on:{"click":_vm.deleteDossier}},[_c(VListItemTitle,[_c(VIcon,{attrs:{"left":""}},[_vm._v(" mdi-delete ")]),_c('i18n',{attrs:{"path":"my-dossiers.delete-dossier","tag":"span"},on:{"click":_vm.deleteDossier}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }