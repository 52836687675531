





































import { Component, Prop, Vue } from "vue-property-decorator";
import { documentTypes } from "@/constants/DocumentTypes";
import UploadDocumentDialog from "@/components/documents/upload/UploadDocumentDialog.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import { roleBasedActionsService } from "@/services/role-based-actions.service";

@Component({
  components: {
    UploadDocumentDialog,
  },
})
export default class SelfMonitoringReportsActions extends Vue {
  @Prop({ default: null })
  dossierActivity!: string;

  @Prop({ default: false })
  canEditReports!: boolean;

  showDialog = false;
  file: File | null = null;
  documentType = documentTypes.SELFMONITORING;

  get userIsAuthorizedToCreateSelfMonitoringReport(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCreateSelfMonitoringReport();
  }

  showUploadDialog(): void {
    this.file = null;
    this.showDialog = true;
  }

  showUploadDialogWithFile(files: File[]): void {
    this.file = files[0];
    this.showDialog = true;
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  emitDocumentAddedEvent(document: SelfMonitoringReportDto | null): void {
    this.$emit("documentAdded", document);
  }
}
