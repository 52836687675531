import CreateLotDto from "@/contracts/lot/CreateLotDto";
import LotDto from "@/contracts/lot/LotDto";
import { techniqueMapper } from "@/mappers/technique.mapper";

class LotMapper {
  public mapToCreateDto(dto: LotDto): CreateLotDto {
    const result = new CreateLotDto();

    result.name = dto.name;
    result.startDate = dto.startDate;
    result.endDate = dto.endDate;
    result.otherProducts = dto.otherProducts;

    result.techniques = dto.techniques ? [] : null;
    if (dto.techniques) {
      dto.techniques
        .filter((technique) => technique.techniqueSteps !== null)
        .forEach((technique) => {
          const newTechnique = techniqueMapper.mapToCreateDto(technique);
          if (newTechnique) {
            result.techniques?.push(newTechnique);
          }
        });
    }
    result.products = dto.products ? [] : null;
    if (dto.products) {
      dto.products.forEach((product) => {
        if (product.id) {
          result.products?.push(product.id);
        }
      });
    }

    result.id = dto.id ?? null;

    return result;
  }
}

export const lotMapper = new LotMapper();
