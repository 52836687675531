import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";

type receivingData = {
  data: dataType;
  valid: boolean;
  id: string;
};

export type dataType = { [key: string]: any };

export default class BaseSectionDynamicComponent<
  T
> extends BaseDynamicComponent<T> {}
