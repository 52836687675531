export default class OverviewExpanderLookup {
  public getComponent(type: string): string {
    switch (type) {
      case "DocumentExchange":
        return "OverviewExpanderDocumentExchange";
      default:
        return "";
    }
  }
}

export const overviewExpanderLookup = new OverviewExpanderLookup();
