import EventBase from "@/app/events/event-base";
import { DetailContext } from "@/app/contexts/detail.context";

export default class DeleteDossierEvent extends EventBase {
  constructor() {
    super();
  }

  getCallBack(detailsContext: DetailContext, payload?: any): any {
    console.log("test delete");
  }
}
