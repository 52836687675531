





































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import DossierDto from "@/contracts/dossier/DossierDto";
import ActivityDomainDto from "@/contracts/activity/ActivityDomainDto";
import { TableColumns } from "@/constants/TableColumns";

@Component
export default class DossierActivityDomainsTable extends Vue {
  // @PropSync("dossier", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: [] })
  availableActivityDomains!: ActivityDomainDto[];

  selectedActivityDomains: ActivityDomainDto[] = [];

  headers: DataTableHeader[] = [
    {
      text: this.$t("my-dossiers.activity-domain") as string,
      value: TableColumns.ACTIVITY_DOMAIN,
      sortable: false,
    },
    {
      text: this.$t("my-dossiers.activity") as string,
      value: TableColumns.ACTIVITY,
      sortable: false,
    },
  ];

  mounted(): void {
    // // setting already selected activity domains
    // const dossierActivityDomains =
    //   this.dossierSync.activityDomains.activityDomains;
    // this.updateSelectedActivityDomains(dossierActivityDomains);
  }

  // //method that sets the selected activity domains
  // updateSelectedActivityDomains(dossierActivityDomains: string[]): void {
  //   if (dossierActivityDomains) {
  //     this.selectedActivityDomains = this.availableActivityDomains.filter(
  //       (activityDomain) =>
  //         dossierActivityDomains.indexOf(activityDomain.id) > -1
  //     );
  //   }
  // }

  updateActivityDomains(activityDomains: ActivityDomainDto[]): void {
    this.selectedActivityDomains = activityDomains;
    this.$emit(
      "selectionChanged",
      activityDomains.map((activityDomain) => activityDomain.id)
    );
  }
}
