










































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { EventDictionary } from "@/app/events/event.dictionary";
import { DetailContext } from "@/app/contexts/detail.context";
import {
  DynamicDetailDefinition,
  DynamicDetailEntry,
} from "@/app/dynamic-components/details/dynamic-detail.model";
import Loader from "@/components/common/Loader.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import router from "@/router";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { DetailComponentBase } from "@/app/dynamic-components/details/detail-component.base";
import IamRole from "@/contracts/iam/IamRole";
import ExtendedActivityDto from "@/contracts/activity/ExtendedActivityDto";
import store from "@/store";

@Component({
  components: { ExchangeComponent, PageHeader, Loader },
})
export default class DossierDetailComponent extends DetailComponentBase {
  get attrs() {
    return {
      boilerplate: false,
    };
  }

  public modal: {
    showModal: boolean;
    component: string;
    entry: DynamicDetailEntry | undefined;
  } = {
    showModal: false,
    component: "",
    entry: undefined,
  };


  protected getDetailDefinition(definitionId: string): Promise<DynamicDetailDefinition> {
    return dossierServiceV2.getDossierDetailDefinition(definitionId)
  }
  protected getDetailData(): Promise<any> {
    return dossierServiceV2.getDossierData(this.$route.params.id);
  }


  protected setDetailContext(data: any, detailDefinition: DynamicDetailDefinition | null) {
    this.detailContext.setData("activity", this.$store.state.activity.currentActivityDefinition);
    const user = this.$store.state.user;
    this.detailContext.setData("user", {
      contactId: user.contactId,
      organizationId: user.organizationId,
      language: user.language,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      roles: user.roles,
      roleIds: user.roleIds
    });
    this.detailContext.setData("bccaId", store.state.appConfig.bccaId);
    console.warn('bccaId',store.state.appConfig.bccaId)
    this.detailContext.setData("dossierEditDefinitionId", this.$store.state.activity.currentActivityDefinition.dossiers.entries[0].dossierModifyDefinitionId);
    this.detailContext.setData("dossier", data.data);
    this.detailContext.setData("dossierStatus", data.status);
    this.detailContext.setData("dossierId", this.$route.params.id);
    this.detailContext.setData("dossierSecurity", this.getDossierSecurity(this.detailDefinitionId));
  }

  goBack() {
    router.back();
  }

  setModal(dialog: string) {
    this.modal.showModal = true;
    this.modal.component = this.getDetailComponent(dialog);
    this.modal.entry = this.detailDefinition?.dialogs[dialog];
  }

  closeModal() {
    this.modal.showModal = false;
    this.modal.component = "";
    this.modal.entry = undefined;
  }


}
