












import { Vue, Component } from "vue-property-decorator";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { DynamicFormEntryModel } from "@/app/dynamic-components/dynamic-components.model";

@Component({
  components: { FormWrapper },
})
export default class DossierDetailComponentGeneralTab extends DetailComponentBase<any> {
  formDefinition: DynamicFormEntryModel | null = null;
  postEntityChanged(newValue: DynamicDetailEntry) {
    console.warn("entry set");
    dossierServiceV2
      .getDossierCreationDefinition("ae5089db-9932-ee11-bdf4-6045bd895f92")
      .then((formDefinition) => {
        this.formDefinition = formDefinition.form;
      });
  }
}
