import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"my-2",attrs:{"title":_vm.$t('general.change-language'),"loading":_vm.loading,"color":"white","text":""}},on),[_c(VIcon,{staticClass:"pr-1 white--text"},[_vm._v(" mdi-earth")]),_c('span',[_vm._v(" "+_vm._s(_vm.userLanguage)+" ")])],1)]}}])},[_c(VList,[_c(VListItemGroup,_vm._l((_vm.supportedLanguages),function(language){return _c(VListItem,{key:language,on:{"click":function($event){return _vm.changeUserLanguage(language)}}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(language.toUpperCase()))])],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }