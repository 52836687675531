


































































import { Component, Prop, Vue } from "vue-property-decorator";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import { TableColumns } from "@/constants/TableColumns";
import { dossierService } from "@/services/dossier.service";
import DossierFilterConfig from "@/contracts/dossier/DossierFilterConfig";
import MyDossiersFilters from "@/components/dossiers/my-dossiers/MyDossiersFilters.vue";
import MyDossiersTable from "@/components/dossiers/my-dossiers/MyDossiersTable.vue";
import Loader from "@/components/common/Loader.vue";
import CreateDayPlanningDto from "@/contracts/dayplanning/CreateDayPlanningDto";
import { i18n } from "@/i18n";
import UpdateDayPlanningDto from "@/contracts/dayplanning/UpdateDayPlanningDto";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import { documentMapper } from "@/mappers/document.mapper";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import LotDto from "@/contracts/lot/LotDto";
import DossierDto from "@/contracts/dossier/DossierDto";
import { Dossier } from "@/contracts/dossier/DossiersDto";
import CheckInDialog from "@/components/lots/dayplanning/CheckInDialog.vue";
import CheckOutDialog from "@/components/lots/dayplanning/checkout/CheckOutDialog.vue";
import CheckInDossierTable from "@/components/checkin/CheckInDossierTable.vue";

@Component({
  components: {
    CheckOutDialog,
    CheckInDialog,
    Loader,
    MyDossiersTable,
    MyDossiersFilters,
    CheckInDossierTable,
  },
})
export default class CheckInDossierDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  canEditLot = false;

  initialLoading = false;
  loading = false;
  submittingCheckIn = false;
  submittingCheckOut = false;
  checkOutSubmitted = false;
  submittingReport = false;
  loadingDayPlanning = false;
  isCheckIn = false;

  showCheckInDialogValue = false;
  showCheckOutDialogValue = false;

  dossierId = "";
  dossier = new DossierDto();
  mostRecentDayPlanningEntry: DayPlanningHistoryEntryDto | null = null;
  lots: LotDto[] = [];
  lotIds: string[] | null = null;

  pageConfig: PageConfig = {
    page: 1,
    pageSize: 10,
  };

  sortConfig: SortConfig = {
    sortBy: TableColumns.START_DATE,
    sortDesc: true,
  };

  filterConfig: DossierFilterConfig = {
    statuses: ["100000000", "100000001"],
    organizations: [],
  };

  get amountOfDossiers(): number {
    return this.$store.state.dossiers.amountOfDossiers;
  }

  get amountOfLots(): number {
    return this.lots.length;
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    this.fetchDossiers(true);
  }

  openCheckInDialog(item: Dossier): void {
    this.dossierId = item.id;
    this.isCheckIn = true;
    this.fetchDossier();
  }

  openCheckOutDialog(item: Dossier): void {
    this.dossierId = item.id;
    this.isCheckIn = false;
    this.fetchDossier();
  }

  checkIfCanEditLot(): void {
    dossierService.canEditDossier(this.dossierId).then((canEditDossier) => {
      this.canEditLot = canEditDossier; // if the dossier cannot be edited, neither can the lots
    });
  }

  fetchDossiers(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    dossierService
      .getDossiers(this.pageConfig, this.sortConfig, this.filterConfig)
      .then((data) => {
        if (data) {
          if (initialFetch) {
            this.$store.commit("setAmountOfDossiers", data.rowCount);
          }

          if (data.results) {
            this.$store.commit("setDossiers", data.results);
          }
        }
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }

        this.loading = false;
      });
  }

  fetchDossier(): void {
    this.loading = true;
    dossierService
      .getDossier(this.dossierId)
      .then((data) => {
        if (data) {
          this.dossier = JSON.parse(JSON.stringify(data));
          this.fetchLots();
          this.checkIfCanEditLot();
        }
      })
      .finally(() => {
        this.loadingDayPlanning = true;
        dossierService
          .getDayPlanningLatestDossier(this.dossierId)
          .then((data) => {
            this.mostRecentDayPlanningEntry = data;

            if (this.isCheckIn) {
              this.showCheckInDialog();
            } else {
              if (this.mostRecentDayPlanningEntry) {
                this.showCheckOutDialog();
              } else {
                Vue.$toast.error(i18n.t("error.no-check-out-available"));
              }
            }
          })
          .catch((error) => console.error(error))
          .finally(() => (this.loadingDayPlanning = false));

        this.loading = false;
      });
  }

  fetchLots(): void {
    this.loading = true;
    this.lots = [];

    this.dossier.lots.forEach((lot) => {
      if (lot.id != null) {
        dossierService
          .getLot(this.dossierId, lot.id)
          .then((data) => {
            if (data) {
              this.lots.push(data);
            }
          })
          .catch((error) => console.error(error))
          .finally(() => (this.loading = false));
      }
    });
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchDossiers();
  }

  sortConfigUpdated(sortConfig: SortConfig): void {
    this.sortConfig = sortConfig;
    this.fetchDossiers();
  }

  close(): void {
    this.$emit("close");
  }

  showCheckInDialog(): void {
    this.showCheckInDialogValue = true;
  }

  submitCheckIn(checkInData: CreateDayPlanningDto): void {
    this.submittingCheckIn = true;
    dossierService
      .createDayPlanning(this.dossierId, checkInData)
      .then(() => {
        Vue.$toast.success(i18n.t("dayplanning.check-in-successful"));
        this.showCheckInDialogValue = false;
        this.loadData();
      })
      .catch(() => Vue.$toast.error(i18n.t("dayplanning.check-in-failed")))
      .finally(() => {
        this.submittingCheckIn = false;
      });
  }

  closeCheckInDialog(): void {
    this.showCheckInDialogValue = false;
  }

  showCheckOutDialog(): void {
    this.showCheckOutDialogValue = true;
  }

  submitCheckOut(checkOutData: UpdateDayPlanningDto): void {
    this.submittingCheckOut = true;
    dossierService
      .updateDayPlanning(this.dossierId, checkOutData)
      .then(() => {
        Vue.$toast.success(i18n.t("dayplanning.check-out-successful"));
        this.showCheckOutDialogValue = true;
        this.checkOutSubmitted = true;
      })
      .catch(() => Vue.$toast.error(i18n.t("dayplanning.check-out-failed")))
      .finally(() => {
        this.lotIds = checkOutData.checkOutLotIds;
        this.submittingCheckOut = false;
      });
  }

  submitReport(report: SelfMonitoringReportDto): void {
    if (report) {
      this.submittingReport = true;
      let formData =
        documentMapper.mapSelfMonitoringReportDtoToFormData(report);

      Promise.resolve(
        this.lotIds?.forEach((lotId) => {
          dossierService.addSelfMonitoringReport(
            this.dossierId,
            lotId,
            formData
          );
        })
      )
        .then(() => {
          Vue.$toast.success(
            i18n.t("self-monitoring-reports.submit-successful")
          );
          this.loadData();
          this.closeCheckOutDialog();
        })
        .catch(() =>
          Vue.$toast.error(i18n.t("self-monitoring-reports.submit-failed"))
        )
        .finally(() => (this.submittingReport = false));
    }
  }

  skipReport(): void {
    this.loadData();
    this.closeCheckOutDialog();
  }

  closeCheckOutDialog(): void {
    this.checkOutSubmitted = false;
    this.showCheckOutDialogValue = false;
  }
}
