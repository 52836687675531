import EventBase from "@/app/events/event-base";
import router, { Routes } from "@/router";
import { DetailContext } from "@/app/contexts/detail.context";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";

export default class ModifyLotsEvent extends EventBase {
  getCallBack(detailContext: DetailContext, payload?: any): any {
    if (detailContext.root && detailContext.root instanceof DossierDetailComponent) {
      detailContext.root.setModal("modifyLots");
    }
  }
}
