






























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ActionTileContent extends Vue {
  @Prop({ default: null })
  image!: string;

  @Prop({ default: "50vw" })
  maxImageWidth!: string;

  @Prop({ default: "50vh" })
  maxImageHeight!: string;

  @Prop({ default: null })
  icon!: string;

  @Prop({ default: null })
  title!: string;

  @Prop({ default: null })
  subtitle!: string;

  get imagePath(): string {
    if (this.image) {
      return require(`@/assets/${this.image}`);
    } else {
      return "";
    }
  }
}
