import axios from "@/plugins/axios";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import store from "@/store";
import { msalService } from "@/services/msal.service";
import IamAuthorization from "@/contracts/iam/IamAuthorization";
import IdTokenClaims from "@/contracts/identity-provider/IdTokenClaims";
import Vue from "vue";
import { i18n, loadMessagesAsync } from "@/i18n";
import { NavigationGuardNext } from "vue-router";
import { authorizationService } from "@/services/authorization.service";
import { authRoles } from "@/constants/AuthRoles";
import { translationService } from "@/services/translation.service";
import { userServiceV2 } from "@/app/services/user.service";
import { userService } from "@/services/user.service";
import ContactDto from "@/contracts/generic/ContactDto";
import { activityServicev2 } from "@/app/services/activity.service";
import { Locales } from "@/contracts/common/Language";
import AllowedExtensionDto from "@/contracts/extension/AllowedExtensionsDto";
import { extensionService } from "@/services/extension.service";

class AuthenticationService {
  signIn(): void {
    try {
      // <MSAL known issue fix cfr. https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2941, https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2149>
      let n = sessionStorage.length;
      while (n--) {
        const key: string = sessionStorage.key(n) || "";
        if (/interaction.status/.test(key)) {
          const interactionStatus = sessionStorage.getItem(key);
          if (interactionStatus === "interaction_in_progress") {
            sessionStorage.removeItem(key);
          }
        }
      }
      // </MSAL known issue fix>

      msalService.acquireTokenRedirect();
    } catch (err) {
      console.error(err);
    }
  }

  async handleAuthenticationResult(authenticationResult: AuthenticationResult): Promise<void> {
    const idTokenClaims: IdTokenClaims =
      authenticationResult.idTokenClaims as IdTokenClaims;

    const iamAuthorization: IamAuthorization = JSON.parse(
      idTokenClaims.idence_authorization
    );

    if (
      !iamAuthorization.membershipContext ||
      !iamAuthorization.membershipContext.length
    ) {
      this.handleInvalidUserAccount();
    } else if (iamAuthorization.membershipContext[0]) {
      if (
        !iamAuthorization.membershipContext[0].roles ||
        !iamAuthorization.membershipContext[0].roles.length ||
        (iamAuthorization.membershipContext[0].roles.length == 1 &&
          iamAuthorization.membershipContext[0].roles[0].name &&
          iamAuthorization.membershipContext[0].roles[0].name ==
            authRoles.SERVICE_PROVIDER_ADMIN)
      ) {
        this.handleInvalidUserRoles();
      }
    }

    store.commit("setSignedIn", true);
    store.commit("setAccessToken", authenticationResult.accessToken);
    store.commit("setIamUserId", iamAuthorization.securityPrincipalId);
    store.commit(
      "setOrganizationId",
      iamAuthorization.membershipContext[0].organization.id
    );
    store.commit(
      "setMembershipUserId",
      iamAuthorization.membershipContext[0].membershipId
    );

    axios.defaults.headers.common = {
      Authorization: `bearer ${authenticationResult.accessToken}`,
    };

    store.commit("setRoles", iamAuthorization.membershipContext[0].roles);

    const userDetails: ContactDto = await userService.getCurrentUser();
    if (userDetails) {
      store.commit("setFirstName", userDetails.firstName);
      store.commit("setLastName", userDetails.lastName);
      store.commit("setEmail", userDetails.email);
      store.commit("setLanguage", userDetails.language);
      store.commit("setActivities", userDetails.activities);
      store.commit("setHasAccessToDossiers", userDetails.activities);
      store.commit("setPoliciesAccepted", userDetails.policiesAccepted);
      store.commit("setContactId", userDetails.id);

      const eligibleActivitiesForUser =
        await activityServicev2.getEligibleActivitiesForUser(
          userDetails.id
        );
      if (eligibleActivitiesForUser) {
        store.commit("setEligibleActivities", eligibleActivitiesForUser);

        const lastusedActivity = localStorage.getItem("currentActivity");
        if (!lastusedActivity) {
          store.dispatch(
            "setCurrentActivity",
            eligibleActivitiesForUser[0]
          );
        } else {
          const foundActivities = eligibleActivitiesForUser.filter(
            (value) => value.id === lastusedActivity
          );
          if (foundActivities && foundActivities.length > 0) {
            store.dispatch("setCurrentActivity", foundActivities[0]);
          } else {
            store.dispatch(
              "setCurrentActivity",
              eligibleActivitiesForUser[0]
            );
          }
        }
      }

      const allowedExtensions: AllowedExtensionDto[] =
        await extensionService.getAllowedExtensions();
      if (allowedExtensions) {
        store.commit("setAllowedExtensions", allowedExtensions);
        let acceptedExtensions = "";
        allowedExtensions.forEach((extension) => {
          if (!acceptedExtensions.includes(extension.fileExtension)) {
            acceptedExtensions =
              allowedExtensions[0].fileExtension === extension.fileExtension
                ? `${extension.fileExtension}`
                : `${acceptedExtensions},${extension.fileExtension}`;
          }
        });
        store.commit("setAcceptedExtensions", acceptedExtensions);
      }

      await loadMessagesAsync(userDetails.language);
      document.title = (await i18n.t("customer-portal.title")) as string;
    } else {
      await loadMessagesAsync(translationService.getUserLanguage());
      document.title = (await i18n.t("customer-portal.title")) as string;
      console.warn("Failed to fetch powerapps account");
    }
  }

  public injectNumber = -1;
  public setAxiosActivityHeader() {
    if(this.injectNumber < 0){
      axios.interceptors.request.eject(this.injectNumber);
      this.injectNumber = -1;
    }

    this. injectNumber = axios.interceptors.request.use((config) => {
      const currentActivity = localStorage.getItem("currentActivity");
      config.headers = {
        ...config.headers,
        "X-ActiveActivity": currentActivity || '',
        "Accept-Language":
          Locales[translationService.getUserLanguage()] ?? Locales.NL,
      };

      return config;
    });
  }

  private handleInvalidUserAccount(): void {
    Vue.$toast.error(i18n.t("error.invalid-user-account"), { timeout: false });
    store.commit("setSignedIn", false);

    const noMembershipContextError = Error(
      "No membershipContext or no supported roles found in token"
    );
    noMembershipContextError.name = "NoMemberShipContextOrRoles";
    throw noMembershipContextError;
  }

  private handleInvalidUserRoles(): void {
    Vue.$toast.error(i18n.t("error.invalid-user-roles"), { timeout: false });
    store.commit("setSignedIn", false);

    const noMembershipContextError = Error(
      "No membershipContext or no supported roles found in token"
    );
    noMembershipContextError.name = "NoMemberShipContextOrRoles";
    throw noMembershipContextError;
  }

  async acquireToken(
    handleResult = true
  ): Promise<AuthenticationResult | void> {
    const accounts: AccountInfo[] | null = msalService.getAllAccounts();

    if (accounts && accounts.length) {
      const currentAccount: AccountInfo = accounts[0];
      msalService.setActiveAccount(currentAccount);

      const auth = await msalService
        .acquireTokenSilent()
        .then(async (authenticationResult: AuthenticationResult) => {
          if (handleResult) {
            console.warn('handle set all');
            await this.handleAuthenticationResult(authenticationResult);
          } else {
            store.commit("setAccessToken", authenticationResult.accessToken);
            axios.defaults.headers.common = {
              Authorization: `bearer ${authenticationResult.accessToken}`,
            };
          }
          return authenticationResult;
        })
        .catch((error) => {
          msalService.logoutRedirect();
          console.error(error);
        });
      return auth;
    } else {
      await Promise.resolve();
    }
  }

  handleRouteCallback(next: NavigationGuardNext) {
    return msalService
      .handleRedirect()
      .then(async (authenticationResult) => {
        if (authenticationResult !== null) {
          await authenticationService.handleAuthenticationResult(authenticationResult);
        } else {
          return Promise.reject();
        }
      })
      .then(async () => {
        await loadMessagesAsync(translationService.getUserLanguage());
        await authorizationService.navigateToLandingPage(next);
      })
      .catch(async (error) => {
        if (error) {
          if (
            error.errorMessage &&
            error.errorMessage.indexOf("AADB2C90118") > -1
          ) {
            try {
              // Password reset policy/authority
              msalService.loginRedirect();
            } catch (err) {
              console.error(err);
            }
          } else if (error.name === "NoMemberShipContextOrRoles") {
            setTimeout(() => {
              msalService.logoutRedirect();
            }, 3000);
          }
        } else {
          // not authenticated
          await authorizationService.navigateToLandingPage(next);
        }
        console.error(error);
      });
  }
}

export const authenticationService = new AuthenticationService();
