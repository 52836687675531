



































import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { Component } from "vue-property-decorator";

export type RadioButtonMetaData = {
  radioButtonLabels?: { [key: string]: string };
  disabled?: boolean;
  color?: string;
  direction?: "column" | "row";
};

@Component({})
export default class RadioButtonBase extends BaseDynamicComponent<RadioButtonMetaData> {
  selectedValue: string | null = null;

  getOptions() {
    if (!this.entry) return [];
    return Object.entries(this.entry.metadata.radioButtonLabels).map(
      (value) => {
        return { key: value[0], label: value[1] };
      }
    );
  }

  onRadioButtonValueChanged(newValue: string): void {
    this.selectedValue = newValue;
    this.setValueView(newValue);
  }
}
