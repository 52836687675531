


























































import { Component, Vue } from "vue-property-decorator";
import { userService } from "@/services/user.service";
import analyticsService from '@/services/analytics.service';

@Component
export default class AcceptPoliciesInterceptor extends Vue {
  termsOfUseAccepted = false;
  dateProcessingPolicyAccepted = false;

  submitting = false;

  get showDialog(): boolean {
    return !this.$store.state.user.hasAcceptedPolicies;
  }

  acceptPolicies(): void {
    this.submitting = true;

    userService
      .acceptPolicies()
      .then(() => {
        this.$store.commit("setPoliciesAccepted", true);
        this.trackAcceptPolicies();
        this.$router.go(0);
      })
      .finally(() => {
        this.submitting = false;
      });
  }

  trackAcceptPolicies(): void {
    console.log('trackAcceptPoliciesw method');
    analyticsService.sendEvent('User Engagement', 'accept_policies', 'Terms and Privacy', 1);
  }
}
