import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"500","persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"confirmation-dialog__title text-h5 grey lighten-2 d-flex justify-center align-center flex-column text-center"},[(_vm.icon)?_c(VIcon,{staticClass:"confirmation-dialog__icon pa-3 secondary--text"},[_vm._v(_vm._s(_vm.icon)+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$t(_vm.title)))])],1),_c(VCardText,[_vm._t("default")],2),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"color":"primary","loading":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t(_vm.confirmLabel))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }