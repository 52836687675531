
















import { Component } from "vue-property-decorator";
import { RenderItem, RenderType } from "@/app/Types";
import { ComponentTypeDictionaryService } from "@/app/dynamic-components/forms/form-components/form-component.lookup";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import BaseDynamicCompositeComponent from "@/app/dynamic-components/forms/form-components/form-component-composite.base";

export type DateTimePickerMetaData = {
  label: string;
};

@Component({
  components: { FormWrapper },
  computed: {
    RenderType() {
      return RenderType;
    },
    ComponentTypeDictionaryService() {
      return ComponentTypeDictionaryService;
    },
  },
})
export default class DateTimePickerBase extends BaseDynamicCompositeComponent<DateTimePickerMetaData> {
  protected async convertFormDataToValue(formData: any): Promise<any> {
    if (formData === null || formData.date === null || formData.time === null)
      return null;
    const date = new Date(formData.date);
    const t = formData.time.split(":");
    date.setHours(t[0]);
    date.setMinutes(t[1]);
    return date;
  }

  protected async convertValueToFormData(value: any): Promise<any> {
    if (!value) return null;
    if (typeof value !== "string") return null;
    const d0 = new Date(value);
    const d1 = new Date(value);
    d0.setHours(0);
    d0.setMinutes(0);
    return {
      date: d0.toDateString(),
      time: d1.getHours() + ":" + d1.getMinutes(),
    };
  }

  protected createDefinition(): RenderItem | null {
    if (!this.entry) return null;
    return {
      id: "root",
      name: "",
      type: RenderType.LinearSection,
      displayMode: this.entry.displayMode,
      displayModeRule: this.entry.displayModeRule,
      computedValue: "",
      validators: [],
      metadata: {
        direction: "horizontal",
      },
      children: [
        {
          id: "date",
          name: this.$tc("datetime.date"),
          type: RenderType.DatePicker,
          displayMode: this.entry.displayMode,
          displayModeRule: "",
          computedValue: "",
          validators: this.entry.validatorsNames,
          metadata: {},
        },
        {
          id: "time",
          name: this.$tc("datetime.time"),
          type: RenderType.TimePicker,
          displayMode: this.entry.displayMode,
          displayModeRule: "",
          computedValue: "",
          validators: this.entry.validatorsNames,
          metadata: {},
        },
      ],
    };
  }
}
