import { render, staticRenderFns } from "./form-component-select.vue?vue&type=template&id=f7b54ff4&scoped=true&"
import script from "./form-component-select.vue?vue&type=script&lang=ts&"
export * from "./form-component-select.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7b54ff4",
  null
  
)

export default component.exports