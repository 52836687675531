





















import { Component, Vue } from "vue-property-decorator";
import { msalService } from "@/services/msal.service";

@Component
export default class AuthenticationInterceptor extends Vue {
  get isSignedIn(): boolean {
    return this.$store.state.user.isSignedIn;
  }

  signIn(): void {
    msalService.logoutRedirect();
  }
}
