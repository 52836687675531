import axios from "axios";
import UserInfo from "@/contracts/generic/UserInfo";
import { Subject, Subscription } from "rxjs";
import { bufferTime, map, take } from "rxjs/operators";
import OrganizationDto from "@/contracts/generic/OrganizationDto";
import { validationRules } from "@/constants/ValidationRules";
import store from "@/store";

class OrganisationService {
  lookup: { [key: string]: OrganizationDto } = {};
  fetcher = new Subject<string>();
  fetchBuffer = new Subject<{ [key: string]: OrganizationDto }>();

  constructor() {
    this.fetcher.pipe(bufferTime(2000)).subscribe((val) => {
      val = val.filter(onlyUnique);
      val = val.filter((v) => !!v && v != "" && v != "$nan");
      val = val.map((v) => v.replaceAll("'", ""));
      if (val.length > 0) {
        return axios
          .post(`${store.state.appConfig.apiBaseUrl}api/v2/organisations/bulk`, val)
          .then((response) => {
            if (response?.data) {
              for (const el of Array.from<OrganizationDto>(response?.data)) {
                this.lookup[el.id] = el;
              }
              this.fetchBuffer.next(this.lookup);
            }
          });
      }
    });
  }

  getOrganisationsByIds(ids: string[]): Promise<OrganizationDto[]> {
    const collector: OrganizationDto[] = [];
    let allFound = true;
    for (const id of ids) {
      if (!this.lookup[id]) {
        this.fetcher.next(id);
        allFound = false;
      } else {
        collector.push(this.lookup[id]);
      }
    }

    if (allFound) {
      return Promise.resolve(collector);
    } else {
      return this.fetchBuffer
        .pipe(
          take(1),
          map((lookup) => {
            return ids.map((id) => lookup[id]);
          })
        )
        .toPromise();
    }
  }
}

export const organisationService = new OrganisationService();

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}
