import { authorizationService } from "@/services/authorization.service";
import { authRoles } from "@/constants/AuthRoles";

class RoleBasedActionsService {
  userIsAuthorizedToCreateDocument(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
      authRoles.INSPECTOR,
    ]);
  }

  userIsAuthorizedToFilterDocumentsByOrganization(): boolean {
    return authorizationService.userIsAuthorized([authRoles.INSPECTOR]);
  }

  userIsAuthorizedToAddOrganizationToDocument(): boolean {
    return authorizationService.userIsAuthorized([authRoles.INSPECTOR]);
  }

  userIsAuthorizedToDeleteDocument(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToCreateDossier(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToDeleteDossier(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToEditDossier(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToFilterDossiersByOrganization(): boolean {
    return authorizationService.userIsAuthorized([authRoles.INSPECTOR]);
  }

  userIsAuthorizedToCreateDossierDocument(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToDeleteDossierDocument(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToCreateLot(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToEditLot(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToDeleteLot(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToCheckInLot(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToCheckOutLot(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToCreateSelfMonitoringReport(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToDeleteSelfMonitoringReport(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
    ]);
  }

  userIsAuthorizedToGetEligibleContacts(): boolean {
    return authorizationService.userIsAuthorized([
      authRoles.ORGANIZATION_ADMIN,
      authRoles.EXECUTOR,
      authRoles.INSPECTOR,
    ]);
  }
}

export const roleBasedActionsService = new RoleBasedActionsService();
