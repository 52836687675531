import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","width":"1500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-md-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("checkin.dialog-title"))+" ")]),_c('h4',{staticClass:"ml-6"},[_vm._v(_vm._s(_vm.$t("checkin.select-dossier")))]),(!_vm.initialLoading)?_c(VCol,[(_vm.amountOfDossiers > 0)?_c('div',[_c('check-in-dossier-table',{attrs:{"loading":_vm.loading,"page-config":_vm.pageConfig,"sort-config":_vm.sortConfig,"checkin-dialog":true},on:{"documentDeleted":function($event){return _vm.fetchDossiers(true)},"pageConfigUpdated":_vm.pageConfigUpdated,"sortConfigUpdated":_vm.sortConfigUpdated,"checkIn":_vm.openCheckInDialog,"checkOut":_vm.openCheckOutDialog}}),(_vm.canEditLot && _vm.amountOfLots > 0)?_c('check-in-dialog',{attrs:{"dossierId":_vm.dossierId,"lot-id":_vm.lots[0].id,"lots":_vm.lots,"show":_vm.showCheckInDialogValue,"submittingCheckIn":_vm.submittingCheckIn,"dossier-check-in":true},on:{"close":_vm.closeCheckInDialog,"submit":_vm.submitCheckIn}}):_vm._e(),(_vm.canEditLot && _vm.amountOfLots > 0)?_c('check-out-dialog',{attrs:{"checkOutSubmitted":_vm.checkOutSubmitted,"dossierId":_vm.dossierId,"lotId":_vm.lots[0].id,"show":_vm.showCheckOutDialogValue,"submittingCheckOut":_vm.submittingCheckOut,"submittingReport":_vm.submittingReport,"dossier-check-in":true,"mostRecentDayPlanningEntry":_vm.mostRecentDayPlanningEntry},on:{"cancelCheckOut":_vm.closeCheckOutDialog,"skipReport":_vm.skipReport,"submitCheckOut":_vm.submitCheckOut,"submitReport":_vm.submitReport}}):_vm._e()],1):_vm._e()]):_c(VCol,[_c('loader',{attrs:{"show":true}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }