import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{attrs:{"value":_vm.expandedPanels,"accordion":"","multiple":""}},_vm._l((_vm.steps),function(step){return _c(VExpansionPanel,{key:step.id,attrs:{"id":step.id}},[_c(VExpansionPanelHeader,{staticClass:"white--text",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{attrs:{"color":"white"}},[_vm._v(" $expand")])]},proxy:true}],null,true)},[_c('div',[_c('span',[_vm._v(_vm._s(step.version)+" - "+_vm._s(_vm._f("formatDate")(step.releaseDate)))])])]),_c(VExpansionPanelContent,{staticClass:"pa-sm-3"},[_c(VRow,[_c(VCol,{staticClass:"text-justify"},[(step.featureUpdates)?_c('div',[_c('h2',[_vm._v("Feature Updates")]),_c('span',{domProps:{"innerHTML":_vm._s(step.featureUpdates)}})]):_vm._e(),(step.resolvedIssues)?_c('div',[_c('h2',[_vm._v("Resolved Issues")]),_c('span',{domProps:{"innerHTML":_vm._s(step.resolvedIssues)}})]):_vm._e()])],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }