import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import AllowedExtensionDto from "@/contracts/extension/AllowedExtensionsDto";

@Module
export default class ExtensionModule extends VuexModule {
  allowedExtensions: AllowedExtensionDto[] = [];
  acceptedExtensions = "";

  @Mutation
  setAllowedExtensions(allowedExtensions: AllowedExtensionDto[]): void {
    this.allowedExtensions = allowedExtensions;
  }

  @Mutation
  setAcceptedExtensions(acceptedExtensions: string): void {
    this.acceptedExtensions = acceptedExtensions;
  }
}
