































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import AvatarStack from "@/components/common/AvatarStack.vue";
import SimpleContactDto from "@/contracts/generic/SimpleContactDto";
import QualifiedEmployeesDialog from "@/components/lots/QualifiedEmployeesDialog.vue";
import PageConfig from "@/contracts/generic/PageConfig";
import { TableColumns } from "@/constants/TableColumns";

@Component({ components: { AvatarStack, QualifiedEmployeesDialog } })
export default class DayPlanningTable extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: null })
  dayPlanningHistoryEntries!: DayPlanningHistoryEntryDto[];

  @Prop({ default: 0 })
  totalDayPlanningHistoryEntries!: number;

  @Prop({ default: null })
  pageConfig!: PageConfig;

  showQualifiedEmployeesDialog = false;
  activeQualifiedEmployees: SimpleContactDto[] | null = null;

  headers: DataTableHeader[] = [
    {
      text: this.$t("dayplanning.start-date") as string,
      value: TableColumns.START_DATE,
      sortable: false,
    },
    {
      text: this.$t("dayplanning.expected-end-date") as string,
      value: TableColumns.EXPECTED_END_DATE,
      sortable: false,
    },
    {
      text: this.$t("dayplanning.end-date") as string,
      value: TableColumns.END_DATE,
      sortable: false,
    },
    {
      text: this.$t("dayplanning.checked-in-by") as string,
      value: TableColumns.CHECKED_IN_BY,
      sortable: false,
    },
    {
      text: this.$t("dayplanning.checked-out-by") as string,
      value: TableColumns.CHECKED_OUT_BY,
      sortable: false,
    },
    {
      text: this.$t("dayplanning.qualified-employees") as string,
      value: TableColumns.QUALIFIED_EXECUTORS,
      sortable: false,
    },
    {
      text: this.$t("dayplanning.documents") as string,
      value: TableColumns.DOCUMENTS,
      sortable: false,
    },
  ];

  hasSelfMonitoringReports(item: DayPlanningHistoryEntryDto): boolean {
    return item.documents?.results.length > 0;
  }

  openQualifiedEmployeesDialog(executors: SimpleContactDto[]): void {
    this.activeQualifiedEmployees = executors;
    this.showQualifiedEmployeesDialog = true;
  }

  closeQualifiedEmployeesDialog(): void {
    this.showQualifiedEmployeesDialog = false;
  }

  pageUpdated(page: number | undefined): void {
    if (page) {
      this.pageConfig.page = page;
      this.emitPageConfigUpdatedEvent();
    }
  }

  itemsPerPageUpdated(pageSize: number | undefined): void {
    if (pageSize) {
      this.pageConfig.pageSize = pageSize;
      this.emitPageConfigUpdatedEvent();
    }
  }

  emitPageConfigUpdatedEvent(): void {
    this.$emit("pageConfigUpdated", this.pageConfig);
  }

  emitRowClickedEvent(item: DayPlanningHistoryEntryDto): void {
    this.$emit("rowClicked", item);
  }
}
