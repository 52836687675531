import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.item['shared'] && _vm.item['organizationId'] === _vm.currentUser.organizationId
    )?_c(VIcon,{attrs:{"title":_vm.$t('my-documents.shared')},on:{"click":function($event){return _vm.openShareDialog(_vm.item)}}},[_vm._v(" mdi-account-supervisor ")]):_vm._e(),(
      !_vm.item['shared'] && _vm.item['organizationId'] === _vm.currentUser.organizationId
    )?_c(VIcon,{attrs:{"title":_vm.$t('my-documents.not-shared')},on:{"click":function($event){return _vm.openShareDialog(_vm.item)}}},[_vm._v(" mdi-share-variant ")]):_vm._e(),(
      _vm.item['shared'] && _vm.item['organizationId'] !== _vm.currentUser.organizationId
    )?_c(VIcon,{attrs:{"title":_vm.$t('my-documents.shared-from')}},[_vm._v(" mdi-call-received ")]):_vm._e(),(_vm.showSharedDialog)?_c('change-share-document-dialog',{attrs:{"show":_vm.showSharedDialog,"documentDto":_vm.selectedShareItem,"submit-label":'share-document.submit'},on:{"close":_vm.closeShareDialog,"changedShare":_vm.changedShare}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }