











import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";

export type ListMetaData = {
  selector: string;
  property: string;
  translated: string;
};

@Component({})
export default class DetailComponentList extends DetailComponentBase<ListMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (this.detailContext && this.entry) {
      console.log(
        "list",
        newValue,
        this.detailContext.resolveDataPath(this.entry.metadata.selector),
        this.entry.metadata.selector
      );
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
    }
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
    }
  }

  get displayvalue(): string {
    if (!this.value || !this.entry || !this.metadata) return "-";
    let val: string[];
    if (!Array.isArray(this.value)) {
      val = [this.value];
    } else {
      val = this.value;
    }
    if (this.metadata.translated) {
      val = val.map((v) => this.$tc(v));
    }
    if (this.metadata.property) {
      return val.map((value1) => value1[this.metadata.property]).join(", ");
    } else {
      return val.join(", ");
    }
  }
}
