















import { Component } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import DossierDetailComponent from "@/app/dynamic-components/details/detail-component-dossier.vue";
import router from "@/router";

@Component({
  components: {
    DossierDetailComponent,
    PageHeader,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
  },
})
export default class DossierDetailView extends ActivityDependendViewBase {
  async onPageReady(): Promise<any> {
    return;
  }
}
