import { render, staticRenderFns } from "./dossier-detail-document-exchanges.vue?vue&type=template&id=5ad15608&scoped=true&"
import script from "./dossier-detail-document-exchanges.vue?vue&type=script&lang=ts&"
export * from "./dossier-detail-document-exchanges.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ad15608",
  null
  
)

export default component.exports