import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardText,{staticClass:"pa-0"},[(_vm.title != null)?_c('h4',{staticClass:"mb-2 mt-6 ms-3"},[_vm._v(_vm._s(_vm.$t(_vm.title)))]):_vm._e(),_c(VList,[_c(VListItemGroup,{staticStyle:{"pointer-events":"none"}},_vm._l((_vm.entries),function(step,index){return _c(VListItem,{key:step.id,on:{"click":function($event){return _vm.onClickEntry(step)}}},[_c(VListItemAvatar,[_c(VIcon,[_vm._v(_vm._s(("mdi-numeric-" + (index + 1) + "-box")))])],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.$t(step.name))}})],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }