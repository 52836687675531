










































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AeTextField from "@/components/common/TextField.vue";
import AeFileInput from "@/components/common/FileInput.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import AeTextarea from "@/components/common/Textarea.vue";
import LotDto from "@/contracts/lot/LotDto";
import StepsPanel from "@/components/common/steps/StepsPanel.vue";
import LotGeneralInfoPanel from "./details/panels/LotGeneralInfoPanel.vue";
import LotPlanningPanel from "./details/panels/LotPlanningPanel.vue";
import LotTechniquesPanel from "./details/panels/LotTechniquesPanel.vue";
import LotProductsPanel from "./details/panels/LotProductsPanel.vue";
import Step from "@/contracts/common/Step";
import DossiersDto from "@/contracts/dossier/DossiersDto";

@Component({
  components: {
    AeFileInput,
    AeAutocomplete,
    AeTextarea,
    AeTextField,
    StepsPanel,
  },
})
export default class AddLotDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: null })
  dossier!: DossiersDto;

  @Prop({ default: false })
  submittingLot!: boolean;

  loading = false;
  editable = true;

  lot = new LotDto();

  steps: Step[] = [
    {
      index: 1,
      id: "lot-general-info",
      title: "lot.general-information",
      component: LotGeneralInfoPanel,
    },
    {
      index: 2,
      id: "lot-planning",
      title: "lot.planning",
      component: LotPlanningPanel,
    },
    {
      index: 3,
      id: "lot-techniques",
      title: "lot.techniques",
      component: LotTechniquesPanel,
    },
    {
      index: 4,
      id: "lot-products",
      title: "lot.products",
      component: LotProductsPanel,
    },
  ];

  get isSubmitDisabled(): boolean {
    return !(
      this.lot &&
      this.lot.name &&
      this.lot.techniques &&
      this.lot.techniques.length > 0 &&
      this.lot.techniques.filter(
        (technique) =>
          !technique.techniqueSteps || technique.techniqueSteps.length == 0
      ).length == 0 &&
      this.lot.products &&
      this.lot.products.length > 0
    );
  }

  close(): void {
    this.$emit("close");
  }

  submit(): void {
    this.$emit("lotAdded", this.lot);
  }

  @Watch("show")
  reset(): void {
    this.lot = new LotDto();
    this.editable = this.show;
  }
}
