














import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";

export type StateMetaData = {
  selector: string;
  translated: string;
  color: string;
};

@Component({})
export default class DetailComponentState extends DetailComponentBase<StateMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (this.detailContext && this.entry) {
      this.value = this.detailContext.resolveDataPath(this.metadata.selector);
    }
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(this.metadata.selector);
    }
  }

  get translatedValue() {
    if (!this.metadata.translated) return this.value;
    return this.$t(this.value || "-");
  }

  get chipColor(): string {
    return this.metadata.color ? this.metadata.color : "primary";
  }
}
