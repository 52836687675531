import { render, staticRenderFns } from "./detail-component-status-updater.vue?vue&type=template&id=7ac22556&scoped=true&"
import script from "./detail-component-status-updater.vue?vue&type=script&lang=ts&"
export * from "./detail-component-status-updater.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac22556",
  null
  
)

export default component.exports