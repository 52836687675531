





























import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import {Component, Vue, Watch} from "vue-property-decorator";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import {DynamicForm, DynamicFormEntry} from "@/app/dynamic-components/forms/dynamic-form.model";
import {RenderItem, RenderType} from "@/app/Types";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import {DynamicDetailEntry} from "@/app/dynamic-components/details/dynamic-detail.model";
import axios from "@/plugins/axios";
import {ExternalContext} from "@/app/contexts/externalContext";
import {dossierMapper} from "@/app/mappers/dossier.mapper";
import Loader from "@/components/common/Loader.vue";
import store from "@/store";

//**
// Form is derived from children in entity
// **//
export type DetailFormMetaData = {
  fetchDefinitionUrl: string;
  fetchDataUrl: string;
  fetchDataMapper: string;
  patchDataUrl: string;
  property: string;
  submitText: string;
  readonlyAfterSubmit: boolean;
};

@Component({
  components: {Loader, FormWrapper },
})
export default class DetailComponentForm extends DetailComponentBase<DetailFormMetaData> {

  formDefinition: any = null;
  form: DynamicForm | null = null;
  formData: any | null = null;
  externalContext = new ExternalContext();
  loadingFormData = true;


  entityForm: DynamicForm | null = null;
  originalData: any = {};
  fetchForm = false;
  fetchFormError = "";
  isSaving = false;
  mounted(): void {
    return;
  }

  postEntityChanged(newValue: DynamicDetailEntry) {
    this.resolveMetadataUrls();

    axios.get<any>(this.metadata.fetchDefinitionUrl).then((definition) => {
      const form: DynamicForm = DynamicForm.BUILD_FROM_JSON_DEFINITION(definition["data"]["form"]);
      axios.get<any>(this.metadata.fetchDataUrl).then((data) => {
        this.originalData = data["data"];
        if('dossier' === this.metadata.fetchDataMapper){
          form.patchForm(dossierMapper.mapFromApi(this.originalData).data);
        } else{
          form.patchForm(this.originalData.data);
        }

        const prop = this.metadata.property;
        const splitNavigation = prop.split('.');
        const formData = form.formData;
        setTimeout(()=>{
          if(!this.form) return;
          this.form.displayMode = this.rules.canEdit ? 'editable' : 'readonly';
          if(splitNavigation.length === 0 || splitNavigation[0] === ''){
            //is root
            this.form?.patchForm(formData);
          }else{
            //is child
            let strippedFormData = formData;
            for (let i = 0; i < splitNavigation.length; i++) {
              if(strippedFormData[splitNavigation[i]]){
                strippedFormData = strippedFormData[splitNavigation[i]]
              }
            }
            this.form?.patchForm(strippedFormData);
          }
          this.loadingFormData = false;
        },2000);


        this.externalContext = new ExternalContext();
        this.externalContext = this.externalContext.inherit(this.detailContext);
        this.formDefinition = this.entry?.children?.length === 1 ? this.entry.children[0] : null;

        this.entityForm = form;
        this.fetchForm = true;
        this.fetchFormError = "";
      }).catch(reason => {
        this.fetchForm = false;
        this.fetchFormError = "general.dossier_details.fetch_data_error";
      })

    }).catch(reason => {
      this.fetchForm = false;
      this.fetchFormError = "general.dossier_details.forms.definition_error";
    });


  }

  onFormDataChanged(formData: any) {
    this.formData = formData;
  }

  recieveForm(form: DynamicForm) {
    this.form = form;
  }


  save(){
    const injectableData = this.formData;
    const prop = this.metadata.property;

    const splitNavigation = prop.split('.');
    let patchObject = {};
    let patchObjectNavPrev = patchObject;
    let patchObjectNav = patchObject;
    if(splitNavigation.length === 0 || splitNavigation[0] === ''){
      //is root
      patchObject = injectableData;
    }else{
      //is child
      for (let i = 0; i < splitNavigation.length; i++) {
        patchObjectNav[splitNavigation[i]] = {};
        patchObjectNavPrev = patchObjectNav;
        patchObjectNav = patchObjectNav[splitNavigation[i]];
      }
      patchObjectNavPrev[splitNavigation[splitNavigation.length-1]] = injectableData;
    }

    if(this.entityForm){
      this.entityForm.patchForm(patchObject);
      const separatedFields = DynamicFormEntry.SEPERATE_FIELDS(this.entityForm.formData, this.entityForm);
      this.originalData.data = separatedFields.data;
      delete this.originalData.dynamic_Properties;
      this.originalData.dynamic_properties = separatedFields.dynamicProperties;

      this.saveData(this.metadata.patchDataUrl, this.originalData);
    }

    return;
  }

  private async saveData(patchDataUrl: string, originalData: any) {
    if(!this.entityForm || !this.form) return;

    const errors = await this.entityForm.getErrors();
    console.warn("errors", errors, JSON.stringify(errors));
    if (errors.length === 1) {
      this.$toast.error("Validation failed: " + errors[0].description);
      return;
    }
    if (errors.length > 1) {
      this.$toast.error("Multiple validations failed.");
      return;
    }

    this.isSaving = true;

    let beforeSaveSuccess = await this.entityForm.beforeSave();
    if (!beforeSaveSuccess) {
      this.$toast.error("Couldn't save attachments.");

      this.isSaving = false;
      return;
    }
    beforeSaveSuccess = await this.form.beforeSave();
    if (!beforeSaveSuccess) {
      this.$toast.error("Couldn't save attachments.");

      this.isSaving = false;
      return;
    }

    axios.put(patchDataUrl, originalData)
        .then(async (res) => {
          Vue.$toast.success("Successfully saved.");
          if(!this.entityForm || !this.form) return;

          let afterSaveSuccess = await this.entityForm.afterSave({
            type: this.metadata.fetchDataMapper,
            referenceId: res.data,
          });
          if (!afterSaveSuccess) {
            this.$toast.error("Some post actions failed!");
            this.isSaving = false;
            return;
          }
          afterSaveSuccess = await this.form.afterSave({
            type: this.metadata.fetchDataMapper,
            referenceId: res.data,
          });
          if (!afterSaveSuccess) {
            this.$toast.error("Some post actions failed!");
            this.isSaving = false;
            return;
          }
          this.isSaving = false;
          if(this.metadata.readonlyAfterSubmit){
            this.form.displayMode = 'readonly';
            this.rules.canEdit=false;
          }

          //this.$router.go(0)
          const p = this.$route.path;
          const q = this.$route.query;
          await this.$router.push({path: '/placeholder', replace: true});
          setTimeout(async () => {
            await this.$router.push({path: p, query: q, replace: true});
          }, 100);
        })
        .catch((reason) => {
          Vue.$toast.error("Something went wrong.");
          this.isSaving = false;
        });
  }

  private resolveMetadataUrls() {
    if(this.metadata.fetchDefinitionUrl && this.metadata.fetchDefinitionUrl.startsWith('api/')){
      this.metadata.fetchDefinitionUrl = store.state.appConfig.apiBaseUrl + this.metadata.fetchDefinitionUrl
    }
    if(this.metadata.fetchDataUrl && this.metadata.fetchDataUrl.startsWith('api/')){
      this.metadata.fetchDataUrl = store.state.appConfig.apiBaseUrl + this.metadata.fetchDataUrl
    }
    if(this.metadata.fetchDataMapper && this.metadata.fetchDataMapper.startsWith('api/')){
      this.metadata.fetchDataMapper = store.state.appConfig.apiBaseUrl + this.metadata.fetchDataMapper
    }
    if(this.metadata.patchDataUrl && this.metadata.patchDataUrl.startsWith('api/')){
      this.metadata.patchDataUrl = store.state.appConfig.apiBaseUrl + this.metadata.patchDataUrl
    }
  }
}
