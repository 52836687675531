import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"confirmation-dialog__title text-h5 grey lighten-2 d-flex justify-center align-center flex-column text-center"},[_c(VIcon,{staticClass:"my-8 my-8",attrs:{"x-large":""}},[_vm._v("mdi-chart-box")]),_c('span',{staticClass:"text-break"},[_vm._v(_vm._s(_vm.$t("self-monitoring-report.title")))])],1),_c(VCardText,[_c(VRow,{attrs:{"justify":"center"}},[_c(VStepper,{attrs:{"alt-labels":"","flat":"","width":"80%","value":"2"}},[_c(VStepperHeader,[_c(VStepperStep,{attrs:{"complete":"","step":"1"}},[_vm._v(_vm._s(_vm.$t("dayplanning.steps-check-out")))]),_c(VDivider),_c(VStepperStep,{attrs:{"step":"2"}},[_vm._v(_vm._s(_vm.$t("dayplanning.steps-self-monitoring-report")))])],1)],1),_c(VCol,{staticClass:"col-sm-8 col-12"},[_c('upload-document-form',{attrs:{"document":_vm.report,"documentType":_vm.documentType,"prefilledApplicableDate":_vm.reportDate,"dossierActivity":_vm.dossierActivity}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.skip}},[_vm._v(" "+_vm._s(_vm.$t("general.skip"))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.isSubmitDisabled,"loading":_vm.submittingReport,"color":"primary"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t("self-monitoring-report.save"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }