import axios from "@/plugins/axios";
import OrganizationUser from "@/contracts/organization/OrganizationUser";
import OrganizationDto from "@/contracts/generic/OrganizationDto";
import store from "@/store";

class OrganizationService {
  public getUsersFromOrganization(
    organizationId: string
  ): Promise<OrganizationUser[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/organizations/${organizationId}/users`)
      .then((response) => {
        return response?.data;
      });
  }

  public getOrganizations(): Promise<OrganizationDto[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/organizations`)
      .then((response) => {
        return response?.data;
      });
  }
}

export const organizationService = new OrganizationService();
