import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.inspection)?_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"align":"center"}},[_c(VRow,[_c(VCol,[_c(VIcon,{staticClass:"mb-3",attrs:{"x-large":""}},[_vm._v("mdi-magnify")]),_c('h2',[_vm._v(_vm._s(_vm.inspection.lotName))]),_c('h4',[_vm._v(_vm._s(_vm.inspection.dossierName))])],1)],1),_c(VRow,[_c(VCol,{attrs:{"align":"left"}},[_c('i18n',{staticClass:"mb-4",attrs:{"path":"my-inspections.details","tag":"h5"}}),_c('ae-label',{attrs:{"path":"my-inspections.lot-status","height":"high"}},[_c(VChip,{attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.inspection.lotStatus))+" ")])],1),_c('ae-label',{attrs:{"path":"my-inspections.dossier-name"}},[_c('router-link',{attrs:{"to":{
                name: 'DossierDetails',
                params: {
                  id: _vm.inspection.dossierId,
                },
              }}},[_vm._v(_vm._s(_vm.inspection.dossierName))])],1),_c('ae-label',{attrs:{"path":"my-inspections.lot-name"}},[_c('router-link',{attrs:{"to":{
                name: 'LotDetails',
                params: {
                  dossierId: _vm.inspection.dossierId,
                  lotId: _vm.inspection.lotId,
                },
              }}},[_vm._v(" "+_vm._s(_vm.inspection.lotName)+" ")])],1),_c('ae-label',{attrs:{"path":"my-inspections.constructor-name"}},[_vm._v(" "+_vm._s(_vm.inspection.constructor)+" ")]),_c('ae-label',{attrs:{"path":"my-inspections.lot-start-date"}},[(_vm.inspection.startDate)?_c('div',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.inspection.startDate),"DD/MM/YYYY"))+" ")]):_vm._e()]),_c('ae-label',{attrs:{"path":"my-inspections.lot-end-date"}},[(_vm.inspection.endDate)?_c('div',[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.inspection.endDate),"DD/MM/YYYY"))+" ")]):_vm._e()])],1)],1),(!_vm.loading)?_c('div',[_c(VRow,{staticClass:"pt-4"},[_c(VCol,{attrs:{"align":"left"}},[_c('div',{staticClass:"d-flex justify-sm-space-between"},[_c('i18n',{staticClass:"mb-4",attrs:{"path":"my-inspections.daily-reports","tag":"h5"}}),_c(VBtn,{staticClass:"mb-4",attrs:{"text":"","x-small":""},on:{"click":_vm.navigateToSelfMonitoringReports}},[_vm._v(" "+_vm._s(_vm.$t("my-inspections.view-all"))+" ")])],1),(_vm.inspectionDetail && _vm.inspectionDetail.mostRecentReportId)?_c('ae-label',{attrs:{"path":"my-inspections.most-recent"}},[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.downloadDocument(
                    _vm.inspectionDetail.mostRecentReportId,
                    _vm.inspectionDetail.mostRecentReportDate
                  )}}},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(_vm.inspectionDetail.mostRecentReportDate),"DD/MM/YYYY"))+" ")])],1):_c('i18n',{attrs:{"path":"general.no-data"}})],1)],1),_c(VRow,{staticClass:"pt-4"},[_c(VCol,{attrs:{"align":"left"}},[_c('div',{staticClass:"d-flex justify-sm-space-between"},[_c('i18n',{staticClass:"mb-4",attrs:{"path":"my-inspections.qualified-employees","tag":"h5"}}),(_vm.inspectionDetail && _vm.inspectionDetail.qualifiedExecutors)?_c(VBtn,{staticClass:"mb-4",attrs:{"text":"","x-small":""},on:{"click":function($event){return _vm.openQualifiedEmployeesDialog(
                    _vm.inspectionDetail.qualifiedExecutors
                  )}}},[_vm._v(" "+_vm._s(_vm.$t("my-inspections.view-all"))+" ")]):_vm._e()],1),(_vm.inspectionDetail && _vm.inspectionDetail.qualifiedExecutors)?_c('avatar-stack',{attrs:{"names":_vm.inspectionDetail.qualifiedExecutors,"maxStackAmount":4,"nameProperty":"fullName","nameKey":"id","size":"sm"},on:{"click":function($event){return _vm.openQualifiedEmployeesDialog(
                  _vm.inspectionDetail.qualifiedExecutors
                )}}}):_c('i18n',{attrs:{"path":"general.no-data"}})],1)],1),_c(VRow,{staticClass:"pt-4"},[_c(VCol,{attrs:{"align":"left"}},[_c('i18n',{staticClass:"mb-4",attrs:{"path":"my-inspections.planned-techniques","tag":"h5"}}),(_vm.inspectionDetail && _vm.inspectionDetail.plannedTechniques)?_c('technique-and-technique-steps-selector',{attrs:{"available-techniques":_vm.inspectionDetail.plannedTechniques,"object-techniques":_vm.inspectionDetail.plannedTechniques,"show-activity-domain":true}}):_c('i18n',{attrs:{"path":"general.no-data"}})],1)],1),_c(VRow,{staticClass:"pt-4"},[_c(VCol,{attrs:{"align":"left"}},[_c('div',{staticClass:"d-flex justify-sm-space-between"},[_c('i18n',{staticClass:"mb-4",attrs:{"path":"my-inspections.currently-ongoing-techniques","tag":"h5"}}),_c(VBtn,{staticClass:"mb-4",attrs:{"text":"","x-small":""},on:{"click":_vm.navigateToDayPlanning}},[_vm._v(" "+_vm._s(_vm.$t("my-inspections.view-all"))+" ")])],1),(_vm.inspectionDetail && _vm.inspectionDetail.ongoingTechniques)?_c('technique-and-technique-steps-selector',{attrs:{"available-techniques":_vm.inspectionDetail.ongoingTechniques,"object-techniques":_vm.inspectionDetail.ongoingTechniques,"editable":false}}):_c('i18n',{attrs:{"path":"general.no-data"}})],1)],1)],1):_vm._e(),(_vm.loading)?_c('loader',{attrs:{"show":true}}):_vm._e()],1)],1):_vm._e(),(_vm.showQualifiedEmployeesDialog && _vm.activeQualifiedEmployees)?_c('qualified-employees-dialog',{attrs:{"show":_vm.showQualifiedEmployeesDialog,"employees":_vm.activeQualifiedEmployees,"nameProperty":"fullName"},on:{"close":_vm.closeQualifiedEmployeesDialog}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }