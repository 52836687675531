































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import LotGeneralInfoPanel from "./panels/LotGeneralInfoPanel.vue";
import LotActions from "./actions/LotActions.vue";
import LotPlanningPanel from "./panels/LotPlanningPanel.vue";
import LotTechniquesPanel from "./panels/LotTechniquesPanel.vue";
import LotProductsPanel from "./panels/LotProductsPanel.vue";
import LotDto from "@/contracts/lot/LotDto";
import StepsOverview from "@/components/common/steps/StepsOverview.vue";
import StepsPanel from "@/components/common/steps/StepsPanel.vue";
import Step from "@/contracts/common/Step";
import LotCheckInActions from "./actions/LotCheckInActions.vue";
import LotReportsActions from "./actions/LotReportsActions.vue";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import PageHeader from "@/components/layout/PageHeader.vue";
import { authorizationService } from "@/services/authorization.service";
import { authRoles } from "@/constants/AuthRoles";

@Component({
  components: {
    LotActions,
    LotCheckInActions,
    LotGeneralInfoPanel,
    LotPlanningPanel,
    LotProductsPanel,
    LotReportsActions,
    StepsPanel,
    LotTechniquesPanel,
    StepsOverview,
    PageHeader,
  },
})
export default class LotDetails extends Vue {
  @PropSync("lot", { type: Object }) lotSync!: LotDto;

  @Prop({ default: false })
  submitting!: boolean;

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: false })
  canEditLot!: boolean;

  @Prop({ default: false })
  canDeleteLot!: boolean;

  @Prop({ default: false })
  canCheckInLot!: boolean;

  @Prop({ default: false })
  canCheckOutLot!: boolean;

  @Prop({ default: false })
  loadingDayPlanning!: boolean;

  @Prop({ default: null })
  mostRecentDayPlanningEntry!: DayPlanningHistoryEntryDto;

  steps: Step[] = [
    {
      index: 1,
      id: "lot-general-info",
      title: "lot.general-information",
      component: LotGeneralInfoPanel,
    },
    {
      index: 2,
      id: "lot-planning",
      title: "lot.planning",
      component: LotPlanningPanel,
    },
    {
      index: 3,
      id: "lot-techniques",
      title: "lot.techniques",
      component: LotTechniquesPanel,
    },
    {
      index: 4,
      id: "lot-products",
      title: "lot.products",
      component: LotProductsPanel,
    },
  ];

  get hasRoleInspector(): boolean {
    return authorizationService.userIsAuthorized([authRoles.INSPECTOR]);
  }

  get isSubmitDisabled(): boolean {
    return !(
      this.lotSync &&
      this.lotSync.name &&
      this.lotSync.techniques &&
      this.lotSync.techniques.length > 0 &&
      this.lotSync.techniques.filter(
        (technique) =>
          !technique.techniqueSteps || technique.techniqueSteps.length == 0
      ).length == 0 &&
      this.lotSync.products &&
      this.lotSync.products.length > 0
    );
  }

  navigateToDossier(): void {
    this.$emit("navigateToDossier");
  }

  deleteLot(): void {
    this.$emit("deleteLot", true);
  }

  editLot(): void {
    this.$emit("editLot", true);
  }

  cancel(): void {
    this.$emit("cancel");
  }

  submit(): void {
    this.$emit("submit");
  }

  checkIn(): void {
    this.$emit("checkIn");
  }

  get isCheckedIn(): boolean {
    if (this.mostRecentDayPlanningEntry) {
      return this.mostRecentDayPlanningEntry.endDate == null;
    }
    return false;
  }

  checkOut(): void {
    this.$emit("checkOut");
  }
}
