














































import { Vue, Component } from "vue-property-decorator";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { RenderItem } from "@/app/Types";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { dossierServiceV2 } from "@/app/services/dossier.service";

export type TabsWithGeneralAndLotsMetaData = {
  //multiplicity: string; scrapped, makes call to lot api now. can be specifiy because of TabsWithGeneralAndLots component
  multiplicityKey: string;
  urlTabFocus: string;
};

@Component({})
export default class DossierDetailComponentTabsWithGeneralAndLots extends DetailComponentBase<TabsWithGeneralAndLotsMetaData> {
  currentTab = 0;

  public generalEntry: DynamicDetailEntry | null = null;
  public generalType = "nan";

  public lotEntry: DynamicDetailEntry | null = null;
  public lotType = "nan";

  public lots: {
    name: string;
    id: string;
  }[] = [];
  public detailContextClones: DetailContext[] = [];

  async postEntityChanged(newValue: DynamicDetailEntry) {
    if (newValue && this.entry?.metadata?.multiplicity) {
      if (!this.entry?.children) return;
      const lotData = this.entry.children.find((child) => child.id === "lots");
      const generalData = this.entry.children.find(
        (child) => child.id === "general"
      );

      if (generalData) {
        this.generalType = detailComponentLookup.getComponentFromDictionary(
          generalData.type
        );
        this.generalEntry = generalData;
      }
      if (lotData) {
        this.lotEntry = lotData;
        this.lotType = detailComponentLookup.getComponentFromDictionary(
          lotData.type
        );
      }

      const arr =
        (await dossierServiceV2.getLots(this.detailContext.data.dossierId))
          ?._embedded?.results || [];
      if (arr && Array.isArray(arr)) {
        this.lots = [];
        this.detailContextClones = [];
        Array.from(arr)
          .filter((value) => (value.status || "").toUpperCase() !== "SCRAPPED")
          .forEach((value) => {
            this.lots.push(value);
            const clone = this.detailContext.Clone();
            clone.setData(this.entry?.metadata?.multiplicityKey, value);
            this.detailContextClones.push(clone);
          });
        this.trySetTabFromUrl();
      }
    }
  }

  private trySetTabFromUrl() {
    if (
      !this.entry ||
      !this.entry?.metadata?.urlTabFocus ||
      this.entry?.metadata?.urlTabFocus === ""
    )
      return;

    const tabName = this.$route.query[this.entry?.metadata?.urlTabFocus];
    if (!tabName || tabName === "") return;

    if (tabName === "general") {
      this.currentTab = 0;
      return;
    }

    const lotIndex = this.lots.findIndex(
      (value) => value.name === tabName || value.id === tabName
    );
    if (lotIndex < 0) return;

    setTimeout(() => {
      this.currentTab = lotIndex + 1;
    }, 500);
  }
}
