import CreateLotDto from "../lot/CreateLotDto";
import {
  DossierActivityDomains,
  DossierAddress,
  DossierContact,
  DossierDiagnosis,
  DossierPlanning,
} from "./DossierDto";

export default class CreateDossierDto {
  id: string | null;
  name: string | null;
  address: DossierAddress;
  contact: DossierContact;
  planning: DossierPlanning;
  diagnosis: DossierDiagnosis;
  activityDomains: DossierActivityDomains;
  lots: CreateLotDto[];

  constructor() {
    this.id = null;
    this.name = null;
    this.address = new DossierAddress();
    this.contact = new DossierContact();
    this.planning = new DossierPlanning();
    this.diagnosis = new DossierDiagnosis();
    this.activityDomains = new DossierActivityDomains();
    this.lots = [];
  }
}
