














import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";

export type DetailAddressMetaData = {
  placeholder?: string;
};

@Component({})
export default class DetailComponentAddress extends DetailComponentBase<DetailAddressMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (this.detailContext && this.entry && this.entry.metadata.selector) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
    }
  }

  get addressTop() {
    if (!this.value) return "-";
    return (
      (this.value["street"] || "-") +
      " " +
      (this.value["number"] || "-") +
      (this.value["box"] || "")
    );
  }

  get addressBottom() {
    if (!this.value) return "";
    return (
      (this.value["postalCode"] || "") +
      " " +
      (this.value["municipality"] || "") +
      " " +
      (this.value["country"] || "")
    );
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    if (this.detailContext && this.entry && this.entry.metadata.selector) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
    }
  }
}
