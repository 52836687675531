






import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { userServiceV2 } from "@/app/services/user.service";
import Loader from "@/components/common/Loader.vue";

@Component({
  components: { Loader },
})
export default class OverviewCellPerson extends OverviewCellBase {
  protected loading = false;
  protected PostResolveValue() {
    if (this.value) {
      this.loading = true;
      const vals = Array.isArray(this.value) ? this.value : [this.value];

      userServiceV2
        .getUsersByIds(vals)
        .then((value) => {
          if (!value || value.length <= 0) {
            this.value = this.$tc("general.unknown");
            this.loading = false;
            return;
          }

          this.value = value.map((value1) => value1.fullName).join(", ");
          this.loading = false;
        })
        .catch((reason) => {
          this.value = this.$tc("general.fetch_failed");
          this.loading = false;
        });
    }
  }
}
