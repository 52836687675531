


















import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { WidgetMetaData } from "@/app/dynamic-components/details/details-components/widgets/widget-metaData.model";
import ActionTile from "@/components/common/ActionTile.vue";

export interface LinkWidgetMetaData extends WidgetMetaData {
  link: string;

  // title: string; -> name
  subtitle: string;

  icon: string;
  image: string;
  imageWidthMax?: string;
  imageHeightMax?: string
}

@Component({
  components: { ActionTile }
})
export default class WidgetComponentLink extends DetailComponentBase<LinkWidgetMetaData> {
  clickedActionTile(){
    this.$router.push({
      path: this.metadata.link
    });
  }
}
