import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { RenderItem, RenderType } from "@/app/Types";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import { DetailContext } from "@/app/contexts/detail.context";
import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import { ValidationError } from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { ruleEngine } from "@/app/services/rule.engine";
/**
 * This is a super class for all base components that are used in the details builder.
 * It takes props that should be available in all base components.
 * Every base component should inherit from this super class.
 */
@Component({})
export default class DetailComponentBase<T> extends Vue {
  protected metadata: T = {} as T;
  protected rules: {[key: string]: boolean} = {}

  @Prop({
    default: () => {
      return null;
    },
  })
  entry!: DynamicDetailEntry | null;
  @Watch("entry", { immediate: true })
  onEntryChanged(newValue: DynamicDetailEntry, oldValue: DynamicDetailEntry) {
    if (newValue && newValue.metadata) {
      this.metadata = this.detailContext.resolvePlaceholders(newValue.metadata);
      const resolvedRules: { [key: string]: string } = this.detailContext.resolvePlaceholders(newValue.rules || {});
      this.rules = {};
      Object.entries(resolvedRules).map(e => {
        this.rules[e[0]] = ruleEngine.resolveRule(this.detailContext, e[1] as string || '');
      })
    }
    this.postEntityChanged(newValue);
  }

  postEntityChanged(newValue: DynamicDetailEntry) {
    return;
  }

  @Prop({ default: () => new DetailContext() })
  detailContext!: DetailContext;

  @Watch("detailContext", { immediate: true })
  onDetailContext(newValue: DetailContext, oldValue: DetailContext) {
    this.postDetailContextChanged(newValue);
  }

  getDetailComponent(type: string): string {
    return detailComponentLookup.getComponentFromDictionary(type);
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    return;
  }
}
