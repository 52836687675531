






































import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/layout/PageHeader.vue";
import PageConfig from "@/contracts/generic/PageConfig";
import { faqService } from "@/services/faq.service";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import { Question } from "@/contracts/faq/QuestionDto";
import Loader from "@/components/common/Loader.vue";
import FaqTable from "@/components/faq/FaqTable.vue";

@Component({
  components: {
    FaqTable,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    PageHeader,
    Loader,
  },
})
export default class FaqView extends Vue {
  initialLoading = false;
  loading = false;
  alignment = "left";

  pageConfig: PageConfig = {
    page: 0,
    pageSize: 100,
  };

  get amountOfQuestions(): number {
    return this.$store.state.faq.amountOfQuestions;
  }

  get questions(): Question[] {
    return this.$store.state.faq.questions;
  }

  mounted(): void {
    this.loadData();
    this.$store.commit("setMenuOptions", "helpNavigation");
  }

  loadData(): void {
    this.fetchQuestions(true);
  }

  fetchQuestions(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    faqService
      .getQuestions(this.pageConfig)
      .then((data) => {
        if (data) {
          if (initialFetch) {
            this.$store.commit("setAmountOfQuestions", data.rowCount);
          }

          if (data.results) {
            this.$store.commit("setQuestions", data.results);
          }
        }
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }

        this.loading = false;
      });
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchQuestions();
  }

  changeAlignment(newAlignment: string) {
    this.alignment = newAlignment;
  }
}
