import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Dossier } from "@/contracts/dossier/DossiersDto";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";

@Module
export default class DossiersModule extends VuexModule {
  amountOfDossiers = 0;
  dossiers: Dossier[] = [];
  amountOfPlanningDocumentsToUpload = 0;
  planningDocumentsToUpload: CreateDocumentDto[] = [];
  amountOfDiagnosisDocumentsToUpload = 0;
  diagnosisDocumentsToUpload: CreateDocumentDto[] = [];

  @Mutation
  setAmountOfDossiers(amountOfDossiers: number): void {
    this.amountOfDossiers = amountOfDossiers;
  }

  @Mutation
  setDossiers(dossiers: Dossier[]): void {
    this.dossiers = dossiers;
  }

  @Mutation
  setAmountOfPlanningDocumentsToUpload(amountOfDocuments: number): void {
    this.amountOfPlanningDocumentsToUpload = amountOfDocuments;
  }

  @Mutation
  setPlanningDocumentsToUpload(documents: CreateDocumentDto[]): void {
    this.planningDocumentsToUpload = documents;
  }

  @Mutation
  setAmountOfDiagnosisDocumentsToUpload(amountOfDocuments: number): void {
    this.amountOfDiagnosisDocumentsToUpload = amountOfDocuments;
  }

  @Mutation
  setDiagnosisDocumentsToUpload(documents: CreateDocumentDto[]): void {
    this.diagnosisDocumentsToUpload = documents;
  }
}
