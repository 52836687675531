import CreateDocumentDto from "./CreateDocumentDto";

export default class SelfMonitoringReportDto extends CreateDocumentDto {
  applicableDate: Date | null;

  constructor() {
    super();
    this.applicableDate = null;
  }
}
