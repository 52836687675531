






























































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import AeTextField from "@/components/common/TextField.vue";
import AeTextarea from "@/components/common/Textarea.vue";
import QualifiedEmployeesList from "@/components/lots/QualifiedEmployeesList.vue";
import TechniqueAndTechniqueStepsSelector from "@/components/lots/TechniqueAndTechniqueStepsSelector.vue";
import DocumentTile from "@/components/documents/DocumentTile.vue";
import InspectionDto from "@/contracts/inspection/InspectionDto";
import AeLabel from "@/components/common/Label.vue";
import InspectionDetailDto from "@/contracts/inspection/InspectionDetailDto";
import { inspectionService } from "@/services/inspection.service";
import Loader from "@/components/common/Loader.vue";
import SimpleContactDto from "@/contracts/generic/SimpleContactDto";
import { Routes } from "@/router";
import { documentService } from "@/services/document.service";
import AvatarStack from "@/components/common/AvatarStack.vue";
import QualifiedEmployeesDialog from "@/components/lots/QualifiedEmployeesDialog.vue";

@Component({
  components: {
    AeTextarea,
    AeTextField,
    AeLabel,
    AvatarStack,
    DocumentTile,
    Loader,
    TechniqueAndTechniqueStepsSelector,
    QualifiedEmployeesList,
    QualifiedEmployeesDialog,
  },
})
export default class InspectionsSlideOutContent extends Vue {
  @Prop({ default: null })
  inspection!: InspectionDto;

  loading = false;
  inspectionDetail: InspectionDetailDto | null = null;

  showQualifiedEmployeesDialog = false;
  activeQualifiedEmployees: SimpleContactDto[] | null = null;

  @Watch("inspection")
  fetchInspectionDetail(): void {
    if (this.inspection) {
      this.loading = true;
      inspectionService
        .getInspectionDetail(this.inspection.lotId)
        .then((data) => {
          if (data) {
            this.inspectionDetail = data;

            if (this.inspectionDetail.plannedTechniques) {
              this.inspectionDetail.plannedTechniques.forEach(
                (technique, index) => {
                  technique.id = index.toString();
                  technique.notEditable = true;
                }
              );
            }

            if (this.inspectionDetail.ongoingTechniques) {
              this.inspectionDetail.ongoingTechniques.forEach(
                (technique) => (technique.notEditable = true)
              );
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.inspectionDetail = null;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  openQualifiedEmployeesDialog(executors: SimpleContactDto[]): void {
    this.activeQualifiedEmployees = executors;
    this.showQualifiedEmployeesDialog = true;
  }

  closeQualifiedEmployeesDialog(): void {
    this.showQualifiedEmployeesDialog = false;
  }

  navigateToSelfMonitoringReports(): void {
    this.$router.push({
      name: Routes.SelfMonitoringReports,
      params: {
        dossierId: this.inspection.dossierId,
        lotId: this.inspection.lotId,
      },
    });
  }

  navigateToDayPlanning(): void {
    this.$router.push({
      name: Routes.DayPlanningHistory,
      params: {
        dossierId: this.inspection.dossierId,
        lotId: this.inspection.lotId,
      },
    });
  }

  downloadDocument(id: string, fileName: string): void {
    documentService.getDocumentById(id, fileName);
  }
}
