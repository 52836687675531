






































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import DossierDto from "@/contracts/dossier/DossierDto";
import LotDto from "@/contracts/lot/LotDto";
import TechniqueDto, {
  TechniqueStepDto,
} from "@/contracts/technique/TechniqueDto";
import { techniquesService } from "@/services/techniques.service";
import Loader from "@/components/common/Loader.vue";
import { dossierService } from "@/services/dossier.service";
import TechniqueAndTechniqueStepsSelector from "@/components/lots/TechniqueAndTechniqueStepsSelector.vue";

@Component({
  components: {
    Loader,
    TechniqueAndTechniqueStepsSelector,
  },
})
export default class LotTechniquesPanel extends Vue {
  @PropSync("data", { type: Object }) lotSync!: LotDto;

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: null })
  dossier!: DossierDto;

  @Prop({ default: null })
  dossierId!: string;

  loading = true;
  availableTechniques: TechniqueDto[] = [];

  mounted(): void {
    this.loading = true;
    if (!this.dossier && this.dossierId) {
      dossierService.getDossier(this.dossierId).then((dossier) => {
        this.fetchAvailableTechniques(dossier);
      });
    } else if (this.dossier) {
      this.fetchAvailableTechniques(this.dossier);
    }
  }

  fetchAvailableTechniques(dossier: DossierDto): void {
    this.loading = true;
    techniquesService
      .getTechniques(dossier.activityDomains.activityDomains)
      .then((techniques) => {
        this.availableTechniques = techniques;
        // v-treeview rows can only be disabled by setting the item-disabled prop (no disable available for entire tree-view)
        // the item-disabled prop does not watch dynamic changes -> fixed with v-if/v-else
        this.availableTechniques.forEach(
          (technique) => (technique.notEditable = true)
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  updateTechniquesOnLot(techniqueStepIds: string[]): void {
    const availableTechniquesCopy: TechniqueDto[] = JSON.parse(
      JSON.stringify(this.availableTechniques)
    );
    this.lotSync.techniques = availableTechniquesCopy
      .filter(
        (availableTechnique) => availableTechnique.techniqueSteps !== null
      )
      .map((availableTechnique) => {
        availableTechnique.techniqueSteps = this.getArrayOfSharedTechniqueSteps(
          availableTechnique.techniqueSteps as TechniqueStepDto[],
          techniqueStepIds
        );
        return availableTechnique;
      })
      .filter(
        (availableTechnique) =>
          availableTechnique.techniqueSteps &&
          availableTechnique.techniqueSteps.length > 0
      );
  }

  getArrayOfSharedTechniqueSteps(
    availableTechniqueSteps: TechniqueStepDto[],
    selectedTechniqueStepIds: string[]
  ): TechniqueStepDto[] {
    return availableTechniqueSteps
      .filter((availableStep) => availableStep.id !== null)
      .filter(
        (availableStep) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          selectedTechniqueStepIds.indexOf(availableStep.id!) > -1
      );
  }
}
