






import { Component } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import { authenticationService } from "@/services/authentication.service";
import Loader from "@/components/common/Loader.vue";

@Component({
  components: {Loader},
})
export default class PlaceholderView extends ActivityDependendViewBase {

  async onPageReady(): Promise<any> {
    return;
  }

}
