






import { Component } from "vue-property-decorator";
import OverviewCellBase from "@/app/dynamic-components/overviews/cells/overview-cell.base";
import { dateFormat } from "@vuejs-community/vue-filter-date-format";

@Component({
  methods: { dateFormat },
  components: {},
})
export default class OverviewCellAddress extends OverviewCellBase {
  public addressTop() {
    if (!this.value) return "-";
    return (
      (this.value["street"] || "-") +
      " " +
      (this.value["number"] || "-") +
      (this.value["box"] || "")
    );
  }

  public addressBottom() {
    if (!this.value) return "";
    return (
      (this.value["postalCode"] || "") +
      " " +
      (this.value["municipality"] || "") +
      " " +
      (this.value["country"] || "")
    );
  }
}
