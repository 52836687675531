import axios from "@/plugins/axios";
import { AxiosPromise } from "axios";
import DossierDto from "@/contracts/dossier/DossierDto";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import DossierFilterConfig from "@/contracts/dossier/DossierFilterConfig";
import DossiersDto, {
  Dossier,
  DossierStatusDto,
} from "@/contracts/dossier/DossiersDto";
import { dossierMapper } from "@/mappers/dossier.mapper";
import DocumentsDto from "@/contracts/document/DocumentsDto";
import LotDto from "@/contracts/lot/LotDto";
import { lotMapper } from "@/mappers/lot.mapper";
import CreateDossierDto from "@/contracts/dossier/CreateDossierDto";
import CreateLotDto from "@/contracts/lot/CreateLotDto";
import CreateDayPlanningDto from "@/contracts/dayplanning/CreateDayPlanningDto";
import ExecutorDto from "@/contracts/organization/ExecutorDto";
import DocumentFilterConfig from "@/contracts/document/DocumentFilterConfig";
import UpdateDayPlanningDto from "@/contracts/dayplanning/UpdateDayPlanningDto";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import PagedResult from "@/contracts/common/PagedResult";
import { dossierStatusMapper } from "@/mappers/dossier-status.mapper";
import { lotStatusMapper } from "@/mappers/lot-status.mapper";
import LotStatusDto from "@/contracts/lot/LotStatusDto";
import LotStatus from "@/contracts/lot/LotStatus";
import DossierFilterDto from "@/contracts/dossier/DossierFilterDto";
import store from "@/store";

class DossierService {
  public getDossierFilters(): Promise<DossierFilterDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/dossiers/filters`)
      .then((response) => {
        response?.data.statuses.map(
          (status: DossierStatusDto) =>
            (status.label = dossierStatusMapper.mapLabelToTranslatableLabel(
              status.label
            ))
        );

        return response?.data;
      });
  }

  public getDossiers(
    pageConfig: PageConfig,
    sortConfig: SortConfig,
    filterConfig: DossierFilterConfig
  ): Promise<DossiersDto> {
    let url = `${store.state.appConfig.apiBaseUrl}api/dossiers?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}&sortDescending=${sortConfig.sortDesc}`;

    if (filterConfig) {
      if (filterConfig.statuses && filterConfig.statuses.length) {
        url += `&statuses=${filterConfig.statuses}`;
      }
      if (filterConfig.organizations && filterConfig.organizations.length) {
        url += `&organizations=${filterConfig.organizations}`;
      }
    }

    return axios.get(url).then((response) => {
      response?.data.results.map((dossier: Dossier) => {
        dossier.status = dossierStatusMapper.mapLabelToTranslatableLabel(
          dossier.status
        );
        return dossier;
      });

      return response?.data;
    });
  }

  public getDossier(id: string): Promise<DossierDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/dossiers/${id}`)
      .then((response) => {
        if (response && response.data) {
          return dossierMapper.map(response.data);
        } else {
          return new DossierDto();
        }
      });
  }

  public createDossier(dossier: DossierDto): Promise<string> {
    const dossierToCreate: CreateDossierDto =
      dossierMapper.mapToCreateDto(dossier);
    return axios
      .post(`${store.state.appConfig.apiBaseUrl}api/dossiers`, dossierToCreate)
      .then((response) => {
        return response.data;
      });
  }

  public updateDossier(dossier: DossierDto): AxiosPromise<void> {
    const dossierToUpdate: CreateDossierDto =
      dossierMapper.mapToCreateDto(dossier);
    return axios.put(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossier.id}`,
      dossierToUpdate
    );
  }

  public deleteDossier(id: string): AxiosPromise<void> {
    return axios.delete(`${store.state.appConfig.apiBaseUrl}api/dossiers/${id}`);
  }

  public getStatuses(): Promise<DossierStatusDto[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/dossiers/statuses`)
      .then((response) => {
        return response?.data.map((status: DossierStatusDto) => {
          if (status) {
            status =
              dossierStatusMapper.mapDossierStatusToDossierStatusWithTranslatableLabel(
                status
              );
          }

          return status;
        });
      });
  }

  public getLotStatuses(): Promise<LotStatus[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/dossiers/lots/statuses`)
      .then((response) => {
        return response?.data.map((status: LotStatusDto) => {
          if (status) {
            status = lotStatusMapper.mapLotStatusDtoToLotStatus(status);
          }
          return status;
        });
      });
  }

  public getDocuments(
    dossierId: string,
    documentType: string
  ): Promise<DocumentsDto> {
    const url = `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/documents?type=${documentType}`;

    return axios.get(url).then((response) => {
      return response?.data;
    });
  }

  public getRelatedDocuments(
    dossierId: string,
    pageConfig: PageConfig,
    sortConfig: SortConfig,
    filterConfig: DocumentFilterConfig
  ): Promise<DocumentsDto> {
    let url = `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/documents?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}&sortDescending=${sortConfig.sortDesc}`;

    if (filterConfig) {
      if (filterConfig.categories && filterConfig.categories.length) {
        url += `&categories=${filterConfig.categories}`;
      }
      if (filterConfig.types && filterConfig.types.length) {
        url += `&types=${filterConfig.types}`;
      }
      if (filterConfig.activities && filterConfig.activities.length) {
        url += `&activities=${filterConfig.activities}`;
      }
      if (filterConfig.uploadedBy && filterConfig.uploadedBy.length) {
        url += `&uploadedBy=${filterConfig.uploadedBy}`;
      }
      if (filterConfig.organizations && filterConfig.organizations.length) {
        url += `&organizations=${filterConfig.organizations}`;
      }
    }

    return axios.get(url).then((response) => {
      return response?.data;
    });
  }

  public addDocument(
    dossierId: string,
    formData: FormData
  ): AxiosPromise<void> {
    return axios.post(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/documents`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  public deleteDocument(dossierId: string, documentId: string): AxiosPromise {
    return axios.delete(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/documents/${documentId}`
    );
  }

  public getLot(dossierId: string, lotId: string): Promise<LotDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lotId}`)
      .then((response) => {
        return response.data;
      });
  }

  public createLot(dossierId: string, lot: LotDto): Promise<string> {
    const lotToCreate: CreateLotDto = lotMapper.mapToCreateDto(lot);
    return axios
      .post(`${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots`, lotToCreate)
      .then((response) => {
        return response.data;
      });
  }

  public updateLot(dossierId: string, lot: LotDto): Promise<void> {
    const lotToUpdate: CreateLotDto = lotMapper.mapToCreateDto(lot);
    return axios.put(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lot.id}`,
      lotToUpdate
    );
  }

  public deleteLot(dossierId: string, lotId: string) {
    return axios.delete(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lotId}`
    );
  }

  public getSelfMonitoringReports(
    dossierId: string,
    lotId: string,
    pageConfig: PageConfig,
    sortConfig: SortConfig,
    filterConfig: DocumentFilterConfig
  ): Promise<DocumentsDto> {
    let url = `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lotId}/reports?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}&sortDescending=${sortConfig.sortDesc}`;

    if (filterConfig) {
      if (filterConfig.categories && filterConfig.categories.length) {
        url += `&categories=${filterConfig.categories}`;
      }
      if (filterConfig.types && filterConfig.types.length) {
        url += `&types=${filterConfig.types}`;
      }
      if (filterConfig.activities && filterConfig.activities.length) {
        url += `&activities=${filterConfig.activities}`;
      }
      if (filterConfig.uploadedBy && filterConfig.uploadedBy.length) {
        url += `&uploadedBy=${filterConfig.uploadedBy}`;
      }
    }

    return axios.get(url).then((response) => {
      return response?.data;
    });
  }

  public addSelfMonitoringReport(
    dossierId: string,
    lotId: string,
    formData: FormData
  ): AxiosPromise<void> {
    return axios.post(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lotId}/report`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  public createDayPlanning(
    dossierId: string,
    dayPlanning: CreateDayPlanningDto
  ): AxiosPromise {
    return axios.post(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/dayplanning/checkin`,
      dayPlanning
    );
  }

  public getQualifiedExecutorsForLot(
    dossierId: string,
    lotId: string
  ): Promise<ExecutorDto[]> {
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lotId}/qualifiedExecutors`
      )
      .then((response) => {
        return response.data;
      });
  }

  public getQualifiedExecutorsForDayPlanning(
    dossierId: string,
    lotId: string
  ): Promise<ExecutorDto[]> {
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lotId}/dayplanning/qualifiedExecutors`
      )
      .then((response) => {
        return response.data;
      });
  }

  public updateDayPlanning(
    dossierId: string,
    dayPlanning: UpdateDayPlanningDto
  ) {
    return axios.put(
      `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/dayplanning/checkout`,
      dayPlanning
    );
  }

  public getDayPlanningHistory(
    dossierId: string,
    lotId: string,
    pageConfig: PageConfig
  ): Promise<PagedResult<DayPlanningHistoryEntryDto>> {
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/${lotId}/dayplanning/history?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}`
      )
      .then((response) => {
        return response.data;
      });
  }

  public getDayPlanningLatestDossier(
    dossierId: string
  ): Promise<DayPlanningHistoryEntryDto> {
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/dayplanning/latest`
      )
      .then((response) => {
        return response.data;
      });
  }

  public getAllActiveDayPlanningCheckIns(dossierId: string): Promise<LotDto[]> {
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/lots/dayplanning/active`
      )
      .then((response) => {
        return response.data;
      });
  }

  public canRequestConformity(dossierId: string): Promise<boolean> {
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/canrequestconformity`
      )
      .then((response) => {
        return response.data;
      });
  }

  public requestConformity(dossierId: string): Promise<string> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/requestconformity`)
      .then((response) => {
        return response.data;
      });
  }

  public canEditDossier(dossierId: string): Promise<boolean> {
    // Checks if you can edit the dossier based on business logic, e.g. current status of the dossier.
    // Dossier editing checks based on roles are performed in the frontend.
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/dossiers/${dossierId}/caneditdossier`)
      .then((response) => {
        return response.data;
      });
  }
}

export const dossierService = new DossierService();
