






























































































import { Component, Prop, Vue } from "vue-property-decorator";
import AeTextField from "@/components/common/TextField.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import AeTimePicker from "@/components/common/TimePicker.vue";
import ExecutorDto from "@/contracts/organization/ExecutorDto";
import { dossierService } from "@/services/dossier.service";
import UpdateDayPlanningDto from "@/contracts/dayplanning/UpdateDayPlanningDto";
import QualifiedEmployeesList from "@/components/lots/QualifiedEmployeesList.vue";
import LotDto from "@/contracts/lot/LotDto";

@Component({
  components: {
    AeAutocomplete,
    AeTextField,
    AeTimePicker,
    QualifiedEmployeesList,
  },
})
export default class CheckOutCardContent extends Vue {
  @Prop({ default: null })
  lotId!: string;

  @Prop({ default: false })
  submittingCheckOut!: boolean;

  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: null })
  startDate!: Date;

  @Prop({ default: false })
  dossierCheckIn!: boolean;

  qualifiedExecutors: ExecutorDto[] = [];
  loadingQualifiedExecutors = false;

  checkOutData = new UpdateDayPlanningDto();
  loadingActiveCheckIns = false;
  availableLots: LotDto[] = [];

  get isSubmitDisabled(): boolean {
    return !(this.checkOutData && this.checkOutData.endDate);
  }

  get startTimeAsString(): string | null {
    if (this.startDate) {
      const startDate = new Date(this.startDate);
      return (
        startDate.getHours().toString().padStart(2, "0") +
        ":" +
        startDate.getMinutes().toString().padStart(2, "0")
      );
    }
    return null;
  }

  public updatedSelectedLots() {
    if (!this.checkOutData?.checkOutLotIds) return;

    if (this.checkOutData.checkOutLotIds.length <= 0) {
      this.qualifiedExecutors = [];
      return;
    }

    this.loadQualifiedExecutionsForDayplanning(
      this.checkOutData.checkOutLotIds
    );
  }

  mounted(): void {
    this.endTimeChanged(new Date());
    this.loadingActiveCheckIns = true;
    dossierService
      .getAllActiveDayPlanningCheckIns(this.dossierId)
      .then((data) => {
        if (data) {
          this.availableLots = data;
        }

        if (!this.dossierCheckIn && this.lotId) {
          let selectedLot = this.availableLots.find(
            (lot) => lot.id === this.lotId
          );
          this.checkOutData.checkOutLotIds =
            selectedLot && selectedLot.id ? [selectedLot.id] : [];
        } else {
          this.checkOutData.checkOutLotIds = [];
        }
      })
      .then(() => {
        const requestedLotId = this.lotId;
        if (requestedLotId) {
          if (this.availableLots && this.availableLots[0].id) {
            this.loadQualifiedExecutionsForDayplanning([requestedLotId]);
          }
        } else {
          this.qualifiedExecutors = [];
        }
      })
      .finally(() => {
        this.loadingActiveCheckIns = false;
      });
  }

  endTimeChanged(date: Date | null): void {
    if (this.checkOutData) {
      this.checkOutData.endDate = date;
    }
  }

  submit(): void {
    if (!this.isSubmitDisabled) {
      this.$emit("submit", this.checkOutData);
    }
  }

  cancel(): void {
    this.$emit("cancel");
    this.checkOutData = new UpdateDayPlanningDto();
  }

  private loadQualifiedExecutionsForDayplanning(ids: string[] | null) {
    if (!ids || ids.length <= 0) {
      this.qualifiedExecutors = [];
      return;
    }

    this.loadingQualifiedExecutors = true;

    this.qualifiedExecutors = [];

    ids.forEach((id) => {
      dossierService
        .getQualifiedExecutorsForDayPlanning(this.dossierId, id)
        .then((data) => {
          if (data) {
            this.qualifiedExecutors.push(
              ...data.filter(
                (executor) => executor.name !== null && executor.id !== null
              )
            );
          }
        })
        .finally(() => (this.loadingQualifiedExecutors = false));
    });
  }
}
