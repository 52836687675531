























































import { Component, Vue } from "vue-property-decorator";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import CheckInDossierTable from "@/components/checkin/CheckInDossierTable.vue";
import CheckOutDialog from "@/components/lots/dayplanning/checkout/CheckOutDialog.vue";
import CheckInDialog from "@/components/lots/dayplanning/CheckInDialog.vue";
import Loader from "@/components/common/Loader.vue";
import BaseSectionDynamicComponent from "../form-component-section.base";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import AccordionView from "@/app/dynamic-components/forms/form-components/sections/AccordionView.DynamicView.vue";
import { RenderType } from "@/app/Types";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import { OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";
import FormComponentErrorMessage from "@/app/dynamic-components/forms/form-validations/form-component-error-message.vue";

export type LotsMetaData = {
  overviewDefinitionId?: string;
};

type Atg = {
  name: string;
  description: string | undefined;
};

@Component({
  computed: {
    RenderType() {
      return RenderType;
    },
    AccordionView() {
      return AccordionView;
    },
  },
  components: {
    FormComponentErrorMessage,
    FormWrapper,
    Loader,
    CheckInDialog,
    CheckOutDialog,
    CheckInDossierTable,
    OverviewComponent,
  },
})
export default class FormComponentLots extends BaseSectionDynamicComponent<LotsMetaData> {
  postSetValueView(value: any) {
    console.warn("changed lots value", value);
  }
  showLotForm = false;

  postMounted() {
    if (this.value == null) {
      this.setValueView([]);
    }
  }

  formData: any = null;
  formDataChanged(formData: any) {
    this.formData = formData;
  }

  form: DynamicForm | null = null;
  recieveForm(form: DynamicForm) {
    this.form = form;
  }

  editExisting: number | undefined = undefined;

  createNewLot() {
    this.showLotForm = true;
    this.form?.clear();
    this.editExisting = undefined;
  }

  onActionPressed(event: OverviewEvent) {
    if (event.metadata.eventId === "delete" && this.value.length > 0) {
      //because these don't have ids, find the first json object that matches
      const itemToDelete = JSON.stringify(event.metadata.item);
      const elementsOfArrayMatching = Array.from(this.value).filter(
        (value) => JSON.stringify(value) === itemToDelete
      );

      if (elementsOfArrayMatching.length > 0) {
        const indexOfElement = Array.from(this.value).indexOf(
          elementsOfArrayMatching[0]
        );
        //needs to be new array to trigger change detection
        this.setValueView(
          Array.from(this.value).filter((value, i) => i !== indexOfElement)
        );
      }
    }
    if (event.metadata.eventId === "edit" && this.value.length > 0) {
      console.warn(event);
      if(!this.value || !Array.isArray(this.value)) return;
      const i = Array.from(this.value).findIndex(value => value.name === event.item.name && value.description === event.item.description);
      if(i < 0) return;

      this.showLotForm = true;
      this.editExisting = i;
      setTimeout(() => {
        this.form?.clear();
        this.form?.patchForm(event.item);
      }, 10);
    }
  }

  closeLotForm() {
    this.showLotForm = false;
  }
  confirmLotForm() {
    if (this.editExisting !== undefined) {
      // hack to trigger change detection. Prop array needs to be changed in meaningfull way
      const temp = this.value || [];
      temp[this.editExisting] = this.formData;
      this.setValueView([]);
      setTimeout(() => {
        temp.forEach((v: any) => this.value.push(v));
      }, 150);
    } else {
      this.value.push(this.formData);
    }
    this.closeLotForm();

    /*if (!this.formData.valid) {
      Vue.$toast.error("ATG nummer is verplicht.");
      return;
    }

    if (this.atgs.some((atg) => atg.name === this.formData.data.atg)) {
      Vue.$toast.error("ATG nummer is reeds ingevoerd.");
      return;
    }*/
  }
}
