























































































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { Routes } from "@/router";
import DossierDto from "@/contracts/dossier/DossierDto";
import LotDto from "@/contracts/lot/LotDto";
import QualifiedEmployeeDto from "@/contracts/dayplanning/QualifiedEmployeeDto";
import QualifiedEmployeesDialog from "@/components/lots/QualifiedEmployeesDialog.vue";
import { TableColumns } from "@/constants/TableColumns";

@Component({ components: { QualifiedEmployeesDialog } })
export default class LotsTable extends Vue {
  @PropSync("dossier", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  showActiveLotsOnly!: boolean;

  activeQualifiedEmployees: string[] | null = null;
  showQualifiedEmployeesDialog = false;

  headers: DataTableHeader[] = [
    {
      text: this.$t("lot.name") as string,
      value: TableColumns.NAME,
      sortable: true,
    },
    {
      text: this.$t("lot.start-date") as string,
      value: TableColumns.START_DATE,
      sortable: true,
    },
    {
      text: this.$t("lot.end-date") as string,
      value: TableColumns.END_DATE,
      sortable: true,
    },
    {
      text: this.$t("lot.qualified-employees") as string,
      value: TableColumns.QUALIFIED_EMPLOYEES,
      sortable: false,
    },
    {
      text: this.$t("lot.status") as string,
      value: TableColumns.STATUS,
      sortable: true,
    },
  ];

  get filteredLots(): LotDto[] {
    if (this.showActiveLotsOnly) {
      return this.dossierSync.lots.filter(
        (lot) => lot.status == "general.active"
      );
    }
    return this.dossierSync.lots;
  }

  showQualifiedEmployees(qualifiedEmployees: string[]): void {
    this.activeQualifiedEmployees = qualifiedEmployees;
    this.showQualifiedEmployeesDialog = true;
  }

  closeQualifiedEmployees(): void {
    this.showQualifiedEmployeesDialog = false;
    this.activeQualifiedEmployees = null;
  }

  hasMultipleQualifiedEmployees(employees: QualifiedEmployeeDto[]): boolean {
    if (employees) {
      return employees.length > 1;
    }
    return false;
  }

  hasOneQualifiedEmployee(employees: QualifiedEmployeeDto[]): boolean {
    if (employees) {
      return employees.length == 1;
    }
    return false;
  }

  showLotDetails(lotId: string): void {
    if (this.dossierSync.id) {
      this.$router.push({
        name: Routes.LotDetails,
        params: {
          dossierId: this.dossierSync.id,
          lotId: lotId,
        },
      });
    }
  }
}
