import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":""}},[(_vm.lotSync.techniques && _vm.lotSync.techniques.length > 0)?_c(VRow,[(!_vm.loading)?_c(VCol,[_c('div',{staticClass:"pt-2 label"},[_c('i18n',{attrs:{"path":"lot.products","tag":"span"}}),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" *")])])],1),_c(VDataTable,{staticClass:"elevation-1 my-5",class:{ 'activities-table__disabled': !_vm.editable },attrs:{"value":_vm.selectedProducts,"headers":_vm.headers,"items":_vm.availableProducts,"loading":_vm.loading,"fixed-header":"","show-select":""},on:{"input":_vm.setSelectedProductsOnLot},scopedSlots:_vm._u([(!_vm.editable)?{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
return [_c(VSimpleCheckbox,_vm._b({attrs:{"disabled":""}},'v-simple-checkbox',props,false))]}}:null,(!_vm.editable)?{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
return [_c(VSimpleCheckbox,{attrs:{"disabled":"","value":isSelected}})]}}:null],null,true)}),_c('ae-text-field',{attrs:{"label":"product.additional-products","hint":"product.additional-products-hint","disabled":!_vm.editable},model:{value:(_vm.lotSync.otherProducts),callback:function ($$v) {_vm.$set(_vm.lotSync, "otherProducts", $$v)},expression:"lotSync.otherProducts"}})],1):_c(VCol,[_c('loader',{attrs:{"show":true}})],1)],1):_c(VRow,[_c(VCol,[_vm._v(" "+_vm._s(_vm.$t("product.techniques-first-description"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }