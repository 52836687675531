import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSimpleCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label)?_c('div',{staticClass:"label pb-4"},[_c('i18n',{attrs:{"path":_vm.label,"tag":"span"}}),(_vm.required)?_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" *")])]):_vm._e()],1):_vm._e(),_c(VDataTable,{staticClass:"elevation-1",class:{ 'activities-table__disabled': !_vm.editable },attrs:{"value":_vm.selectedActivityDomains,"headers":_vm.headers,"items":_vm.availableActivityDomains,"fixed-header":"","hide-default-footer":"","show-select":""},on:{"input":_vm.updateActivityDomains},scopedSlots:_vm._u([(!_vm.editable)?{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
return [_c(VSimpleCheckbox,_vm._b({attrs:{"disabled":""}},'v-simple-checkbox',props,false))]}}:null,(!_vm.editable)?{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
return [_c(VSimpleCheckbox,{attrs:{"disabled":"","value":isSelected}})]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }