import Vue from "vue";
import OverviewExpanderDocumentExchange from "@/app/dynamic-components/overviews/expanders/components/exchange/overview-expander-document-exchange.vue";

export default class OverviewExpanderDependencies {
  public registerDependencies() {
    Vue.component(
      "OverviewExpanderDocumentExchange",
      OverviewExpanderDocumentExchange
    );
  }
}

export const overviewExpanderDependencies = new OverviewExpanderDependencies();
