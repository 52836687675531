import FormComponentLots from "@/app/dynamic-components/forms/form-components/components/form-component-lots.vue";
import DumbRenderSection from "@/app/dynamic-components/forms/form-components/sections/form-section-linear.vue";
import DateTimePickerBase from "@/app/dynamic-components/forms/form-components/components/form-component-datetimepicker.vue";
import DynamicSelectFromEmbedded from "@/app/dynamic-components/forms/form-components/components/form-component-select-from-embedded.vue";
import FormComponentMulticheckinSelector from "@/app/dynamic-components/forms/form-components/components/checkin/form-component-multicheckin-selector.vue";
import FormComponentSelectOrgAndUserInRoles
  from "@/app/dynamic-components/forms/form-components/components/form-component-select-org-user-in-roles.vue";

interface ComponentDictionaryInterface {
  [key: string]: string;
}

export class ComponentTypeDictionaryService {
  static getComponentFromDictionary = (componentString: string): string => {
    const ComponentTypeDictionary: ComponentDictionaryInterface = {
      TextField: "TextFieldBase",
      AddressForm: "AddressFormBase",
      TestSection: "TestSection",
      ActivityDomainForm: "ActivityDomainForm",
      PlanningForm: "PlanningForm",
      Header: "FormComponentHeader",
      SelectOrg: "FormComponentSelectOrgInRole",
      SelectPerson: "FormComponentSelectPersonInRole",
      Lots: "FormComponentLots",
      TextArea: "TextAreaBase",
      Acordeon: "Acordeon",
      LinearSection: "LinearSection",
      Select: "SelectBase",
      Selectv2: "DynamicSelect",
      FileInput: "FileInputBase",
      ContactDetailsForm: "ContactDetailsFormBase",
      CheckBox: "CheckBoxBase",
      RadioButton: "RadioButtonBase",
      Switch: "SwitchBase",
      Slider: "SliderBase",
      DatePicker: "DatePickerBase",
      TimePicker: "TimePickerBase",
      DateTimePicker: "DateTimePickerBase",
      Planner: "PlannerBase",
      SelectOverviewItem: "DynamicSelectFromOverview",
      SelectEmbeddedList: "DynamicSelectFromEmbedded",
      ExtraText: "FormComponentText",
      FormComponentMulticheckinSelector: "FormComponentMulticheckinSelector",
      SelectFromOverview: "FormComponentOverviewSelection",
      Checkin: "FormComponentCheckin",
      SelectOrgPerson: "FormComponentSelectOrgAndUserInRoles"
    };

    const result: string = ComponentTypeDictionary[componentString];

    if (!result) {
      console.error(`Component ${componentString} not found in dictionary`);
      return "";
    }
    return result;
  };
}
