import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","eager":true,"width":_vm.dialogWidth},model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[(_vm.formDialogModel)?_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t(_vm.formDialogModel.title))+" ")]),_c(VCardText,[_c('FormWrapper',{attrs:{"form-definition":_vm.formDialogModel.form,"external-context":_vm.formDialogModel.externalContext},on:{"dynamicForm":function($event){return _vm.recieveForm($event)},"formDataChanged":function($event){return _vm.onFormDataChanged($event)}}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("general.close"))+" ")]),(_vm.formDialogModel.destroyLabel)?_c(VBtn,{staticStyle:{"color":"white !important"},attrs:{"color":"red","loading":_vm.processing},on:{"click":_vm.destroyDialog}},[_vm._v(" "+_vm._s(_vm.$t(_vm.formDialogModel.destroyLabel))+" ")]):_vm._e(),(_vm.formDialogModel.submitLabel)?_c(VBtn,{attrs:{"color":"primary","loading":_vm.processing},on:{"click":_vm.confirmDialog}},[_vm._v(" "+_vm._s(_vm.$t(_vm.formDialogModel.submitLabel))+" ")]):_vm._e()],1)],1):_c(VCard,[_c('loader')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }