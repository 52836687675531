import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("upload-document.title"))+" ")]),_c(VCardText,[_c('upload-document-form',{attrs:{"document":_vm.document,"documentType":_vm.documentType,"dossierActivity":_vm.dossierActivity,"files":_vm.files,"loading":_vm.loading},on:{"filesAllowedCheck":_vm.filesAllowedCheck}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.isSubmitDisabled,"color":"primary"},on:{"click":_vm.submit}},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.submitLabel || "general.submit")))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }