import axios from "@/plugins/axios";
import DocumentsDto, { DocumentDto } from "@/contracts/document/DocumentsDto";
import ReferenceData from "@/contracts/generic/ReferenceData";
import { AxiosPromise } from "axios";
import PageConfig from "@/contracts/generic/PageConfig";
import SortConfig from "@/contracts/generic/SortConfig";
import DocumentFilterConfig from "@/contracts/document/DocumentFilterConfig";
import DocumentFilterDto from "@/contracts/document/DocumentFilterDto";
import ContactDto from "@/contracts/generic/ContactDto";
import store from "@/store";

class DocumentService {
  public getDocumentFilters(): Promise<DocumentFilterDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/documents/filters`)
      .then((response) => {
        return response?.data;
      });
  }

  public getDocuments(
    pageConfig: PageConfig,
    sortConfig: SortConfig,
    filterConfig: DocumentFilterConfig
  ): Promise<DocumentsDto> {
    let url = `${store.state.appConfig.apiBaseUrl}api/documents?page=${pageConfig.page}&pageSize=${pageConfig.pageSize}&sortDescending=${sortConfig.sortDesc}`;

    if (filterConfig) {
      if (filterConfig.categories && filterConfig.categories.length) {
        url += `&categories=${filterConfig.categories}`;
      }
      if (filterConfig.types && filterConfig.types.length) {
        url += `&types=${filterConfig.types}`;
      }
      if (filterConfig.activities && filterConfig.activities.length) {
        url += `&activities=${filterConfig.activities}`;
      }
      if (filterConfig.uploadedBy && filterConfig.uploadedBy.length) {
        url += `&uploadedBy=${filterConfig.uploadedBy}`;
      }
      if (filterConfig.organizations && filterConfig.organizations.length) {
        url += `&organizations=${filterConfig.organizations}`;
      }
    }

    return axios.get(url).then((response) => {
      return response?.data;
    });
  }

  public addDocument(formData: FormData): AxiosPromise<void> {
    return axios.post(`${store.state.appConfig.apiBaseUrl}api/documents`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  public getCategories(documentType?: string): Promise<ReferenceData[]> {
    let url = store.state.appConfig.apiBaseUrl + "api/documents/categories";

    if (documentType) {
      url += `?documentType=${documentType}`;
    }

    return axios.get(url).then((response) => {
      return response?.data;
    });
  }

  public getTypes(
    categoryId: string,
    documentType: string
  ): Promise<ReferenceData[]> {
    let url = `${store.state.appConfig.apiBaseUrl}api/documents/categories/${categoryId}/types`;

    if (documentType) {
      url += `?documentType=${documentType}`;
    }

    return axios.get(url).then((response) => {
      return response?.data;
    });
  }

  public getAllTypes(): Promise<ReferenceData[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/documents/types`)
      .then((response) => {
        return response?.data;
      });
  }

  public getDocumentById(id: string, fileName: string): Promise<void> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/documents/${id}`, {
        responseType: "blob",
      })
      .then((response) => {
        if (response && response.data) {
          const fileURL = window.URL.createObjectURL(response.data);
          const fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      });
  }

  public deleteDocument(id: string): AxiosPromise {
    return axios.delete(`${store.state.appConfig.apiBaseUrl}api/documents/${id}`);
  }

  public modifySharedPersons(
    documentDto: DocumentDto | null,
    sharedWith: string[] | ContactDto[] | null
  ): AxiosPromise<DocumentDto> {
    if (!documentDto) return Promise.reject("no document");
    return axios.put(
      `${store.state.appConfig.apiBaseUrl}api/documents/${documentDto.id}/share`,
      {
        sharedWith: sharedWith,
      }
    );
  }
}

export const documentService = new DocumentService();
