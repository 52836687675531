























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { validationRules } from "@/constants/ValidationRules";
import { ValidationError } from "@/app/dynamic-components/forms/form-validations/form-validator.base";

@Component
export default class AeDatePicker extends Vue {
  @Prop({ default: [] })
  errors!: ValidationError[];
  @Prop({ default: [] })
  errorMessages!: string[];

  @Prop({ default: null })
  value!: string;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: "en-us" })
  locale!: string;

  @Prop({ default: false })
  iconOnRight!: boolean;

  rules: unknown[] = [];

  menu = false;
  dateParsed: string | null = null;
  dateFormatted: string | null = null;

  @Watch("dateParsed")
  formatAndAssignDate(dateParsed: string): void {
    this.dateFormatted = this.formatDate(dateParsed);
  }

  mounted(): void {
    this.initializeFormattedAndParsedDate();

    if (this.required) {
      this.rules.push(validationRules.required);
    }
  }

  pickerValueChanged(newValue: string): void {
    this.menu = false;

    this.dateParsed = newValue;
    this.dateFormatted = this.formatDate(newValue);

    if (this.dateParsed) {
      this.emitDate(this.dateParsed);
    }
  }

  inputValueChanged(newValue: string): void {
    if (this.hasDateCorrectLength(newValue)) {
      this.dateParsed = this.parseDate(newValue);
      this.dateFormatted = newValue;

      if (this.dateParsed) {
        this.emitDate(this.dateParsed);
      }
    } else if (newValue.length === 0) {
      this.dateParsed = null;
      this.dateFormatted = null;

      this.emitDate(this.dateParsed);
    }
  }

  parseDate(formattedDate: string | null): string | null {
    if (!formattedDate) {
      return null;
    }

    if (!this.hasDateCorrectLength(formattedDate)) {
      return this.dateParsed;
    }

    const [day, month, year] = formattedDate.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  formatDate(parsedDate: string | null): string | null {
    if (!parsedDate || parsedDate === "1970-01-01") return null;

    const [year, month, day] = parsedDate.split("-");
    return `${day}/${month}/${year}`;
  }

  private hasDateCorrectLength(date: string): boolean {
    return date.length === 10;
  }

  private emitDate(date: string | null): void {
    this.$emit("input", date && new Date(date).toISOString());
  }

  @Watch("value")
  private initializeFormattedAndParsedDate() {
    if (this.value) {
      //this.value is a string in ISO format
      const initialDate = new Date(this.value);
      this.dateParsed =
        initialDate.getFullYear() +
        "-" +
        (initialDate.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        initialDate.getDate().toString().padStart(2, "0");
      this.dateFormatted =
        initialDate.getDate().toString().padStart(2, "0") +
        "/" +
        (initialDate.getMonth() + 1).toString().padStart(2, "0") +
        "/" +
        initialDate.getFullYear();
    } else {
      this.dateParsed = null;
      this.dateFormatted = null;
    }
  }
}
