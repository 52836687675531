import Vue from "vue";
import axios from "axios";
import store from "@/store";
import { i18n } from "@/i18n";
import { authenticationService } from "@/services/authentication.service";
import { msalService } from "@/services/msal.service";

// apply interceptor on request
axios.interceptors.request.use(
  async (config) => {
    if (msalService.isMsalInstantiated()) {
      await authenticationService.acquireToken(false);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// apply interceptor on response
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 400:
        console.warn("Bad request");
        Vue.$toast.error(i18n.t("error.bad-request"));
        break;
      case 401:
        console.warn("The user is unauthorized to execute the API call");
        location.reload();
        break;
      case 403:
        switch (error.response.data.reason) {
          case "policies_not_accepted":
            console.warn("The user did not accept the required policies");
            store.commit("setPoliciesAccepted", false);
            break;
          default:
            console.warn(
              "The user is forbidden to access the requested resource"
            );
            Vue.$toast.error(i18n.t("error.not-allowed"));
        }
        break;
      case 413:
        console.warn("The max file size was exceeded");
        Vue.$toast.error(i18n.t("error.max-file-size-exceeded"));
        break;
      case 415:
        console.warn("The uploaded file is too big or not supported");
        Vue.$toast.error(i18n.t(error.response.data));
        break;
      case 429:
        console.warn("Too many requests");
        Vue.$toast.error(i18n.t("error.too-many-requests"));
        break;
      case 500:
        console.error(error.response.status);
        switch (error.response.data.reason) {
          case "user_incomplete":
            Vue.$toast.error(i18n.t("error.invalid-user-account"), {
              timeout: false,
            });
            store.commit("setSignedIn", false);
            break;
          default:
            // eslint-disable-next-line no-case-declarations
            const key = "error.internal-server-error";
            // eslint-disable-next-line no-case-declarations
            let message = i18n.t(key);

            if (message === key) {
              message = "Server unavailable";
            }
            Vue.$toast.error(message);
        }
        break;
      default:
        console.error("An unknown error occurred");
    }

    return Promise.reject(error);
  }
);

export default axios;
