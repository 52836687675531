import ProductDto from "@/contracts/products/ProductDto";
import TechniqueDto from "@/contracts/technique/TechniqueDto";
import axios from "@/plugins/axios";
import store from "@/store";

class TechniquesService {
  public getTechniques(activityDomains: string[]): Promise<TechniqueDto[]> {
    const concatActivityDomains = activityDomains.join(",");
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/techniques?activitydomains=${concatActivityDomains}`
      )
      .then((response) => {
        return response.data;
      });
  }

  public getProducts(techniques: TechniqueDto[]): Promise<ProductDto[]> {
    const concatTechniqueIds: string = techniques
      .map((technique) => technique.id)
      .join(",");
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/techniques/products?ids=${concatTechniqueIds}`
      )
      .then((response) => {
        return response.data;
      });
  }
}

export const techniquesService = new TechniquesService();
