














































































































import { Vue, Component, Watch } from "vue-property-decorator";
import BaseDynamicComponent from "@/app/dynamic-components/forms/form-components/form-component.base";
import TextField from "@/components/common/TextField.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import Autocomplete from "@/components/common/Autocomplete.vue";

type AddressFormMetaData = {
  allowCoordinates: boolean;
};

@Component({
  components: { Autocomplete, TextField, AeAutocomplete },
})
export default class AddressFormBase extends BaseDynamicComponent<AddressFormMetaData> {
  isAddressAvailble = true;

  mounted(): void {
    this.value = {
      country: '',
      municipality: '',
      postalCode: '',
      street: '',
      number: '',
      box: ''
    };
  }

  onSelectContentChanged(newValue: unknown, valueKey: string) {
    if (this.value == null) {
      this.setValueView({});
    }
    this.value[valueKey] = newValue;
  }

  onSelectValidityChanged(isValid: boolean) {
    return;
  }

  onAddressAvailableChange() {
    console.log("brrrt! AddressAvailableChange");
    if (this.isAddressAvailble) {
      this.value["latitude"] = null;
      this.value["longitude"] = null;
    } else {
      this.value["street"] = null;
      this.value["number"] = null;
      this.value["box"] = null;
    }
  }
}
