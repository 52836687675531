
































































import { Component, Prop, Vue } from "vue-property-decorator";
import UploadDocumentForm from "@/components/documents/upload/UploadDocumentForm.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import { documentTypes } from "@/constants/DocumentTypes";
import { dossierService } from "@/services/dossier.service";

@Component({
  components: {
    UploadDocumentForm,
  },
})
export default class SelfMonitoringReportCardContent extends Vue {
  @Prop({ default: null })
  lotId!: string;

  @Prop({ default: false })
  submittingReport!: boolean;

  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: null })
  reportDate!: Date;

  report = new SelfMonitoringReportDto();

  documentType = documentTypes.SELFMONITORING;

  dossierActivity: string | null = null;

  get isSubmitDisabled(): boolean {
    return !(
      this.report &&
      this.report.files &&
      this.report.category &&
      this.report.type &&
      this.report.activity &&
      this.report.applicableDate
    );
  }

  mounted(): void {
    dossierService.getDossier(this.dossierId).then((data) => {
      if (data) {
        this.dossierActivity = data.activityDomains.activity;
      }
    });
  }

  submit(): void {
    if (!this.isSubmitDisabled) {
      this.$emit("submit", this.report);
    }
  }

  skip(): void {
    this.$emit("skip");
  }
}
