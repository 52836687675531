export default class Severity {
  name: string | null;
  color: string | null;
  icon: string | null;

  constructor() {
    this.name = null;
    this.color = null;
    this.icon = null;
  }
}
