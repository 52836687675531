import {FormValidator, FormValidatorBase,} from "@/app/dynamic-components/forms/form-validations/form-validator.base";
import {i18n} from "@/i18n";
import {DynamicFormEntry} from "@/app/dynamic-components/forms/dynamic-form.model";
import {RenderType} from "@/app/Types";

export default class RequiredFormValidator extends FormValidatorBase {
  constructor() {
    const req = i18n.tc("required");
    super("required", "%name% " + req);
  }

  isValid(formData: any, entry: DynamicFormEntry): boolean {
    if (formData === undefined || formData === null) return false;
    if(entry.type === RenderType.SelectOrgPerson){
      return formData.org && formData.org.length > 0 && formData.persons && formData.persons.length > 0;
    }

    if (typeof formData === "boolean") {
      return formData;
    }
    if (typeof formData === "string") {
      return "" !== formData.replaceAll(" ", "");
    }
    if (typeof formData === "number") {
      return true;
    }
    if (Array.isArray(formData)) {
      return Array.from(formData).length > 0;
    }
    if (typeof formData === "object" && formData instanceof Date) {
      return true;
    }
    if (typeof formData === "object") {
      return Object.keys(formData).length > 0;
    }
    // should have covered al basises
    return false;
  }

  constructImpl(params: any[]): FormValidator {
    const validator = new RequiredFormValidator();
    validator.params = params;
    return validator;
  }
}
