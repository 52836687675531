import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.detailDefinition &&
      !_vm.isLoading &&
      _vm.getDossierSecurity(_vm.detailDefinitionId) &&
      !_vm.getDossierSecurity(_vm.detailDefinitionId).singleDossier
    )?_c('page-header',{attrs:{"header-icon-name":"mdi-folder","header-title":_vm.title,"header-subtitle":_vm.subtitle,"back-button-title":"my-dossiers.to-my-dossiers"},on:{"backButtonClicked":function($event){return _vm.goBack()}}}):_vm._e(),(
      _vm.detailDefinition &&
      !_vm.isLoading &&
      _vm.getDossierSecurity(_vm.detailDefinitionId) &&
      !_vm.getDossierSecurity(_vm.detailDefinitionId).singleDossier
    )?_c(VDivider,{staticClass:"my-1"}):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"flex-grow-1"},[(_vm.detailDefinition && !_vm.isLoading && _vm.detailDefinition.summary)?_c(_vm.getDetailComponent(_vm.detailDefinition.summary.type),{tag:"component",attrs:{"entry":_vm.detailDefinition.summary,"detailContext":_vm.detailContext}}):_c('div',[_c(VSkeletonLoader,_vm._b({attrs:{"type":"card-heading, table-thead","max-width":600,"min-height":150}},'v-skeleton-loader',_vm.attrs,false))],1)],1),_c('div',{},[(_vm.detailDefinition && !_vm.isLoading)?_c('dossier-detail-component-actions',{attrs:{"actions":_vm.detailDefinition.actions,"detailContext":_vm.detailContext}}):_c('div',[_c(VSkeletonLoader,_vm._b({attrs:{"type":"text, button, button, button","min-height":150,"min-width":200}},'v-skeleton-loader',_vm.attrs,false))],1)],1)]),(
      _vm.detailDefinition &&
      !_vm.isLoading &&
      _vm.detailDefinition.children &&
      _vm.detailDefinition.children.length > 0
    )?_c('div',{staticClass:"mt-4"},_vm._l((this.detailDefinition.children),function(definition,i){return _c(_vm.getDetailComponent(definition.type),{key:i,tag:"component",attrs:{"entry":definition,"detailContext":_vm.detailContext}})}),1):_c('div',[_c(VSkeletonLoader,_vm._b({staticClass:"mb-4",attrs:{"type":"heading"}},'v-skeleton-loader',_vm.attrs,false)),_c(VSkeletonLoader,_vm._b({attrs:{"type":"table-thead, actions, table"}},'v-skeleton-loader',_vm.attrs,false))],1),_c(VDialog,{attrs:{"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"persistent":"","eager":true,"width":"1200"},model:{value:(_vm.modal.showModal),callback:function ($$v) {_vm.$set(_vm.modal, "showModal", $$v)},expression:"modal.showModal"}},[_c(VCard,[(_vm.modal.component !== '')?_c(_vm.modal.component,{tag:"component",attrs:{"entry":_vm.modal.entry,"detailContext":_vm.detailContext}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }