import NotificationDto from "@/contracts/notification/NotificationDto";
import axios from "@/plugins/axios";
import store from "@/store";

class NotificationService {
  public getRelevantNotification(): Promise<NotificationDto> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/notification`)
      .then((response) => {
        return response.data;
      });
  }
}

export const notificationService = new NotificationService();
