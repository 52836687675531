


























import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry, DynamicDetailModel } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { WidgetMetaData } from "@/app/dynamic-components/details/details-components/widgets/widget-metaData.model";
import ActionTile from "@/components/common/ActionTile.vue";
import ActionTileContent from "@/components/common/ActionTileContent.vue";
import { ActivitySecurity, defaultDossierSecurity, DossierSecurity } from "@/app/models/activity/security.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import router, { Routes } from "@/router";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import Loader from "@/components/common/Loader.vue";


@Component({
  components: { Loader }
})
export default class WidgetComponentDossierAtgs extends DetailComponentBase<WidgetMetaData> {

  public dossierData: any = null;
  public atgs: {
    id: string,
    name: string,
    description: string,
    dynamic_Properties: {
      description_long: string
    }
  }[] = [];
  public loading = true;
  public dossierExists = false;

  async postEntityChanged(newValue: DynamicDetailEntry) {
    this.loading = true;
    const success = await this.getAtgs();
    this.loading = false;
    this.dossierExists = success;
  }

  private async getAtgs() : Promise<boolean> {
    const activityDefinition: ActivityDefinitionModel = this.$store.state.activity.currentActivityDefinition;
    if((activityDefinition?.dossiers?.entries?.length || 0) <= 0) return false;
    const dossierDefintionId = activityDefinition?.dossiers?.entries[0].dossierDefinitionId;

    const activitySecurity: ActivitySecurity = this.$store.state.activity.currentActivitySecurity;
    const dossierSecurity =  activitySecurity?.dossiers[dossierDefintionId] || defaultDossierSecurity(dossierDefintionId);
    if(!dossierSecurity.singleDossier) return false;

    let firstDossier: any = null;
    try{
      const pageResponse = await dossierServiceV2.getFirstDossier();
      if((pageResponse?._embedded?.results?.length || 0) <= 0) return false;
      firstDossier = pageResponse._embedded.results[0];
    }catch (reason){
      return false;
    }
    if(!firstDossier?.id) return false;

    let dossierData: any = null;
    try{
      dossierData = await dossierServiceV2.getDossierData(firstDossier.id);
    }catch (reason){
      return false;
    }
    if(!dossierData) return false;

    this.dossierData = dossierData;
    this.atgs = dossierData?.data?.lots || [];
    this.atgs = this.atgs.sort((x, y) => {
      return x.name < y.name ? -1 : x.name > y.name ? 1 : 0;
    })
    return true;
  }

  get imagePath(): string {
    return require(`@/assets/atg_logo.png`);
  }
}
