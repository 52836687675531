




















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DetailAction } from "@/app/dynamic-components/details/dynamic-detail.model";
import { EventDictionary } from "@/app/events/event.dictionary";
import { DetailContext } from "@/app/contexts/detail.context";
import { ruleEngine } from "@/app/services/rule.engine";

@Component({})
export default class DossierDetailComponentActions extends Vue {
  securityMap: { [key: string]: { canShow: boolean; isEnabled: boolean } } = {};
  canSeeAnyActions = false;

  @Prop({
    default: () => {
      return null;
    },
  })
  detailContext!: DetailContext;

  @Prop({ default: [] })
  actions!: DetailAction[];

  @Watch("detailContext", { immediate: true })
  onDetailContext(newValue: DetailContext, oldValue: DetailContext) {
    this.actions.forEach((action) => {
      this.securityMap[action.id] = { canShow: false, isEnabled: false };
      console.warn(action.enabled,ruleEngine.resolveRule(this.detailContext, action.enabled),action.shown,ruleEngine.resolveRule(this.detailContext, action.shown))

      if (
        action.enabled &&
        action.enabled !== "" &&
        (action.enabled === "true" || ruleEngine.resolveRule(this.detailContext, action.enabled))
      ) {
        this.securityMap[action.id].isEnabled = true;
      }
      if (
        action.shown &&
        action.shown !== "" &&
        (action.shown === "true" || ruleEngine.resolveRule(this.detailContext, action.shown))
      ) {
        this.securityMap[action.id].canShow = true;
      }
    });
    this.canSeeAnyActions = Object.values(this.securityMap).some(
      (value) => value.canShow
    );
  }

  fireEvent(eventName: string) {
    EventDictionary[eventName].getCallBack(this.detailContext);
  }
}
