







































































import { Component, Prop, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import LotDetails from "@/components/lots/details/LotDetails.vue";
import { Routes } from "@/router";
import LotDto from "@/contracts/lot/LotDto";
import { dossierService } from "@/services/dossier.service";
import { i18n } from "@/i18n";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import Loader from "@/components/common/Loader.vue";
import CheckInDialog from "@/components/lots/dayplanning/CheckInDialog.vue";
import CreateDayPlanningDto from "@/contracts/dayplanning/CreateDayPlanningDto";
import CheckOutDialog from "@/components/lots/dayplanning/checkout/CheckOutDialog.vue";
import UpdateDayPlanningDto from "@/contracts/dayplanning/UpdateDayPlanningDto";
import { documentMapper } from "@/mappers/document.mapper";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import PageConfig from "@/contracts/generic/PageConfig";
import { roleBasedActionsService } from "@/services/role-based-actions.service";
import DossierDto from "@/contracts/dossier/DossierDto";

@Component({
  components: {
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    CheckInDialog,
    CheckOutDialog,
    ConfirmationDialog,
    Loader,
    LotDetails,
  },
})
export default class LotDetailsView extends Vue {
  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: null })
  lotId!: string;

  dossier = new DossierDto();
  lot = new LotDto();
  lotCopy = new LotDto();
  lots: LotDto[] = [];
  loading = false;
  submitting = false;
  editable = false;

  loadingDayPlanning = false;
  mostRecentDayPlanningEntry: DayPlanningHistoryEntryDto | null = null;

  canEditLot = false;

  submittingCheckIn = false;
  submittingCheckOut = false;
  checkOutSubmitted = false;
  submittingReport = false;

  showDeleteDialogValue = false;
  showCheckInDialogValue = false;
  showCheckOutDialogValue = false;

  get userIsAuthorizedToEditLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToEditLot();
  }

  get userIsAuthorizedToDeleteLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToDeleteLot();
  }

  get userIsAuthorizedToCheckInLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCheckInLot();
  }

  get userIsAuthorizedToCheckOutLot(): boolean {
    return roleBasedActionsService.userIsAuthorizedToCheckOutLot();
  }

  navigateToDossier(): void {
    this.$router.push({
      name: Routes.DossierDetails,
      params: {
        id: this.dossierId,
      },
    });
  }

  mounted(): void {
    this.loadData();
  }

  loadData(): void {
    this.fetchDossier();
    this.fetchLots();
    this.fetchMostRecentDayplanningEntry();
    this.checkIfCanEditLot();
  }

  fetchDossier(): void {
    this.loading = true;

    dossierService
      .getDossier(this.dossierId)
      .then((data) => {
        if (data) {
          this.dossier = JSON.parse(JSON.stringify(data));
          this.fetchLots();
        }
      })
      .finally(() => {
        this.loading = false;
      });
  }

  fetchLots(): void {
    this.loading = true;
    this.dossier.lots.forEach((lot) => {
      if (lot.id != null) {
        dossierService
          .getLot(this.dossierId, lot.id)
          .then((data) => {
            if (data) {
              this.lots.push(data);
              let selectedLot = this.lots.find((lot) => lot.id === this.lotId);
              this.lot = selectedLot ? selectedLot : new LotDto();
            }
          })
          .catch((error) => console.error(error))
          .finally(() => (this.loading = false));
      }
    });
  }

  fetchMostRecentDayplanningEntry(): void {
    this.loadingDayPlanning = true;
    const pageConfig: PageConfig = {
      page: 1,
      pageSize: 1,
    };
    dossierService
      .getDayPlanningHistory(this.dossierId, this.lotId, pageConfig)
      .then((data) => {
        if (data && data.results && data.results.length > 0) {
          this.mostRecentDayPlanningEntry = data.results[0];
        }
      })
      .catch((error) => console.error(error))
      .finally(() => (this.loadingDayPlanning = false));
  }

  checkIfCanEditLot(): void {
    dossierService.canEditDossier(this.dossierId).then((canEditDossier) => {
      this.canEditLot = canEditDossier; // if the dossier cannot be edited, neither can the lots
    });
  }

  makeLotEditable(): void {
    this.lotCopy = JSON.parse(JSON.stringify(this.lot));
    this.editable = true;
  }

  submitLotChanges(): void {
    this.submitting = true;
    dossierService
      .updateLot(this.dossierId, this.lot)
      .then(() => Vue.$toast.success(i18n.t("lot.updated")))
      .catch(() => {
        Vue.$toast.error(i18n.t("lot.update-failed"));
        this.restoreLot();
      })
      .finally(() => {
        this.submitting = false;
        this.editable = false;
      });
  }

  cancelLotChanges(): void {
    this.editable = false;
    this.restoreLot();
  }

  restoreLot(): void {
    this.lot = JSON.parse(JSON.stringify(this.lotCopy));
    this.lotCopy = new LotDto();
  }

  deleteLot(): void {
    if (this.lot && this.lotId && this.dossierId) {
      this.submitting = true;
      dossierService
        .deleteLot(this.dossierId, this.lotId)
        .then(() => {
          Vue.$toast.success(i18n.t("delete-lot.success"));
          this.submitting = false;
          this.navigateToDossier();
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("delete-lot.failed"));
          this.submitting = false;
          this.closeConfirmDeleteDialog();
        });
    }
  }

  showConfirmDeleteDialog(): void {
    this.showDeleteDialogValue = true;
  }

  closeConfirmDeleteDialog(): void {
    this.showDeleteDialogValue = false;
  }

  showCheckInDialog(): void {
    this.showCheckInDialogValue = true;
  }

  submitCheckIn(checkInData: CreateDayPlanningDto): void {
    this.submittingCheckIn = true;
    dossierService
      .createDayPlanning(this.dossierId, checkInData)
      .then(() => {
        Vue.$toast.success(i18n.t("dayplanning.check-in-successful"));
        this.showCheckInDialogValue = false;
        this.loadData();
      })
      .catch(() => Vue.$toast.error(i18n.t("dayplanning.check-in-failed")))
      .finally(() => {
        this.submittingCheckIn = false;
      });
  }

  closeCheckInDialog(): void {
    this.showCheckInDialogValue = false;
  }

  showCheckOutDialog(): void {
    this.showCheckOutDialogValue = true;
  }

  submitCheckOut(checkOutData: UpdateDayPlanningDto): void {
    this.submittingCheckOut = true;
    dossierService
      .updateDayPlanning(this.dossierId, checkOutData)
      .then(() => {
        Vue.$toast.success(i18n.t("dayplanning.check-out-successful"));
        this.showCheckOutDialogValue = true;
        this.checkOutSubmitted = true;
      })
      .catch(() => Vue.$toast.error(i18n.t("dayplanning.check-out-failed")))
      .finally(() => {
        this.submittingCheckOut = false;
      });
  }

  submitReport(report: SelfMonitoringReportDto): void {
    if (report) {
      this.submittingReport = true;
      let formData =
        documentMapper.mapSelfMonitoringReportDtoToFormData(report);
      dossierService
        .addSelfMonitoringReport(this.dossierId, this.lotId, formData)
        .then(() => {
          Vue.$toast.success(
            i18n.t("self-monitoring-reports.submit-successful")
          );
          this.loadData();
          this.closeCheckOutDialog();
        })
        .catch(() =>
          Vue.$toast.error(i18n.t("self-monitoring-reports.submit-failed"))
        )
        .finally(() => {
          this.submittingReport = false;
        });
    }
  }

  skipReport(): void {
    this.loadData();
    this.closeCheckOutDialog();
  }

  closeCheckOutDialog(): void {
    this.checkOutSubmitted = false;
    this.showCheckOutDialogValue = false;
  }
}
