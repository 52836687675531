import ExchangeComponent from "@/app/components/exchange/exchange-component.vue";

export interface InvolvedParty {
  id: string;
  contactId: string;
  roleId: string;
  contactType: "USER" | "ORGANISATION";
}

export interface ExchangeStep {
  id: string;
  stepId: string; // this id is the dataverse id, not the id used by us.
  state: "INPROGRESS" | "COMPLETED" | "SKIPPED" | "TODO";
  data: any;
  lastModifiedOn: string;
}

export interface Exchange {
  id: string;
  exchangeDefinitionId: string;
  currentStep: string;
  state: "INPROGRESS" | "COMPLETED" | "CANCELED";
  createdOn: string;
  createdBy: string;
  lastModifiedOn: string;
  lastModifiedBy: string;
  involvedParties: InvolvedParty[];
  steps: ExchangeStep[];
}

export class ExchangeControl {
  private exchangeComponent: ExchangeComponent;
  constructor(exchangeComponent: ExchangeComponent) {
    this.exchangeComponent = exchangeComponent;
  }

  public setStepPosition(stepId: string) {
    if (this.exchangeComponent) {
      this.exchangeComponent.setState(stepId);
    }
  }
}

export interface ExchangeEvent {
  action: "SUBMIT" | "CLOSE" | "UPDATESTEP" | "VIEWNEXT";
  metadata: { [key: string]: any };
}
