












import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { userServiceV2 } from "@/app/services/user.service";
import { organisationService } from "@/app/services/organisation.service";
import UserInfo from "@/contracts/generic/UserInfo";

export type DetailOrganisationAndPersonsMetaData = {
  showUsers: boolean;
  translationKeyPerson: string;
};

@Component({})
export default class DetailComponentOrganisationAndPersons extends DetailComponentBase<DetailOrganisationAndPersonsMetaData> {
  value: {org: string | string[], persons: string | string[]} | null = null;

  contacts: UserInfo[] = [];
  org = '...';

  postEntityChanged(newValue: DynamicDetailEntry) {
    this.resolveValue();
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    this.resolveValue();
  }

  private resolveValue() {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
      if (this.value && this.value.org && this.value.org.length > 0) {
        organisationService
          .getOrganisationsByIds(Array.isArray(this.value.org) ? this.value.org : [this.value.org])
          .then((orgList) => {
            if (!orgList || orgList.length <= 0) return;
            this.org = orgList[0].name;
          })
          .catch((reason) => {
            this.org = this.value?.org as string || 'Unknown';
            console.warn("Failed to fetch org");
          });
      }
      if (this.value && this.value.persons && this.value.persons.length > 0) {
        userServiceV2
            .getUsersByIds(Array.isArray(this.value.persons) ? this.value.persons : [this.value.persons])
            .then((personList) => {
              if (!personList || personList.length <= 0) return;
              this.contacts = personList;
            })
            .catch((reason) => {
              this.contacts = [];
              console.warn("Failed to fetch org");
            });
      }
    }
  }
}
