
























import { Component, Prop, Vue } from "vue-property-decorator";
import { Routes } from "@/router";

@Component
export default class SelfMonitoringReportsDetailsActions extends Vue {
  @Prop({ default: null }) dossierId!: string;
  @Prop({ default: null }) lotId!: string;

  navigateToDossierDetails(): void {
    this.$router.push({
      name: Routes.LotDetails,
      params: {
        dossierId: this.dossierId,
        lotId: this.lotId,
      },
    });
  }
}
