import axios, { AxiosPromise } from "axios";
import { eligibleOrganistions } from "@/app/mock/eligible-organisations.mock";
import { eligibleUsers } from "@/app/mock/eligible-users.mock";
import UserInfo from "@/contracts/generic/UserInfo";
import store from "@/store";

class RoleService {
  public getEligableOrganisations(roleId: string): Promise<any> {
    if(!roleId) return Promise.resolve([]);
    return axios
      .get(
        `${store.state.appConfig.apiBaseUrl}api/v2/roles/${roleId}/eligible-organisations?page=0&pageSize=100`
      )
      .then((response) => {
        return response?.data["_embedded"]["results"];
      });
    //return Promise.resolve(eligibleOrganistions);
  }

  public getEligibleUsersForRole(roleId: string, searchAllOrganisations: boolean, ownOrganisationId?: string): Promise<UserInfo[]> {
    if(!roleId) return Promise.resolve([]);

    let url = `${store.state.appConfig.apiBaseUrl}api/v2/roles/${roleId}/eligible-users?page=0&pageSize=100`;
    if(!searchAllOrganisations){
      url = url + `&filter=ae_contractorid:${ownOrganisationId}`;
      if(!ownOrganisationId){
        return Promise.resolve([]);
      }
    }

    return axios
      .get(url)
      .then((response) => {
        return response?.data["_embedded"]["results"];
      });
    //return Promise.resolve(eligibleUsers);
  }
}

export const roleService = new RoleService();
