import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { translationService } from "@/services/translation.service";
import { Language } from "@/contracts/common/Language";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: {},
  silentTranslationWarn: true,
});

export function setI18nLanguage(language: string): void {
  i18n.locale = language;
}

export function loadMessagesAsync(
  language: Language
): Promise<void | LocaleMessages> {
  return translationService.getTranslations(language).then((msgs) => {
    i18n.setLocaleMessage(language.toLowerCase(), msgs);
    setI18nLanguage(language.toLowerCase());
  });
}
