import axios from "@/plugins/axios";
import ReferenceData from "@/contracts/generic/ReferenceData";
import ActivityDomainDto from "@/contracts/activity/ActivityDomainDto";
import store from "@/store";

class ActivityService {
  public getActivities(): Promise<ReferenceData[]> {
    return axios.get(`${store.state.appConfig.apiBaseUrl}api/activities`).then((response) => {
      return response?.data;
    });
  }
  public getActivityById(activityId: string): Promise<ReferenceData> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/activities/${activityId}`)
      .then((response) => {
        return response?.data;
      });
  }

  public getActivityDomainsOfActivity(
    activityId: string
  ): Promise<ActivityDomainDto[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/activities/${activityId}/activity-domains`)
      .then((response) => {
        return response?.data;
      });
  }

  public getActivityDomains(): Promise<ActivityDomainDto[]> {
    return axios
      .get(`${store.state.appConfig.apiBaseUrl}api/activities/activity-domains`)
      .then((response) => {
        return response?.data;
      });
  }
}

export const activityService = new ActivityService();
