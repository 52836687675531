



























import Vue from "vue";
import { Component } from "vue-property-decorator";
import AppBar from "@/components/layout/AppBar.vue";
import { i18n, loadMessagesAsync } from "@/i18n";
import Footer from "@/components/layout/Footer.vue";
import CookieConsentMessage from "@/components/common/CookieConsentMessage.vue";
import { authenticationService } from "@/services/authentication.service";
import { userService } from "@/services/user.service";
import store from "@/store";
import { translationService } from "@/services/translation.service";
import ContactDto from "@/contracts/generic/ContactDto";
import AllowedExtensionDto from "@/contracts/extension/AllowedExtensionsDto";
import { extensionService } from "@/services/extension.service";
import Banner from "@/components/banner/NotificationBanner.vue";
import NotificationBanner from "@/components/banner/NotificationBanner.vue";
import axios from "@/plugins/axios";
import { Locales } from "@/contracts/common/Language";
import { activityServicev2 } from "@/app/services/activity.service";

@Component({
  components: {
    NotificationBanner,
    Banner,
    CookieConsentMessage,
    AppBar,
    Footer,
  },
})
export default class App extends Vue {
  appKey = 0;

  get translationsLoaded(): number {
    return Object.keys(i18n.messages).length;
  }
  async mounted(): Promise<void> {
    /*setTimeout(async () => {
      const token = await authenticationService.acquireToken();
    }, 2000);*/
    return;
  }
}
