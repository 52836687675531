
































import QualifiedEmployeeDto from "@/contracts/dayplanning/QualifiedEmployeeDto";
import { Component, Prop, Vue } from "vue-property-decorator";
import QualifiedEmployeesList from "@/components/lots/QualifiedEmployeesList.vue";

@Component({ components: { QualifiedEmployeesList } })
export default class QualifiedEmployeesDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: null })
  employees!: QualifiedEmployeeDto;

  @Prop({ default: "name" })
  nameProperty!: string;

  close(): void {
    this.$emit("close");
  }
}
