









import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";
import { DetailContext } from "@/app/contexts/detail.context";
import { Component } from "vue-property-decorator";
import { userServiceV2 } from "@/app/services/user.service";

export type TextMetaData = unknown;

@Component({})
export default class DetailComponentPerson extends DetailComponentBase<TextMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    this.resolveValue();
  }

  protected postDetailContextChanged(newValue: DetailContext) {
    this.resolveValue();
  }

  private resolveValue() {
    if (this.entry && this.detailContext) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
      if (this.value && this.value != "") {
        userServiceV2
          .getUsersByIds([this.value])
          .then((userList) => {
            if (!userList || userList.length <= 0) return;
            this.value = userList[0].fullName;
          })
          .catch((reason) => {
            console.warn("Failed to fetch user");
          });
      }
    }
  }
}
