




























import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/layout/PageHeader.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import FaqTable from "@/components/faq/FaqTable.vue";
import PageConfig from "@/contracts/generic/PageConfig";
import { Question } from "@/contracts/faq/QuestionDto";
import { faqService } from "@/services/faq.service";
import ReleaseTable from "@/components/faq/ReleaseTable.vue";

@Component({
  components: {
    ReleaseTable,
    FaqTable,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    PageHeader,
  },
})
export default class NewReleasesView extends Vue {
  initialLoading = false;
  loading = false;

  pageConfig: PageConfig = {
    page: 1,
    pageSize: 10,
  };

  get amountOfReleases(): number {
    return this.$store.state.faq.amountOfReleases;
  }

  get releases(): Question[] {
    return this.$store.state.faq.releases;
  }

  mounted(): void {
    this.loadData();
    this.$store.commit("setMenuOptions", "helpNavigation");
  }

  loadData(): void {
    this.fetchReleases(true);
  }

  fetchReleases(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    faqService
      .getReleases(this.pageConfig)
      .then((data) => {
        if (data) {
          if (initialFetch) {
            this.$store.commit("setAmountOfReleases", data.rowCount);
          }

          if (data.results) {
            this.$store.commit("setReleases", data.results);
          }
        }
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }

        this.loading = false;
      });
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchReleases();
  }
}
