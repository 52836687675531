

















import { Component, Prop, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import DossierDto from "@/contracts/dossier/DossierDto";
import DossierDetails from "@/components/dossiers/details/DossierDetails.vue";
import { dossierService } from "@/services/dossier.service";
import { i18n } from "@/i18n";
import { Routes } from "@/router";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { documentMapper } from "@/mappers/document.mapper";
import { AxiosPromise } from "axios";
import LotDto from "@/contracts/lot/LotDto";

@Component({
  components: {
    DossierDetails,
    AcceptPoliciesInterceptor,
    AuthenticationInterceptor,
    Loader,
  },
})
export default class CreateDossierView extends Vue {
  @Prop({ default: null })
  activityId!: string;

  submitting = false;

  dossier = new DossierDto();

  get planningDocumentsToUpload(): CreateDocumentDto[] {
    return this.$store.state.dossiers.planningDocumentsToUpload;
  }

  get diagnosisDocumentsToUpload(): CreateDocumentDto[] {
    return this.$store.state.dossiers.diagnosisDocumentsToUpload;
  }

  goBack(): void {
    this.$router.back();
  }

  lotAdded(lot: LotDto): void {
    this.dossier.lots.push(lot);
  }

  submit(): void {
    this.submitting = true;

    dossierService
      .createDossier(this.dossier)
      .then((dossierId) => {
        if (
          (this.planningDocumentsToUpload &&
            this.planningDocumentsToUpload.length) ||
          (this.diagnosisDocumentsToUpload &&
            this.diagnosisDocumentsToUpload.length)
        ) {
          const promises: AxiosPromise<void>[] = [];

          if (
            this.planningDocumentsToUpload &&
            this.planningDocumentsToUpload.length
          ) {
            this.planningDocumentsToUpload.forEach((doc) => {
              const formData =
                documentMapper.mapCreateDocumentDtoToFormData(doc);
              promises.push(dossierService.addDocument(dossierId, formData));
            });
          }

          if (
            this.diagnosisDocumentsToUpload &&
            this.diagnosisDocumentsToUpload.length
          ) {
            this.diagnosisDocumentsToUpload.forEach((doc) => {
              const formData =
                documentMapper.mapCreateDocumentDtoToFormData(doc);
              promises.push(dossierService.addDocument(dossierId, formData));
            });
          }

          return Promise.all(promises)
            .catch(() => {
              Vue.$toast.error(i18n.t("my-dossiers.document-upload-failed"));
            })
            .finally(() => {
              this.$store.commit("setAmountOfPlanningDocumentsToUpload", 0);
              this.$store.commit("setPlanningDocumentsToUpload", []);
              this.$store.commit("setAmountOfDiagnosisDocumentsToUpload", 0);
              this.$store.commit("setDiagnosisDocumentsToUpload", []);
            });
        }
        return Promise.resolve();
      })
      .then(() => {
        Vue.$toast.success(i18n.t("my-dossiers.dossier-created"));
        this.$router.push({ name: Routes.MyDossiers });
      })
      .catch(() => {
        Vue.$toast.error(i18n.t("my-dossiers.dossier-create-failed"));
      })
      .finally(() => {
        this.submitting = false;
      });
  }
}
