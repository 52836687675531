import CreateTechniqueDto from "../technique/CreateTechniqueDto";

export default class CreateLotDto {
  id: string | null;
  name: string | null;
  startDate: Date | null;
  endDate: Date | null;
  techniques: CreateTechniqueDto[] | null;
  products: string[] | null;
  otherProducts: string | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.startDate = null;
    this.endDate = null;
    this.techniques = null;
    this.products = null;
    this.otherProducts = null;
  }
}
