import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import { detailComponentLookup } from "@/app/dynamic-components/details/details-components/detail-component.lookup";
import { DetailContext } from "@/app/contexts/detail.context";
import { DynamicDetailDefinition } from "@/app/dynamic-components/details/dynamic-detail.model";
import { BehaviorSubject, Subscription } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { dossierServiceV2 } from "@/app/services/dossier.service";

@Component({})
export class DetailComponentBase extends ActivityDependendViewBase{

  protected detailContext = new DetailContext();
  protected isLoading = true;

  @Prop({ default: null })
  protected detailDefinitionId!: string;
  protected detailDefinition: DynamicDetailDefinition | null = null;
  protected fetchDetailDefinitionSubject = new BehaviorSubject<any>(null);
  private fetchDetailDefinitionSubscription: Subscription | undefined;

  protected title = "-";
  protected subtitle: string | undefined = undefined;

  protected windowHeight = window.innerHeight
  protected windowWidth = window.innerWidth

  @Watch("detailDefinitionId", { immediate: true })
  onDetailDefinitionIdChanged(val: string, oldVal: string) {
    this.fetchDetailDefinitionSubject.next(val);
  }

  mounted(): void {
    this.fetchDetailDefinitionSubscription = this.fetchDetailDefinitionSubject
      .pipe(debounceTime(100))
      .subscribe((definitionId) => {
        if (definitionId) {
          this.getDetailDefinition(definitionId)
            .then((definition) => {
              this.detailDefinition = definition;
              this.setDefinitionContextData();
              this.onSetDefinition(definition);
            })
            .catch(reason => {
              return;
            });
        }
      });

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  }

  beforeDestroy(): void {
    if (this.fetchDetailDefinitionSubscription)
      this.fetchDetailDefinitionSubscription.unsubscribe();

    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.windowHeight = window.innerHeight
    this.windowWidth = window.innerWidth
  }

  async onPageReady(): Promise<any> {
    this.detailContext.setRoot(this, this.detailDefinitionId);
    await this.getData();
  }

  public async getData(): Promise<any>{
    this.isLoading = true;
    const data = await this.getDetailData();
    this.setDetailContext(data, this.detailDefinition);
    this.resolveTitlePlaceHolder();
    this.isLoading = false;
    return data;
  }


  protected getDetailComponent(type: string): string {
    return detailComponentLookup.getComponentFromDictionary(type);
  }

  private setDefinitionContextData() {
    if (this.detailDefinition && this.detailDefinition.detailContextData) {
      for (const detailContextDataEntry of Object.entries(
        this.detailDefinition.detailContextData
      )) {
        const k = detailContextDataEntry[0];
        const v = detailContextDataEntry[1];
        this.detailContext.setData(k, v);
      }
    }
  }

  protected resolveTitlePlaceHolder() {
    if (this.detailContext && this.detailDefinition) {
      this.title = this.detailContext.resolveDataPath(this.detailDefinition.title);
      this.subtitle = this.detailContext.resolveDataPath(this.detailDefinition.subtitle) || undefined;
    }
  }

  protected getDetailDefinition(definitionId: string): Promise<DynamicDetailDefinition> {
    return Promise.reject("override not set");
  }

  protected onSetDefinition(definition: DynamicDetailDefinition): void{
    return;
  }

  protected getDetailData(): Promise<any> {
    return Promise.resolve({});
  }

  protected setDetailContext(data: any, detailDefinition: DynamicDetailDefinition | null) {
    return;
  }
}
