import EventBase from "@/app/events/event-base";
import router, { Routes } from "@/router";
import { DetailContext } from "@/app/contexts/detail.context";
import { Vue } from "vue-property-decorator";

export default class EditDossierEvent extends EventBase {
  getCallBack(detailsContext: DetailContext, payload?: any): any {
    router.push({ name: Routes.EditDossierV2 });
  }
}
