





















































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import DossierDto from "@/contracts/dossier/DossierDto";
import UploadDocumentTile from "@/components/documents/upload/UploadDocumentTile.vue";
import DocumentTile from "@/components/documents/DocumentTile.vue";
import { dossierService } from "@/services/dossier.service";
import { documentTypes } from "@/constants/DocumentTypes";
import { DocumentDto } from "@/contracts/document/DocumentsDto";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import AeSwitch from "@/components/common/Switch.vue";
import { i18n } from "@/i18n";
import VueI18n from "vue-i18n";

@Component({
  components: { AeSwitch, DocumentTile, UploadDocumentTile },
})
export default class DossierDiagnosisPanel extends Vue {
  @PropSync("data", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  editable!: boolean;

  initialLoading = false;
  loading = false;

  documentType = documentTypes.DIAGNOSIS;
  amountOfDocuments = 0;
  documents: DocumentDto[] = [];

  get amountOfDocumentsToUpload(): number {
    return this.$store.state.dossiers.amountOfDiagnosisDocumentsToUpload;
  }

  get documentsToUpload(): CreateDocumentDto[] {
    return this.$store.state.dossiers.diagnosisDocumentsToUpload;
  }

  get sensitizingText(): string {
    const sensitizingText: VueI18n.TranslateResult = this.$t(
      "my-dossiers.diagnosis-sensitizing-text"
    ) as string;
    return sensitizingText.replace(/\n/g, "<br>");
  }

  mounted(): void {
    if (this.dossierSync.id) {
      this.fetchDocuments(true);
    } else {
      this.amountOfDocuments = 0;
      this.documents = [];
    }

    this.$store.commit("setAmountOfDiagnosisDocumentsToUpload", 0);
    this.$store.commit("setDiagnosisDocumentsToUpload", []);
  }

  fetchDocuments(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    if (this.dossierSync.id) {
      dossierService
        .getDocuments(this.dossierSync.id, documentTypes.DIAGNOSIS)
        .then((data) => {
          if (data) {
            if (initialFetch) {
              this.amountOfDocuments = data.rowCount;
            }

            if (data.results) {
              this.documents = data.results;
            }
          }
        })
        .finally(() => {
          if (initialFetch) {
            this.initialLoading = false;
          }

          this.loading = false;
        });
    }
  }

  documentAdded(document: CreateDocumentDto | null): void {
    if (document) {
      let amountOfDocumentsToUpload: number = this.amountOfDocumentsToUpload;
      let diagnosisDocumentsToUpload: CreateDocumentDto[] =
        this.documentsToUpload;

      // only needed to have a unique key in the v-for loop, won't be sent to API
      document.id = amountOfDocumentsToUpload.toString();

      amountOfDocumentsToUpload++;
      diagnosisDocumentsToUpload.push(document);

      this.$store.commit(
        "setAmountOfDiagnosisDocumentsToUpload",
        amountOfDocumentsToUpload
      );
      this.$store.commit(
        "setDiagnosisDocumentsToUpload",
        diagnosisDocumentsToUpload
      );
    }
  }

  deleteDocument(documentId: string): void {
    if (this.dossierSync.id) {
      dossierService
        .deleteDocument(this.dossierSync.id, documentId)
        .then(() => {
          this.fetchDocuments(true);
          Vue.$toast.success(i18n.t("my-documents.document-deleted"));
        })
        .catch(() => {
          Vue.$toast.error(i18n.t("my-documents.document-delete-failed"));
        });
    }
  }

  deleteDocumentToUpload(index: number): void {
    let amountOfDocumentsToUpload: number = this.amountOfDocumentsToUpload;
    let diagnosisDocumentsToUpload: CreateDocumentDto[] =
      this.documentsToUpload;

    amountOfDocumentsToUpload--;
    diagnosisDocumentsToUpload.splice(index, 1);

    this.$store.commit(
      "setAmountOfDiagnosisDocumentsToUpload",
      amountOfDocumentsToUpload
    );
    this.$store.commit(
      "setDiagnosisDocumentsToUpload",
      diagnosisDocumentsToUpload
    );
  }
}
