


































import { Component, Prop, Vue } from "vue-property-decorator";
import CreateDocumentDto from "@/contracts/document/CreateDocumentDto";
import { documentTypes } from "@/constants/DocumentTypes";
import UploadDocumentDialog from "@/components/documents/upload/UploadDocumentDialog.vue";

@Component({
  components: {
    UploadDocumentDialog,
  },
})
export default class RelatedDocumentsActions extends Vue {
  @Prop({ default: null })
  dossierActivity!: string;

  @Prop({ default: false })
  canAddDocuments!: boolean;

  showDialog = false;
  file: File | null = null;
  documentType = documentTypes.RELATED;

  showUploadDialog(): void {
    this.file = null;
    this.showDialog = true;
  }

  showUploadDialogWithFile(files: File[]): void {
    this.file = files[0];
    this.showDialog = true;
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  emitDocumentAddedEvent(document: CreateDocumentDto | null): void {
    this.$emit("documentAdded", document);
  }
}
