





































import { Component, Vue } from "vue-property-decorator";
import AeAutocomplete from "@/components/common/Autocomplete.vue";
import { LotStatuses } from "@/constants/LotStatuses";
import { i18n } from "@/i18n";
import { inspectionService } from "@/services/inspection.service";
import InspectionFilterDto from "@/contracts/inspection/InspectionFilterDto";
import LotStatusDto from "@/contracts/lot/LotStatusDto";
import { lotStatusMapper } from "@/mappers/lot-status.mapper";

@Component({
  components: { AeAutocomplete },
})
export default class InspectionsFilters extends Vue {
  loading = false;

  preselectedStatuses: string[] = [LotStatuses.CREATED, LotStatuses.ACTIVE];

  selectedStatuses: number[] = [];
  selectedConstructors: string[] = [];

  filters: InspectionFilterDto | null = null;

  mounted(): void {
    this.fetchFilterData();
  }

  fetchFilterData(): void {
    this.loading = true;

    inspectionService
      .getInspectionFilters()
      .then((data) => {
        if (data) {
          this.filters = data;

          this.selectedStatuses = this.filters.statuses // Returns the complete LotStatus object for the strings in preselectedStatuses
            .filter((status) =>
              this.preselectedStatuses.some(
                (preselectedStatus) => status.label === preselectedStatus
              )
            )
            .filter((status) => status.value !== null)
            .map((status) => status.value as number);

          this.filters.statuses = this.filters.statuses.map(
            (status: LotStatusDto) => {
              status.label = lotStatusMapper.mapLabelToTranslatableLabel(
                status.label
              );
              return status;
            }
          );
        }
      })
      .catch((err) => {
        console.error(err);
        Vue.$toast.error(i18n.t("my-inspections.filter-fetch-failed"));
      })
      .finally(() => {
        this.loading = false;
        this.filterConfigUpdated();
        this.$emit("filtersLoaded");
      });
  }

  statusesUpdated(statuses: number[]): void {
    this.selectedStatuses = statuses;
    this.filterConfigUpdated();
  }

  constructorsUpdated(constructors: string[]): void {
    this.selectedConstructors = constructors;
    this.filterConfigUpdated();
  }

  private filterConfigUpdated(): void {
    const args = {
      statuses: this.selectedStatuses,
      constructors: this.selectedConstructors,
    };

    this.$emit("filterConfigUpdated", args);
  }
}
