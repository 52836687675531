export interface DossierSecurity {
  dossierDefinitionId: string;
  create: boolean;
  edit: boolean;
  read: boolean;
  list: boolean;
  other: { [key: string]: boolean };
  singleDossier: boolean;
}

export function defaultDossierSecurity(
  dossierDefinitionId: string
): DossierSecurity {
  return {
    dossierDefinitionId: dossierDefinitionId,
    create: false,
    edit: false,
    read: false,
    list: false,
    other: {},
    singleDossier: false,
  };
}

export interface ActivitySecurity {
  dossiers: { [key: string]: DossierSecurity };
  tabs: { [key: string]: boolean };
  home: { [key: string]: string };
}
