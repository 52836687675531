





























import { Component, Prop, Vue } from "vue-property-decorator";
import UpdateDayPlanningDto from "@/contracts/dayplanning/UpdateDayPlanningDto";
import CheckOutCardContent from "@/components/lots/dayplanning/checkout/CheckOutCardContent.vue";
import SelfMonitoringReportCardContent from "@/components/lots/dayplanning/checkout/SelfMonitoringReportCardContent.vue";
import SelfMonitoringReportDto from "@/contracts/document/SelfMonitoringReportDto";
import DayPlanningHistoryEntryDto from "@/contracts/dayplanning/DayPlanningHistoryEntryDto";
import AeAutocomplete from "@/components/common/Autocomplete.vue";

@Component({
  components: {
    CheckOutCardContent,
    SelfMonitoringReportCardContent,
    AeAutocomplete,
  },
})
export default class CheckOutDialog extends Vue {
  @Prop({ default: false })
  show!: boolean;

  @Prop({ default: null })
  lotId!: string;

  @Prop({ default: false })
  submittingCheckOut!: boolean;

  @Prop({ default: false })
  submittingReport!: boolean;

  @Prop({ default: null })
  dossierId!: string;

  @Prop({ default: false })
  checkOutSubmitted!: boolean;

  @Prop({ default: false })
  dossierCheckIn!: boolean;

  @Prop({ default: null })
  mostRecentDayPlanningEntry!: DayPlanningHistoryEntryDto;

  submitCheckOut(checkOutData: UpdateDayPlanningDto): void {
    this.$emit("submitCheckOut", checkOutData);
  }

  cancelCheckOut(): void {
    this.$emit("cancelCheckOut");
  }

  submitReport(report: SelfMonitoringReportDto): void {
    this.$emit("submitReport", report);
  }

  skipReport(): void {
    this.$emit("skipReport");
  }
}
