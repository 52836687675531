
















import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class FormComponentErrorMessage extends Vue {
  @Prop({ default: [] })
  protected errorMessages!: string[];
}
