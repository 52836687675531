


































import { Component, Vue } from "vue-property-decorator";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import RecentDocumentsTile from "@/components/documents/RecentDocumentsTile.vue";
import QuickActions from "@/components/dashboard/QuickActions.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";

@Component({
  components: {
    AcceptPoliciesInterceptor,
    QuickActions,
    RecentDocumentsTile,
    AuthenticationInterceptor,
  },
})
export default class DashboardView extends Vue {
  get firstName(): string {
    return this.$store.state.user.firstName;
  }
}
