























































import { Component, Vue } from "vue-property-decorator";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import { inspectionService } from "@/services/inspection.service";
import PageConfig from "@/contracts/generic/PageConfig";
import InspectionFilterConfig from "@/contracts/inspection/InspectionFilterConfig";
import InspectionDto from "@/contracts/inspection/InspectionDto";
import Loader from "@/components/common/Loader.vue";
import AeSlideOut from "@/components/layout/SlideOut.vue";
import { i18n } from "@/i18n";
import InspectionsTable from "@/components/inspection/InspectionsTable.vue";
import InspectionsFilters from "@/components/inspection/InspectionsFilters.vue";
import EmptyInspectionsTile from "@/components/inspection/EmptyInspectionsTile.vue";
import InspectionSortConfig from "@/contracts/inspection/InspectionSortConfig";
import { TableColumns } from "@/constants/TableColumns";
import InspectionsSlideOutContent from "@/components/inspection/InspectionsSlideOutContent.vue";
import PageHeader from "@/components/layout/PageHeader.vue";

@Component({
  components: {
    AcceptPoliciesInterceptor,
    AeSlideOut,
    AuthenticationInterceptor,
    EmptyInspectionsTile,
    InspectionsFilters,
    InspectionsTable,
    InspectionsSlideOutContent,
    Loader,
    PageHeader,
  },
})
export default class MyInspectionsView extends Vue {
  initialLoading = true;
  loading = false;

  showSlideOut = false;

  pageConfig: PageConfig = {
    page: 1,
    pageSize: 10,
  };

  sortConfig: InspectionSortConfig = {
    sortBy: [TableColumns.END_DATE],
    sortDescending: [false],
  };

  filterConfig: InspectionFilterConfig = {
    statuses: [],
    constructors: [],
  };

  amountOfInspections = 0;
  inspections: InspectionDto[] | null = null;
  itemActiveInSlideOut: InspectionDto | null = null;

  fetchInspections(initialFetch = false): void {
    if (initialFetch) {
      this.initialLoading = true;
    }

    this.loading = true;

    inspectionService
      .getInspections(this.pageConfig, this.sortConfig, this.filterConfig)
      .then((data) => {
        if (data) {
          if (initialFetch) {
            this.amountOfInspections = data.rowCount;
          }

          if (data.results) {
            this.inspections = data.results;
          }
        }
      })
      .catch(() => {
        Vue.$toast.error(i18n.t("my-inspections.get-inspections-failed"));
      })
      .finally(() => {
        if (initialFetch) {
          this.initialLoading = false;
        }
        this.loading = false;
      });
  }

  pageConfigUpdated(pageConfig: PageConfig): void {
    this.pageConfig = pageConfig;
    this.fetchInspections();
  }

  sortConfigUpdated(sortConfig: InspectionSortConfig): void {
    this.sortConfig = sortConfig;
    this.fetchInspections();
  }

  filterConfigUpdated(filterConfig: InspectionFilterConfig): void {
    this.filterConfig = filterConfig;
    this.fetchInspections();
  }

  openSlideOut(item: InspectionDto): void {
    if (!this.showSlideOut) {
      this.itemActiveInSlideOut = item;
      this.showSlideOut = true;
    }
  }

  closeSlideOut(): void {
    if (this.showSlideOut) {
      this.itemActiveInSlideOut = null;
      this.showSlideOut = false;
    }
  }
}
