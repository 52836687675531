























































































































































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import ReferenceData from "@/contracts/generic/ReferenceData";
import { countryService } from "@/services/country.service";
import DossierDto from "@/contracts/dossier/DossierDto";
import AeTextField from "@/components/common/TextField.vue";
import AeAutocomplete from "@/components/common/Autocomplete.vue";

@Component({
  components: { AeTextField, AeAutocomplete },
})
export default class DossierGeneralInfoPanel extends Vue {
  @PropSync("data", { type: Object }) dossierSync!: DossierDto;

  @Prop({ default: false })
  editable!: boolean;

  loading = false;

  addressAvailable = true;

  countries: ReferenceData[] = [];

  mounted(): void {
    this.addressAvailable =
      (this.dossierSync && !this.dossierSync.id) ||
      this.dossierSync.address.street !== null;
    this.fetchCountries();
  }

  addressAvailableToggled(previousAddressAvailable: boolean): void {
    if (previousAddressAvailable) {
      this.dossierSync.address.latitude = null;
      this.dossierSync.address.longitude = null;
    } else {
      this.dossierSync.address.street = null;
      this.dossierSync.address.number = null;
      this.dossierSync.address.box = null;
    }
  }

  private fetchCountries() {
    this.loading = true;

    countryService
      .getCountries()
      .then((data) => {
        data.map((i) => (i.name = this.$t(i.translationKey) as string));
        this.countries = data;
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
