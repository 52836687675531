















import { Component } from "vue-property-decorator";
import OverviewExpanderBase from "@/app/dynamic-components/overviews/expanders/overview-expander.base";
import DossierDetailDocumentExchanges from "@/app/components/dossier-detail-document-exchanges.vue";
import { OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";

@Component({
  components: { DossierDetailDocumentExchanges },
})
export default class OverviewExpanderDocumentExchange extends OverviewExpanderBase {
  mounted(): void {
    return;
  }

  public openExchangeDialog() {
    this.eventbus?.next({
      item: this.item,
      metadata: {},
      type: "ACTION",
    });
  }

  protected onEventRecieved(ev: OverviewEvent) {
    if (ev.type === "FETCHED_DATA") {
      this.$forceUpdate();
    }
  }
}
