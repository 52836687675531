



























import { Vue, Component, Prop } from "vue-property-decorator";
import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { exchangeService } from "@/app/services/exchange.service";
import { Exchange } from "@/app/components/exchange/exchange.model";
import FormWrapper from "@/app/dynamic-components/forms/FormWrapper.vue";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";
import {
  ExchangeDefinition,
  ExchangeDefinitionStep,
} from "@/app/dynamic-components/details/dynamic-detail.model";

@Component({
  components: { FormWrapper },
})
export default class DossierDetailDocumentRow extends Vue {
  @Prop({
    default: () => {
      return null;
    },
  })
  exchange!: Exchange;

  @Prop({
    default: () => {
      return {};
    },
  })
  definition!: ExchangeDefinition;

  forms: { [key: string]: DynamicForm } = {};

  async mounted() {
    this.patchExchangeDataToForms();
  }
  recieveForm(form: DynamicForm, step: ExchangeDefinitionStep) {
    this.forms[step.id] = form;
  }

  hasCompletedSteps(exchange: Exchange): boolean{
    return exchange && exchange.steps && exchange.steps.length > 0 &&  exchange.steps.findIndex(value =>  value.state === "COMPLETED" && value.data.file) >= 0;
  }

  patchExchangeDataToForms() {
    //forms need to arrive first, sadly no way to check atm
    setTimeout(() => {
      this.exchange?.steps.forEach((existingStep) => {
        if (
          (existingStep.state === "COMPLETED" ||
            existingStep.state === "INPROGRESS") &&
          this.forms[existingStep.id]
        ) {
          this.forms[existingStep.id].patchForm(existingStep.data);
        }
      });
    }, 500);
  }

  private displayFiles = {
    id: "root",
    name: "",
    type: "LinearSection",
    displayMode: "readonly",
    displayModeRule: "",
    computedValue: "",
    metadata: {},
    children: [
      {
        id: "file",
        name: "",
        type: "FileInput",
        displayMode: "inherit",
        displayModeRule: "",
        computedValue: "",
        metadata: {
          label: "Bestand",
          required: true,
          accept: ".xlsx,.docx",
        },
      },
    ],
  };
}
