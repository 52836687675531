import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[(_vm.showDialog)?_c(VDialog,{attrs:{"width":"600","persistent":"","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.$t("my-dossiers.select-activity-title"))+" ")]),_c(VCardText,[_c(VRow,[_c(VCol,[_c('i18n',{attrs:{"path":"my-dossiers.select-activity-description","tag":"span"}})],1)],1),_c(VRow,[_c(VCol,[_c('ae-autocomplete',{attrs:{"value":_vm.selectedActivity,"clearable":true,"items":_vm.activities,"loading":_vm.loading,"item-text":"name","item-value":"id","required":true,"sort-alphabetically":true,"label":"my-dossiers.activity"},on:{"input":_vm.changeActivity}})],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("general.cancel"))+" ")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.selectedActivity},on:{"click":_vm.navigateToCreateDossiers}},[_vm._v(" "+_vm._s(_vm.$t("general.submit"))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }