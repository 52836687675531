export default class NotificationDto {
  id: string | null;
  title: string | null;
  message: string | null;
  severity: number | null;
  severityName: string | null;
  startDate: Date | null;
  endDate: Date | null;

  constructor() {
    this.id = null;
    this.title = null;
    this.message = null;
    this.severity = null;
    this.severityName = null;
    this.startDate = null;
    this.endDate = null;
  }
}
