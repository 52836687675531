











































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import TechniqueDto, {
  TechniqueStepDto,
} from "@/contracts/technique/TechniqueDto";
import { InspectorTechniqueDto } from "@/contracts/inspection/InspectionDetailDto";

@Component
export default class TechniqueAndTechniqueStepsSelector extends Vue {
  @Prop({ default: null }) // all techniques require property notEditable to be set to "true" to allow disabling the treeview (no better solution for disabling was offered by Vuetify)
  availableTechniques!: TechniqueDto[] | InspectorTechniqueDto[] | null;

  @Prop({ default: null })
  objectTechniques!: TechniqueDto[] | InspectorTechniqueDto[];

  @Prop({ default: false })
  editable!: boolean;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: null })
  label!: string;

  @Prop({ default: false })
  selectRootNodesOnly!: boolean;

  @Prop({ default: false })
  showActivityDomain!: boolean;

  selectedTechniques: string[] = [];
  selectedTechniqueSteps: string[] = [];

  mounted(): void {
    this.fetchSelectedTechniqueStepsFromLot();
  }

  @Watch("objectTechniques")
  fetchSelectedTechniqueStepsFromLot(): void {
    if (this.objectTechniques && this.objectTechniques.length) {
      if ("techniqueSteps" in this.objectTechniques[0]) {
        if (this.selectRootNodesOnly) {
          this.selectedTechniques = (
            this.objectTechniques as TechniqueDto[]
          ).map((technique: TechniqueDto) => technique.id ?? "");
        } else {
          this.selectedTechniqueSteps = (
            this.objectTechniques as TechniqueDto[]
          )
            .filter(
              (technique: TechniqueDto) => technique.techniqueSteps !== null
            )
            .flatMap(
              (technique: TechniqueDto) =>
                technique.techniqueSteps ?? new TechniqueStepDto()
            )
            .filter(
              (techniqueStep: TechniqueStepDto) => techniqueStep.id !== null
            )
            .map((techniqueStep: TechniqueStepDto) => techniqueStep.id ?? "");
        }
      } else {
        this.selectedTechniqueSteps = (
          this.objectTechniques as InspectorTechniqueDto[]
        ).map((technique: InspectorTechniqueDto) => technique.id ?? "");
      }
    } else {
      // on initial load
      this.selectedTechniqueSteps = [];
    }
  }

  techniqueStepsSelectionUpdated(techniqueStepIds: string[]): void {
    this.selectedTechniqueSteps = techniqueStepIds;
    this.$emit("input", this.selectedTechniqueSteps);
  }

  getTechniqueSteps(technique: TechniqueDto): string {
    if (technique && technique.techniqueSteps) {
      return technique.techniqueSteps
        .map((step: TechniqueStepDto) => {
          if (step && step.translationKey) {
            return this.$t(step.translationKey);
          } else {
            return "";
          }
        })
        .join(", ")
        .replace(/\s/g, " ");
    } else {
      return "";
    }
  }

  techniquesSelectionUpdated(techniqueIds: string[]): void {
    this.selectedTechniques = techniqueIds;

    if (this.availableTechniques) {
      const selectedTechniqueSteps = (
        this.availableTechniques as TechniqueDto[]
      )
        .filter((technique: TechniqueDto) => {
          if (techniqueIds && technique && technique.id) {
            return techniqueIds.includes(technique.id);
          } else {
            return false;
          }
        })
        .flatMap((technique: TechniqueDto) => technique.techniqueSteps)
        .map((step: TechniqueStepDto | null) => (step ? step.id : ""));

      this.$emit("input", selectedTechniqueSteps);
    }
  }
}
