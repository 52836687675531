export class BaseTechniqueDto {
  id: string | null;
  translationKey: string | null;
  techniqueSteps: TechniqueStepDto[] | null;
  notEditable: boolean | null; // v-treeview rows can only be disabled by setting the item-disabled prop (no disable available for entire tree-view)

  constructor() {
    this.id = null;
    this.translationKey = null;
    this.techniqueSteps = null;
    this.notEditable = null;
  }
}

export default class TechniqueDto extends BaseTechniqueDto {
  activityTranslationKey: string | null;
  activityDomainTranslationKey: string | null;

  constructor() {
    super();
    this.activityTranslationKey = null;
    this.activityDomainTranslationKey = null;
  }
}

export class TechniqueStepDto {
  id: string | null;
  translationKey: string | null;
  sequence: number | null;
  notEditable: boolean | null;

  constructor() {
    this.id = null;
    this.translationKey = null;
    this.sequence = null;
    this.notEditable = null;
  }
}
