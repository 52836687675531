













import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component } from "vue-property-decorator";
import { DynamicDetailEntry } from "@/app/dynamic-components/details/dynamic-detail.model";

export type DetailContactMetaData = {
  placeholder?: string;
};

@Component({})
export default class DetailComponentContact extends DetailComponentBase<DetailContactMetaData> {
  value: string | null = null;

  postEntityChanged(newValue: DynamicDetailEntry) {
    if (this.detailContext && this.entry && this.entry.metadata.selector) {
      this.value = this.detailContext.resolveDataPath(
        this.entry.metadata.selector
      );
    }
  }
}
