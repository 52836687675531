import { DynamicFormEntry } from "@/app/dynamic-components/forms/dynamic-form.model";
import VueI18n from "vue-i18n";
import { Vue } from "vue-property-decorator";
import { i18n } from "@/i18n";

export class ValidationError {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    this.id = id;
    this.description = description;
  }
}

export interface FormValidator {
  getErrors(
    formData: any,
    entry: DynamicFormEntry
  ): Promise<ValidationError[] | null>;
  getTemplatedDescription(): string;
  getKey(): string;
  constructImpl(params: any[]): FormValidator;
}

export abstract class FormValidatorBase implements FormValidator {
  protected key: string;
  protected templatedDescription: string;
  protected params: any[] = [];

  async getErrors(
    formData: any,
    entry: DynamicFormEntry
  ): Promise<ValidationError[] | null> {
    if (this.isValid(formData,entry)) {
      return null;
    } else {
      return [
        new ValidationError(
          this.key,
          await this.resolveTemplatedDescription(
            this.templatedDescription,
            entry
          )
        ),
      ];
    }
  }

  getTemplatedDescription(): string {
    return this.templatedDescription;
  }

  getKey(): string {
    return this.key;
  }

  constructor(key: string, templatedDescription: string) {
    this.key = key;
    this.templatedDescription = templatedDescription;
  }

  private async resolveTemplatedDescription(
    templatedDescription: string,
    entry: DynamicFormEntry
  ) {
    templatedDescription = (await i18n.t(templatedDescription)) as string;
    return templatedDescription
      .replaceAll("%name%", i18n.tc(entry.name))
      .replaceAll("%value%", i18n.tc(entry.value));
  }

  abstract isValid(formData: any, entry: DynamicFormEntry): boolean;

  abstract constructImpl(params: any[]): FormValidator;
}
