import axios from "@/plugins/axios";
import { LocaleMessages } from "vue-i18n";
import store from "@/store";
import { Language } from "@/contracts/common/Language";

class TranslationService {
  public get supportedLanguages(): Language[] {
    // if (process.env.NODE_ENV !== "production") return ["NL", "FR", "EN", "NAN"];
    // return ["NL", "FR", "EN"];
    return ["NL", "FR", "EN", "NAN"];
  }

  getUserLanguage(): Language {
    let userLanguage =
      store.state.user.language ||
      navigator.language.split("-")[0].toUpperCase();

    if (!this.supportedLanguages.includes(userLanguage)) {
      userLanguage = "EN";
    }

    return userLanguage;
  }

  public async getTranslations(language: Language): Promise<LocaleMessages> {
    if (language === "NAN") return {};
    try {
      const response = await axios.get(
        `${store.state.appConfig.apiBaseUrl}api/translations/${language}`
      );
      if (!response?.data) return {};
      return response.data;
    } catch (reason) {
      return {};
    }
  }
}

export const translationService = new TranslationService();
