import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{staticClass:"pt-0",attrs:{"chips":_vm.chips,"clearable":_vm.clearable,"deletable-chips":_vm.deletableChips,"dense":_vm.dense,"disabled":_vm.disabled,"item-text":_vm.itemText,"item-value":_vm.itemValue,"items":_vm.autocompleteItems,"loading":_vm.loading,"multiple":_vm.multiple,"no-data-text":_vm.$t('general.no-data'),"value":_vm.autoCompleteValue,"search-input":_vm.intermediateValue,"error":_vm.errors.length > 0,"error-count":_vm.errors.length,"error-messages":_vm.errorMessages},on:{"input":_vm.update,"update:searchInput":function($event){_vm.intermediateValue=$event},"update:search-input":function($event){_vm.intermediateValue=$event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t(_vm.label)))]),(_vm.required)?_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" *")])]):_vm._e()]},proxy:true},(_vm.translate)?{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.itemText ? _vm.$t(item[_vm.itemText]) : _vm.$t(item))+" ")]}}:null,(_vm.translate)?{key:"selection",fn:function(ref){
var item = ref.item;
return [(_vm.chips)?_c(VChip,{staticClass:"mb-1"},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.itemText ? _vm.$t(item[_vm.itemText]) : _vm.$t(item))+" ")])]):_c('span',[_vm._v(" "+_vm._s(_vm.itemText ? _vm.$t(item[_vm.itemText]) : _vm.$t(item))+" ")])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }