





























import { Component, Prop, Vue } from "vue-property-decorator";
import Step from "@/contracts/common/Step";
import { DynamicFormEntryModel } from "@/app/dynamic-components/dynamic-components.model";

@Component
export default class DynamicComponentsEntryList extends Vue {
  @Prop({ default: [] })
  entries!: DynamicFormEntryModel[];

  @Prop({ default: null })
  title!: string;

  protected onClickEntry(entry: DynamicFormEntryModel) {
    this.$emit("onClickEntry", entry);
  }
}
