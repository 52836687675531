
















































import { Component, Vue } from "vue-property-decorator";
import ActivityDependendViewBase from "@/app/views/activity-dependend-view.base";
import OverviewComponent from "@/app/dynamic-components/overviews/overview-component.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import AcceptPoliciesInterceptor from "@/components/common/AcceptPoliciesInterceptor.vue";
import AuthenticationInterceptor from "@/components/common/AuthenticationInterceptor.vue";
import Loader from "@/components/common/Loader.vue";
import ActionTile from "@/components/common/ActionTile.vue";
import MyDocumentsView from "@/views/documents/MyDocumentsView.vue";
import {
  FormDialogControl,
  FormDialogModel,
} from "@/app/components/dialog/form-dialog.model";
import { RenderType } from "@/app/Types";
import { ExternalContext } from "@/app/contexts/externalContext";
import FormDialogComponent from "@/app/components/dialog/form-dialog-component.vue";
import ActivityDefinitionModel from "@/app/models/activity/activity-definition.model";
import { dossierServiceV2 } from "@/app/services/dossier.service";
import { OverviewControl, OverviewEvent } from "@/app/dynamic-components/overviews/overiew.model";
import { checkinService } from "@/app/services/checkin.service";
import { DynamicForm } from "@/app/dynamic-components/forms/dynamic-form.model";

@Component({
  components: {
    FormDialogComponent,
    MyDocumentsView,
    ActionTile,
    Loader,
    AuthenticationInterceptor,
    AcceptPoliciesInterceptor,
    PageHeader,
    OverviewComponent,
  },
})
export default class CheckinsOverviewView extends ActivityDependendViewBase {
  additionalFilters: { [key: string]: string } = {};
  additionalMetadata: { [key: string]: any } = {};
  createNewCheckinModel: FormDialogModel = {
    title: "Check-in for an activity",
    submitLabel: null,
    referenceType: "sd",
    referenceId: "sqd",
    destroyLabel: null,
    form: {
      id: "select",
      name: "",
      type: RenderType.ACORDEON,
      displayMode: "editable",
      displayModeRule: "",
      computedValue: "",
      metadata: {},
      children: [
        {
          id: "selectlocation",
          name: "Select location to Check-in",
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {
            SkipElementAsFormData: true,
          },
          children: [
            {
              id: "selectdossier",
              name: "Dossier",
              type: RenderType.SelectOverviewItem,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                path: "api/v2/dossiers?page=0&pageSize=100&filter=statecode:'Active'",
                itemText: "data.general_information.dossier_name",
                itemValue: "id",
                items: [],
                multiple: false,
              },
            },
            {
              id: "selectlots",
              name: "Lots",
              type: RenderType.SelectOverviewItem,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                path: "api/v2/dossiers/${data.form.selectdossier}/lots?page=0&pageSize=100&filter=statecode:'Active'",
                itemText: "name",
                itemValue: "id",
                items: [],
                multiple: true,
                disableSearchOnEntry: true,
              },
            },
            {
              id: "selectTechnics",
              name: "Technics",
              type: RenderType.SELECTV2,
              displayMode: "editable",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                items:
                  "data.external.dossierdefinition.detailContextData.techniqueExchangeDefinitions",
                itemText: "key",
                itemValue: "value",
                multiple: true,
              },
            },
          ],
        },
        {
          id: "exchangesteps",
          name: "Select Check-in activities",
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {},
          children: [
            {
              id: "checkins",
              name: "",
              type: RenderType.FormComponentMulticheckinSelector,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
          ],
        },
        {
          id: "checkin",
          name: "Check-in",
          type: RenderType.SECTION,
          displayMode: "editable",
          displayModeRule: "",
          computedValue: "",
          metadata: {},
          children: [
            {
              id: "expectedStartDate",
              name: "Estimated start of Check-in",
              type: RenderType.DateTimePicker,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                required: true,
                label: "Voorziene startdatum",
                editable: true,
              },
            },
            {
              id: "expectedEndDate",
              name: "Estimated Check-out",
              type: RenderType.DateTimePicker,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                required: true,
                label: "Voorziene einddatum",
                editable: true,
              },
            },
            {
              id: "description",
              name: "Executioner",
              type: RenderType.SelectPerson,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              metadata: {
                roleIds: [""],
              },
            },
            {
              id: "description",
              name: "More information",
              type: RenderType.TextArea,
              displayMode: "inherit",
              displayModeRule: "",
              computedValue: "",
              metadata: {},
            },
          ],
        },
      ],
    },
    externalContext: new ExternalContext(),
  };

  async onActivityUpdated(): Promise<any> {
    this.createCheckinComplete();
    const activityDefinition = this.$store.state.activity
      .currentActivityDefinition as ActivityDefinitionModel;
    this.createNewCheckinModel.externalContext.setData(
      "activitydefinition",
      activityDefinition
    );
    const detailDefinitionId =
      activityDefinition.dossiers.entries[0].dossierDetailDefinitionId;
    await dossierServiceV2
      .getDossierDetailDefinition(detailDefinitionId)
      .then((def) => {
        if (def) {
          this.createNewCheckinModel.externalContext.setData(
            "dossierdefinition",
            def
          );
        }
      });
    return;
  }

  showCreateDialog() {
    this.createNewFormDialogControl.show();
  }

  private createNewFormDialogControl!: FormDialogControl;
  recieveCreateNewFormDialogControl(control: FormDialogControl) {
    this.createNewFormDialogControl = control;
    this.createNewFormDialogControl.submitChanges = this.createNewPlanning;
  }

  createNewPlanning(formData: any): Promise<boolean> {
    console.warn("create new planning", formData);
    return Promise.resolve(true);
  }

  private checkoutItem: any = null;
  public editPressed(overviewEvent: OverviewEvent) {
    console.log(overviewEvent);
    this.checkoutItem = overviewEvent.item
    this.checkinCompleteControl?.show(overviewEvent.item.id, overviewEvent.item);
    setTimeout(()=> {
      this.overviewControl?.sendEvent({
        type: "CLEAR_ACTION",
        item: null,
        metadata: {},
      });
    },2000);

  }

  private overviewControl: OverviewControl | null = null;
  public recieveOverviewControl(overviewControl: OverviewControl) {
    this.overviewControl = overviewControl;
  }

  protected checkinCompleteModel: FormDialogModel | null = null;
  protected checkinCompleteControl: FormDialogControl | null = null;

  protected recieveCheckinCompleteControl(control: FormDialogControl) {
    this.checkinCompleteControl = control;
    control.submitChanges = this.completeCheckin;
    control.afterSubmitChanges = this.setExternalEntiity;
  }

  public async setExternalEntiity(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<any> {
    return true;
  }

  public completeCheckin(
    form: DynamicForm,
    formData: any,
    referenceId?: string
  ): Promise<boolean> {
    form.patchForm({
      actualEndDate: new Date(),
    });
    return checkinService
      .completeCheckin(form, this.checkoutItem.id, form.formData)
      .then((value) => {
        this.checkoutItem = null;
        this.overviewControl?.refresh();
        return true;
      });
  }

  private createCheckinComplete() {
    this.checkinCompleteModel = {
      externalContext: new ExternalContext(),
      referenceType: "",
      referenceId: "",
      title: this.$tc("checkin.complete.title"),
      submitLabel: this.$tc("checkin.complete.submit"),
      destroyLabel: null,
      form: {
        id: "root",
        name: "",
        type: RenderType.LinearSection,
        displayMode: "editable",
        displayModeRule: "",
        computedValue: "",
        validators: [],
        metadata: {
          direction: "vertical",
        },
        children: [
          {
            id: "expectedStartDate",
            name: "",
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "expectedEndDate",
            name: this.$tc("checkin.create.expectedEndDate"),
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "actualEndDate",
            name: "",
            type: RenderType.DateTimePicker,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "executorsContactIds",
            name: "checkin.create.persons",
            type: RenderType.SelectPerson,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              roleIds: ["d8bb43dc-6a21-ee11-9967-6045bd895bfb"],
              multiple: true,
            },
          },
          {
            id: "executorOrganisationId",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "markExecutorsAsBusy",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
          {
            id: "linkName",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "linkPath",
            name: "",
            type: RenderType.TEXTFIELD,
            displayMode: "hidden",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {
              isDynamicApiField: true,
            },
          },
          {
            id: "description",
            name: this.$tc("checkin.create.description"),
            type: RenderType.TextArea,
            displayMode: "editable",
            displayModeRule: "",
            computedValue: "",
            validators: [],
            metadata: {},
          },
        ],
      },
    };
  }
}
