








import { Component, Vue } from "vue-property-decorator";
import VueI18n from "vue-i18n";

@Component
export default class PrivacyPolicyView extends Vue {
  get privacyPolicy(): string {
    const privacyPolicy: VueI18n.TranslateResult = this.$t(
      "privacy-policy.text"
    ) as string;
    return privacyPolicy.replace(/\n/g, "<br>");
  }
}
