

























import DetailComponentBase from "@/app/dynamic-components/details/details-components/detail-component.base";
import { Component } from "vue-property-decorator";

export type SummaryMetaData = unknown;

@Component({})
export default class DetailComponentSummary extends DetailComponentBase<SummaryMetaData> {}
