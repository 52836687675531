



















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class AeAvatar extends Vue {
  @Prop({ default: null })
  name!: string;

  @Prop({ default: "md" })
  size!: "sm" | "md" | "lg";

  @Prop({ default: "primary" })
  color!: string;

  get nameAcronym(): string {
    if (this.name) {
      return this.name[0];
    }
    return "?";
  }

  get convertedName(): string {
    return this.name ?? "Unknown";
  }

  get convertedSize(): number {
    switch (this.size) {
      case "sm": {
        return 30;
      }
      case "md": {
        return 36;
      }
      case "lg": {
        return 48;
      }
      default: {
        return 36;
      }
    }
  }

  click(): void {
    this.$emit("click");
  }
}
